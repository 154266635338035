import React from 'react';
import { useSelector } from 'react-redux';

import { onChangeAction } from '../../../actions/product/action';

import UseOnChange from '../../../hook/UseOnChange';
import UIKit from "../../UIKit";

const PriceCard = () => {
    const { product } = useSelector((state) => state.product);

    const useOnChange = UseOnChange(onChangeAction);

    return (
        <UIKit.Card
            title={"가격"}
            cardOptions={"uk-card-default uk-card-style"}
            cardBodyOptions={""}
        >
            <div data-uk-grid>
                <div className="uk-width-1-1">
                    <div data-uk-grid>
                        <div className="uk-width-1-2 uk-width-1-4@m">
                            <div>
                                <label
                                    className="uk-form-label uk-text-meta"
                                    htmlFor="price"
                                >
                                    원가(KRW)
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        id="cost"
                                        name="cost"
                                        type="text"
                                        placeholder="원가 입력"
                                        value={Number(product.cost).toLocaleString()}
                                        onChange={useOnChange.dispatchAction}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-width-1-4@m">
                            <div>
                                <label
                                    className="uk-form-label uk-text-meta"
                                    htmlFor="totalPrice"
                                >
                                    판매가
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        id="price"
                                        name="price"
                                        type="text"
                                        placeholder="판매가"
                                        value={Number(product.price).toLocaleString()}
                                        onChange={useOnChange.dispatchAction}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-width-1-4@m">
                            <div>
                                <label
                                    className="uk-form-label uk-text-meta"
                                    htmlFor="point"
                                >
                                    적립금(KRW)
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        id="point"
                                        name="point"
                                        type="text"
                                        placeholder=" 입력"
                                        value={Number(product.point).toLocaleString()}
                                        onChange={useOnChange.dispatchAction}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <div>
                                <label
                                    className="uk-form-label uk-text-meta"
                                    htmlFor="isUsePoint"
                                >
                                    적립금 사용
                                </label>
                                <div className="uk-form-controls">
                                    <label className="uk-form-small uk-pointer uk-padding-remove-horizontal">
                                        <input
                                                className="uk-radio"
                                                type="radio"
                                                name="isUsePoint"
                                                value={1}
                                                checked={parseInt(product.isUsePoint, 10) === 1 ? true : false}
                                                onChange={useOnChange.dispatchAction}
                                            />가능
                                    </label>
                                    <label className="uk-form-small uk-pointer">
                                        <input
                                            className="uk-radio"
                                            type="radio"
                                            name="isUsePoint"
                                            value={0}
                                            checked={parseInt(product.isUsePoint, 10) === 0 ? true : false}
                                            onChange={useOnChange.dispatchAction}
                                        />불가
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UIKit.Card>
    );
};

export default PriceCard;
