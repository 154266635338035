import React, {useState, useCallback}  from 'react';

const useCheckboxForm = (initValue) => {
    const [value, setValue] = useState(initValue);

    const init = useCallback((data) => {
        const makeCheckboxs = {
            total: {
                checked: false,
                value: 0,
            },
            isShowDetailOption: false
        };
        if(data.data) {
            for(let a of data.data) {
                makeCheckboxs[a.seq] = {
                  value: a.seq,
                  checked: false
                }
            }
        } else {
            for(let a of data) {
                makeCheckboxs[a.seq] = {
                  value: a.seq,
                  checked: false
                }
            }
        }
        setValue({ ...value, ...makeCheckboxs });
    }, [value]);

    const onChangeCheckboxAll = useCallback(() => {
        const keys = Object.keys(value);
        value.total.checked = !value.total.checked;
        for(let key of keys) {
            if(key === 'isShowDetailOption') {
                continue;
            }
            value[key].checked = value.total.checked ? true : false;
        }
        value.total.value = value.total.checked === true ?  keys.length - 2 : 0;
        value.isShowDetailOption = value.total.checked;
        setValue({ ...value });
    }, [value]);

    const onChangeCheckbox = useCallback(seq => {
        const keys = Object.keys(value);
        let falseCount = 0;
        let trueCount = 0;

        value[seq].checked = !value[seq].checked;
        for(let key of keys) {
            if(key !== 'total' && key !== 'isShowDetailOption') {
                value[key].checked === false ? falseCount++ : trueCount++;
            }
        }

        falseCount === 0 ? value.total.checked = true : value.total.checked = false;
        if(trueCount > 0) {
            value.total.value = trueCount;
            value.isShowDetailOption = true;
        } else {
            value.isShowDetailOption = false;
        }

        setValue({ ...value });
    }, [value]);

    const remove = useCallback((seq) => {
        value.total.checked = false;
        value.total.value = 0;
        const seqs = seq.split(",");
        for(let seq of seqs) {
            delete value[seq];
        }
        setValue({...value});
    }, [value]);
    return { value, init, onChangeCheckboxAll, onChangeCheckbox, remove};
};

export default useCheckboxForm;
