import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import UIKit from "../UIKit";

const Header = ({ title, buttons = null }) => {
    return (
        <div>
            <header id="top-head" className="uk-position-fixed">
                <div className="uk-container uk-container-expand uk-background-primary uk-padding-horizontal uk-padding-medium-horizontal@m">
                    <nav className="uk-navbar">
                        <div className="uk-navbar-left">
                            <div className="uk-navbar-item uk-padding-remove-horizontal uk-light">
                                <Link
                                    className="uk-navbar-toggle uk-padding-remove-left uk-hidden@m"
                                    data-uk-toggle="target:#offcanvas-nav"
                                    data-uk-navbar-toggle-icon
                                    to=''
                                ></Link>
                                <span className="uk-text-white uk-text-1_2">
                                    {title}
                                </span>
                            </div>

                            <ul className="uk-navbar-nav uk-visible@m"></ul>
                        </div>
                        <div className="uk-navbar-right">
                            <div className="uk-navbar-item uk-padding-remove-horizontal uk-right">
                                {buttons &&
                                    buttons.length > 0 &&
                                    buttons.map((button, index) => (
                                        <UIKit.Button
                                            key={index}
                                            buttonOptions={button.buttonOptions}
                                            buttonName={button.buttonName}
                                            onClickAction={button.onClickAction}
                                            toggle={button.toggle ? button.toggle : ''}
                                        />
                                    ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.string.isRequired
};

export default Header;
