import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Uikit from 'uikit';

import {
    addOptionAction,
    onChangeForOption,
    addOptionValueAction,
    removeOptionValueAction,
    onChangeEssentialAction,
    removeOptionAction,
    changeOptionListAction,
    removeSkuAction,
    loadAllSkusAction
} from '../../../actions/product/action';

import UseOnChange from '../../../hook/UseOnChange';

import UIKit from "../../UIKit";

import SkuModal from './SkuModal';

const OptionCard = () => {
    const dispatch = useDispatch();
    const { skus, product, skusAll } = useSelector((state) => state.product);
    const { selectedBrand } = useSelector((state) => state.user);
    
    const [ onModalIndex, setOnModalIndex ] = useState(0);
    
    const useOnChange = UseOnChange(onChangeForOption);
    const useOnChangeForOptionList = UseOnChange(changeOptionListAction);

    useEffect(() => {		
        const brandSeq = selectedBrand === 'all' ? product.brand_seq : selectedBrand;
        const search = {
            dateSelect: "all",
            searchType: 'brand_seq',
            searchValue: brandSeq,
        };

        let stringifyedSearch = JSON.stringify(search);
        stringifyedSearch = btoa(stringifyedSearch);
        const searchQuery = `search=${stringifyedSearch}`;

        dispatch(loadAllSkusAction(searchQuery));
    }, [skus]);
    
    const optionListSetting = (keys, isEssential) => {
        let optJson = product.option.filter(function(element){
            return element.status && element.essential && element.name!== "" && element.value.length > 0;
        });

        let rtnData = [];
        keys.map((key, index) => {
            let resultJson=[];
            product.option.map((option, index) => {

                let searchJson = option.value.filter(function(element){
                    return element.status && element.key == key;
                });

                if(searchJson.length > 0){
                    if(isEssential){
                        if(option.essential){
                            resultJson = searchJson;
                        }
                    }else{
                        if(!option.essential && option.status){
                            resultJson = [option.name, searchJson[0].name];
                        }
                    }
                }
            });
            if(resultJson && resultJson.length>0){
                if(isEssential){
                    rtnData.push(<td key={index}>{resultJson[0].name}</td>);
                }else{
                    rtnData.push(
                        <Fragment key={0}>
                            <td >{resultJson[0]}</td>
                            <td key={1}>{resultJson[1]}</td>
                        </Fragment>
                    );
                }
            }
        });
        if(isEssential){
            if(rtnData.length != optJson.length){
                rtnData = [];
            }
        }else{
            if(keys.length != 1){
                rtnData = [];
            }
        }
        return rtnData;
    };

    const getSkuInput = useCallback((optionList, index) => {
        let skuList = [];
        if(skusAll) {
            for(let sku of skusAll) {
                if(optionList.sku) {
                    for(let optionSku of optionList.sku) {
                        if(optionSku === sku.seq) {
                            skuList.push(sku.name);
                        }
                    }
                }
            }
        }
        return (
            <input
                type="text"
                className="uk-input uk-form-small uk-hidden"
                readOnly
                data-uk-toggle={`target: #skuModal`}
                value={skuList.join(",")}
            />
        );
    }, [skusAll]);

    const getPTag = useCallback((optionList, modalIndex) => {
        let skuList = [];
		if(skusAll) {
            for(let sku of skusAll) {
                if(optionList.sku) {
                    for(let optionSku of optionList.sku) {
                        if(optionSku === sku.seq) {
                            skuList.push(sku.name);
                        }
                    }
                }
            }
        }
		skuList.sort(function(a,b){
			return a["seq"]-b["seq"];
		});
        return (

            <div
                className="uk-input uk-width-1-1 uk-height-auto uk-height-min-30" 
                onClick={(e) => skuModalSetting(e, modalIndex)}
            >
                {
                    skuList.map((sku, index) => (
                        <span
                            className="uk-background-muted uk-padding-7-left uk-padding-7-right uk-margin-8-right uk-position-z-index-2 uk-border-d9d9d9"
                            onClick={(e) => e.stopPropagation()}
                            key={index}
                        >
                            {sku}
                            <Link
                                to="#"
                                data-uk-close
                                className="uk-close-small uk-margin-3-right uk-margin-3-left"
                                onClick={(e) => removeSku(e, modalIndex, index)}
                            />
                        </span>
                    ))
                }
            </div>
        );
    }, [skusAll]);

    const skuModalSetting = useCallback((e, modalIndex) => {
        // 이벤트 버블링 이슈
        // 부모에게 이벤트 전파를 방지
        e.stopPropagation();

        setOnModalIndex(modalIndex);
        showSkuModal();
    }, []);

    const showSkuModal = useCallback(() => {
        Uikit.modal('#skuModal').show();
    }, []);

    const removeSku = useCallback((e, modalIndex, targetIndex) => {
        // 이벤트 버블링 이슈
        // 부모에게 이벤트 전파를 방지
        e.stopPropagation();
        dispatch(removeSkuAction(modalIndex, targetIndex));
    }, []);

    const addOption = useCallback(() => {
        dispatch(addOptionAction());
    }, []);

    const onKeyDown = useCallback((e, parentIndex, childIndex = -1) => {
        if(e.keyCode === 13) {
            dispatch(addOptionValueAction(parentIndex, childIndex, e.target.value));
            e.target.value = "";
        }
    }, []);

    const removeOptionValue = useCallback((parentIndex, childIndex) => {
        dispatch(removeOptionValueAction(parentIndex, childIndex));
    }, []);

    const onChangeEssential = useCallback((e, parentIndex) => {
        if(window.confirm('옵션 상세 내역이 초기화 됩니다.\n계속 진행하시겠습니까?')){
            dispatch(onChangeEssentialAction(parentIndex))
        }
    }, []);

    const removeOption = useCallback((parentIndex) => {
        dispatch(removeOptionAction(parentIndex));
    }, []);

    
    return (
        <UIKit.Div classOptions={"uk-width-1-1"}>
            <UIKit.Card
                title={"옵션"}
                cardOptions={"uk-card-default uk-card-style"}
                cardBodyOptions={""}
            >
                <div data-uk-grid>
                    {
                        product.option &&
                                product.option.map((option,parentIndex) => (
                                    option.status ?
                                    (
                                        <div className="uk-width-1-1" key={parentIndex}>
                                            <div className="uk-position-relative" data-uk-grid>
                                                <div className='uk-width-medium'>
                                                    <label className="uk-form-label uk-text-meta uk-text-0_8" htmlFor={`optionName${parentIndex}`}>옵션명</label>
                                                    <div className="uk-form-controls">
                                                        <input
                                                            className="uk-input uk-form-small"
                                                            id={`optionName${parentIndex}`}
                                                            name={`optionName${parentIndex}`}
                                                            type="text"
                                                            placeholder="옵션명"
                                                            value={option.name}
                                                            onChange={(e) => useOnChange.dispatchHaveIndexAction("optionName", parentIndex, "", e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='uk-width-expand'>
                                                    <label className="uk-form-label uk-text-meta uk-text-0_8" htmlFor={`optionName${parentIndex}Value`}>옵션값</label>
                                                    <div className="uk-form-controls">
                                                        <div data-uk-grid>
                                                            {
                                                                option.value && option.value.length > 0 &&
                                                                        option.value.map((value,childIndex) => (
                                                                            value.status ? (
                                                                                <div className="uk-position-relative uk-width-auto optionInput" key={childIndex}>
                                                                                    <input
                                                                                        className='uk-input uk-form-small uk-background-muted uk-padding-xsmall-left uk-padding-15-right uk-width-110'
                                                                                        type='text'
                                                                                        value={value.name}
                                                                                        onChange={(e) => useOnChange.dispatchHaveIndexAction("optionValue", parentIndex, childIndex, e)}
                                                                                    />
                                                                                    <Link
                                                                                        to="#"
                                                                                        data-uk-close
                                                                                        className="uk-close-small uk-position-absolute uk-position-center-right uk-margin-3-right"
                                                                                        onClick={() => removeOptionValue(parentIndex, childIndex)}
                                                                                    />
                                                                                </div>
                                                                            ) : ""
                                                                        ))
                                                            }
                                                        </div>
                                                        <div className={option.value && option.value.length > 0 ? "uk-margin-xsmall-top" : ""}>
                                                            <input
                                                                id={`optionName${parentIndex}Value`}
                                                                className="uk-input uk-form-small"
                                                                type="text"
                                                                placeholder="옵션값 입력"
                                                                onKeyDown={(e) => onKeyDown(e, parentIndex)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-small'>
                                                    <label className="uk-form-label uk-text-meta uk-text-0_8">필수여부</label>
                                                    <div className="uk-form-controls">
                                                        <input
                                                            type='checkbox'
                                                            name={`essential${parentIndex}`}
                                                            className="uk-checkbox"
                                                            id={`essential${parentIndex}`}
                                                            checked={option.essential}
                                                            onChange={(e) => onChangeEssential(e, parentIndex)}
                                                        />
                                                        <label className="uk-pointer" htmlFor={`essential${parentIndex}`}>필수</label>
                                                    </div>
                                                </div>
                                                <div className='uk-width-auto'>
                                                    <Link
                                                        to="#"
                                                        data-uk-close
                                                        className="uk-position-absolute uk-position-center-right uk-margin-3-right"
                                                        onClick={() => removeOption(parentIndex)}
                                                        data-uk-tooltip="옵션 삭제"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    ""
                                ))
                    }
                    <div className="uk-clearfix uk-width-1-1">
                        <div className="uk-float-left">
                            <button
                                className='uk-button uk-button-default uk-button-small'
                                type='button'
                                onClick={addOption}
                            >
                                옵션 추가
                            </button>
                        </div>
                    </div>
                    <div className='uk-width-1-1'>
                        <div className="uk-border uk-border-gray2">
                            {
                                product.optionList && (
                                    <div className="uk-padding-horizontal">
                                        <p className="uk-margin-top uk-margin-remove-bottom uk-text-0_9">※필수옵션</p>
                                        <table
                                            className={
                                                "uk-table uk-table-small uk-table-hover uk-table-divider uk-table-responsive uk-table-middle uk-margin-xsmall-top uk-text-0_8"
                                            }
                                        >
                                            <thead>
                                                <tr>
                                                    {
                                                        product.option.map((_, index) =>
                                                            product.option[index].essential && product.option[index].value.length>0 && product.option[index].status ?
                                                            (
                                                                <th key={index}>{product.option[index].name}</th>
                                                            )
                                                            : null
                                                        )
                                                    }
                                                    <th>옵션가격</th>
                                                    <th className="uk-width-large">sku</th>
                                                    <th>옵션상태</th>
                                                </tr>
                                            </thead>
                                            <tbody className="uk-text-0_5">
                                                {
                                                    product.optionList.map((optionList, index) => {
                                                        if(optionList.status !== 99) {
                                                            let keys = optionList.key.toString().split('_');
                                                            let optionListData = optionListSetting(keys, true);
                                                            return optionListData && optionListData.length > 0 &&
                                                                <tr key={index}>
                                                                    {optionListData}
                                                                    <td>
                                                                        <input
                                                                            type='text'
                                                                            value={Number(optionList.price).toLocaleString()}
                                                                            className="uk-input uk-form-small"
                                                                            onChange={(e) => useOnChangeForOptionList.dispatchChangeOptionList('price', index, e)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            getPTag(optionList, index, `skuModal_assesntial_${index}`)
                                                                        }
                                                                        {
                                                                            getSkuInput(optionList, index, `skuModal_assesntial_${index}`)
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            className="uk-select uk-form-small"
                                                                            value={optionList.status}
                                                                            onChange={(e) => useOnChangeForOptionList.dispatchChangeOptionList('status', index, e)}
                                                                        >
                                                                            <option value={1}>판매중</option>
                                                                            <option value={2}>품절</option>
                                                                            <option value={0}>숨김</option>
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                        }

                                                    })
                                                }
                                                <tr className="noData uk-text-center">
                                                    <td colSpan="2">필수옵션이 없습니다.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            <hr/>
                            {
                                product.optionList && (
                                    <div className="uk-padding-horizontal">
                                        <p className="uk-margin-top uk-margin-remove-bottom uk-text-0_9">※선택옵션</p>
                                        <table
                                            className={
                                                "uk-table uk-table-small uk-table-hover uk-table-divider uk-table-responsive uk-table-middle uk-margin-xsmall-top uk-text-0_8"
                                            }
                                        >
                                            <thead>
                                                <tr>
                                                    <th>옵션명</th>
                                                    <th>옵션값</th>
                                                    <th>옵션가격</th>
                                                    <th className="uk-width-large">sku</th>
                                                    <th>옵션상태</th>
                                                </tr>
                                            </thead>
                                            <tbody className="uk-text-0_5">
                                                {
                                                    product.optionList.map((optionList, index) => {
                                                        if(optionList.status !== 99) {
                                                            let keys = optionList.key.toString().split('_');
                                                            let optionListData = optionListSetting(keys, false);
                                                            return (
                                                                optionListData && optionListData.length> 0 &&
                                                                    <tr key={index}>
                                                                        {optionListData}
                                                                        <td className="uk-width-1-1">
                                                                            <input
                                                                                type='text'
                                                                                value={Number(optionList.price).toLocaleString()}
                                                                                className="uk-input uk-form-small" onChange={(e) => useOnChangeForOptionList.dispatchChangeOptionList('price', index, e)}
                                                                            />
                                                                        </td>
                                                                        <td className="uk-width-1-1">
                                                                            {
                                                                                getPTag(optionList, index, `skuModal_not_assential_${index}`)
                                                                            }
                                                                            {
                                                                                getSkuInput(optionList, index, `skuModal_not_assential_${index}`)
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <select className="uk-select uk-form-small" value={optionList.status} onChange={(e) => useOnChangeForOptionList.dispatchChangeOptionList('status', index, e)}>
                                                                                <option value={1}>판매중</option>
                                                                                <option value={0}>숨김</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                                <tr className="noData uk-text-center">
                                                    <td colSpan="5">선택옵션이 없습니다.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </UIKit.Card>
            <SkuModal modalIndex={onModalIndex} />
        </UIKit.Div>
    )
};


export default OptionCard;
