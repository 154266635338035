import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as Sentry from "@sentry/browser";

import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit.js";
import "uikit/dist/js/uikit-icons.min.js";
import "./resource/uikit-customize.css";
import "./resource/index.css";
import "./resource/design.css";
import "./resource/font.css";
import "./resource/mdi/css/materialdesignicons.min.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import configureStore from './stores/configureStore';
import {Provider} from 'react-redux';

// Sentry.init({
//     dsn: "https://8d5dcaf4d8a84b81b67249bcfbf60100@sentry.io/2809415"
// });

const store = configureStore();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));
