import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

const UseFormForDesign = (initValue, validation) => {
    const [value, setValue] = useState();

    const setInitValue = useCallback((data) => {
        let copyData = {...data};
        let targetData = {...value, ...copyData};
        setValue({...targetData});
    },[]);

    const onChange = useCallback((event) => {
        if(event.target) {
            let targetValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            let targetName = event.target.name;
            let numberCheck = /^[0-9]+$/;
            if (numberCheck.test(targetValue)) {
                targetValue = parseInt(targetValue);
            }
            let temp = _.cloneDeep(value);

            // 헤더 타입 변경할 경우 일부 데이터 수동 변경
            if(targetName == "template.header.type"){
                // 데스크탑 페이지 위치를 B에서는 전체 위치로 사용하므로, 사용할 수 없는 값은 디폴트 값 처리
                if(targetValue=='B' && (temp.template.header.position.desktop.page == 'center' || temp.template.header.position.desktop.page == 'none')){
                    _.set(temp, 'template.header.position.desktop.page', 'left');
                }
            }

            // 헤더 페이지설정 - 타입 변경 할 경우 href 강제 변경
            if(targetName.replace(/(\[(.*?)\])/g,'') == 'template.header.page.type'){
                let newTarget = targetName.replace('.type','') + '.href';
                let newValue = '';
                if(targetValue == 'product'){
                    newValue = '/product/all';
                }
                _.set(temp, newTarget, newValue);
            }

            _.set(temp, targetName, targetValue);
            setValue(temp);
        }
    }, [value]);

    const onChangeNameSet = useCallback((data, name) => {
        let temp = _.cloneDeep(value);
        
        _.set(temp, name, data);
        setValue(temp);
    }, [value]);

    const onClickPageArrAdd = useCallback((target) => {
        let temp = _.cloneDeep(value);
        let addArray = {href:'', name:'', type:''};

        temp.template.header.page.push(addArray);
        setValue(temp);

    },[value]);

    const onClickPageArrRemove = useCallback((index) => {
        let temp = _.cloneDeep(value);

        temp.template.header.page.splice(index,1);
        setValue(temp);
    },[value]);

    return {value, setInitValue, onChange, onChangeNameSet, onClickPageArrAdd, onClickPageArrRemove};
};

export default UseFormForDesign;