import { useState, useCallback } from 'react';

const UseFormImage = (initValue) => {
    const [value, setValue] = useState(initValue);

    const setInitValue = useCallback((data) => {
        setValue(data)
    },[]);

    const onChange = useCallback((data) => {
        if (data.target) {
            let targetName = data.target.name;
            let targetValue = data.target.value;
            if(targetName === 'phone') {
                targetValue = targetValue.replace(/[^0-9]/g,'');
                targetValue = targetValue.replace(/,/g, "");
            }
            setValue({...value, [targetName]: targetValue});
        } else {
            setValue({...value, ...data});
        }
    }, [value]);

    const onChangeImage = useCallback(e => {
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            setValue({...value, img: imageFile, preview: reader.result});
        };
    }, [value]);
    return { value, onChange, setInitValue, onChangeImage };
};

export default UseFormImage;
