import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_ORDERS_REQUEST,
    LOAD_ORDERS_SUCCESS,
    LOAD_ORDERS_FAILURE,
    LOAD_COUNTBYSTATUS_REQUEST,
    LOAD_COUNTBYSTATUS_SUCCESS,
    LOAD_COUNTBYSTATUS_FAILURE,
    DELIVERY_EXCEL_REQUEST,
    DELIVERY_EXCEL_SUCCESS,
    DELIVERY_EXCEL_FAILURE,
    PERCEL_REGIST_REQUEST,
    PERCEL_REGIST_SUCCESS,
    PERCEL_REGIST_FAILURE,
    LOAD_DELIVERY_EXCEL_LIST_REQUEST,
    LOAD_DELIVERY_EXCEL_LIST_SUCCESS,
    LOAD_DELIVERY_EXCEL_LIST_FAILURE,
    DOWNLOAD_DELIVERY_EXCEL_REQUEST,
    DOWNLOAD_DELIVERY_EXCEL_SUCCESS,
    DOWNLOAD_DELIVERY_EXCEL_FAILURE,
    DESTROY_DELIVERY_EXCEL_REQUEST,
    DESTROY_DELIVERY_EXCEL_SUCCESS,
    DESTROY_DELIVERY_EXCEL_FAILURE,
    UPDATE_ORDER_DETAIL_REQUEST,
    UPDATE_ORDER_DETAIL_SUCCESS,
    UPDATE_ORDER_DETAIL_FAILURE,
    LOAD_ORDER_REQUEST,
    LOAD_ORDER_SUCCESS,
    LOAD_ORDER_FAILURE,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAILURE,
    LOAD_PERCEL_REGISTS_REQUEST,
    LOAD_PERCEL_REGISTS_SUCCESS,
    LOAD_PERCEL_REGISTS_FAILURE,
} from '../reducers/order';

import {
    USER_ORDER_MEMO_UPDATE
} from '../actions/user/type';

import {
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_SUCCESS,
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_FAILURE,
    ORDER_MEMO_UPDATE,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_SUCCESS,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_FAILURE,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_SUCCESS,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_FAILURE,
} from '../actions/order/type';

const getMe = state => {
    return state.user.me;
}

function loadOrdersAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `admin/orders${query}`
  } else {
      endPoint = "admin/orders?page=1&status=2";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadOrders(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadOrdersAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ORDERS_SUCCESS,
      data: result.data,
    });

    yield put({
        type: LOAD_COUNTBYSTATUS_REQUEST,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ORDERS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadCountByStatusAPI(user) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get('orders/countByStatus', {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadCountByStatus(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadCountByStatusAPI, user);
    yield put({
      // put은 dispatch 동일
      type: LOAD_COUNTBYSTATUS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_COUNTBYSTATUS_FAILURE,
      error: e.response.data,
    });
  }
}

function deliveryExcelAPI(user) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get('/orders/deliveryExcel', {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* deliveryExcel(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(deliveryExcelAPI, user);
    yield put({
      // put은 dispatch 동일
      type: DELIVERY_EXCEL_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DELIVERY_EXCEL_FAILURE,
      error: e.response.data,
    });
  }
}


function percelRegistAPI(user, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/orders/percelRegist', formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* percelRegist(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(percelRegistAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: PERCEL_REGIST_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: PERCEL_REGIST_FAILURE,
      error: e.response.data,
    });
  }
}

function loadDeliveryExcelListAPI(user) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get('/orders/deliveryExcels', {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadDeliveryExcelList(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadDeliveryExcelListAPI, user);
    yield put({
      // put은 dispatch 동일
      type: LOAD_DELIVERY_EXCEL_LIST_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_DELIVERY_EXCEL_LIST_FAILURE,
      error: e.response.data,
    });
  }
}

function downloadDeliveryExcelAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`/orders/deliveryExcel/${query}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* downloadDeliveryExcel(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(downloadDeliveryExcelAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: DOWNLOAD_DELIVERY_EXCEL_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DOWNLOAD_DELIVERY_EXCEL_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyDeliveryExcelAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.delete(`/orders/deliveryExcel/${query}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyDeliveryExcel(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(destroyDeliveryExcelAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: DESTROY_DELIVERY_EXCEL_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_DELIVERY_EXCEL_FAILURE,
      error: e.response.data,
    });
  }
}

function updateOrderDetailAPI(user,query, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(query, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateOrderDetail(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(updateOrderDetailAPI, user, action.query, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_ORDER_DETAIL_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_ORDER_DETAIL_FAILURE,
      error: e.response.data,
    });
  }
}

function loadOrderAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.get(`admin/orders/order/getOrder${query}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadOrder(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadOrderAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ORDER_SUCCESS,
      data: result.data,
    });

  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ORDER_FAILURE,
      error: e.response.data,
    });
  }
}

function updateOrderAPI(user, orderSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`orders/updateOrder/${orderSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateOrder(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(updateOrderAPI, user, action.orderSeq, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_ORDER_SUCCESS,
      data: result.data,
      orderSeq: action.orderSeq,
      memo: action.data.get("memo"),
    });

    if(action.isUserModal === 1) {
        yield put({
            type: USER_ORDER_MEMO_UPDATE,
            orderSeq: action.orderSeq,
            memo: action.data.get("memo"),
        });
    }

    if(action.isUserModal === 2) {
        yield put({
            type: ORDER_MEMO_UPDATE,
            memo: action.data.get("memo"),
        });
    }
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_ORDER_FAILURE,
      error: e.response.data,
    });
  }
}

function loadPercelRegistsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.get(`percelRegists?${query}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadPercelRegists(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadPercelRegistsAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_PERCEL_REGISTS_SUCCESS,
      data: result.data,
    });

  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PERCEL_REGISTS_FAILURE,
      error: e.response.data,
    });
  }
}

function updateManagerChangeOrderDetailStatusAPI(user, orderDetailSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`admin/orders/details/${orderDetailSeq}/managerChangeOrderDetailStatus`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateManagerChangeOrderDetailStatus(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(updateManagerChangeOrderDetailStatusAPI, user, action.orderDetailSeq, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_FAILURE,
      error: e.response.data,
    });
  }
}

function updateManagerChangeOrderBasedStatusAPI(user, orderSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`admin/orders/${orderSeq}/managerChangeOrderBasedStatus`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateManagerChangeOrderBasedStatus(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(updateManagerChangeOrderBasedStatusAPI, user, action.orderSeq, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_FAILURE,
      error: e.response.data,
    });
  }
}

function updateManagerChangeOrderForceCancelAPI(user, orderSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`admin/orders/${orderSeq}/managerChangeOrderForceCancel`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateManagerChangeOrderForceCancel(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(updateManagerChangeOrderForceCancelAPI, user, action.orderSeq);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_FAILURE,
      error: e.response.data,
    });
  }
}

function loadGetOrderDetailCountByStatusAPI(user, division) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`orders/details/getOrderDetailCountByStatus?division=${division}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadGetOrderDetailCountByStatus(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadGetOrderDetailCountByStatusAPI, user, action.division);
    yield put({
      // put은 dispatch 동일
      type: LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadOrders() {
  yield takeLatest(LOAD_ORDERS_REQUEST, loadOrders);
}

function* watchLoadCountByStatus() {
  yield takeEvery(LOAD_COUNTBYSTATUS_REQUEST, loadCountByStatus);
}

function* watchDeliveryExcel() {
  yield takeEvery(DELIVERY_EXCEL_REQUEST, deliveryExcel);
}

function* watchPercelRegist() {
  yield takeLatest(PERCEL_REGIST_REQUEST, percelRegist);
}

function* watchLoadDeliveryExcelList() {
  yield takeEvery(LOAD_DELIVERY_EXCEL_LIST_REQUEST, loadDeliveryExcelList);
}

function* watchDownLoadDeliveryExcel() {
  yield takeEvery(DOWNLOAD_DELIVERY_EXCEL_REQUEST, downloadDeliveryExcel);
}

function* watchDestroyDeliveryExcel() {
  yield takeEvery(DESTROY_DELIVERY_EXCEL_REQUEST, destroyDeliveryExcel);
}

function* watchUpdateOrderDetail() {
  yield takeEvery(UPDATE_ORDER_DETAIL_REQUEST, updateOrderDetail);
}

function* watchLoadOrder() {
  yield takeEvery(LOAD_ORDER_REQUEST, loadOrder);
}

function* watchUpdateOrder() {
  yield takeEvery(UPDATE_ORDER_REQUEST, updateOrder);
}

function* watchLoadPercelRegists() {
  yield takeEvery(LOAD_PERCEL_REGISTS_REQUEST, loadPercelRegists);
}

function* watchUpdateManagerChangeOrderDetailStatus() {
  yield takeEvery(UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST, updateManagerChangeOrderDetailStatus);
}

function* watchUpdateManagerChangeOrderBasedStatus() {
  yield takeEvery(UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST, updateManagerChangeOrderBasedStatus);
}

function* watchUpdateManagerChangeOrderForceCancel() {
  yield takeEvery(UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST, updateManagerChangeOrderForceCancel);
}

function* watchLoadGetOrderDetailCountByStatus() {
  yield takeEvery(LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST, loadGetOrderDetailCountByStatus);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadOrders),
      fork(watchLoadCountByStatus),
      fork(watchDeliveryExcel),
      fork(watchPercelRegist),
      fork(watchLoadDeliveryExcelList),
      fork(watchDownLoadDeliveryExcel),
      fork(watchDestroyDeliveryExcel),
      fork(watchUpdateOrderDetail),
      fork(watchLoadOrder),
      fork(watchUpdateOrder),
      fork(watchLoadPercelRegists),
      fork(watchUpdateManagerChangeOrderDetailStatus),
      fork(watchUpdateManagerChangeOrderBasedStatus),
      fork(watchUpdateManagerChangeOrderForceCancel),
      fork(watchLoadGetOrderDetailCountByStatus),
  ]);
}
