import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";
import Edit from "./Edit";
import { useDispatch } from 'react-redux';
import { loadUserAction } from '../../../reducers/user';

const OrderLog = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}/all`} component={List} />
            <Route exact path={`${match.path}/new`} component={Edit} />
            <Route
                exact
                path={`${match.path}/all/:id`}
                component={Edit}
            />
        <Redirect from="*" to="/setting/orderLog" />
        </Switch>
    );
};

export default OrderLog;
