import produce from "immer";
import {
    UPDATE_DESIGN_REQUEST,
    UPDATE_DESIGN_SUCCESS,
    UPDATE_DESIGN_FAILURE,
    UPDATE_DESIGN_RESET,
    LOAD_DESIGN_REQUEST,
    LOAD_DESIGN_SUCCESS,
    LOAD_DESIGN_FAILURE,
} from '../actions/design/type';

export const initialState = {
    design: null,
    brand: null,
    loadDesignDone: false,
    loadDesignError: null,
    loadDesignLoading: false,
    updateDesign: null,
    updateDesignDone: false,
    updateDesignError: null,
    updateDesignLoading: false,
};

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case UPDATE_DESIGN_RESET: {
                draft.updateDesignLoading = false;
                draft.updateDesignDone = false;
                draft.updateDesign = null;
                break;
            }
            case UPDATE_DESIGN_REQUEST: {
                draft.updateDesignLoading = true;
                draft.updateDesignDone = false;
                draft.updateDesignError = null;
                break;
            }
            case UPDATE_DESIGN_SUCCESS: {
                draft.updateDesign = action.data;
                draft.updateDesignLoading = false;
                draft.updateDesignDone = true;
                break;
            }
            case UPDATE_DESIGN_FAILURE: {
                draft.updateDesignLoading = false;
                draft.updateDesignError = action.error;
                break;
            }
            case LOAD_DESIGN_REQUEST: {
                draft.loadDesignLoading = true;
                draft.loadDesignDone = false;
                draft.loadDesignError = null;
                break;
            }
            case LOAD_DESIGN_SUCCESS: {
                draft.design = action.data.design;
                draft.brand = action.data;
                draft.loadDesignLoading = false;
                draft.loadDesignDone = true;
                break;
            }
            case LOAD_DESIGN_FAILURE: {
                draft.loadDesignLoading = false;
                draft.loadDesignError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
