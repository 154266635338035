import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIKIT from "uikit";

import { loadUserAction, userInitAction } from '../../../reducers/user';

import Info from './Info';
import Loader from "../Loader";
import OrderInfo from '../Order/Info';

const UserModal = ({ openUserModal, setOpenUserModal, userSeq=""}) => {
    const dispatch = useDispatch();
    const { user, updateUserDone, updateUser, loadUserDone, loadUserError } = useSelector((state) => state.user);

    useEffect(() => {
        if(openUserModal) {
            if(userSeq) {
                dispatch(loadUserAction(userSeq));
            } else {
                UIKIT.modal('#userModal').hide();
                setOpenUserModal(false);
                alert(`연동되지 않은 유저입니다.`);
            }
        }
    }, [openUserModal]);

    useEffect(() => {
        if(openUserModal && loadUserDone) {
            UIKIT.modal('#userModal').show();

            setOpenUserModal(false);
        }

        if(openUserModal && loadUserError) {
            alert(loadUserError.msg);
            setOpenUserModal(false);
        }
    }, [loadUserDone, loadUserError]);

    useEffect(() => {
        if(updateUserDone) {
            alert(updateUser.data.msg);
            dispatch(userInitAction());
        }
    }, [updateUserDone]);

    useEffect(() => {
        return () => {
            dispatch(userInitAction());
        }
    }, []);

    return (
        <div id="userModal" className="uk-modal-container uk-position-z-index-9999999" data-uk-modal="container:false;">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default uk-close" type="button" data-uk-close onClick={() => setOpenUserModal(false)}></button>
                <div className="uk-modal-header uk-padding-small-horizontal uk-padding-horizontal@m">회원 정보</div>
                <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                    <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-3@m">
                            <Info />
                        </div>
                        <div className="uk-width-1-1 uk-width-2-3@m">
                            <OrderInfo isUserModal={1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModal;
