import React, { useCallback, useState, } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DesignEdit from './Design';

const Design = ({ match }) => {
    return (
        <Switch>
            <Route exact path={match.path} component={DesignEdit} />
        </Switch>
    );
};

export default Design;