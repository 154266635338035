import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Uikit from 'uikit';
import { addSkuAction, loadSkusAction, updateSkuResetAction, updateSkuAction } from '../../../actions/product/action';

import Pagination from "../Pagination";
import Loader  from '../Loader';

const SkuModal = ({
	modalIndex,
}) => {
	const dispatch = useDispatch();
	const { skus, product, loadSkusLoading, createSkuDone, updateSkuError} = useSelector((state) => state.product);
	const { selectedBrand } = useSelector((state) => state.user);

	const [skusArr, setSkusArr] = useState(null);

	const [searchValue, setSearchValue] = useState("");
	const [searchParameter, setSearchParameter] = useState("skus");

	const [modalMode, setModalMode] = useState("");
	const [submitSeq, setSubmitSeq] = useState("");
	const [submitName, setSubmitName] = useState("");
	const [submitMemo, setSubmitMemo] = useState("");

	useEffect(() => {
		reSearch();
		return () => {
			setSearchValue('');
			setSkusArr('');
			setSearchParameter('skus');
		};
	},[modalIndex]);
	
	useEffect(() => {
		setSkusArr(skus);
	},[skus]);

	useEffect(() => {
		if(updateSkuError) {
			alert(updateSkuError.msg);
		}
	}, [updateSkuError]);

	useEffect(() => {
		if(createSkuDone){
			dispatch(updateSkuResetAction());
			reSearch();
		}
	}, [createSkuDone]);
	
	
	const reSearch = useCallback(() => {
		const brandSeq = selectedBrand === 'all' ? product.brand_seq : selectedBrand;
		const search = {
			dateSelect: "all",
			searchType: 'brand_seq',
			searchValue: brandSeq,
		};
		let stringifyedSearch = JSON.stringify(search);
		stringifyedSearch = btoa(stringifyedSearch);
		getSkus(stringifyedSearch);
	},[]);

	const getSkus = useCallback(async(search = "", page = 1) => {
		let endPoint = 'skus';
		endPoint = `search=${search}&page=${page}`;
		dispatch(loadSkusAction(endPoint));
	}, []);
	
	const onClickSku = useCallback((modalIndex, skuSeq, skuName) => {
		dispatch(addSkuAction(modalIndex, skuSeq, skuName));
	}, [product]);

	const onSubmitSkuSearch = useCallback(async(searchValue) => {
		const brandSeq = selectedBrand === 'all' ? product.brand_seq : selectedBrand;
		const search = {
			dateSelect: "all",
			searchType: 'nameOrMemo',
			searchValue: encodeURIComponent(searchValue),
			searchs: [
				{
					searchType: 'brand_seq',
					searchValue: brandSeq,
				}
			]
		};
		let stringifyedSearch = JSON.stringify(search);
		stringifyedSearch = btoa(stringifyedSearch);
		setSearchParameter(stringifyedSearch);
		getSkus(stringifyedSearch);
	});

	const onClickSkuPageChange = useCallback((index) => {
		if(searchParameter == "skus"){
			const brandSeq = selectedBrand === 'all' ? product.brand_seq : selectedBrand;
			const search = {
				dateSelect: "all",
				searchType: 'brand_seq',
				searchValue: brandSeq,
			};
			let stringifyedSearch = JSON.stringify(search);
			stringifyedSearch = btoa(stringifyedSearch);
			getSkus(stringifyedSearch, index);
		}else{
			getSkus(searchParameter, index);
		}
	}, [searchParameter]);

	const onSkuSubmitModal = useCallback((e, mode)=>{
		setModalMode(mode);		
		if(mode == "add"){
			setSubmitSeq("");
			setSubmitName("");
			setSubmitMemo("");
		}
		Uikit.modal("#SkuSubmitFormModal").show();
	}, []);

	const onSkuModify = useCallback((e, mode, seq, name, memo)=>{
		e.stopPropagation();

		setSubmitSeq(seq);
		setSubmitName(name);
		setSubmitMemo(memo);
		onSkuSubmitModal(e, mode);
	}, []);

	const getUpdatesku = useCallback((seq, name, memo) => {
		const brandSeq = selectedBrand === 'all' ? product.brand_seq : selectedBrand;
		const sendData = {
			brand_seq: brandSeq,
			name: name,
			memo: memo,
		};
		dispatch(updateSkuAction(seq, sendData));
		Uikit.modal("#SkuSubmitFormModal").hide();
	}, []);

	return (
		<div>

			<div id="SkuSubmitFormModal" className="" data-uk-modal="stack: true">
				<div className="uk-modal-dialog uk-margin-medium-top">
					<button className="uk-modal-close-default" type="button" data-uk-close></button>
					<div className="uk-modal-header">
						<h2 className="uk-modal-title">SKU추가</h2>
					</div>
					<div className="uk-modal-body uk-margin-remove uk-padding-remove">
						<div className="uk-width-1-1 uk-grid uk-margin-remove-left uk-padding-vertical uk-border-bottom uk-border-gray2">
							<div className="uk-width-1-1">SKU코드</div>
							<div className="uk-width-1-1 uk-padding-medium-horizontal">
								<input
									type="text"
									className="uk-input"
									value={submitName}
									onChange={(e) => setSubmitName(e.target.value)}
									onKeyPress={(e) => {if(e.key === "Enter"){ getUpdatesku(submitSeq, submitName, submitMemo, modalMode) }}}
								/>
							</div>

							<div className="uk-width-1-1 uk-margin-top">메모</div>
							<div className="uk-width-1-1 uk-padding-medium-horizontal">
								<input
									type="text"
									className="uk-input"
									value={submitMemo ? submitMemo : ""}
									onChange={(e) => setSubmitMemo(e.target.value)}
									onKeyPress={(e) => {if(e.key === "Enter"){ getUpdatesku(submitSeq, submitName, submitMemo, modalMode) }}}
								/>
							</div>
						</div>
					</div>

					<div className="uk-modal-footer uk-text-right">
						<a
							className="uk-button uk-button-default uk-button-small uk-margin-small-right uk-modal-close"
						>
							취소
						</a>
						<button
							className="uk-button uk-button-primary uk-button-small"
							onClick={() => getUpdatesku(submitSeq, submitName, submitMemo, modalMode)}
						>
							{(modalMode == "add") ? "추가" : "수정"}
						</button>
					</div>
				</div>
            </div>

			<div id="skuModal" className="uk-padding-vertical" data-uk-modal="stack: true">
				
				<div className="uk-modal-dialog uk-margin-large-top uk-height-min-775">
					<button className="uk-modal-close-default" type="button" data-uk-close></button>
					<div className="uk-modal-header uk-border-none">
						<h2 className="uk-modal-title">SKU설정</h2>
					</div>
					
					<div className="uk-modal-body uk-margin-remove uk-padding-remove">
						<div className="uk-width-1-1 uk-grid uk-margin-remove-left uk-padding-vertical">
							<div className="uk-width-65p">
								<input
									type="text"
									className="uk-input"
									value={searchValue}
									onChange={(e) => setSearchValue(e.target.value)}
									onKeyPress={(e) => {if(e.key === "Enter"){ onSubmitSkuSearch(e.target.value) }}}
								/>
							</div>

							<div className="uk-width-expand">
								<button
									className="uk-button uk-height-40 uk-button-default uk-button-small uk-margin-small-right"
									onClick={() => onSubmitSkuSearch(searchValue)}
								>
									검색
								</button>
								<button
									className="uk-button uk-height-40 uk-button-primary uk-button-small"
									onClick={(e) => onSkuSubmitModal(e, "add")}
								>
									추가
								</button>
							</div>

						</div>
						{
							loadSkusLoading ? <Loader/> :
							<div className="uk-width-1-1">
								<table className="uk-table uk-table-small uk-table-hover uk-table-divider">
									<thead className="uk-text-center">
										<tr>
											<th>seq</th>
											<th>SKU코드</th>
											<th>메모</th>
											<th>수정</th>
										</tr>
									</thead>
									<tbody className="uk-text-center">
										{
											skusArr && skusArr.data.length > 0 ? skusArr.data.map((sku) => (
												<tr
													key={sku.seq}
													onClick={() => onClickSku(modalIndex, sku.seq, sku.name)}
													className="uk-pointer"
												>
													<td>{sku.seq}</td>
													<td>{sku.name}</td>
													<td>{sku.memo}</td>
													<td>
														<button
															className='uk-button uk-button-default uk-button-small'
															type='button'
															onClick={(e) => onSkuModify(e, "modify", sku.seq, sku.name, sku.memo)}
														>
															수정
														</button>
												</td>
												</tr>
											)) : (
												<tr>
													<td colSpan={4}>검색결과가 없습니다.</td>
												</tr>
											)
										}
									</tbody>
								</table>
								<div>
									<Pagination
										currentPage={skusArr && skusArr.current_page}
										pageLast={skusArr && skusArr.last_page}
										onClick={onClickSkuPageChange}
									/>
								</div>
							</div>
						}
					</div>

				</div>
			</div>
		</div>
	)
};

SkuModal.propTypes = {
	modalIndex: PropTypes.number.isRequired,
};

export default SkuModal;
