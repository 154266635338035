import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
  LOAD_PGINFOS_REQUEST,
  LOAD_PGINFOS_SUCCESS,
  LOAD_PGINFOS_FAILURE,
  LOAD_PGINFO_REQUEST,
  LOAD_PGINFO_SUCCESS,
  LOAD_PGINFO_FAILURE,
  UPDATE_PGINFO_REQUEST,
  UPDATE_PGINFO_SUCCESS,
  UPDATE_PGINFO_FAILURE,
} from '../reducers/pgInfo';

const getMe = state => {
  return state.user.me;
}

function loadPgInfosAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
    token = `bearer${user.token}`;
  } else {
    token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
    endPoint = `pgInfos${query}`
  } else {
    endPoint = "pgInfos";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
      Authorization : token,
      Brand: brand
    }
  });
}

function* loadPgInfos(action) {
  try {
    const user =  yield select(getMe);
    const result = yield call(loadPgInfosAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_PGINFOS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PGINFOS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadPgInfoAPI(user, pgInfoSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
    token = `bearer${user.token}`;
  } else {
    token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`/pgInfos/${pgInfoSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
      Authorization : token,
      Brand: brand
    }
  });
}

function* loadPgInfo(action) {
  try {
    const user =  yield select(getMe);
    const result = yield call(loadPgInfoAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_PGINFO_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PGINFO_FAILURE,
      error: e.response.data,
    });
  }
}

function updatePgInfoAPI(user, pgInfoSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
    token = `bearer${user.token}`;
  } else {
    token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/pgInfos/${pgInfoSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
      Authorization : token,
      Brand: brand
    }
  });
}

function* updatePgInfo(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updatePgInfoAPI, user, action.seq, action.data);
    if(result.data.token) {
      localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_PGINFO_SUCCESS,
      data: result.data,
      pgInfoSeq: action.pgInfoSeq,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_PGINFO_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadPgInfos() {
  yield takeEvery(LOAD_PGINFOS_REQUEST, loadPgInfos);
}

function* watchLoadPgInfo() {
  yield takeEvery(LOAD_PGINFO_REQUEST, loadPgInfo);
}

function* watchUpdatePgInfo() {
  yield takeLatest(UPDATE_PGINFO_REQUEST, updatePgInfo);
}

export default function* userSaga() {
  yield all([
    fork(watchLoadPgInfos),
    fork(watchLoadPgInfo),
    fork(watchUpdatePgInfo),
  ]);
}
