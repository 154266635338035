import React, { useState, useCallback } from 'react';
import { readFileAsync } from '../common';

const UseFormForProduct = (initValue, validation) => {
    const [value, setValue] = useState(initValue);

    const setInitValue = useCallback((data) => {
        const product = JSON.parse(JSON.stringify(data));
        product.optionListTemp = data.optionList;
        product.previewImages = data.img;
        if(product.optionList) {
            for(let option of product.optionList) {
                if(option.sku) {
                    option.sku = option.sku;
                } else {
                    option.sku = [];
                }

            }
        }
        setValue(product);
    },[]);

    const onChange = useCallback((data, editor="") => {
        if(editor=="jodit"){
            setValue({...value, content:data});
        }else if(data.target) {
            let targetValue = data.target.value;
            let targetName = data.target.name;
            if (targetName === 'price' || targetName === 'cost' || targetName === 'point') {
                if (targetValue) {
                    targetValue = targetValue.replace(/[^0-9]/g,'');
                    targetValue = targetValue.replace(/,/g, "");
                }
            }
            setValue({...value, [targetName]: targetValue, timestamp:new Date().getTime()});
        } else {
            setValue({...value, ...data, timestamp:new Date().getTime()});
        }
    }, [value]);

    const onChangeImage = useCallback(async(e) => {
        let files = e.target.files;
        let img = [...value.img];
        let previewImages = [...value.previewImages];
        for(let file of files) {
            const imageFile = file;
            const result = await readFileAsync(imageFile);
            previewImages.push(result);
            img.push(imageFile);
        }
        setValue({...value, img, previewImages});
    }, [value]);

    const onClickRemovePreviewImage = useCallback((index) => {
        let previewImages = [...value.previewImages];
        let img = [...value.img];
        previewImages.splice(index, 1);
        img.splice(index, 1);
        setValue({...value, img, previewImages});
    }, [value]);

    const onClickRemoveImagesFile = useCallback((index) => {
        value.img.splice(index, 1);
        setValue({ ...value });

    }, [value]);

    const addOption = useCallback(() => {
        value.option.push({name: '', value: [], essential: true, status: true});
        setValue({...value});
    },[value]);

    const onChangeOptionName = useCallback((e, index) => {
        value.option[index].name = e.target.value;
        setValue({...value});
    }, [value]);

    const onChangeEssential = useCallback((e, index) => {
        if(window.confirm('옵션 상세 내역이 초기화 됩니다.\n계속 진행하시겠습니까?')){
            value.option[index].essential = !value.option[index].essential;
            if(!value.option[index].essential){
                for (let optionValue of value.option[index].value) {
                    removeOptionListValue(optionValue.key, false);
                }
            }
            onClickOptionTotalResult();
            setValue({...value});
        }
    },[value]);

    const removeOption = useCallback((index) => {
        value.option[index].status = false;
        onClickOptionTotalResult();
    }, [value]);

    const addOptionValue = useCallback((index, value) => {
        value.option[index].value.push({ value });
        setValue({...value});
    }, [value]);

    const onChangeOptionValue = useCallback((e, parentIndex, childIndex) => {
        value.option[parentIndex].value[childIndex].name = e.target.value;
        setValue({...value});
    }, [value]);

    const removeOptionValue = useCallback((parentIndex, childIndex) => {
        removeOptionListValue(value.option[parentIndex].value[childIndex].key, false);
        value.option[parentIndex].value.splice(childIndex, 1);
        onClickOptionTotalResult();
    }, [value]);

    const onClickOptionTotalResult = useCallback(() => {
        let options = value.option;
        let origOptionList = value.optionList;
        value.optionList = [];
        let copyList = [];
        let i = 0;
        for (let option of options) {
            if(option.status){
                if (option.essential) {
                    if(option.value.length>0){
                        copyList.push(option);
                    }
                }else{
                    let optionValues = option.value;
                    for (let optionValue of optionValues) {
                        value.optionList.push({key: optionValue.key, price: value.price ? value.price : 0, status: 1, skus: []});
                    }
                    setValue({...value});
                }
            } else {
                if(!option.seq) {
                    options.splice(i, 1);
                }
            }
            i++;
        }
        if(copyList.length > 0) {
            RecursiveFor(copyList, 0, '');
        }

        value.optionList.map((obj, index) => {
            var searchJson = value.optionListTemp.filter(function(element){
                return (element.key == obj.key);
            });
            if(searchJson.length>0){
                value.optionList[index] = searchJson[0];
            }

            var searchJson2 = origOptionList.filter(function(element){
                return (element.key == obj.key);
            });
            if(searchJson2.length>0){
                value.optionList[index] = searchJson2[0];
            }
        });
    }, [value]);

    const onChangeOptionPrice = useCallback((e, index) => {
        var targetValue = e.target.value;
        targetValue = targetValue.replace(/[^0-9]/g,'');
        targetValue = targetValue.replace(/,/g, "");
        value.optionList[index].price = targetValue;
        setValue({...value});
    }, [value]);

    const onChangeOptionStatus = useCallback((e, index) => {
        value.optionList[index].status = e.target.value;
        setValue({...value});
    }, [value]);

    const removeOptionListValue = (key, isCorrect) => {
        var searchJson = value.optionList.filter(function(element){
            if(isCorrect){
                return (element.key == key);
            }else{
                return (element.key.indexOf("_"+key)>-1 || element.key.indexOf(key+"_")>-1);
            }
        });

        if(searchJson.length>0){
            for (let target of searchJson) {
                value.optionList.splice(value.optionList.indexOf(target), 1);
            }
        }
    }

    const RecursiveFor = (list, n, str) => {
        if(list.length <= n){
            var searchJson = value.optionList.filter(function(element){
                return element.key == str;
            });
            if(searchJson.length==0){
                let keys = str.split('_');
                keys.map((key, index) => {
                    removeOptionListValue(key, true);
                });
                value.optionList.push({key: str, price: value.price ? value.price : 0, status: 1, skus: []});
            }
            setValue({...value});

            return;
        }
        if (list[n].value) {
            for(let i = 0; i < list[n].value.length; i++){
                RecursiveFor(list, n+1, str === "" ? list[n].value[i].key : `${str}_${list[n].value[i].key}`);
            }
        }
    }

    const onKeyDown = useCallback((e, parentIndex, childIndex = -1) => {
        if(!value.price) {
            alert('판매가를 먼저 입력해주세요');
            return;
        }
        if(e.keyCode === 13) {
            if(childIndex === -1) {
                value.option[parentIndex].value.push({ name: e.target.value, key: new Date().getTime()+""});
                e.target.value = '';
                setValue({...value});
            }
            onClickOptionTotalResult();
        }
    }, [value]);

    const onClickSku = useCallback((modalIndex, skuSeq, skuName) => {
        if(!value.optionList[modalIndex].sku) {
            value.optionList[modalIndex].sku = [];
        }

        if(value.optionList[modalIndex].sku.indexOf(skuSeq) !== -1) {
            if(window.confirm('이미 추가된 seq입니다. 제거하시겠습니까?')) {
                const skuTargetIndex = value.optionList[modalIndex].sku.indexOf(skuSeq);
                value.optionList[modalIndex].sku.splice(skuTargetIndex, 1);
                setValue({...value});
            } else {
                return;
            }
        } else {
            value.optionList[modalIndex].sku.push(skuSeq);
            setValue({...value});
        }

    }, [value]);

    const onResetSku = useCallback(() => {
        for(let optionList of value.optionList) {
            optionList.sku = [];
        }

        setValue({ ...value });
    }, [value]);

    return {
        value,
        onChange,
        setInitValue,
        onChangeImage,
        onClickRemovePreviewImage,
        onClickRemoveImagesFile,
        addOption,
        onChangeOptionName,
        onChangeEssential,
        removeOption,
        addOptionValue,
        onChangeOptionValue,
        removeOptionValue,
        onClickOptionTotalResult,
        onChangeOptionPrice,
        onChangeOptionStatus,
        onKeyDown,
        onClickSku,
        onResetSku
    };
};

export default UseFormForProduct;
