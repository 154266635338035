import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import UIKIT from 'uikit';

import { percelRegistAction, loadPercelRegistsAction } from '../../../reducers/order';

import PercelRegistFormModal from './PercelRegistFormModal';
import PercelRegistTableStyle from './PercelRegistTableStyle';
import { getAgoDate } from '../../../common';

const PercelRegistModal = ({ openPercelRegistModal, setOpenPercelRegistModal }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {
        loadPercelRegistsDone,
        loadPercelRegistsError,
        percelRegists,
        percelRegistDone,
    } = useSelector((state) => state.order);

    useEffect(() => {
        if(openPercelRegistModal) {
            let search = {
                dateSelect: "all",
                searchType: "all",
                status: "all",
                regdate: getAgoDate(7)
            };
            search = JSON.stringify(search);
            const query = `per_page=5&search=${btoa(search)}`
            dispatch(loadPercelRegistsAction(query));
        }
    }, [openPercelRegistModal]);

    return (
        <div id="percelRegistModal" className="uk-modal-container uk-overflow-auto uk-position-z-index-9999999" data-uk-modal="container: false;">
            <div className="uk-modal-body uk-modal-dialog uk-width-800 uk-margin-auto-vertical uk-padding-xsmall-horizontal uk-padding-xsmall-top" data-uk-overflow-auto>
                <div className="uk-padding-small uk-height-300">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <div className="uk-width-1-1">
                        <h4 className="uk-margin-small-bottom">송장 일괄 등록</h4>
                    </div>
                    <hr className="uk-margin-small-top uk-border-gray" />
                    <PercelRegistFormModal />
                </div>
                <div>
                    <PercelRegistTableStyle />
                </div>
            </div>
        </div>
    )
};

export default PercelRegistModal;
