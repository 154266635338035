import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import { loadMeAction } from '../../../reducers/user';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import { useFormData } from '../../../hook';

import UIKit from "../../../Components/UIKit";
import ExcelConvertModal from '../../../Components/Widget/Review/ExcelConvertModal';
import ReviewListStyle from '../../../Components/Widget/Review/ListStyle';
import SearchHead from "../../../Components/Widget/SearchHead";
import SearchModal from '../../../Components/Widget/SearchModal';
import SearchForm from '../../../Components/Widget/SearchForm';

const initDatas = {
    useSearchComponents: {
        dateSearch: {
            isUse: true,
            title: '조회기간',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'regDate',
                    name: '등록일'
                }
            ]
        },
        informationSearch: {
            isUse: true,
            title: '구매평 정보',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'contentForAdmin',
                    name: '구매평 내용'
                }, {
                    value: 'productName',
                    name: '상품명'
                }, {
                    value: 'customWriter',
                    name: '작성자 아이디',
                }
            ]
        },
        statusSearch: {
            isUse: false,
            title: '사용여부'
        },
        starScoreSearch: {
            isUse: true,
            title: '별'
        },
        payTypeSearch: {
            isUse: true,
            title: '구매평 타입'
        },
    }
};

const List = () => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone, loadMeError } = useSelector((state) => state.review);

    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all',
        payType: 'all',
    });

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);


     return loadMeLoading ? null : (
        <div>
            <ExcelConvertModal />
            <div>
                <SearchHead modalId={"reviewSearchModal"}  />
                <SearchModal modalId={"reviewSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>

            </div>
            <div className="uk-margin-medium-top">
                <ReviewListStyle />
            </div>
        </div>
    );
};

export default List;
