import React, {} from 'react';
import _ from 'lodash';

const TextSetting = (props) => {
    return (
        <div className="uk-margin-medium-bottom">
            <div className="uk-position-relative uk-margin-small-bottom">
                <h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">기본텍스트</span></h4>
            </div>
            <div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>장바구니</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.cart"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.cart}
                      />
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>구매하기</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.purchase"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.purchase}
                      />
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>상품상세정보</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.productDetail"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.productDetail}
                      />
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>구매평</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.review"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.review}
                      />
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>구매평 점수</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.reviewScore"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.reviewScore}
                      />
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>상단 베스트 리뷰</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.bestReview"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.bestReview}
                      />
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>상품문의</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                      <input
                        type="text"
                        className="uk-input"
                        name="template.basic.text.qna"
                        onChange={props.data.onChange}
                        value={props.data.value.template.basic.text.qna}
                      />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TextSetting;