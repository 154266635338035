import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ActionLoader from "../../Widget/ActionLoader";
import Pagination from "../../Widget/Pagination";

const Type3 = ({ instance, isDispatch = false }) => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);

    useEffect(() => {
        if (isDispatch) {
            dispatch(instance.reducer.action("?" + instance.queryString));
        }
    }, [selectedBrand, instance.queryString]);

    const onClickPageChange = (index) => {
        dispatch(
            instance.reducer.action(
                "?" + instance.queryString + "&page=" + index
            )
        );
    };

    return instance.reducer.loading ? (
        <ActionLoader />
    ) : instance.reducer.done &&
      instance.reducer.obj &&
      instance.reducer.obj.data ? (
        instance.reducer.obj.data.length > 0 ? (
            <div>
                <table className="uk-table uk-table-divider uk-table-hover uk-table-small uk-table-middle uk-text-0_8">
                    <thead className="uk-text-center uk-text-1_1">
                        <tr>
                            <th className="uk-table-shrink uk-text-nowrap">
                                일자
                            </th>
                            <th className="uk-table-shrink uk-text-nowrap">
                                주문수
                            </th>
                            <th className="uk-table-shrink uk-text-nowrap">
                                매출액
                            </th>
                            <th className="uk-table-shrink uk-text-nowrap">
                                방문자
                            </th>
                            <th className="uk-table-shrink uk-text-nowrap">
                                가입
                            </th>
                            <th className="uk-table-shrink uk-text-nowrap">
                                문의
                            </th>
                            <th className="uk-table-shrink uk-text-nowrap">
                                후기
                            </th>
                        </tr>
                    </thead>
                    <tbody className="uk-text-center">
                        {instance.reducer.obj.data.map((val, idx) => (
                            <tr key={idx}>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {val.date}
                                </td>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {val.orderCount}건
                                </td>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {parseInt(
                                        val.totalPrice - val.cancelPrice,
                                        10
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {val.googleAnalyticsData.visitors}명
                                </td>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {val.userCount}명
                                </td>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {val.qnaCount}건
                                </td>
                                <td className="uk-table-shrink uk-text-nowrap">
                                    {val.reviewCount}건
                                </td>
                            </tr>
                        ))}

                        <tr>
                            <td className="uk-table-shrink uk-text-nowrap">
                                합계
                            </td>
                            <td className="uk-table-shrink uk-text-nowrap">
                                {instance.reducer.obj.total.totalOrderCount} 건
                            </td>
                            <td className="uk-table-shrink uk-text-nowrap">
                                {parseInt(instance.reducer.obj.total.totalPrice - instance.reducer.obj.total.totalCancelPrice, 10).toLocaleString()} 원
                            </td>
                            <td className="uk-table-shrink uk-text-nowrap">
                                {instance.reducer.obj.total.totalVisitors} 명
                            </td>
                            <td className="uk-table-shrink uk-text-nowrap">
                                {instance.reducer.obj.total.UserCount} 명
                            </td>
                            <td className="uk-table-shrink uk-text-nowrap">
                                {instance.reducer.obj.total.QnaCount} 건
                            </td>
                            <td className="uk-table-shrink uk-text-nowrap">
                                {instance.reducer.obj.total.ReviewCount} 건
                            </td>
                        </tr>
                    </tbody>
                </table>
                {instance.reducer.obj && (
                    <Pagination
                        currentPage={instance.reducer.obj.current_page}
                        pageLast={instance.reducer.obj.last_page}
                        onClick={onClickPageChange}
                    />
                )}
            </div>
        ) : (
            <div className="uk-text-0_8 uk-text-center">데이터가 없습니다.</div>
        )
    ) : (
        <div className="uk-text-0_8 uk-text-center">
            데이터 조회가 실패하였습니다.
        </div>
    );
};

export default Type3;
