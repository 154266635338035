import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_ORDERLOGS_REQUEST,
    LOAD_ORDERLOGS_SUCCESS,
    LOAD_ORDERLOGS_FAILURE,
    LOAD_ORDERLOG_REQUEST,
    LOAD_ORDERLOG_SUCCESS,
    LOAD_ORDERLOG_FAILURE,
} from '../reducers/log';

const getMe = state => {
    return state.user.me;
}

function loadOrderLogsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `orderLogs${query}`
  } else {
      endPoint = "orderLogs";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadOrderLogs(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadOrderLogsAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ORDERLOGS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ORDERLOGS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadOrderLogAPI(user, orderLogSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.get(`/orderLogs/${orderLogSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadOrderLog(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadOrderLogAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ORDERLOG_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ORDERLOG_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadOrderLogs() {
  yield takeEvery(LOAD_ORDERLOGS_REQUEST, loadOrderLogs);
}

function* watchLoadOrderLog() {
  yield takeEvery(LOAD_ORDERLOG_REQUEST, loadOrderLog);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadOrderLogs),
      fork(watchLoadOrderLog),
  ]);
}
