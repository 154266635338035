import {
    LOAD_DESIGN_REQUEST,
    UPDATE_DESIGN_REQUEST,
    UPDATE_DESIGN_RESET,
} from './type';

export const loadDesignAction = (brandSeq) => ({
    type: LOAD_DESIGN_REQUEST,
    data: brandSeq,
});

export const updateDesignAction = (brandSeq, data) => ({
    type: UPDATE_DESIGN_REQUEST,
    data,
    brandSeq,
});

export const updateDesignResetAction = (seq) => ({
    type: UPDATE_DESIGN_RESET,
});
