import produce from "immer";
import {
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_SUCCESS,
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_FAILURE,
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_RESET,
    ORDER_MEMO_UPDATE,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_SUCCESS,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_FAILURE,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_RESET,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_SUCCESS,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_FAILURE,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_RESET,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE,
} from '../actions/order/type';

export const initialState = {
    loadOrdersLoading: false,
    loadOrdersDone: false,
    loadOrdersError: null,
    orders: null,
    loadCountByStatusLoading: false,
    loadCountByStatusDone: false,
    loadCountByStatusError: null,
    countByStatus: null,
    deliveryExcelLoading: false,
    deliveryExcelDone: false,
    deliveryExcelError: null,
    deliveryExcel: null,
    percelRegistLoading: false,
    percelRegistDone: false,
    percelRegistError: null,
    percelRegist: null,
    loadDeliveryExcelListLoading: false,
    loadDeliveryExcelListDone: false,
    loadDeliveryExcelListError: null,
    deliveryExcelList: null,
    downloadDeliveryExcelLoading: false,
    downloadDeliveryExcelDone: false,
    downloadDeliveryExcelError: null,
    downloadDeliveryExcel: null,
    destroyDeliveryExcelLoading: false,
    destroyDeliveryExcelDone: false,
    destroyDeliveryExcelError: null,
    destroyDeliveryExcel: null,
    updateOrderDetailLoading: false,
    updateOrderDetailDone: false,
    updateOrderDetailError: null,
    updateOrderDetail: null,
    loadOrderLoading: false,
    loadOrderDone: false,
    loadOrderError: null,
    order: null,
    loadPercelRegistsLoading: false,
    loadPercelRegistsDone: false,
    loadPercelRegistsError: null,
    percelRegists: null,
    updateManagerChangeOrderDetailStatusLoading: false,
    updateManagerChangeOrderDetailStatusDone: false,
    updateManagerChangeOrderDetailStatusError: null,
    updateManagerChangeOrderDetailStatus: null,
    updateManagerChangeOrderBasedStatusLoading: false,
    updateManagerChangeOrderBasedStatusDone: false,
    updateManagerChangeOrderBasedStatusError: null,
    updateManagerChangeOrderBasedStatus: null,
    updateManagerChangeOrderForceCancelLoading: false,
    updateManagerChangeOrderForceCancelDone: false,
    updateManagerChangeOrderForceCancelError: null,
    updateManagerChangeOrderForceCancel: null,
    loadGetOrderDetailCountByStatusLoading: false,
    loadGetOrderDetailCountByStatusDone: false,
    loadGetOrderDetailCountByStatusError: null,
    loadGetOrderDetailCountByStatus: null,
};

export const LOAD_ORDER_REQUEST = "LOAD_ORDER_REQUEST";
export const LOAD_ORDER_SUCCESS = "LOAD_ORDER_SUCCESS";
export const LOAD_ORDER_FAILURE = "LOAD_ORDER_FAILURE";

export const LOAD_ORDERS_REQUEST = "LOAD_ORDERS_REQUEST";
export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const LOAD_ORDERS_FAILURE = "LOAD_ORDERS_FAILURE";
export const LOAD_ORDERS_RESET = "LOAD_ORDERS_RESET";

export const LOAD_COUNTBYSTATUS_REQUEST = "LOAD_COUNTBYSTATUS_REQUEST";
export const LOAD_COUNTBYSTATUS_SUCCESS = "LOAD_COUNTBYSTATUS_SUCCESS";
export const LOAD_COUNTBYSTATUS_FAILURE = "LOAD_COUNTBYSTATUS_FAILURE";

export const DELIVERY_EXCEL_REQUEST = "DELIVERY_EXCEL_REQUEST";
export const DELIVERY_EXCEL_SUCCESS = "DELIVERY_EXCEL_SUCCESS";
export const DELIVERY_EXCEL_FAILURE = "DELIVERY_EXCEL_FAILURE";
export const DELIVERY_EXCEL_RESET = "DELIVERY_EXCEL_RESET";

export const PERCEL_REGIST_REQUEST = "PERCEL_REGIST_REQUEST";
export const PERCEL_REGIST_SUCCESS = "PERCEL_REGIST_SUCCESS";
export const PERCEL_REGIST_FAILURE = "PERCEL_REGIST_FAILURE";

export const LOAD_DELIVERY_EXCEL_LIST_REQUEST = "LOAD_DELIVERY_EXCEL_LIST_REQUEST";
export const LOAD_DELIVERY_EXCEL_LIST_SUCCESS = "LOAD_DELIVERY_EXCEL_LIST_SUCCESS";
export const LOAD_DELIVERY_EXCEL_LIST_FAILURE = "LOAD_DELIVERY_EXCEL_LIST_FAILURE";

export const DOWNLOAD_DELIVERY_EXCEL_REQUEST = "DOWNLOAD_DELIVERY_EXCEL_REQUEST";
export const DOWNLOAD_DELIVERY_EXCEL_SUCCESS = "DOWNLOAD_DELIVERY_EXCEL_SUCCESS";
export const DOWNLOAD_DELIVERY_EXCEL_FAILURE = "DOWNLOAD_DELIVERY_EXCEL_FAILURE";
export const DOWNLOAD_DELIVERY_EXCEL_RESET = "DOWNLOAD_DELIVERY_EXCEL_RESET";

export const DESTROY_DELIVERY_EXCEL_REQUEST = "DESTROY_DELIVERY_EXCEL_REQUEST";
export const DESTROY_DELIVERY_EXCEL_SUCCESS = "DESTROY_DELIVERY_EXCEL_SUCCESS";
export const DESTROY_DELIVERY_EXCEL_FAILURE = "DESTROY_DELIVERY_EXCEL_FAILURE";

export const UPDATE_ORDER_DETAIL_REQUEST = "UPDATE_ORDER_DETAIL_REQUEST";
export const UPDATE_ORDER_DETAIL_SUCCESS = "UPDATE_ORDER_DETAIL_SUCCESS";
export const UPDATE_ORDER_DETAIL_FAILURE = "UPDATE_ORDER_DETAIL_FAILURE";
export const UPDATE_ORDER_DETAIL_RESET = "UPDATE_ORDER_DETAIL_RESET";

export const LOAD_DELIVERY_EXCEL_LIST_RESET = "LOAD_DELIVERY_EXCEL_LIST_RESET";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const LOAD_PERCEL_REGISTS_REQUEST = "LOAD_PERCEL_REGISTS_REQUEST";
export const LOAD_PERCEL_REGISTS_SUCCESS = "LOAD_PERCEL_REGISTS_SUCCESS";
export const LOAD_PERCEL_REGISTS_FAILURE = "LOAD_PERCEL_REGISTS_FAILURE";

export const DESTROY_DELIVERY_EXCEL_RESET = "DESTROY_DELIVERY_EXCEL_RESET";

export const PERCEL_REGIST_RESET = "PERCEL_REGIST_RESET";

export const deliveryExcelResetAction = () => ({
    type: DELIVERY_EXCEL_RESET,
});

export const percelRegistResetAction = () => ({
    type: PERCEL_REGIST_RESET,
});

export const destroyDeliveryExcelResetAction = () => ({
    type: DESTROY_DELIVERY_EXCEL_RESET,
});

export const loadPercelRegistsAction = (query) => ({
    type: LOAD_PERCEL_REGISTS_REQUEST,
    data: query,
})
export const downloadDeliveryExcelResetAction = () => ({
    type: DOWNLOAD_DELIVERY_EXCEL_RESET,
});

export const updateOrderAction = (formData, orderSeq, isUserModal) => ({
    type: UPDATE_ORDER_REQUEST,
    data: formData,
    orderSeq,
    isUserModal,
});

export const loadOrdersAction = (query) => ({
    type: LOAD_ORDERS_REQUEST,
    data: query,
});

export const loadCountByStatusAction = () => ({
    type: LOAD_COUNTBYSTATUS_REQUEST,
});

export const deliveryExcelAction = () => ({
    type: DELIVERY_EXCEL_REQUEST,
});

export const percelRegistAction = (formData) => ({
    type: PERCEL_REGIST_REQUEST,
    data: formData
});

export const loadDeliveryExcelListAction = () => ({
    type: LOAD_DELIVERY_EXCEL_LIST_REQUEST
});

export const downloadDeliveryExcelAction = (query) => ({
    type: DOWNLOAD_DELIVERY_EXCEL_REQUEST,
    data: query
});

export const destroyDeliveryExcelAction = (query) => ({
    type: DESTROY_DELIVERY_EXCEL_REQUEST,
    data: query,
});

export const updateOrderDetailAction = (query, data) => ({
    type: UPDATE_ORDER_DETAIL_REQUEST,
    query,
    data,
});

export const updateOrderDetailResetAction = () => ({
    type: UPDATE_ORDER_DETAIL_RESET,
});

export const loadDeliveryExcelListReset = () => ({
    type: LOAD_DELIVERY_EXCEL_LIST_RESET
});

export const loadOrderAction = (oId) => ({
    type: LOAD_ORDER_REQUEST,
    data: oId,
});

export const loadOrdersResetAction = () => ({
    type: LOAD_ORDERS_RESET,
})

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST: {
                draft.loadGetOrderDetailCountByStatusLoading = true;
                draft.loadGetOrderDetailCountByStatusDone = false;
                draft.loadGetOrderDetailCountByStatus = null;
                break;
            }
            case LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS: {
                draft.loadGetOrderDetailCountByStatusLoading = false;
                draft.loadGetOrderDetailCountByStatusDone = true;
                draft.loadGetOrderDetailCountByStatus = action.data;
                break;
            }
            case LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE: {
                draft.loadGetOrderDetailCountByStatusLoading = false;
                draft.loadGetOrderDetailCountByStatusError = action.error;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST: {
                draft.updateManagerChangeOrderBasedStatusLoading = true;
                draft.updateManagerChangeOrderBasedStatusDone = false;
                draft.updateManagerChangeOrderBasedStatusError = null;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_SUCCESS: {
                draft.updateManagerChangeOrderBasedStatusLoading = false;
                draft.updateManagerChangeOrderBasedStatusDone = true;
                draft.updateManagerChangeOrderBasedStatus = action.data;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_FAILURE: {
                draft.updateManagerChangeOrderBasedStatusLoading = false;
                draft.updateManagerChangeOrderBasedStatusError = action.error;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_RESET: {
                draft.updateManagerChangeOrderBasedStatusLoading = false;
                draft.updateManagerChangeOrderBasedStatusDone = false;
                draft.updateManagerChangeOrderBasedStatusError = null;
                draft.updateManagerChangeOrderBasedStatus = null;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST: {
              draft.updateManagerChangeOrderForceCancelLoading = true;
              draft.updateManagerChangeOrderForceCancelDone = false;
              draft.updateManagerChangeOrderForceCancelError = null;
              break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_SUCCESS: {
                draft.updateManagerChangeOrderForceCancelLoading = false;
                draft.updateManagerChangeOrderForceCancelDone = true;
                draft.updateManagerChangeOrderForceCancel = action.data;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_FAILURE: {
                draft.updateManagerChangeOrderForceCancelLoading = false;
                draft.updateManagerChangeOrderForceCancelError = action.error;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_RESET: {
                draft.updateManagerChangeOrderForceCancelLoading = false;
                draft.updateManagerChangeOrderForceCancelDone = false;
                draft.updateManagerChangeOrderForceCancelError = null;
                draft.updateManagerChangeOrderForceCancel = null;
                break;
            }
            case ORDER_MEMO_UPDATE: {
                draft.order = {...draft.order, memo: action.memo};
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST: {
                draft.updateManagerChangeOrderDetailStatusLoading = true;
                draft.updateManagerChangeOrderDetailStatusDone = false;
                draft.updateManagerChangeOrderDetailStatusError = null;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_SUCCESS: {
                draft.updateManagerChangeOrderDetailStatusLoading = false;
                draft.updateManagerChangeOrderDetailStatusDone = true;
                draft.updateManagerChangeOrderDetailStatus = action.data;
                break;
            }
            case UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_FAILURE: {
                draft.updateManagerChangeOrderDetailStatusLoading = false;
                draft.updateManagerChangeOrderDetailStatusError = action.error;
                break;
            }

            case UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_RESET: {
                draft.updateManagerChangeOrderDetailStatusLoading = false;
                draft.updateManagerChangeOrderDetailStatusDone = false;
                draft.updateManagerChangeOrderDetailStatusError = null;
                draft.updateManagerChangeOrderDetailStatus = null;
                break;
            }
            case DELIVERY_EXCEL_RESET: {
                draft.deliveryExcelLoading = false;
                draft.deliveryExcelDone = false;
                draft.deliveryExcelError = null;
                break;
            }
            case LOAD_ORDERS_RESET: {
                draft.loadOrdersLoading = false;
                draft.loadOrdersDone = false;
                draft.loadOrdersError = null;
                draft.orders = null;
                break;
            }
            case PERCEL_REGIST_RESET: {
                draft.percelRegistLoading = false;
                draft.percelRegistDone = false;
                draft.percelRegist = null;
                draft.percelRegistError = null;
                break;
            }
            case DESTROY_DELIVERY_EXCEL_RESET: {
                draft.destroyDeliveryExcelLoading = false;
                draft.destroyDeliveryExcelDone = false;
                draft.destroyDeliveryExcelError = null;
                draft.destroyDeliveryExcel = null;
                break;
            }
            case LOAD_PERCEL_REGISTS_REQUEST: {
                draft.loadPercelRegistsLoading = true;
                draft.loadPercelRegistsDone = false;
                draft.loadPercelRegistsError = null;
                break;
            }
            case LOAD_PERCEL_REGISTS_SUCCESS: {
                draft.percelRegists = action.data;
                draft.loadPercelRegistsLoading = false;
                draft.loadPercelRegistsDone = true;
                break;
            }
            case LOAD_PERCEL_REGISTS_FAILURE: {
                draft.loadPercelRegistsLoading = false;
                draft.loadPercelRegistsError = action.error;
                break;
            }
            case LOAD_ORDER_REQUEST: {
                draft.loadOrderLoading = true;
                draft.loadOrderDone = false;
                draft.loadOrderError = null;
                break;
            }
            case LOAD_ORDER_SUCCESS: {
                draft.order = action.data;
                draft.loadOrderLoading = false;
                draft.loadOrderDone = true;
                break;
            }
            case LOAD_ORDER_FAILURE: {
                draft.loadOrderLoading = false;
                draft.loadOrderError = action.error;
                break;
            }
            case LOAD_DELIVERY_EXCEL_LIST_RESET : {
                draft.loadDeliveryExcelListLoading = true;
                draft.loadDeliveryExcelListDone = false;
                draft.loadDeliveryExcelListError = null;
                draft.deliveryExcelList = null;
            }
            case UPDATE_ORDER_DETAIL_REQUEST: {
                draft.updateOrderDetailLoading = true;
                draft.updateOrderDetailDone = false;
                draft.updateOrderDetailError = null;
                break;
            }
            case UPDATE_ORDER_DETAIL_SUCCESS: {
                draft.updateOrderDetail = action.data;
                draft.updateOrderDetailLoading = false;
                draft.updateOrderDetailDone = true;
                break;
            }
            case UPDATE_ORDER_DETAIL_FAILURE: {
                draft.updateOrderDetailLoading = false;
                draft.updateOrderDetailError = action.error;
                break;
            }
            case UPDATE_ORDER_DETAIL_RESET: {
                draft.updateOrderDetailLoading = false;
                draft.updateOrderDetailDone = false;
                draft.updateOrderDetailError = null;
                draft.updateOrderDetail = null;
                break;
            }
            case DESTROY_DELIVERY_EXCEL_REQUEST: {
                draft.destroyDeliveryExcelLoading = true;
                draft.destroyDeliveryExcelDone = false;
                draft.destroyDeliveryExcelError = null;
                break;
            }
            case DESTROY_DELIVERY_EXCEL_SUCCESS: {
                draft.destroyDeliveryExcel = action.data;
                draft.destroyDeliveryExcelLoading = false;
                draft.destroyDeliveryExcelDone = true;
                break;
            }
            case DESTROY_DELIVERY_EXCEL_FAILURE: {
                draft.destroyDeliveryExcelLoading = false;
                draft.destroyDeliveryExcelError = action.error;
                break;
            }
            case DOWNLOAD_DELIVERY_EXCEL_REQUEST: {
                draft.downloadDeliveryExcelLoading = true;
                draft.downloadDeliveryExcelDone = false;
                draft.downloadDeliveryExcelError = null;
                break;
            }
            case DOWNLOAD_DELIVERY_EXCEL_SUCCESS: {
                draft.downloadDeliveryExcel = action.data;
                draft.downloadDeliveryExcelLoading = false;
                draft.downloadDeliveryExcelDone = true;
                break;
            }
            case DOWNLOAD_DELIVERY_EXCEL_FAILURE: {
                draft.downloadDeliveryExcelLoading = false;
                draft.downloadDeliveryExcelError = action.error;
                break;
            }
            case DOWNLOAD_DELIVERY_EXCEL_RESET: {
                draft.downloadDeliveryExcelLoading = false;
                draft.downloadDeliveryExcelDone = false;
                draft.downloadDeliveryExcel = null;
                draft.downloadDeliveryExcelError = null;
            }
            case LOAD_DELIVERY_EXCEL_LIST_REQUEST: {
                draft.loadDeliveryExcelListLoading = true;
                draft.loadDeliveryExcelListDone = false;
                draft.loadDeliveryExcelListError = null;
                break;
            }
            case LOAD_DELIVERY_EXCEL_LIST_SUCCESS: {
                const excelFiles = {};
                for(let excelFile of action.data.excelFiles) {
                    let excelFileDate = excelFile.regdate.substring(0, 10);
                    if(excelFiles[excelFileDate]) {
                        excelFiles[excelFileDate].push(excelFile);
                    } else {
                        excelFiles[excelFileDate] = [];
                        excelFiles[excelFileDate].push(excelFile);
                    }
                }
                draft.deliveryExcelList = excelFiles;
                draft.loadDeliveryExcelListLoading = false;
                draft.loadDeliveryExcelListDone = true;
                break;
            }
            case LOAD_DELIVERY_EXCEL_LIST_FAILURE: {
                draft.loadDeliveryExcelListLoading = false;
                draft.loadDeliveryExcelListError = action.error;
                break;
            }
            case PERCEL_REGIST_REQUEST: {
                draft.percelRegistLoading = true;
                draft.percelRegistDone = false;
                draft.percelRegistError = null;
                break;
            }
            case PERCEL_REGIST_SUCCESS: {
                draft.percelRegistLoading = false;
                draft.percelRegistDone = true;
                draft.percelRegist = action.data;
                break;
            }
            case PERCEL_REGIST_FAILURE: {
                draft.percelRegistLoading = false;
                draft.percelRegistError = action.error;
                break;
            }
            case DELIVERY_EXCEL_REQUEST: {
                draft.deliveryExcelLoading = true;
                draft.deliveryExcelDone = false;
                draft.deliveryExcelError = null;
                break;
            }
            case DELIVERY_EXCEL_SUCCESS: {
                draft.deliveryExcel = action.data;
                draft.deliveryExcelLoading = false;
                draft.deliveryExcelDone = true;
                break;
            }
            case DELIVERY_EXCEL_FAILURE: {
                draft.deliveryExcelLoading = false;
                draft.deliveryExcelError = action.error;
                break;
            }
            case LOAD_COUNTBYSTATUS_REQUEST: {
                draft.loadCountByStatusLoading = true;
                draft.loadCountByStatusDone = false;
                draft.loadCountByStatusError = null;
                break;
            }
            case LOAD_COUNTBYSTATUS_SUCCESS: {
                draft.countByStatus = action.data;
                draft.loadCountByStatusLoading = false;
                draft.loadCountByStatusDone = true;
                break;
            }
            case LOAD_COUNTBYSTATUS_FAILURE: {
                draft.loadCountByStatusLoading = false;
                draft.loadCountByStatusError = action.error;
                break;
            }
            case LOAD_ORDERS_REQUEST: {
                draft.loadOrdersLoading = true;
                draft.loadOrdersDone = false;
                draft.loadOrdersError = null;
                break;
            }
            case LOAD_ORDERS_SUCCESS: {
                draft.orders = action.data;
                draft.loadOrdersLoading = false;
                draft.loadOrdersDone = true;
                break;
            }
            case LOAD_ORDERS_FAILURE: {
                draft.loadOrdersLoading = false;
                draft.loadOrdersError = action.error;
                break;
            }
            case UPDATE_ORDER_REQUEST: {
                draft.updateOrderLoading = true;
                draft.updateOrderDone = false;
                draft.updateOrderError = null;
                break;
            }
            case UPDATE_ORDER_SUCCESS: {
                draft.updateOrder = action.data;
                draft.updateOrderLoading = false;
                draft.updateOrderDone = true;
                
                const orderIndex = draft.orders.data.findIndex((order) => order.seq === action.orderSeq);
                draft.orders.data[orderIndex] = action.data.result;
                break;
            }
            case UPDATE_ORDER_FAILURE: {
                draft.updateOrderLoading = false;
                draft.updateOrderError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}



export default reducer;
