import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadMeAction } from "../../reducers/user";
import { loadCountsForSidenavAction } from "../../reducers/analytics";

import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";
import ConfigData from "../../ConfigData";
import Module from "../../Components/Module";

const formatDate = (date) => {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // 월을 두 자리 숫자로
    const day = d.getDate().toString().padStart(2, "0"); // 일을 두 자리 숫자로
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
};

const getDefaultStartDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    return formatDate(today);
};

const getDefaultEndDate = () => {
    const today = new Date();
    return formatDate(today);
};

const encodeQueryParam = (param) => {
    return btoa(JSON.stringify(param));
};

const Summary = () => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone } = useSelector((state) => state.user);
    const [startDate, setStartDate] = useState(getDefaultStartDate());
    const [endDate, setEndDate] = useState(getDefaultEndDate());
    const [selectedOption, setSelectedOption] = useState("day");

    const [query, setQuery] = useState({
        date: encodeQueryParam([getDefaultStartDate(), getDefaultEndDate()]),
        group: encodeQueryParam("day"),
    });

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDateChange = ({ startDate, endDate }) => {
        setStartDate(formatDate(startDate));
        setEndDate(formatDate(endDate));
    };

    const configData = {
        analytics: {
            object: new ConfigData.Analytics2(),
            query: query,
        },
    };

    useEffect(() => {
        const newQuery = {
            date: encodeQueryParam([startDate, endDate]),
            group: encodeQueryParam(selectedOption),
        };
        setQuery(newQuery);
    }, [startDate, endDate, selectedOption]);

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    return loadMeLoading
        ? null
        : loadMeDone && (
              <div>
                  <div className="uk-width-1-1">
                      <Header title={"개요"} />
                  </div>
                  <div className="uk-width-1-1">
                      <div
                          className="uk-width-1-2 uk-grid uk-grid-small"
                          data-uk-grid
                      >
                          <div className="uk-width-1-4">
                              <select
                                  className="uk-select uk-form-small uk-height-30"
                                  value={selectedOption}
                                  onChange={handleSelectChange}
                              >
                                  <option value="day">일별</option>
                                  <option value="month">월별</option>
                              </select>
                          </div>
                          <div className="uk-width-expand">
                              <a href="#">
                                  <input
                                      className="uk-input uk-form-small"
                                      id="startDate"
                                      name="startDate"
                                      type="text"
                                      readOnly
                                      value={
                                          startDate + " ~ " + endDate || "날짜"
                                      }
                                      placeholder={"날짜"}
                                  />
                              </a>
                              <UIKit.DateRangePicker
                                  startDate={new Date(startDate || new Date())}
                                  endDate={new Date(endDate || new Date())}
                                  onDateChange={handleDateChange}
                              />
                          </div>
                      </div>
                      <div
                          className="uk-grid uk-grid-small"
                          data-uk-grid
                          data-uk-height-match="target: > div > .uk-card"
                      >
                          {/* <div className="uk-width-1-1 uk-width-1-2@m">
                              <div className="uk-card uk-card-default uk-card-small">
                                  <div className="uk-card-header uk-text-0_9">
                                      <p>방문자 현황</p>
                                  </div>
                                  <div className="uk-card-body uk-overflow-auto uk-height-medium uk-height-large@m uk-flex@m uk-flex-middle uk-position-relative">
                                      <Module.Chart.Type1
                                          instance={configData.analytics.object.get(
                                              "list",
                                              query
                                          )}
                                          isDispatch={true}
                                      />
                                  </div>
                              </div>
                          </div> */}
                          <div className="uk-width-1-1 uk-width-1-2@m">
                              <div className="uk-card uk-card-default uk-card-small">
                                  <div className="uk-card-header uk-text-0_9">
                                      <p>일자별 요약</p>
                                  </div>
                                  <div className="uk-card-body uk-padding-xsmall-horizontal uk-padding-horizontal@m uk-position-relative uk-overflow-auto">
                                      <Module.List.Type3
                                          instance={configData.analytics.object.get(
                                              "list",
                                              query
                                          )}
                                          isDispatch={true}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          );
};

export default Summary;
