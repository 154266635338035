import React, { useEffect } from "react";
import { useSelector , useDispatch } from 'react-redux';

import { loadMeAction } from '../../reducers/user';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

import { useFormData } from '../../hook';

import { initDatas } from './common';

import UIKit from "../../Components/UIKit";
import OrderExchangeTableStyle from '../../Components/Widget/Order/ExchangeTableStyle';
import SearchHead from "../../Components/Widget/SearchHead";
import SearchModal from '../../Components/Widget/SearchModal';
import SearchForm from '../../Components/Widget/SearchForm';

const ExchangeList = ({ history, match }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone, loadMeError } = useSelector((state) => state.user);

    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all'
    });

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <div>
                <SearchHead modalId={"reviewSearchModal"}  />
                <SearchModal modalId={"reviewSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>

            </div>
            <div className="uk-margin-medium-top">
                <OrderExchangeTableStyle payType={1} />
            </div>
        </div>
    );
};

export default ExchangeList;
