class ConfigData{
    state;
    rtn;

    constructor(state) {
        this.state = state;
        this.rtn = {
            queryString: "",
            reducer : {obj:null, action:null, loading:null, done:null}
        };
    }

    makeQuery(query){
        if(query && query!=""){
            this.rtn.queryString = (Object.keys(query).reduce(function(a,k){a.push(k+'='+encodeURIComponent(query[k]));return a},[]).join('&'));
        }
    }
}

export default ConfigData;
