import React from "react";
import PropTypes from "prop-types";

const Card = ({
    cardOptions = "",
    cardBodyOptions = "",
    cardHeaderOptions = "",
    title,
    isImageCard = false,
    onChangeImage,
    children
}) => {
  return (
    <div className={`uk-card ${cardOptions}`}>
      {title && (
        <div className={`uk-card-header uk-position-relative ${cardHeaderOptions}`}>
            {isImageCard ? (
                <div
                    className="uk-form-custom uk-position-center-right uk-margin-right"
                    data-uk-form-custom
                >
                    <input
                        type="file"
                        data-preview="img"
                        onChange={onChangeImage}
                        multiple
                        accept='image/*'
                    />
                    <button
                        className="uk-button uk-button-default uk-button-small"
                        type="button"
                    >
                        이미지 선택
                    </button>
                </div>
            ) : ("")}
            <h3 className="uk-card-title uk-margin-remove-vertical">{title}</h3>
        </div>
      )}
      <div
        className={`uk-card-body ${cardBodyOptions}`}
      >
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  cardOptions: PropTypes.string,
  cardBodyOptions: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Card;
