import React from "react";
import PropTypes from "prop-types";

const InputImage = ({ data, tags, className, onChangeImage }) => {
    return (
        <div className={className}>
            {tags &&
                tags.map((tag, index) => (
                    <div key={index}>
                        <label
                            className={tag.label.class}
                            {...tag.label.options}
                        >
                            {tag.label.name}
                        </label>
                        <div className="uk-cover-container uk-height-small">
                            <img
                                className={tag.image.class}
                                data-uk-cover
                                alt={tag.label.name}
                            />
                        </div>
                        <div
                            className="uk-form-custom uk-margin-small-top"
                            data-uk-form-custom
                        >
                            <input
                                type="file"
                                {...tag.input.options}
                                data-preview=""
                                value=""
                                onChange={onChangeImage}
                            />
                            <button
                                className="uk-button uk-button-default uk-button-small"
                                type="button"
                            >
                                수정
                            </button>
                        </div>
                    </div>
                ))}
        </div>
    );
};

InputImage.propTypes = {
    data: PropTypes.object,
    tags: PropTypes.array,
    className: PropTypes.string,
    onChangeImage: PropTypes.func
};
export default InputImage;
