import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux';
import queryString from "query-string";

import { loginUserAction, loadMeAction } from '../reducers/user';

import Loader from "../Components/Widget/Loader";


const Login = ({ history }) => {
    const dispatch = useDispatch();
    const { loginUserDone, loginUserLoading, loginUserError, loadMeDone, me } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(loadMeAction());
    }, []);

    useEffect(() => {
        if(loadMeDone && me) {
            window.location.href = "/dashboard";
        }
    }, [loadMeDone]);

    useEffect(() => {
        const {
            location: { hash }
        } = history;
        if (hash) {
            setLoading(true);
        }
        const code = queryString.parse(history.location.search).code;
        if (code) {
            dispatch(loginUserAction(code));
        } else {
            setLoading(false);
        }
    }, [history]);

    useEffect(() => {
        if(loginUserDone) {
            window.location.href = "/dashboard";
            // history.push("/dashboard");
        }
    }, [loginUserDone]);

    useEffect(() => {
        if(loginUserError) {
             window.location.href = "/login";
            alert(loginUserError.msg);
        }
    }, [loginUserError]);


    return (
        <div>
            {loading ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <div
                    className="login uk-cover-container uk-background-secondary uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden"
                    data-uk-height-viewport
                >
                    <div className="uk-position-cover uk-overlay-primary"></div>
                    <div
                        className="uk-width-medium uk-padding-small uk-position-z-index"
                        uk-scrollspy="cls: uk-animation-fade"
                    >
                        <div className="uk-text-center uk-margin uk-margin-50-bottom">
                            <img
                                src="/image/logo.png"
                                className="uk-height-50"
                                alt="Logo"
                            />
                        </div>
                        <a
                            className="uk-button uk-button-primary uk-border-pill uk-width-1-1"
                            href={`https://accounts.google.com/o/oauth2/auth?client_id=721909459458-siffjmh95igkh1hkt7p6o5qs0n8r947v.apps.googleusercontent.com&redirect_uri=${window.location.protocol}//${window.location.host}/login&response_type=code&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email`}
                        >
                            로그인
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
