import axios from "axios";

const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEV_API_BASE_URL
            : process.env.REACT_APP_REAL_API_BASE_URL
});

const makeConfig = () => {
    api.defaults.headers.Authorization = `bearer${localStorage.getItem("token")}`;
    api.defaults.headers.Brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
};

export const userApi = {
    login: async (path, code) => {
        try {
            makeConfig();
            const result = await api.post(`${path}`, {
                code
            });
            return result;
        } catch (error) {
            return error.response;
        }
    },
    logout: async(path) => {
        try {
            makeConfig();
            const result = await api.get(`${path}/logout`);
            return result;
        } catch (error) {
            return error.response;
        }
    },
    me: async path => {
        try {
            makeConfig();
            const result = await api.get(`${path}`);
            return result;
        } catch (error) {
            return error.response;
        }
    },
    index: async (path) => {
        try {
            makeConfig();
            const result = await api.get(`${path}`);
            return result;
        } catch (error) {
            return error.response;
        }
    },
    create: async (path, data) => {
        try {
            makeConfig();
            return await api.post(`${path}`, data);
        } catch (error) {
            return error.response;
        }
    },
    show: async (path, seq) => {
        try {
            makeConfig();
            return await api.get(`${path}/${seq}`);
        } catch (error) {
            return error.response;
        }
    },
    update: async (path, seq, data) => {
        try {
            makeConfig();
            if(seq) {
                return await api.post(`${path}/${seq}`, data);
            } else {
                return await api.post(`${path}`, data);
            }
        } catch (error) {
            return error.response;
        }
    },
    destroy: async (path, seq) => {
        try {
            makeConfig();
            return await api.delete(`${path}/${seq}`);
        } catch (error) {
            return error.response;
        }
    },
    download: async (path) => {
        try {
            makeConfig();
            const result = await api.get(`${path}`);
            if(result.data.file && result.data.name){
                const linkSource = "data:application/pdf;base64,"+result.data.file;
                const link = document.createElement('a');
                link.href = linkSource;
                link.setAttribute('download', result.data.name);
                document.body.appendChild(link);
                link.click();
                var body = document.body.removeChild(link);
            }

            return result;
        } catch (error) {
            return error.response;
        }
    },
    upload: async (path, data) => {
        try {
            makeConfig();
            const result = await api.post(`${path}`, data);
            return result;
        } catch (error) {
            return error.response;
        }
    },
};
