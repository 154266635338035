import React, { useCallback, useEffect } from 'react';
import PropTypes from "prop-types";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import UIKit from "../UIKit";

const SearchForm = ({
    searchForm,
    useSearchComponents
}) => {
    const { dateSearch, informationSearch, statusSearch, deliveryStatusSearch, payTypeSearch } = useSearchComponents;
    const location = useLocation();
    useEffect(() => {
        const parsedQueryString = queryString.parse(location.search);
        if(parsedQueryString && parsedQueryString.search) {
            const parsedBase64Search = decodeURIComponent(atob(parsedQueryString.search));
            if(parsedBase64Search) {
                const parsedJSONSearch = JSON.parse(parsedBase64Search);
                searchForm.onChange({
                    dateSelect: parsedJSONSearch.dateSelect ? parsedJSONSearch.dateSelect : 'all',
                    dateRadio: '',
                    startDate: parsedJSONSearch.regdate ? parsedJSONSearch.regdate[0] : "",
                    endDate: parsedJSONSearch.regdate ? parsedJSONSearch.regdate[1] : "",
                    searchType: parsedJSONSearch.searchType ? parsedJSONSearch.searchType : 'all',
                    searchValue: parsedJSONSearch.searchValue ? decodeURIComponent(parsedJSONSearch.searchValue) : '',
                    status: parsedJSONSearch.status ? parsedJSONSearch.status : 'all',
                    deliveryStatus: parsedQueryString.status ? parsedQueryString.status : 'all',
                    payType: parsedJSONSearch.payType ? parsedJSONSearch.payType : 'all',
                });
            }
        } else {
            searchForm.clearValues();
        }

    }, [location.search]);

    const onClickChangeDateRadio = useCallback((data) => {
       let now = new Date();
       let month = '' + (now.getMonth() + 1);
       let day = '' + now.getDate();
       let year = now.getFullYear();

       if (month.length < 2) {
           month = `0${month}`;
       }
       if (day.length < 2) {
           day = `0${day}`;
       }

       let startDate = [year, month, day].join('-');

       let endDate = '';
       if(data === 1) {
           endDate = startDate;
       } else if(data === 2) {
           now.setDate(7);
       } else if(data === 3) {
           now.setMonth(now.getMonth() + 1)
       } else if(data === 4) {
           now.setMonth(now.getMonth() + 3)
       }

       let endYear = now.getFullYear();
       let endMonth = '' + (now.getMonth() + 1);
       let endDay = '' + now.getDate();

       if (endMonth.length < 2) {
           endMonth = `0${endMonth}`;
       }
       if (endDay.length < 2) {
           endDay = `0${endDay}`;
       }

       endDate = [endYear, endMonth, endDay].join('-');

       searchForm.onChange({
           dateRadio: data,
           startDate,
           endDate
       });
   }, [searchForm]);
    return (
        <div className="uk-width-1-1">
            {
                dateSearch.isUse && (
                    <div className="uk-width-1-1">
                        <label className="uk-form-label" htmlFor="dateSelect">{dateSearch.title}</label>
                        <div className="uk-margin-xsmall-top" data-uk-grid>
                            <div className="uk-width-1-5@m">
                                <div className="uk-form-controls">
                                    <select
                                        className="uk-select uk-form-small"
                                        id="dateSelect"
                                        name="dateSelect"
                                        value={searchForm.value.dateSelect}
                                        onChange={searchForm.onChange}
                                    >
                                        {
                                            dateSearch.options.map((option, index) => (
                                                <option value={option.value} key={index}>{option.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="uk-width-2-5@m">
                                <div className="uk-button-group uk-width-1-1">
                                    <UIKit.Button
                                        buttonOptions={`uk-button uk-button-small uk-width-1-4 uk-padding-xsmall-horizontal uk-margin-2-left ${searchForm.value.dateRadio === 1 ? 'uk-button-primary' : 'uk-button-default'}`}
                                        buttonName={"오늘"}
                                        onClickAction={() => onClickChangeDateRadio(1)}
                                    />
                                    <UIKit.Button
                                        buttonOptions={`uk-button uk-button-small uk-width-1-4 uk-padding-xsmall-horizontal ${searchForm.value.dateRadio === 2 ? 'uk-button-primary' : 'uk-button-default'}`}
                                        buttonName={"1주일"}
                                        onClickAction={() => onClickChangeDateRadio(2)}
                                    />
                                    <UIKit.Button
                                        buttonOptions={`uk-button uk-button-small uk-width-1-4 uk-padding-xsmall-horizontal ${searchForm.value.dateRadio === 3 ? 'uk-button-primary' : 'uk-button-default'}`}
                                        buttonName={"1개월"}
                                        onClickAction={() => onClickChangeDateRadio(3)}
                                    />
                                    <UIKit.Button
                                        buttonOptions={`uk-button uk-button-small uk-width-1-4 uk-padding-xsmall-horizontal ${searchForm.value.dateRadio === 4 ? 'uk-button-primary' : 'uk-button-default'}`}
                                        buttonName={"3개월"}
                                        onClickAction={() => onClickChangeDateRadio(4)}
                                    />
                                </div>
                            </div>
                            <div className="uk-width-2-5@m">
                                <div data-uk-grid>
                                    <div className="uk-width-expand">
                                        <a href="#">
                                            <input
                                                className="uk-input uk-form-small"
                                                id="startDate"
                                                name="startDate"
                                                type="text"
                                                readOnly
                                                value={searchForm.value.startDate}
                                                placeholder={"시작 날짜"}
                                            />
                                        </a>
                                        <UIKit.DatePicker target={"startDate"} onChange={searchForm.onChange} />
                                    </div>
                                    <div className="uk-width-auto uk-padding-remove uk-text-center">
                                        <span className="uk-margin-small-left uk-margin-small-right">~</span>
                                    </div>
                                    <div className="uk-width-expand uk-padding-remove">
                                        <a href="#">
                                            <input
                                                className="uk-input uk-form-small"
                                                id="endDate"
                                                name="endDate"
                                                type="text"
                                                readOnly
                                                value={searchForm.value.endDate}
                                                placeholder={"종료 날짜"}
                                            />
                                        </a>
                                        <UIKit.DatePicker target={"endDate"} onChange={searchForm.onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                informationSearch.isUse && (
                    <div className="uk-width-1-1 uk-margin-top">
                        <label className="uk-form-label" htmlFor="searchType">{informationSearch.title}</label>
                        <div className="uk-margin-xsmall-top" data-uk-grid>
                            <div className="uk-width-2-5 uk-width-1-5@m">
                                <div className="uk-margin">
                                    <div className="uk-form-controls">
                                        <select
                                            className="uk-select uk-form-small"
                                            id="searchType"
                                            name="searchType"
                                            value={searchForm.value.searchType}
                                            onChange={searchForm.onChange}
                                        >
                                            {
                                                informationSearch.options.map((option, index) => (
                                                    <option value={option.value} key={index}>{option.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-3-5 uk-width-4-5@m">
                                <div className="uk-margin">
                                    <input
                                        id="searchValue"
                                        name="searchValue"
                                        className="uk-input uk-form-small"
                                        type="text"
                                        placeholder="검색어 입력"
                                        value={searchForm.value.searchValue}
                                        onChange={searchForm.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                statusSearch.isUse && (
                    <div className="uk-width-1-1 uk-margin-top">
                        <label className="uk-form-label" htmlFor="status">{statusSearch.title}</label>
                        <div className="uk-margin-xsmall-top" data-uk-grid>
                            <div className="uk-width-1-1">
                                <div className="uk-margin">
                                    <div className="uk-form-controls">
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="status"
                                                value={'all'}
                                                checked={searchForm.value.status === 'all' ? true : false}
                                                onChange={searchForm.onChange}
                                            />전체
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="status"
                                                value={1}
                                                checked={parseInt(searchForm.value.status,10) === 1 ? true : false}
                                                onChange={searchForm.onChange}
                                            />사용
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="status"
                                                value={0}
                                                checked={parseInt(searchForm.value.status,10) === 0 ? true : false}
                                                onChange={searchForm.onChange}
                                            />미사용
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                deliveryStatusSearch && deliveryStatusSearch.isUse && window.location.pathname === '/order/all' && (
                    <div className="uk-width-1-1 uk-margin-top">
                        <label className="uk-form-label" htmlFor="status">{deliveryStatusSearch.title}</label>
                        <div className="uk-margin-xsmall-top" data-uk-grid>
                            <div className="uk-width-1-1">
                                <div className="uk-margin">
                                    <div className="uk-form-controls">
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={'all'}
                                                checked={searchForm.value.deliveryStatus === 'all' ? true : false}
                                                onChange={searchForm.onChange}
                                            />전체
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={1}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 1 ? true : false}
                                                onChange={searchForm.onChange}
                                            />입금대기
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={2}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 2 ? true : false}
                                                onChange={searchForm.onChange}
                                            />결제완료
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={3}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 3 ? true : false}
                                                onChange={searchForm.onChange}
                                            />배송준비
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={4}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 4 ? true : false}
                                                onChange={searchForm.onChange}
                                            />배송중
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={5}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 5 ? true : false}
                                                onChange={searchForm.onChange}
                                            />배송완료
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={6}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 6 ? true : false}
                                                onChange={searchForm.onChange}
                                            />취소완료
                                        </label>
                                        {/* <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={7}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 7 ? true : false}
                                                onChange={searchForm.onChange}
                                            />반품완료
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="deliveryStatus"
                                                value={8}
                                                checked={parseInt(searchForm.value.deliveryStatus, 10) === 8 ? true : false}
                                                onChange={searchForm.onChange}
                                            />교환완료
                                        </label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                payTypeSearch && payTypeSearch.isUse && (
                    <div className="uk-width-1-1 uk-margin-top">
                        <label className="uk-form-label" htmlFor="status">{payTypeSearch.title}</label>
                        <div className="uk-margin-xsmall-top" data-uk-grid>
                            <div className="uk-width-1-1">
                                <div className="uk-margin">
                                    <div className="uk-form-controls">
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="payType"
                                                value={'all'}
                                                checked={searchForm.value.payType === 'all' ? true : false}
                                                onChange={searchForm.onChange}
                                            />전체
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="payType"
                                                value={1}
                                                checked={parseInt(searchForm.value.payType, 10) === 1 ? true : false}
                                                onChange={searchForm.onChange}
                                            />홈페이지
                                        </label>
                                        <label className="uk-form-small uk-pointer">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="payType"
                                                value={2}
                                                checked={parseInt(searchForm.value.payType, 10) === 2 ? true : false}
                                                onChange={searchForm.onChange}
                                            />네이버페이
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

SearchForm.propTypes = {
    searchForm: PropTypes.object.isRequired,
    useSearchComponents: PropTypes.object.isRequired
};

export default SearchForm;
