import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_REVIEWS_REQUEST,
    LOAD_REVIEWS_SUCCESS,
    LOAD_REVIEWS_FAILURE,
    UPLOAD_EXCEL_FOR_REVIEW_REQUEST,
    UPLOAD_EXCEL_FOR_REVIEW_SUCCESS,
    UPLOAD_EXCEL_FOR_REVIEW_FAILURE,
    CREATE_EXCEL_CONVERT_DATA_REVIEW_REQUEST,
    CREATE_EXCEL_CONVERT_DATA_REVIEW_SUCCESS,
    CREATE_EXCEL_CONVERT_DATA_REVIEW_FAILURE,
    DESTROY_REVIEW_REQUEST,
    DESTROY_REVIEW_SUCCESS,
    DESTROY_REVIEW_FAILURE,
    UPDATE_REVIEW_REQUEST,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAILURE,
} from '../reducers/review';

const getMe = state => {
    return state.user.me;
}

function loadReviewsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `admin/reviews${query}`
  } else {
      endPoint = "admin/reviews";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadReviews(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadReviewsAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_REVIEWS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_REVIEWS_FAILURE,
      error: e.response.data,
    });
  }
}

function uploadExcelForReviewAPI(user, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.post("reviews/excelConvert", data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* uploadExcelForReview(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(uploadExcelForReviewAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPLOAD_EXCEL_FOR_REVIEW_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPLOAD_EXCEL_FOR_REVIEW_FAILURE,
      error: e.response.data,
    });
  }
}

function createExcelConvertDataReviewAPI(user, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.post("reviews/storeAll", data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createExcelConvertDataReview(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(createExcelConvertDataReviewAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: CREATE_EXCEL_CONVERT_DATA_REVIEW_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_EXCEL_CONVERT_DATA_REVIEW_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyReviewAPI(user, reviewSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }


  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.delete(`/reviews/${reviewSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyReview(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(destroyReviewAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: DESTROY_REVIEW_SUCCESS,
      data: result.data,
      reviewSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_REVIEW_FAILURE,
      error: e.response.data,
    });
  }
}

function updateReviewAPI(user, reviewSeq, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }


  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/reviews/${reviewSeq}`, data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateReview(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateReviewAPI, user, action.reviewSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_REVIEW_SUCCESS,
      data: result.data,
      userSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_REVIEW_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadReviews() {
  yield takeEvery(LOAD_REVIEWS_REQUEST, loadReviews);
}

function* watchUploadExcelForReview() {
  yield takeEvery(UPLOAD_EXCEL_FOR_REVIEW_REQUEST, uploadExcelForReview);
}

function* watchCreateExcelConvertDataReview() {
  yield takeEvery(CREATE_EXCEL_CONVERT_DATA_REVIEW_REQUEST, createExcelConvertDataReview);
}

function* watchDestroyReview() {
  yield takeEvery(DESTROY_REVIEW_REQUEST, destroyReview);
}

function* watchUpdateReview() {
  yield takeEvery(UPDATE_REVIEW_REQUEST, updateReview);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadReviews),
      fork(watchUploadExcelForReview),
      fork(watchCreateExcelConvertDataReview),
      fork(watchDestroyReview),
      fork(watchUpdateReview),
  ]);
}
