import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";

const Point = ({ match }) => {
    return (
        <Switch>
            <Route
                exact
                path={match.path}
                render={() => <Redirect to="/point/all" />}
            />
            <Route exact path={`${match.path}/all`} component={List} />
        </Switch>
    );
};

export default Point;
