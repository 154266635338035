import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";

const Review = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}/all`} component={List} />
            <Redirect from="*" to="/product/review/all" />
        </Switch>
    );
};

export default Review;
