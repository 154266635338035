import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../../reducers/user';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import { useFormData } from '../../../hook';

import UIKit from "../../../Components/UIKit";
import Loader from "../../../Components/Widget/Loader";
import Header from "../../../Components/Widget/Header";
import OrderLogTableStyle from '../../../Components/Widget/OrderLog/TableStyle';
import SearchHead from "../../../Components/Widget/SearchHead";
import SearchModal from '../../../Components/Widget/SearchModal';
import SearchForm from '../../../Components/Widget/SearchForm';

const initDatas = {
    useSearchComponents: {
        dateSearch: {
            isUse: true,
            title: '조회기간',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'regDate',
                    name: '등록일'
                }
            ]
        },
        informationSearch: {
            isUse: true,
            title: '주문 로그 정보',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'order_seq',
                    name: '주문 seq'
                }
            ]
        },
        statusSearch: {
            isUse: false,
            title: '사용여부'
        }
    }
};

const List = ({ history }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone } = useSelector((state) => state.user);
    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all'
    });

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"주문 로그 목록"} />
            </UIKit.Div>
            <div>
                <SearchHead modalId={"orderLogSearchModal"}  />
                <SearchModal modalId={"orderLogSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>
                <OrderLogTableStyle />
            </div>
        </div>
    );
};

export default List;
