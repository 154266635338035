import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_BRANDS_REQUEST,
    LOAD_BRANDS_SUCCESS,
    LOAD_BRANDS_FAILURE,
    DESTROY_BRAND_REQUEST,
    DESTROY_BRAND_SUCCESS,
    DESTROY_BRAND_FAILURE,
    LOAD_BRAND_REQUEST,
    LOAD_BRAND_SUCCESS,
    LOAD_BRAND_FAILURE,
    UPDATE_BRAND_REQUEST,
    UPDATE_BRAND_SUCCESS,
    UPDATE_BRAND_FAILURE,
    CREATE_BRAND_REQUEST,
    CREATE_BRAND_SUCCESS,
    CREATE_BRAND_FAILURE,
    NAVERPAY_TEST_EXCEL_REQUEST,
    NAVERPAY_TEST_EXCEL_SUCCESS,
    NAVERPAY_TEST_EXCEL_FAILURE
} from '../reducers/brand';

const getMe = state => {
    return state.user.me;
}

function loadBrandsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `brands${query}`
  } else {
      endPoint = "brands";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadBrands(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadBrandsAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_BRANDS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_BRANDS_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyBrandAPI(user, brandSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.delete(`/brands/${brandSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyBrand(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(destroyBrandAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: DESTROY_BRAND_SUCCESS,
      data: result.data,
      brandSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_BRAND_FAILURE,
      error: e.response.data,
    });
  }
}

function loadBrandAPI(user, brandSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.get(`/brands/${brandSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadBrand(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadBrandAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_BRAND_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_BRAND_FAILURE,
      error: e.response.data,
    });
  }
}

function updateBrandAPI(user, brandSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/brands/${brandSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateBrand(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateBrandAPI, user, action.brandSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_BRAND_SUCCESS,
      data: result.data,
      brandSeq: action.brandSeq,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_BRAND_FAILURE,
      error: e.response.data,
    });
  }
}

function createBrandAPI(user, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/brands', formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createBrand(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(createBrandAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: CREATE_BRAND_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_BRAND_FAILURE,
      error: e.response.data,
    });
  }
}

function naverPayTestExcelAPI(user, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/naverAPICheck', data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* naverPayTestExcel(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(naverPayTestExcelAPI, user, action.data);

    yield put({
      // put은 dispatch 동일
      type: NAVERPAY_TEST_EXCEL_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: NAVERPAY_TEST_EXCEL_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadBrands() {
  yield takeEvery(LOAD_BRANDS_REQUEST, loadBrands);
}

function* watchDestroyBrand() {
  yield takeEvery(DESTROY_BRAND_REQUEST, destroyBrand);
}

function* watchLoadBrand() {
  yield takeEvery(LOAD_BRAND_REQUEST, loadBrand);
}

function* watchUpdateBrand() {
  yield takeLatest(UPDATE_BRAND_REQUEST, updateBrand);
}

function* watchCreateBrand() {
  yield takeLatest(CREATE_BRAND_REQUEST, createBrand);
}

function* watchNaverPayTestExcel() {
  yield takeLatest(NAVERPAY_TEST_EXCEL_REQUEST, naverPayTestExcel);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadBrands),
      fork(watchDestroyBrand),
      fork(watchLoadBrand),
      fork(watchUpdateBrand),
      fork(watchCreateBrand),
      fork(watchNaverPayTestExcel),
  ]);
}
