import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import StarRatingComponent from 'react-star-rating-component';

const LiStyle = () => {
    const { user } = useSelector((state) => state.user);
    return (
        <div>
            {
                user && user.review && user.review.length>0 ? (
                    <ul class="uk-list uk-list-striped">
                    {
                        user.review.map((item, index) => (
                            <li key={index}>
                                <div className="uk-margin-small-bottom uk-flex-top uk-grid-collapse uk-padding-5" data-uk-grid>
                                    <div className="uk-width-auto">
                                        <img src={item.product && item.product.img && item.product.img[0] ? item.product.img[0] : ""} className="uk-object-cover uk-width-50 uk-height-50 uk-margin-xsmall-right" />
                                    </div>
                                    <div className="uk-width-expand">
                                        <span className={`uk-text-0_85 uk-display-inline-block uk-text-top uk-margin-1-top ${item.status==0 ? "uk-text-danger" : "uk-text-success" }`}>[{item.status==0 ? "숨김" : "노출"}]</span>
                                        {
                                            item.payReviewId && item.payReviewId.split("_")[0]==2 && (
                                                <img src="/image/naver_simple.png" className="uk-object-cover uk-width-13 uk-height-auto uk-margin-small-left uk-text-top uk-margin-xsmall-top" />
                                            )
                                        }
                                        <span className="uk-text-0_9 uk-vertical-middle uk-margin-xsmall-left uk-text-top uk-text-break">{item.product && item.product.name ? item.product.name : ""}</span>
                                        <p className="uk-text-0_8 uk-margin-small-top">
                                            <span className="uk-text-middle uk-text-0_7">
                                                <StarRatingComponent
                                                    disabled
                                                    id="ReviewScore"
                                                    name="ReviewScore"
                                                    starCount={5}
                                                    value={item.score}
                                                    renderStarIcon={() => <span className="mdi mdi-heart"></span>}
                                                    starColor={"#ff2e55"}
                                                />
                                            </span>
                                            <span className="uk-text-top uk-margin-2-left"> | {item.regdate}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="uk-text-0_9 htmlContentArea uk-padding-xsmall-horizontal">
                                    {ReactHtmlParser(item.content ? item.content : "")}
                                </div>
                            </li>
                        ))
                    }
                    </ul>
                )
                :
                (
                    <p className="uk-text-center uk-margin-medium-top">데이터가 없습니다.</p>
                )
            }
        </div>
    );
};

export default LiStyle;
