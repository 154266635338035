import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Brand from './Brand';
import Admin from './Admin';
import DevSetting from './DevSetting';
import OrderLog from './OrderLog';
import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';
import NaverPayTest from "./NaverPayTest";


const Setting = ({ match }) => {
    return (
        <WithSideNavLayout>
            <Switch>
                <Route
                    exact
                    path={'/setting/admin'}
                    render={() => <Redirect to="/setting/admin/all" />}
                />
                <Route path={`${match.path}/admin`} component={Admin} />

                <Route
                    exact
                    path={'/setting/brand'}
                    render={() => <Redirect to="/setting/brand/all" />}
                />
                <Route path={`${match.path}/brand`} component={Brand} />

                <Route
                    exact
                    path={'/setting/devSetting'}
                    render={() => <Redirect to="/setting/devSetting/all" />}
                />
                <Route path={`${match.path}/devSetting`} component={DevSetting} />
                <Route path={`${match.path}/naverPayTest`} component={NaverPayTest} />
                <Route path={`${match.path}/log`} component={OrderLog} />
                <Redirect from="*" to="/setting/admin" />
            </Switch>
        </WithSideNavLayout>
    );
};

export default Setting;
