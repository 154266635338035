import { useState, useCallback } from 'react';
import namer from 'korean-name-generator';

export const useFormData = (initValue) => {
    const [value, setValue] = useState(initValue);

    const init = useCallback((data) => {
        setValue(data);
    }, []);

    const onChangeIndex = useCallback((e, index, target, key) => {
        if (e.target) {
            value[target][index][key] = e.target.value;
            setValue({ ...value });
        } else {
            value[target][index][key] = e;
            setValue({ ...value });
        }
    }, [value]);

    const onChange = useCallback((data) => {
        if (data.target) {
            const targetName = data.target.name.split(".");
            let targetValue = data.target.value;
            if(targetName.length > 1) {
                if(targetName[1] === 'etcFee') {
                    if (targetValue) {
                        targetValue = targetValue.replace(/[^0-9]/g,'');
                        targetValue = targetValue.replace(/,/g, "");
                        targetValue = isNaN(parseInt(targetValue)) ? "숫자만 입력해주세요." : parseInt(targetValue);
                    }
                }
                setValue({...value, [targetName[0]]: { ...value[targetName[0]], [targetName[1]]: targetValue } });
            } else {
                setValue({...value, [data.target.name]: data.target.value});
            }
        } else {
            setValue({...value, ...data});
        }
    }, [value]);

    const onClearSeqValue = useCallback((seq) => {
        value[seq] = "";
        setValue({...value});
    }, [value]);

    const clearValues = useCallback(() => {
        let newValue = Object.assign({}, value);
        for(let key of Object.keys(value)) {
            let value = "";
            if(key === 'dateSelect' || key === 'searchType' || key === 'status' || key === 'deliveryStatus' || key === 'payType') {
                value = 'all';
            } else {
                value = "";
            }
            newValue = {...newValue, [key] : value};
        }
        setValue(newValue);
    }, [value]);

    return {value, onChange, init, onChangeIndex, onClearSeqValue, clearValues};
};

export const useInput = (initValue) => {
    const [value, setValue] = useState(initValue);

    const onChange = useCallback((e, isMoneyFormat = 0) => {
            if(isMoneyFormat === 1) {
                let targetValue = e.target.value.replace(/[^0-9]/g,'');
                targetValue = targetValue.replace(/,/g, "");
                setValue(isNaN(parseInt(targetValue)) ? "숫자만 입력해주세요." : parseInt(targetValue));
            } else {
                setValue(e.target.value);
            }
    }, []);

    return {value, onChange};
};

export const useFormForReview = (initValue) => {
    const [value, setValue] = useState(initValue);

    const serverDataInit = useCallback((data) => {
        let newData = [];
        const imagePattern = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i;
        const textPattern = /(<([^>]+)>)/ig;

        for(let review of data) {
            const imageExec = imagePattern.exec(review.content);
            let replaced = review.content.replace(textPattern, "");
            replaced = replaced.replace(imagePattern, "");
            const preview = {
                image: imageExec && imageExec[1] ? imageExec[1] : "",
                content: replaced ? replaced : review.content,
            };

            const scoreArr = [4, 5, 5];
            const ramdomScoreIndex = Math.floor(Math.random() * 3);
            const randomTrueFalse = Math.random() >= 0.5;
            const copyReview = { ...review };
            if(!copyReview.customWriter) {
                copyReview.customWriter = namer.generate(randomTrueFalse);
            }
            if(!copyReview.score) {
                copyReview.score = scoreArr[ramdomScoreIndex];
            }
            copyReview.preview = preview;
            newData.push(copyReview);
        }
        setValue([...value, ...newData]);
    }, [value]);

    const onChange = useCallback((content, index) => {
        value[index].content = content;
        const imagePattern = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i;
        const textPattern = /(<([^>]+)>)/ig;

        const imageExec = imagePattern.exec(content);
        let replaced = content.replace(textPattern, "");
        replaced = replaced.replace(imagePattern, "");
        const preview = {
            image: imageExec && imageExec[1] ? imageExec[1] : "",
            content: replaced ? replaced : content,
        };

        value[index].preview = preview;
        setValue([ ...value ]);
    }, [value]);

    return { value, serverDataInit, onChange };
}
