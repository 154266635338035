import React, { useCallback, useEffect } from "react";
import {useSelector} from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import UsePrevious from '../../hook/UsePrevious';

const Pagination = ({ currentPage = 1, pageLast = 2, onClick = null }) => {
    const history = useHistory();
    const location = useLocation();

    const {selectedBrand} = useSelector((state) => state.user);

    const prevBrand = UsePrevious(selectedBrand);

    // 브랜드 변경시 페이지 1페이지로 갈수있도록 변경
    useEffect(() => {
        if(prevBrand) {
            if(prevBrand !== selectedBrand) {
                onClickPageChange(1);
            }
        }
    }, [selectedBrand]);

    const onClickPageChange = useCallback((index) => {
        let query = `page=${index}`;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.page;
            query = `page=${index}&${decodeURIComponent(queryString.stringify(nowQueryStringObj))}`;
        }

        history.push({
            pathname: location.pathname,
            search: query
        });
    }, [history, location]);

    let tag = [];
    let ul = [];
    let li = [];
    let element = {
        pageRange: 5
    };
    const perPage = currentPage % 5 === 0 ? parseInt(currentPage / (element.pageRange), 10) - 1 : parseInt(currentPage / (element.pageRange), 10)
    element.start = element.pageRange * perPage + 1;
    element.end = element.start + element.pageRange - 1;
    element.end = element.end > pageLast ? pageLast : element.end;
    if (currentPage != 1) {
        li.push(
            <li key={"first"}>
                <span
                    className="mdi mdi-chevron-double-left uk-text-1_4 uk-margin-m5-top uk-display-inline-block uk-pointer"
                    onClick={() => onClick ? onClick(1) : onClickPageChange(1)}
                ></span>
            </li>
        );
    }
    if (element.pageRange < currentPage) {
        li.push(
            <li key={"prev"}>
                <span
                    className="uk-pointer"
                    data-uk-pagination-previous
                    onClick={() => onClick ? onClick(Number(element.start) - element.pageRange) : onClickPageChange(Number(element.start) - element.pageRange)}
                ></span>
            </li>
        );
    }
    for (var i = element.start; i <= element.end; i++) {
        let index = i;
        if (i === currentPage) {
            li.push(
                <li className="uk-active" key={i}>
                    <span>{i}</span>
                </li>
            );
        } else {
            li.push(
                <li key={i}>
                    <span
                        className="uk-pointer"
                        onClick={() => onClick ? onClick(index) : onClickPageChange(index)}
                    >
                        {i}
                    </span>
                </li>
            );
        }
    }
    if (element.end < pageLast) {
        li.push(
            <li key={"next"}>
                <span
                    className="uk-pointer"
                    data-uk-pagination-next
                    onClick={() => onClick ? onClick(Number(element.end) + 1) : onClickPageChange(Number(element.end) + 1)}
                ></span>
            </li>
        );
    }
    if (currentPage != pageLast) {
        li.push(
            <li key={"last"}>
                <span
                    className="mdi mdi-chevron-double-right uk-text-1_4 uk-margin-m5-top uk-display-inline-block uk-pointer"
                    onClick={() => onClick ? onClick(pageLast) : onClickPageChange(pageLast)}
                ></span>
            </li>
        );
    }
    ul.push(
        <ul
            className="uk-pagination uk-flex-center"
            data-uk-margin
            key={"pagenation"}
        >
            {li}
        </ul>
    );
    tag.push(<div key={"pageDiv"}>{ul}</div>);
    return tag;
};

export default Pagination;
