import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadBrandAction, updateBrandAction, createBrandAction, updateBrandResetAction } from '../../../reducers/brand';

import UseFormForBrandSetting from '../../../hook/UseFormForBrandSetting';

import UIKit from '../../UIKit';
import Header from '../../Widget/Header';

import PgInfoModal from './PgInfoModal';

const FormStyle = ({ paramId }) => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const {
        loadBrandLoading,
        brand,
        loadBrandDone,
        updateBrand,
        updateBrandDone,
        updateBrandError,
        createBrandError,
        createBrandDone,
        createBrand
    } = useSelector((state) => state.brand);
    const formDatas = UseFormForBrandSetting({
        seq: -1,
        name: '',
        domain: '',
        logo: '',
        logo2: '',
        favicon: '',
        metaImage: '',
        status: '',
        business_seq: '',
        textFooter: '',
        foldFooter: '',
        regdate: '',
        moddate: '',
        firebaseServiceKey: '',
        snsClientId: {
            kakao: '',
            google: '',
            naver: ''
        },
        snsClientSecret: {
            kakao: '',
            google: '',
            naver: ''
        },
        pgType: '',
        pixelId: '',
        googleAdsId: '',
        ga: {
            ids: '',
            tid: '',
        },
        smtp: {
            email: '',
            password: '',
        },
        kakaoChannel: {
            name: '',
            id: '',
            javascriptKey: '',
        },
        siteInfo: {
            keyword: '',
            description: '',
            siteNameKorean: '',
            siteNameEnglish: '',
            naverSiteKey: '',
            googleSiteKey: ''
        },
        deliveryPrice: 0,
        freeShippingStandard: 0,
        deliverySetting: {
            jeju: 0,
            island: 0,
            returnDeliveryPrice: 0,
            exchangeDeliveryPrice: 0,
        },
        hostingToken: '',
        cdn: '',
        facebookConv: '',
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [openPgInfoModal, setOpenPgInfoModal ] = useState(false);

    useEffect(() => {
        if(paramId) {
            dispatch(loadBrandAction(paramId));
        }
    }, []);

    useEffect(() => {
        if(loadBrandDone) {
            if(paramId) {
                formDatas.setInitValue(brand);
            }
        }
    }, [loadBrandDone]);

    useEffect(() => {
        if(updateBrandDone) {
            alert(updateBrand.data.msg);
            // window.location.reload();
        }

        if(createBrandDone) {
            alert(createBrand.data.msg);
            window.location.href = `/setting/brand/all/${createBrand.data.seq}`;
        }
    }, [updateBrandDone, createBrandDone]);

    useEffect(() => {
        if(updateBrandError) {
            setErrorMessage(Object.values(updateBrandError)[0]);
            if(formRef?.current[Object.keys(updateBrandError)[0]]) {
                if(formRef.current[Object.keys(updateBrandError)[0]].focus) {
                    formRef.current[Object.keys(updateBrandError)[0]].focus();
                }

            }

        }
        if(createBrandError) {
            setErrorMessage(Object.values(createBrandError)[0]);
            if(formRef?.current[Object.keys(createBrandError)[0]]) {
                if(formRef.current[Object.keys(createBrandError)[0]].focus) {
                    formRef.current[Object.keys(createBrandError)[0]].focus();
                }
            }
        }
    }, [updateBrandError, createBrandError]);


    const onSubmit = useCallback((e) => {
        e.preventDefault();
        const target = document.getElementById("brandForm");
        const formData = new FormData(target);
        let pixelId = typeof formDatas.value.pixelId === "object" ? formDatas.value.pixelId : formDatas.value.pixelId.split(",");
        let googleAdsId = typeof formDatas.value.googleAdsId === "object" ? formDatas.value.googleAdsId : formDatas.value.googleAdsId.split(",");
        formData.append("snsClientId", JSON.stringify(formDatas.value.snsClientId));
        formData.append("snsClientSecret", JSON.stringify(formDatas.value.snsClientSecret));
        formData.append("pixelId", JSON.stringify(pixelId));
        formData.append("googleAdsId", JSON.stringify(googleAdsId));
        formData.append("smtp", JSON.stringify(formDatas.value.smtp));
        formData.append("kakaoChannel", JSON.stringify(formDatas.value.kakaoChannel));
        formData.append("siteInfo", JSON.stringify(formDatas.value.siteInfo));
        formData.append("facebookConv", JSON.stringify(formDatas.value.facebookConv));

        formData.append("ga", JSON.stringify(formDatas.value.ga));

        if (String(formDatas.value.deliveryPrice).replace(/,/g, "")) {
            formData.append('deliveryPrice', String(formDatas.value.deliveryPrice).replace(/,/g, ""));
        }

        if (String(formDatas.value.freeShippingStandard).replace(/,/g, "")) {
            formData.append('freeShippingStandard', String(formDatas.value.freeShippingStandard).replace(/,/g, ""));
        }

        if(formDatas.value.deliverySetting) {
            const deliverySetting = {...formDatas.value.deliverySetting};


            if (String(formDatas.value.deliverySetting.island).replace(/,/g, "")) {

                deliverySetting.island = String(formDatas.value.deliverySetting.island).replace(/,/g, "");
            }

            if (String(formDatas.value.deliverySetting.jeju).replace(/,/g, "")) {
                deliverySetting.jeju = String(formDatas.value.deliverySetting.jeju).replace(/,/g, "");
            }

            if (String(formDatas.value.deliverySetting.returnDeliveryPrice).replace(/,/g, "")) {
                deliverySetting.returnDeliveryPrice = String(formDatas.value.deliverySetting.returnDeliveryPrice).replace(/,/g, "");
            }

            if (String(formDatas.value.deliverySetting.exchangeDeliveryPrice).replace(/,/g, "")) {
                deliverySetting.exchangeDeliveryPrice = String(formDatas.value.deliverySetting.exchangeDeliveryPrice).replace(/,/g, "");
            }

            formData.append("deliverySetting", JSON.stringify(deliverySetting));
        }

        const sendData = {
            snsClientId: formDatas.value.snsClientId,
        };

        if (paramId) {
            formData.append("_method", "PUT");
        }

        if (paramId) {
            dispatch(updateBrandAction(paramId, formData));
        } else {
            dispatch(createBrandAction(formData));
        }
    },[paramId, formDatas]);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-transparent uk-button-radius uk-margin-small-right",
            buttonName: paramId ? '수정' : '등록',
            onClickAction: onSubmit
        }
    ];

    const onClickOpenPgInfoModal = useCallback(() => {
        setOpenPgInfoModal(true);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateBrandResetAction());
        }
    }, []);

    return (
        <form className="toggle-class" id="brandForm" onSubmit={onSubmit} encType="multipart/form-data" ref={formRef}>
            <div className='uk-width-1-1'>
                <div>
                    <Header title={'브랜드 ' + (paramId ? "수정" : "추가")} buttons={buttons} />
                </div>
            </div>
            <div data-uk-grid>
                <UIKit.Div classOptions={"uk-width-1-2@m"}>
                    <UIKit.Card
                        title={"기본 정보"}
                        cardOptions={"uk-card-default uk-card-style"}
                        cardBodyOptions={""}
                    >
                            {errorMessage && (
                                <div className="uk-alert-danger" data-uk-alert>
                                    <a
                                        className="uk-alert-close"
                                        data-uk-close
                                        onClick={() => setErrorMessage("")}
                                    />
                                    <p>{errorMessage}</p>
                                </div>
                            )}

                            <div data-uk-grid>
                                <div className="uk-width-1-1">
                                    <div data-uk-grid>
                                        <div className="uk-width-1-4 uk-text-center">
                                            <div>
                                                <label
                                                    className="uk-form-label uk-text-meta uk-margin-remove-top uk-margin-small-bottom uk-display-block"
                                                    htmlFor="logo"
                                                >
                                                    <span>로고</span>
                                                    <div className="uk-width-small uk-height-small uk-align-center uk-display-flex-vertical-center">
                                                        <img
                                                            className="uk-width-1-1 uk-max-height-100p"
                                                            src={formDatas.value.preview ?
                                                                    formDatas.value.preview : formDatas.value.logo ?
                                                                        formDatas.value.logo : require("../../../resource/uikit/avatar.svg")
                                                            }
                                                            alt={formDatas.value.preview ? formDatas.value.preview : formDatas.value.logo}
                                                        />
                                                    </div>
                                                </label>
                                                <div
                                                    className="uk-form-custom uk-margin-small-top"
                                                    data-uk-form-custom
                                                >
                                                    <input
                                                        type="file"
                                                        id="logo"
                                                        name="logo"
                                                        data-preview="image1"
                                                        onChange={formDatas.onChangeImage}
                                                        accept={"image/*"}
                                                    />
                                                    <button
                                                        className="uk-button uk-button-default uk-button-small"
                                                        type="button"
                                                    >
                                                        수정
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-text-center">
                                            <div>
                                                <label
                                                    className="uk-form-label uk-text-meta uk-margin-remove-top uk-margin-small-bottom uk-display-block"
                                                    htmlFor="logo2"
                                                >
                                                    <span>서브 로고</span>
                                                
                                                <div className="uk-width-small uk-height-small uk-align-center uk-background-gray uk-display-flex-vertical-center">
                                                    <img
                                                        className="uk-width-1-1 uk-max-height-100p"
                                                        src={formDatas.value.logo2Preview ?
                                                                formDatas.value.logo2Preview : formDatas.value.logo2 ?
                                                                    formDatas.value.logo2 : require("../../../resource/uikit/avatar.svg")
                                                        }
                                                        alt={formDatas.value.logo2Preview ? formDatas.value.logo2Preview : formDatas.value.logo2}
                                                    />
                                                </div>
                                                </label>
                                                <div
                                                    className="uk-form-custom uk-margin-small-top"
                                                    data-uk-form-custom
                                                >
                                                    <input
                                                        type="file"
                                                        id="logo2"
                                                        name="logo2"
                                                        data-preview="logo2"
                                                        onChange={formDatas.onChangeImage}
                                                        accept={"image/*"}
                                                    />
                                                    <button
                                                        className="uk-button uk-button-default uk-button-small"
                                                        type="button"
                                                    >
                                                        수정
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-text-center">
                                            <div>
                                                <label
                                                    className="uk-form-label uk-text-meta uk-margin-remove-top uk-margin-small-bottom uk-display-block"
                                                    htmlFor="metaImage"
                                                >
                                                    <span>메타 이미지</span>
                                                    <div className="uk-width-small uk-height-small uk-align-center uk-display-flex-vertical-center">
                                                        <img
                                                            className="uk-width-1-1 uk-max-height-100p"
                                                            src={formDatas.value.metaImagePreview ?
                                                                    formDatas.value.metaImagePreview : formDatas.value.metaImage ?
                                                                        formDatas.value.metaImage : require("../../../resource/uikit/avatar.svg")
                                                            }
                                                            alt={formDatas.value.metaImagePreview ? formDatas.value.metaImagePreview : formDatas.value.metaImage}
                                                        />
                                                    </div>
                                                </label>
                                                <div
                                                    className="uk-form-custom uk-margin-small-top"
                                                    data-uk-form-custom
                                                >
                                                    <input
                                                        type="file"
                                                        id="metaImage"
                                                        name="metaImage"
                                                        data-preview="image1"
                                                        onChange={formDatas.onChangeImage}
                                                        accept={"image/*"}
                                                    />
                                                    <button
                                                        className="uk-button uk-button-default uk-button-small"
                                                        type="button"
                                                    >
                                                        수정
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-text-center">
                                            <div>
                                                <label
                                                    className="uk-form-label uk-text-meta uk-margin-remove-top uk-margin-small-bottom uk-display-block"
                                                    htmlFor="favicon"
                                                >
                                                    <span>파비콘</span>
                                                    <div className="uk-width-small uk-height-small uk-align-center uk-display-flex-vertical-center">
                                                        <img
                                                            className="uk-width-1-1 uk-max-height-100p"
                                                            src={formDatas.value.faviconPreview ?
                                                                    formDatas.value.faviconPreview : formDatas.value.favicon ?
                                                                        formDatas.value.favicon : require("../../../resource/uikit/avatar.svg")
                                                            }
                                                            alt={formDatas.value.faviconPreview ? formDatas.value.faviconPreview : formDatas.value.favicon}
                                                        />
                                                    </div>
                                                </label>
                                                <div
                                                    className="uk-form-custom uk-margin-small-top"
                                                    data-uk-form-custom
                                                >
                                                    <input
                                                        type="file"
                                                        id="favicon"
                                                        name="favicon"
                                                        data-preview="favicon"
                                                        onChange={formDatas.onChangeImage}
                                                        accept={".ico"}
                                                    />
                                                    <button
                                                        className="uk-button uk-button-default uk-button-small"
                                                        type="button"
                                                    >
                                                        수정
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3">
                                    <div className="uk-margin-top">
                                        <label
                                            className="uk-form-label uk-text-meta"
                                            htmlFor="name"
                                        >
                                            브랜드명
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input uk-form-small"
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="브랜드명 입력"
                                                value={formDatas.value.name}
                                                onChange={formDatas.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3">
                                    <div className="uk-margin-top">
                                        <label
                                            className="uk-form-label uk-text-meta"
                                            htmlFor="domain"
                                        >
                                            도메인
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input uk-form-small"
                                                id="domain"
                                                name="domain"
                                                type="text"
                                                placeholder="도메인 입력"
                                                value={formDatas.value.domain}
                                                onChange={formDatas.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3">
                                    <div className="uk-margin-top">
                                        <label
                                            className="uk-form-label uk-text-meta"
                                            htmlFor="tel"
                                        >
                                            전화번호
                                        </label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input uk-form-small"
                                                id="tel"
                                                name="tel"
                                                type="text"
                                                placeholder="전화번호 입력"
                                                defaultValue={formDatas.value.tel}
                                                onChange={formDatas.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3">
                                    <div className="uk-margin-top">
                                        <label
                                            className="uk-form-label uk-text-meta"
                                            htmlFor="status"
                                        >
                                            쇼핑몰상태
                                        </label>
                                        <div className="uk-form-controls">
                                            <label className="uk-form-small uk-pointer uk-padding-remove-left">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="status"
                                                    value={1}
                                                    checked={parseInt(formDatas.value.status,10) === 1 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />사용
                                            </label>
                                            <label className="uk-form-small uk-pointer">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="status"
                                                    value={0}
                                                    checked={parseInt(formDatas.value.status,10) === 0 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />미사용
                                            </label>
                                            <label className="uk-form-small uk-pointer">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="status"
                                                    value={2}
                                                    checked={parseInt(formDatas.value.status,10) === 2 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />검수중
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3">
                                    <div className="uk-margin-top">
                                        <label
                                            className="uk-form-label uk-text-meta"
                                            htmlFor="textFooter"
                                        >
                                            텍스트푸터(원본)
                                        </label>
                                        <div className="uk-form-controls">
                                            <label className="uk-form-small uk-pointer uk-padding-remove-left">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="textFooter"
                                                    value={1}
                                                    checked={parseInt(formDatas.value.textFooter,10) === 1 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />사용
                                            </label>
                                            <label className="uk-form-small uk-pointer">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="textFooter"
                                                    value={0}
                                                    checked={parseInt(formDatas.value.textFooter,10) === 0 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />미사용
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3">
                                    <div className="uk-margin-top">
                                        <label
                                            className="uk-form-label uk-text-meta"
                                            htmlFor="foldFooter"
                                        >
                                            푸터노출
                                        </label>
                                        <div className="uk-form-controls">
                                            <label className="uk-form-small uk-pointer uk-padding-remove-left">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="foldFooter"
                                                    value={1}
                                                    checked={parseInt(formDatas.value.foldFooter,10) === 1 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />접기
                                            </label>
                                            <label className="uk-form-small uk-pointer">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="foldFooter"
                                                    value={0}
                                                    checked={parseInt(formDatas.value.foldFooter,10) === 0 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />펼침
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </UIKit.Card>
                    <UIKit.Card
                        title={"SEO 정보"}
                        cardOptions={"uk-card-default uk-card-style uk-margin-top"}
                        cardBodyOptions={""}
                    >
                        <div data-uk-grid>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="siteInfo.siteNameKorean"
                                    >
                                        사이트이름(한글)
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="siteInfo.siteNameKorean"
                                            name="siteInfo.siteNameKorean"
                                            type="text"
                                            placeholder="사이트이름(한글)"
                                            value={formDatas.value.siteInfo.siteNameKorean}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="siteInfo.siteNameEnglish"
                                    >
                                        사이트이름(영어)
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="siteInfo.siteNameEnglish"
                                            name="siteInfo.siteNameEnglish"
                                            type="text"
                                            placeholder="사이트이름(영어)"
                                            value={formDatas.value.siteInfo.siteNameEnglish}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="siteInfo.keyword"
                                    >
                                        키워드
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="siteInfo.keyword"
                                            name="siteInfo.keyword"
                                            type="text"
                                            placeholder="키워드 입력"
                                            value={formDatas.value.siteInfo.keyword}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="siteInfo.description"
                                    >
                                        설명
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="siteInfo.description"
                                            name="siteInfo.description"
                                            type="text"
                                            placeholder="설명 입력"
                                            value={formDatas.value.siteInfo.description}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="siteInfo.naverSiteKey"
                                    >
                                        네이버사이트 인증키
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="siteInfo.naverSiteKey"
                                            name="siteInfo.naverSiteKey"
                                            type="text"
                                            placeholder="네이버사이트 인증키"
                                            value={formDatas.value.siteInfo.naverSiteKey}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="siteInfo.googleSiteKey"
                                    >
                                        구글사이트 인증키
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="siteInfo.googleSiteKey"
                                            name="siteInfo.googleSiteKey"
                                            type="text"
                                            placeholder="구글사이트 인증키"
                                            value={formDatas.value.siteInfo.googleSiteKey}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </UIKit.Card>
                    <UIKit.Card
                        title={"배송비 관련 설정"}
                        cardOptions={"uk-card-default uk-card-style uk-margin-top"}
                        cardBodyOptions={""}
                    >
                        <div data-uk-grid>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="deliveryPrice"
                                    >
                                        기본 배송비 설정
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="deliveryPrice"
                                            name="deliveryPrice"
                                            type="text"
                                            placeholder="기본 배송비 설정"
                                            value={Number(formDatas.value.deliveryPrice).toLocaleString()}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="freeShippingStandard"
                                    >
                                        무료 배송비 금액 기준
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="freeShippingStandard"
                                            name="freeShippingStandard"
                                            type="text"
                                            placeholder="무료 배송비 금액 기준"
                                            value={Number(formDatas.value.freeShippingStandard).toLocaleString()}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="deliverySetting.island"
                                    >
                                        도서산간 배송비 설정
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="deliverySetting.island"
                                            name="deliverySetting.island"
                                            type="text"
                                            placeholder="도서산간 배송비 설정"
                                            value={Number(formDatas.value.deliverySetting.island).toLocaleString()}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="deliverySetting.jeju"
                                    >
                                        제주도 배송비 설정
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="deliverySetting.jeju"
                                            name="deliverySetting.jeju"
                                            type="text"
                                            placeholder="도서산간 배송비 설정"
                                            value={Number(formDatas.value.deliverySetting.jeju).toLocaleString()}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="deliverySetting.jeju"
                                    >
                                        반품 배송비 설정
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="deliverySetting.returnDeliveryPrice"
                                            name="deliverySetting.returnDeliveryPrice"
                                            type="text"
                                            placeholder="반품 배송비 설정"
                                            value={formDatas.value.deliverySetting.returnDeliveryPrice ? Number(formDatas.value.deliverySetting.returnDeliveryPrice).toLocaleString() : 0}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-width-1-2">
                                <div className="uk-margin-top">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="deliverySetting.jeju"
                                    >
                                        교환 배송비 설정
                                    </label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="deliverySetting.exchangeDeliveryPrice"
                                            name="deliverySetting.exchangeDeliveryPrice"
                                            type="text"
                                            placeholder="교환 배송비 설정"
                                            value={formDatas.value.deliverySetting.exchangeDeliveryPrice ? Number(formDatas.value.deliverySetting.exchangeDeliveryPrice).toLocaleString() : 0}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </UIKit.Card>
                </UIKit.Div>
                <UIKit.Div classOptions={"uk-width-1-2@m"}>
                    <UIKit.Card
                        title={"연동 정보"}
                        cardOptions={"uk-card-default uk-card-style"}
                        cardBodyOptions={""}
                    >
                        <div className="uk-child-width-1-1" data-uk-grid>
                            <div>
                                <div className="uk-child-width-1-1" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="firebaseServiceKey">
                                            Firebase 서비스 키
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="firebaseServiceKey"
                                            name="firebaseServiceKey"
                                            type="text"
                                            placeholder="Firebase 서비스 키 입력"
                                            value={formDatas.value.firebaseServiceKey ? formDatas.value.firebaseServiceKey : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-1" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="hostingToken">
                                            호스팅 토큰
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="hostingToken"
                                            name="hostingToken"
                                            type="text"
                                            placeholder="Firebase 호스팅 토큰 입력"
                                            value={formDatas.value.hostingToken ? formDatas.value.hostingToken : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-1" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="cdn">
                                            cdn주소
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="cdn"
                                            name="cdn"
                                            type="text"
                                            placeholder="cdn주소"
                                            value={formDatas.value.cdn ? formDatas.value.cdn : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-2@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="snsClientId.kakao">
                                            카카오 REST API 키
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="snsClientId.kakao"
                                            name="snsClientId.kakao"
                                            type="text"
                                            placeholder="카카오 REST API 키 입력"
                                            value={formDatas.value.snsClientId.kakao ? formDatas.value.snsClientId.kakao : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="snsClientSecret.kakao">
                                            카카오 Client Secret
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="snsClientSecret.kakao"
                                            name="snsClientSecret.kakao"
                                            type="text"
                                            placeholder="카카오 Client Secret 입력"
                                            value={formDatas.value.snsClientSecret.kakao ? formDatas.value.snsClientSecret.kakao : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-2@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="snsClientId.naver">
                                            네이버 Client ID
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="snsClientId.naver"
                                            name="snsClientId.naver"
                                            type="text"
                                            placeholder="네이버 Client ID 입력"
                                            value={formDatas.value.snsClientId.naver ? formDatas.value.snsClientId.naver : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="snsClientSecret.naver">
                                            네이버 Client Secret
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="snsClientSecret.naver"
                                            name="snsClientSecret.naver"
                                            type="text"
                                            placeholder="네이버 Client Secret 입력"
                                            value={formDatas.value.snsClientSecret.naver ? formDatas.value.snsClientSecret.naver : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-2@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="snsClientId.google">
                                            구글 Client ID
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="snsClientId.google"
                                            name="snsClientId.google"
                                            type="text"
                                            placeholder="구글 Client ID 입력"
                                            value={formDatas.value.snsClientId.google ? formDatas.value.snsClientId.google : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="snsClientSecret.google">
                                            구글 Client Secret
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="snsClientSecret.google"
                                            name="snsClientSecret.google"
                                            type="text"
                                            placeholder="구글 Client Secret 입력"
                                            value={formDatas.value.snsClientSecret.google ? formDatas.value.snsClientSecret.google : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-uk-grid>
                                    <div className="uk-width-1-3 uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="pgType">
                                            사업자정보
                                        </label>
                                        <div>
                                            <select
                                                className="uk-select uk-form-small"
                                                onChange={formDatas.onChange}
                                                name="business_seq"
                                                value={formDatas?.value?.business_seq === undefined ? "-1" : formDatas.value.business_seq}
                                            >
                                                <option value="-1">선택</option>
                                                <option value="1">문코퍼레이션</option>
                                                <option value="2">이니스컴퍼니</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3 uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="pgType">
                                            PG종류
                                        </label>
                                        <div>
                                            <label className="uk-form-small uk-pointer uk-padding-remove-left">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="pgType"
                                                    value={1}
                                                    checked={parseInt(formDatas.value.pgType,10) === 1 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />이노페이
                                            </label>
                                            <label className="uk-form-small uk-pointer">
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="pgType"
                                                    value={4}
                                                    checked={parseInt(formDatas.value.pgType,10) === 4 ? true : false}
                                                    onChange={formDatas.onChange}
                                                />이니시스
                                            </label>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3 uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="pgType">
                                            PG 정보
                                        </label>
                                        <div>
                                            <button
                                                className='uk-button uk-button-default uk-button-small'
                                                type='button'
                                                onClick={() => onClickOpenPgInfoModal()}
                                            >
                                                PG정보수정
                                            </button>
                                            { formDatas.value.pgInfo && formDatas.value.payInfo &&
                                                <PgInfoModal
                                                    openPgInfoModal = {openPgInfoModal}
                                                    setOpenPgInfoModal = {setOpenPgInfoModal}
                                                    propsPgInfo = {formDatas.value.pgInfo}
                                                    payInfo = {formDatas.value.payInfo}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-1@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="pixelId">
                                            페이스북 pixel Id
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="pixelId"
                                            name="pixelId"
                                            type="text"
                                            placeholder="페이스북 pixel id 입력"
                                            value={formDatas.value.pixelId ? formDatas.value.pixelId : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="uk-child-width-1-1@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="googleAdsId">
                                            구글 Ads 전환 Id
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="googleAdsId"
                                            name="googleAdsId"
                                            type="text"
                                            placeholder="구글 Ads 전환 id 입력"
                                            value={formDatas.value.googleAdsId ? formDatas.value.googleAdsId : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="uk-child-width-1-2@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="ga.ids">
                                            구글 GA4 속성 ID
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="ga.ids"
                                            name="ga.ids"
                                            type="text"
                                            placeholder="구글 GA4 속성 ID 입력"
                                            value={formDatas.value.ga.ids ? formDatas.value.ga.ids : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="ga.tid">
                                            구글 GA4 측정 ID(tid)
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="ga.tid"
                                            name="ga.tid"
                                            type="text"
                                            placeholder="구글 GA4 측정 ID(tid) 입력"
                                            value={formDatas.value.ga.tid ? formDatas.value.ga.tid : ''}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-2@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="smtp.email">
                                            다음 smtp 아이디
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="smtp.email"
                                            name="smtp.email"
                                            type="text"
                                            placeholder="다음 smtp용 아이디 입력"
                                            value={formDatas.value.smtp ? formDatas.value.smtp.email : ""}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="smtp.password">
                                            다음 smtp 비밀번호
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="smtp.password"
                                            name="smtp.password"
                                            type="text"
                                            placeholder="다음 smtp용 비밀번호 입력"
                                            value={formDatas.value.smtp ? formDatas.value.smtp.password : ""}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="uk-child-width-1-1@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="kakaoChannel.name">
                                            카카오 고객센터 아이디
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="kakaoChannel.name"
                                            name="kakaoChannel.name"
                                            type="text"
                                            placeholder="카카오 고객센터 @를 제외한 아이디 입력"
                                            value={formDatas.value.kakaoChannel ? formDatas.value.kakaoChannel.name : ""}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="uk-child-width-1-2@m" data-uk-grid>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="kakaoChannel.id">
                                            카카오 채널 ID
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="kakaoChannel.id"
                                            name="kakaoChannel.id"
                                            type="text"
                                            placeholder="카카오 채널 ID 입력"
                                            value={formDatas.value.kakaoChannel ? formDatas.value.kakaoChannel.id : ""}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-form-label uk-text-meta" htmlFor="kakaoChannel.javascriptKey">
                                            카카오 Javascript키
                                        </label>
                                        <input
                                            className="uk-input uk-form-small"
                                            id="kakaoChannel.javascriptKey"
                                            name="kakaoChannel.javascriptKey"
                                            type="text"
                                            placeholder="카카오 Javascript키 입력"
                                            value={formDatas.value.kakaoChannel ? formDatas.value.kakaoChannel.javascriptKey : ""}
                                            onChange={formDatas.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </UIKit.Card>
                    <UIKit.Card
                        title={"페이스북 전환 API 정보"}
                        cardOptions={"uk-card-default uk-card-style uk-margin-top"}
                        cardBodyOptions={""}
                    >
                        <div className="uk-clearfix uk-width-1-1 uk-padding-10-bottom">
                            <div className="uk-float-left">
                                <button
                                    className='uk-button uk-button-default uk-button-small'
                                    type='button'
                                    onClick={() => formDatas.addFacebookConv("facebookConv")}
                                >
                                    항목 추가
                                </button>
                            </div>
                        </div>
                        { 
                            formDatas.value.facebookConv && 
                            formDatas.value.facebookConv.map((brand,fbIndex) => (
                                <div className="uk-position-relative uk-grid-column-small uk-grid-row-large uk-child-width-1-1@s uk-border uk-border-gray2 uk-margin-remove-left" data-uk-grid key={fbIndex}>
                                    <div className='uk-card uk-card-body'>
                                        <label className="uk-form-label uk-text-meta uk-text-0_8" htmlFor={`facebookConv.id.${fbIndex}`}>id</label>
                                        <div className="uk-form-controls">
                                            <input
                                                className="uk-input uk-form-small"
                                                id={`facebookConv.id.${fbIndex}`}
                                                name={`facebookConv.id.${fbIndex}`}
                                                type="text"
                                                placeholder="id"
                                                value={brand ? brand.id : ""}
                                                onChange={formDatas.onChangeArray}
                                            />
                                        </div>
                                    </div>                                    
                                    <div className='uk-card uk-card-body uk-margin-remove-top'>
                                        <label className="uk-form-label uk-text-meta uk-text-0_8" htmlFor={`facebookConv.access_token.${fbIndex}`}>access_token</label>
                                        <div className="uk-form-controls">
                                            <div data-uk-grid>
                                                
                                            </div>
                                            <div className="">
                                                <input
                                                    className="uk-input uk-form-small"
                                                    id={`facebookConv.access_token.${fbIndex}`}
                                                    name={`facebookConv.access_token.${fbIndex}`}
                                                    type="text"
                                                    placeholder="access_token"
                                                    value={brand ? brand.access_token : ""}
                                                    onChange={formDatas.onChangeArray}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='uk-card uk-card-body uk-margin-remove-top'>
                                        <label className="uk-form-label uk-text-meta uk-text-0_8" htmlFor={`facebookConv.memo.${fbIndex}`}>memo</label>
                                        <div className="uk-form-controls">
                                            <div data-uk-grid>
                                                
                                            </div>
                                            <div className="">
                                                <input
                                                    className="uk-input uk-form-small"
                                                    id={`facebookConv.memo.${fbIndex}`}
                                                    name={`facebookConv.memo.${fbIndex}`}
                                                    type="text"
                                                    placeholder="memo"
                                                    value={brand ? brand.memo : ""}
                                                    onChange={formDatas.onChangeArray}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='uk-width-50 uk-position-absolute uk-position-top-r15 uk-position-right-r5'>
                                        <Link
                                            to="#"
                                            data-uk-close
                                            className="uk-margin-30-top"
                                            data-uk-tooltip="항목 삭제"
                                            onClick={() => formDatas.removeFacebookConv("facebookConv",fbIndex)}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </UIKit.Card>
                </UIKit.Div>
            </div>
        </form>
    )
}

FormStyle.propTypes = {
    paramId: PropTypes.string.isRequired,
};

export default FormStyle;
