import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { loadReviewsAction, destroyReviewAction, updateReviewAction } from '../../../reducers/review';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import useCheckboxForm from '../../../hook/UseCheckboxForm';

import Loader from '../Loader';
import HeaderDetailOptions from './HeaderDetailOptions';
import ReviewCardStyle from './CardStyle';
import Pagination from "../Pagination";
import Editor from '../Editor';
import UserModal from '../User/UserModal';
import HeaderStyle from './HeaderStyle';
import UIKit from "../../../Components/UIKit";
import queryString from 'query-string';

const ListStyle = () => {
    const dispatch = useDispatch();
    const { selectedBrand, loadUserDone } = useSelector((state) => state.user);
    const {
        reviews,
        loadReviewsLoading,
        loadReviewsDone,
        loadReviewsError,
        destroyReviewDone,
        destroyReviewError,
        destroyReview,
        updateReview,
        updateReviewDone,
        updateReviewError,
    } = useSelector((state) => state.review);

    const location = useLocation();
    const history = useHistory();
    const query = queryString.parse(location.search);
    const checkboxForm =  useCheckboxForm(null);
    const [isShowDetailOptions, setIsShowDetailOptions] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [targetUserSeq, setTargetUserSeq] = useState("");
    const [nowTab, setNowTab] = useState(false);
    const [isOnlyPhotoReview, setIsOnlyPhotoReivew] = useState(false);

    useEffect(() => {
        if(checkboxForm && checkboxForm.value) {
            if(checkboxForm.value.total.checked) {
                setIsShowDetailOptions(true);
                return;
            }

            const keys = Object.keys(checkboxForm.value);
            for(let key of keys) {
                if(key !== 'total' && key !== 'isShowDetailOption') {
                    if(checkboxForm.value[key].checked) {
                        setIsShowDetailOptions(true);
                        return;
                    }
                }
            }
            setIsShowDetailOptions(false);
        }
    }, [checkboxForm.value]);

    useEffect(() => {
        dispatch(loadReviewsAction(location.search));
        const parsedQuery = queryString.parse(location.search);
        setIsOnlyPhotoReivew(parsedQuery.isOnlyPhotoReview === "true" ? true : false);
    }, [location, destroyReviewDone, selectedBrand]);

    useEffect(() => {
        if(loadReviewsDone) {
            checkboxForm.init(reviews);
        }
    }, [loadReviewsDone]);

    useEffect(() => {
        if(destroyReviewDone) {
            alert(destroyReview.data.msg);
            setIsShowDetailOptions(false);
            checkboxForm.remove(destroyReview.reviewSeq);
            dispatch(loadCountsForSidenavAction());
        }

        if(destroyReviewError) {
            if(destroyReviewError.message) {
                alert(destroyReviewError.message);
            } else {
                alert(destroyReviewError.msg);
            }
        }
    }, [destroyReviewDone, destroyReviewError]);

    const onClickAllStatusChange = useCallback((status) => {
        let hideSeqs = [];
        const keys = Object.keys(checkboxForm.value);
        for(let key of keys) {
            if(key === 'total' || key === 'isShowDetailOption') continue;
            checkboxForm.value[key].checked === true && hideSeqs.push(checkboxForm.value[key].value);
        }
        hideSeqs = hideSeqs.join(',');
        const formData = new FormData();
        formData.append('status', status);
        formData.append("_method", "PUT");
        dispatch(updateReviewAction(hideSeqs, formData));
        dispatch(loadCountsForSidenavAction());
    }, [checkboxForm]);

    useEffect(() => {
        if(updateReviewDone) {
            alert(updateReview.msg);
            dispatch(loadCountsForSidenavAction());
        }

        if(updateReviewError) {
            if(updateReviewError.msg) {
                alert(updateReviewError.msg);
            } else {
                alert(updateReviewError.message);
            }
        }
    }, [updateReviewDone, updateReviewError]);

    const onClickOpenUserModal = useCallback((userSeq) => {
        setTargetUserSeq(userSeq);
        setOpenUserModal(true);
    }, []);

    const onClickOnlyPhotoReview = useCallback(() => {
        setIsOnlyPhotoReivew(!isOnlyPhotoReview);
        let makeQuery = "";
        if(query.search) {
            makeQuery = `page=1&isOnlyPhotoReview=${!isOnlyPhotoReview}&search=${query.search}`;
        } else {
            makeQuery = `page=1&isOnlyPhotoReview=${!isOnlyPhotoReview}`;
        }
        history.push({
            pathname: location.pathname,
            search: makeQuery
        });
    }, [isOnlyPhotoReview, query]);

    return loadReviewsLoading ? <Loader /> : loadReviewsDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <HeaderStyle />
            </UIKit.Div>
            {
                checkboxForm && checkboxForm.value && isShowDetailOptions && (
                    <HeaderDetailOptions
                        checkboxForm={checkboxForm}
                        destroyModelAction={destroyReviewAction}
                        onClickAllStatusChange={onClickAllStatusChange}
                    />
                )
            }
            {
                <div className={`uk-margin-small-top ${ isOnlyPhotoReview ? "uk-text-primary" : "" }`}>
                    <span className="uk-pointer uk-text-0_8" onClick={onClickOnlyPhotoReview}>
                        <span className="mdi mdi-camera-outline uk-text-1_3 uk-margin-3-right"/>
                        <span>포토구매평만 보기</span>
                    </span>
                </div>
            }

            <div className="uk-width-1-1 uk-margin-medium-top">
                {
                    checkboxForm &&
                    checkboxForm.value &&
                    checkboxForm.value.total && (
                        <label>
                            <input
                                className="uk-checkbox"
                                type="checkbox"
                                {...checkboxForm.value.total}
                                onChange={checkboxForm.onChangeCheckboxAll}
                            />
                            <span className="uk-text-0_9">전체 구매평 {reviews && reviews.total}건</span>
                        </label>
                    )
                }
            </div>

            <div className="uk-width-1-1 uk-margin-remove">
                {
                    checkboxForm.value &&
                    reviews &&
                    reviews.data &&
                    reviews.data.map((item, index) => (
                        <ReviewCardStyle
                            key={item.seq}
                            review={item}
                            checkboxForm={checkboxForm}
                            onClickOpenUserModal={onClickOpenUserModal}
                        />
                    ))
                }
            </div>
            <div className="uk-margin-medium-top">
                <Pagination
                    currentPage={reviews.current_page}
                    pageLast={reviews.last_page}
                />
            </div>
            <UserModal
                openUserModal={openUserModal}
                setOpenUserModal={setOpenUserModal}
                userSeq={targetUserSeq}
            />
        </div>
    );
};

export default ListStyle;
