import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import UIKit from "../UIKit";

const SearchHead = ({ modalId }) => {
    const location = useLocation();
    const history = useHistory();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultPerPage = parsedLocationSearch.per_page ? parsedLocationSearch.per_page : 10;
    const [perPage, setPerPage] = useState(defaultPerPage);

    useEffect(() => {
        const parsedQueryString = queryString.parse(location.search);
        if(parsedQueryString &&
            parsedQueryString.page &&
            parseInt(parsedQueryString.page) === 1 && parsedQueryString.status &&
            !parsedQueryString.per_page
        ) {
            setPerPage(10);
        }
    }, [location.search]);

    const onChangePerPage = useCallback((e) => {
        const clickedPerpage = e.target.value;
        let nextQueryString = `per_page=${clickedPerpage}`;
        let nowQueryStringObj = null;
        if(location.search) {
            nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.per_page;
            if(nowQueryStringObj.page) {
                nowQueryStringObj.page = 1;
            }
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${nextQueryString}`;
            }
        }
        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
        setPerPage(e.target.value);
    }, [history, location]);

    return (
        <div id="search-head" className="uk-position-fixed uk-background-white uk-padding-small-vertical">
            <div className="uk-container uk-container-expand uk-padding-horizontal uk-padding-medium-horizontal@m">
                <nav className="uk-navbar">
                    <div className="uk-navbar-left">
                        <select
                            className="uk-select uk-form-small uk-form-width-small"
                            id="perPage"
                            name="perPage"
                            value={perPage}
                            onChange={onChangePerPage}
                        >
                            <option value={10}>10개씩 보기</option>
                            <option value={25}>25개씩 보기</option>
                            <option value={50}>50개씩 보기</option>
                            <option value={100}>100개씩 보기</option>
                        </select>
                    </div>
                    <div className="uk-navbar-right">
                        <UIKit.Button
                            buttonOptions={'uk-button uk-button-small uk-button-default'}
                            buttonName={'검색'}
                            toggle={`target: #${modalId}`}
                        />
                    </div>
                </nav>
            </div>
        </div>
    );
};

SearchHead.propTypes = {
    modalId: PropTypes.string.isRequired
};

export default SearchHead;
