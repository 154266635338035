import React, { useState, useEffect, useCallback, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";

import { loadMeAction } from '../../../reducers/user';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import ProductFormStyle from '../../../Components/Widget/Product/FormStyle';
import UIKit from "../../../Components/UIKit";

const Edit = ({ match }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone, loadMeError } = useSelector((state) => state.user);
    const { destroyProductDone, destroyProduct } = useSelector((state) => state.product);
    const [paramId, setParamId] = useState("");
    const history = useHistory();

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    useEffect(() => {
        const {
            params: { id }
        } = match;

        if (id) {
            setParamId(id);
        }
    }, [match]);

    useEffect(() => {
        if(destroyProductDone) {
            alert(destroyProduct.data.msg);
            window.location.href = "/product/item/all";
        }
    }, [destroyProductDone]);

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <ProductFormStyle paramId={paramId} />
        </div>
    );
};

export default Edit;
