import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";
import Edit from "./Edit";

const Item = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}/new`} component={Edit} />
            <Route exact path={`${match.path}/all/:id`} component={Edit} />
            <Route exact path={`${match.path}/all`} component={List} />
            <Route
                exact
                path={match.path}
                render={() => <Redirect to="/product/item" />}
            />
        </Switch>
    );
};

export default Item;
