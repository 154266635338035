import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UIKIT from 'uikit';

import { userInitAction } from '../../../reducers/user';

import MemoFormStyle from './MemoFormStyle';

const MemoModal = ({ openMemoModal, setOpenMemoModal }) => {
    const dispatch = useDispatch();
    const { user, updateUserDone, updateUser } = useSelector((state) => state.user);

    useEffect(() => {
        if(openMemoModal) {
            UIKIT.modal('#userMemoModal').show();
            UIKIT.util.on('#userMemoModal','hide', function () {
                dispatch(userInitAction());
            });
            setOpenMemoModal(false);
        }
    }, [openMemoModal]);

    useEffect(() => {
        if(updateUserDone) {
            UIKIT.modal('#userMemoModal').hide();
            alert(updateUser.data.msg);
            setOpenMemoModal(false);
            dispatch(userInitAction());

        }
    }, [updateUserDone]);

    return (
        <div id="userMemoModal" data-uk-modal>
            <div className="uk-modal-dialog uk-modal-body">
                <h3 className="uk-modal-title">{user && user.name} 회원 관리자 메모</h3>
                <div className="uk-alert-primary" data-uk-alert>
                    <p>회원별로 기록하실 사항이 있다면 메모해 주세요. <br /> 회원에겐 표시되지 않습니다.</p>
                </div>
                <MemoFormStyle />
            </div>
        </div>
    );
};

export default MemoModal;
