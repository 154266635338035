import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const UseOnChange = (action) => {
    const dispatch = useDispatch();
    const dispatchAction = useCallback((e) => {
        if(e.target) {
            e.persist();
        }
        dispatch(action(e));
    }, [action]);

    const dispatchHaveIndexAction = useCallback((division, parentIndex="", childIndex="", e) => {
        e.persist();
        dispatch(action(division, parentIndex, childIndex, e));
    }, [action]);

    const dispatchChangeOptionList = useCallback((division, parentIndex, e) => {
        e.persist();
        dispatch(action(division, parentIndex, e));
    }, [action]);

    return { dispatchAction, dispatchHaveIndexAction, dispatchChangeOptionList };
};

export default UseOnChange;
