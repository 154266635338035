import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {managerChangeOrderDetailStatusAction} from "../../../actions/order/action";

const CancelInfoForm = ({ orderDetailSeq, returnInfoParent = null }) => {
    const dispatch = useDispatch();
    const [returnInfo, setReturnInfo] = useState(returnInfoParent ? returnInfoParent : {bankName: "", bankNum: "", accountHolder: ""});

    const onChangeInput = useCallback((e) => {
        setReturnInfo({
            ...returnInfo,
            [e.target.name] : e.target.value
        })
    }, [returnInfo]);

    const onSubmitReturnInfo = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('returnInfo', JSON.stringify(returnInfo));
        formData.append('status', 2);
        formData.append("_method", "PUT");
        dispatch(managerChangeOrderDetailStatusAction(orderDetailSeq, formData));

    }, [returnInfo, orderDetailSeq]);

    return (
        <form onSubmit={onSubmitReturnInfo}>
            <input
                className="uk-input"
                placeholder="환불은행명"
                name="bankName"
                value={returnInfo.bankName}
                onChange={onChangeInput}
            />
            <input
                className="uk-input"
                placeholder="환불계좌번호"
                name="bankNum"
                value={returnInfo.bankNum}
                onChange={onChangeInput}
            />
            <input
                className="uk-input"
                placeholder="예금주"
                name="accountHolder"
                value={returnInfo.accountHolder}
                onChange={onChangeInput}
            />
            <div className="uk-text-right">
                <button className="uk-button uk-button-text uk-margin-small-right uk-drop-close" type="button">취소</button>
                <button className="uk-button uk-button-text uk-text-primary" type="submit">저장</button>
            </div>

        </form>
    );
};

export default CancelInfoForm;
