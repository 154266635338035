import React, {} from 'react';
import ColorPicker from "../ColorPicker.js";

const BasicSetting = (props) => {
    return (
        <div>
            <div className="uk-margin-medium-bottom">
                <div className="uk-position-relative uk-margin-small-bottom">
                    <h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">색상</span></h4>
                </div>
                <div>
                    <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                        <div className='uk-width-100 uk-text-0_9'>
                            <span>가격 글자</span>
                        </div>
                        <div className="uk-width-expand">
                            <ColorPicker 
                                inputNameData="template.basic.color.font.price"
                                onCompleteData={props.data.onChangeNameSet}
                                settingData={props.data.value.template.basic.color.font.price}
                                pickerPosition="right"
                            />
                        </div>
                    </div>
                    <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                        <div className='uk-width-100 uk-text-0_9'>
                            <span>버튼 글자</span>
                        </div>
                        <div className="uk-width-expand">
                            <ColorPicker 
                                inputNameData="template.basic.color.font.button"
                                onCompleteData={props.data.onChangeNameSet}
                                settingData={props.data.value.template.basic.color.font.button}
                                pickerPosition="right"
                            />
                        </div>
                    </div>
                    <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                        <div className='uk-width-100 uk-text-0_9'>
                            <span>버튼 배경</span>
                        </div>
                        <div className="uk-width-expand">
                            <ColorPicker 
                                inputNameData="template.basic.color.background.button"
                                onCompleteData={props.data.onChangeNameSet}
                                settingData={props.data.value.template.basic.color.background.button}
                                pickerPosition="right"
                            />
                        </div>
                    </div>
                    <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                        <div className='uk-width-100 uk-text-0_9'>
                            <span>구매평 별점</span>
                        </div>
                        <div className="uk-width-expand">
                            <ColorPicker 
                                inputNameData="template.basic.color.font.reviewScore"
                                onCompleteData={props.data.onChangeNameSet}
                                settingData={props.data.value.template.basic.color.font.reviewScore}
                                pickerPosition="right"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-margin-medium-bottom">
                <div className="uk-position-relative uk-margin-small-bottom">
                    <h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">추가 옵션</span></h4>
                </div>
                <div>
                    <div className="settingItem uk-margin-top">
						<input
							type="checkbox"
							className={`uk-checkbox`}
							id="template.basic.option.kakaoChat.status"
							name="template.basic.option.kakaoChat.status"
							onChange={props.data.onChange}
							checked={props.data.value.template.basic.option.kakaoChat.status}
						/>
						<label htmlFor="template.basic.option.kakaoChat.status" className="uk-text-0_9 uk-pointer uk-vertical-top"><span>카카오 채팅 아이콘 표시</span></label>
					</div>
                    {
                        props.data.value.template.basic.option.kakaoChat.status && (
                            <>
                                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                                    <div className='uk-width-100 uk-text-0_9'>
                                        <span>아이콘 색상</span>
                                    </div>
                                    <div className="uk-width-expand">
                                        <ColorPicker 
                                            inputNameData="template.basic.option.kakaoChat.color"
                                            onCompleteData={props.data.onChangeNameSet}
                                            settingData={props.data.value.template.basic.option.kakaoChat.color}
                                            pickerPosition="right"
                                        />
                                    </div>
                                </div>
                                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                                    <div className='uk-width-100 uk-text-0_9'>
                                        <span>배경 색상</span>
                                    </div>
                                    <div className="uk-width-expand">
                                        <ColorPicker 
                                            inputNameData="template.basic.option.kakaoChat.backgroundColor"
                                            onCompleteData={props.data.onChangeNameSet}
                                            settingData={props.data.value.template.basic.option.kakaoChat.backgroundColor}
                                            pickerPosition="right"
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default BasicSetting;