import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useConfirm from '../../hook/UseConfirm';
import UIKit from '../UIKit';

const HeaderDetailOptions = ({checkboxForm, destroyModelAction}) => {
    const dispatch = useDispatch();
    const { destroyUserDone, destroyUserError, destroyUser, users } = useSelector((state) => state.user);

    const destroyParentAction = useCallback(async(destroySeqs) => {
        dispatch(destroyModelAction(destroySeqs));
    }, []);

    const destroyAction = useCallback(async () => {
        let destroySeqs = [];
        const keys = Object.keys(checkboxForm.value);
        keys.map(key => {
          if(key === 'total') return;
          checkboxForm.value[key].checked === true && destroySeqs.push(checkboxForm.value[key].value);
        });

        destroySeqs = destroySeqs.join(',');
        destroyParentAction(destroySeqs);
    },[checkboxForm]);

     const onClickConfirmDestroy = useConfirm('삭제된 항목은 복원할 수 없습니다.\n선택항목을 삭제하시겠습니까?', destroyAction);

    return (
        <div>
          <header id="top-head" className="uk-position-fixed uk-position-z-index">
              <div className="uk-container uk-container-expand uk-background-primary uk-padding-horizontal uk-padding-medium-horizontal@m">
                  <nav
                      className="uk-navbar"
                      data-uk-navbar="mode:click; duration: 250"
                  >
                      <div className="uk-navbar-left">
                          <div className="uk-navbar-item uk-padding-remove-left uk-light">
                              <Link
                                  className="uk-navbar-toggle uk-padding-remove-left uk-hidden@m"
                                  data-uk-toggle="target:#offcanvas-nav"
                                  data-uk-navbar-toggle-icon
                                  to='#'
                              ></Link>
                              <span className="uk-text-white uk-text-1_2">
                                  {checkboxForm.value.total.value}건 선택
                              </span>
                          </div>

                          <ul className="uk-navbar-nav uk-visible@m"></ul>
                      </div>
                      <div className="uk-navbar-right">
                          <div className="uk-navbar-item uk-padding-remove-left uk-right">
                              <UIKit.Button
                                  buttonOptions={'uk-button uk-button-small uk-button-white uk-button-radius'}
                                  buttonName={'기능 ▽'}
                              />
                              <div className="uk-padding-remove" uk-dropdown="mode: click; pos:bottom-right;">
                                <ul className="uk-nav uk-dropdown-nav uk-list-padding-small uk-list-hover-default">
                                    <li onClick={onClickConfirmDestroy}><span>일괄 삭제</span></li>
                                </ul>
                              </div>
                          </div>
                          <ul className="uk-navbar-nav uk-visible@m"></ul>
                      </div>
                  </nav>
              </div>
          </header>
        </div>
    )
};

export default HeaderDetailOptions;
