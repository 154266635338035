import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import List from "./List";
import Edit from "./Edit";
import Group from "./Group";
import GroupEdit from "./GroupEdit";
import Point from "./Point";
import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';

const User = ({ match }) => {
    return (
        <WithSideNavLayout>
            <Switch>
                <Route exact path={`${match.path}/new`} component={Edit} />
                <Route exact path={`${match.path}/all`} component={List} />
                <Route exact path={`${match.path}/all/:id`} component={Edit} />
                <Route exact path={`${match.path}/Group`} component={Group} />
                <Route
                    exact
                    path={`${match.path}/Group/:id`}
                    component={GroupEdit}
                />
                <Route exact path={`${match.path}/Point`} component={Point} />
                <Route
                    exact
                    path={match.path}
                    render={() => <Redirect to="/user/all" />}
                />
            </Switch>
        </WithSideNavLayout>
    );
};

export default User;
