import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { loadDevSettingAction, updateDevSettingAction, createDevSettingAction } from '../../../reducers/devSetting';

import UseFormImage from '../../../hook/UseFormImage';

const FormStyle = ({ paramId }) => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const {
        loadDevSettingLoading,
        devSetting,
        loadDevSettingDone,
        updateDevSetting,
        updateDevSettingDone,
        updateDevSettingError,
        createDevSettingError,
        createDevSettingDone,
        createDevSetting
    } = useSelector((state) => state.devSetting);

    const { me } = useSelector((state) => state.user);

    const formDatas = UseFormImage({
        seq: -1,
        domain: '',
        brand_seq: '',
        regdate: ''
    });
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            if (paramId) {
                formData.append("_method", "PUT");
            }
            if (paramId) {
                dispatch(updateDevSettingAction(paramId, formData));
            } else {
                dispatch(createDevSettingAction(formData));
            }
        }, [paramId]);

        useEffect(() => {
            if(paramId) {
                dispatch(loadDevSettingAction(paramId));
            }
        }, []);

        useEffect(() => {
            if(loadDevSettingDone) {
                if(paramId) {
                    formDatas.setInitValue(devSetting);
                }
            }
        }, [loadDevSettingDone]);

        useEffect(() => {
            if(updateDevSettingDone) {
                alert(updateDevSetting.data.msg);
                window.location.reload();
            }

            if(createDevSettingDone) {
                alert(createDevSetting.data.msg);
                window.location.href = `/setting/devSetting/all/${createDevSetting.data.seq}`;
            }
        }, [updateDevSettingDone, createDevSettingDone]);

        useEffect(() => {
            if(updateDevSettingError) {
                setErrorMessage(Object.values(updateDevSettingError)[0]);
                if(formRef?.current[Object.keys(updateDevSettingError)[0]]) {
                    formRef.current[Object.keys(updateDevSettingError)[0]].focus();
                }

            }

            if(createDevSettingError) {
                setErrorMessage(Object.values(createDevSettingError)[0]);
                if(formRef?.current[Object.keys(createDevSettingError)[0]]) {
                    formRef.current[Object.keys(createDevSettingError)[0]].focus();
                }

            }
        }, [updateDevSettingError, createDevSettingError]);
    return (
        <form
            className="toggle-class"
            onSubmit={onSubmit}
            encType="multipart/form-data"
            ref={formRef}
        >
            {errorMessage && (
                <div className="uk-alert-danger" data-uk-alert>
                    <Link
                        to="#"
                        className="uk-alert-close"
                        data-uk-close
                        onClick={() => setErrorMessage()("")}
                    />
                    <p>{errorMessage}</p>
                </div>
            )}

            <div data-uk-grid>
                <div className="uk-width-1-2">
                    <div className="uk-margin-top">
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="id"
                        >
                            도메인
                        </label>
                        <div className="uk-form-controls">
                            <input
                                className="uk-input uk-form-small"
                                id="domain"
                                name="domain"
                                type="text"
                                placeholder="도메인 입력"
                                value={formDatas.value.domain}
                                onChange={formDatas.onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <div className="uk-margin-top">
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="name"
                        >
                            브랜드
                        </label>
                        <div className="uk-form-controls">
                            <select className="uk-select" name="brand_seq" id="brand_seq" value={formDatas.value.brand_seq} onChange={formDatas.onChange}>
                                {
                                    me.brand && me.brand.map(data => (
                                        <option key={data.seq} value={data.seq}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-align-right uk-margin-medium-top">
                <button
                    type="submit"
                    className="uk-button uk-button-primary"
                >
                    저장
                </button>
            </div>
        </form>
    )
}

FormStyle.propTypes = {
    paramId: PropTypes.string.isRequired,
};

export default FormStyle;
