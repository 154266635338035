import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIKIT from 'uikit';

import {
    loadDeliveryExcelListAction,
    downloadDeliveryExcelAction,
    destroyDeliveryExcelAction,
    downloadDeliveryExcelResetAction,
    destroyDeliveryExcelResetAction
} from '../../../reducers/order';

import { getDownloadDate } from '../../../common';

const ExcelFilesModal = ({ openExcelFilesModal, setOpenExcelFilesModal }) => {
    const dispatch = useDispatch();
    const {
        downloadDeliveryExcelLoading,
        downloadDeliveryExcelDone,
        downloadDeliveryExcel,
        destroyDeliveryExcelLoading,
        destroyDeliveryExcelDone,
        destroyDeliveryExcelError,
        destroyDeliveryExcel,
        deliveryExcelList,
        loadDeliveryExcelListDone
    } = useSelector((state) => state.order);

    useEffect(() => {
        if(openExcelFilesModal) {
            dispatch(loadDeliveryExcelListAction());
        }

    }, [openExcelFilesModal]);

    const downloadExcel = useCallback((deliveryExcelDate, fileName) => {
        let deliveryExcelDateSplit = deliveryExcelDate.split("-");
        let deliveryExcelDateJoin = deliveryExcelDateSplit.join("");

        let query = `${deliveryExcelDateJoin}/${encodeURIComponent(fileName)}`;
        dispatch(downloadDeliveryExcelAction(query));
    }, []);

    useEffect(() => {
        if(downloadDeliveryExcelDone) {
            if(downloadDeliveryExcel.file && downloadDeliveryExcel.name) {
                const linkSource = "data:application/pdf;base64,"+ downloadDeliveryExcel.file;
                const link = document.createElement('a');
                link.href = linkSource;
                link.setAttribute('download', downloadDeliveryExcel.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }
        }
    }, [downloadDeliveryExcelDone]);

    const deleteDeliveryExcel = useCallback(async(deliveryExcelDate, fileName) => {
        let deliveryExcelDateSplit = deliveryExcelDate.split("-");
        let deliveryExcelDateJoin = deliveryExcelDateSplit.join("");
        dispatch(destroyDeliveryExcelAction(`${deliveryExcelDateJoin}/${encodeURIComponent((fileName))}`));
    }, []);

    useEffect(() => {
        if(destroyDeliveryExcelDone) {
            alert(destroyDeliveryExcel.msg);
            dispatch(loadDeliveryExcelListAction());
        }

        if(destroyDeliveryExcelError) {
            alert(destroyDeliveryExcelError.msg);
        }
    }, [destroyDeliveryExcelDone, destroyDeliveryExcelError]);

    useEffect(() => {
        UIKIT.util.on('#excelFiles', 'hide', function () {
            dispatch(destroyDeliveryExcelResetAction());
        });
        return () => {
            UIKIT.util.off('#excelFiles', 'hide');
            dispatch(downloadDeliveryExcelResetAction());
        }
    }, []);

    return (
        <div id="excelFiles" data-uk-modal="container: false;" className="uk-modal-container uk-position-z-index-9999999">
            <div className="uk-modal-body uk-modal-dialog uk-width-800 uk-margin-auto-vertical uk-padding-xsmall-horizontal uk-padding-xsmall-top" data-uk-overflow-auto>
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-header">
                    <h2 className="uk-modal-title">
                        배송준비처리된 주문엑셀 다운로드
                    </h2>
                </div>
                <div className="uk-modal-body">
                    <ul data-uk-accordion>
                        {
                            deliveryExcelList &&
                            Object.keys(deliveryExcelList).length > 0 &&
                            Object.keys(deliveryExcelList).sort().reverse().map((deliveryExcelDate, index) => (
                                <li key={index}>
                                    <a className="uk-accordion-title" href="#">
                                        {deliveryExcelDate}일 엑셀 목록
                                    </a>
                                    <div className="uk-accordion-content">
                                        <table className="uk-table uk-table-divider uk-text-center">
                                            <thead>
                                                <tr>
                                                    <th className="uk-text-center">파일명</th>
                                                    <th className="uk-text-center">생성날짜</th>
                                                    <th className="uk-text-center">다운로드<br/> 가능 기간</th>
                                                    <th className="uk-text-center">다운로드</th>
                                                    <th className="uk-text-center">삭제</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    deliveryExcelList[deliveryExcelDate].map((detail) => (
                                                        <tr key={detail.seq}>
                                                            <td className="uk-text-center">{detail.fileName}</td>
                                                            <td className="uk-text-center">{detail.regdate}</td>
                                                            <td className="uk-text-center">{getDownloadDate(deliveryExcelDate)}</td>
                                                            <td className="uk-text-center">
                                                                {
                                                                    detail.isFinish ? (
                                                                        <button
                                                                            className="uk-button uk-button-primary uk-button-small"
                                                                            onClick={() => downloadExcel(deliveryExcelDate, detail.fileName)}
                                                                        >
                                                                            다운로드
                                                                        </button>
                                                                    ) : detail.errorLog ? (
                                                                        <p
                                                                            className="uk-text-danger"
                                                                        >
                                                                            에러발생 : {detail.errorLog}
                                                                        </p>
                                                                    ) : (
                                                                        <p

                                                                        >
                                                                            처리중입니다...
                                                                        </p>
                                                                    )
                                                                }

                                                            </td>
                                                            <td className="uk-text-center">
                                                                <button
                                                                    className="uk-button uk-button-danger uk-button-small"
                                                                    onClick={() => deleteDeliveryExcel(deliveryExcelDate, detail.fileName)}
                                                                >
                                                                    삭제
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="uk-modal-footer">
                </div>
            </div>
        </div>
    )
};

export default ExcelFilesModal;
