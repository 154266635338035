import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select,
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_ME_REQUEST,
    LOAD_ME_SUCCESS,
    LOAD_ME_FAILURE,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAILURE,
    LOAD_USERS_REQUEST,
    LOAD_USERS_SUCCESS,
    LOAD_USERS_FAILURE,
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    DESTROY_USER_REQUEST,
    DESTROY_USER_SUCCESS,
    DESTROY_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
} from '../reducers/user';

const getMe = state => {
    return state.user.me;
}

function loadMeAPI(user) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }


  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get("/admins/me", {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadMe(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadMeAPI, user);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: LOAD_ME_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ME_FAILURE,
      error: e.response.data,
    });
  }
}

function loadUserAPI(user, userSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`/users/${userSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadUser(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadUserAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: LOAD_USER_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_USER_FAILURE,
      error: e.response.data,
    });
  }
}

function loadUsersAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  let endPoint = "";
  if(query) {
      endPoint = `/users${query}`
  } else {
      endPoint = "/users";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadUsers(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadUsersAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: LOAD_USERS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_USERS_FAILURE,
      error: e.response.data,
    });
  }
}

function loginUserAPI(code) {
  // 서버에 요청을 보내는 부분
  return axios.post("/admins/login", { code }, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
  });
}

function* loginUser(action: any) {
  try {
    const result = yield call(loginUserAPI, action.code);
    const { token } = result.data;
    if (token) {
        localStorage.removeItem('selectedBrand');
        localStorage.setItem("token", token);
    }

    yield put({
      // put은 dispatch 동일
      type: LOGIN_USER_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOGIN_USER_FAILURE,
      error: e.response.data,
    });
  }
}

function logoutUserAPI(code) {
  // 서버에 요청을 보내는 부분
  const token = `bearer${localStorage.getItem("token")}`;
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get("/admins/logout", {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* logoutUser(action: any) {
  try {
    const result = yield call(logoutUserAPI, action.code);
    localStorage.removeItem('token');
    localStorage.removeItem('selectedBrand');

    yield put({
      // put은 dispatch 동일
      type: LOGOUT_USER_SUCCESS,
    });
  } catch (e) {
    // logoutAPI 실패
    yield put({
      type: LOGOUT_USER_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyUserAPI(user, userSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }


  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.delete(`/users/${userSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyUser(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(destroyUserAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: DESTROY_USER_SUCCESS,
      data: result.data,
      userSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_USER_FAILURE,
      error: e.response.data,
    });
  }
}

function updateUserAPI(user, userSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/users/${userSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateUser(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateUserAPI, user, action.userSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_USER_SUCCESS,
      data: result.data,
      userSeq: action.userSeq,
      memo: action.data.get("memo"),
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_USER_FAILURE,
      error: e.response.data,
    });
  }
}

function createUserAPI(user, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/users', formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createUser(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(createUserAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: CREATE_USER_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_USER_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadMe() {
  yield takeLatest(LOAD_ME_REQUEST, loadMe);
}

function* watchLoadUser() {
  yield takeLatest(LOAD_USER_REQUEST, loadUser);
}

function* watchLoadUsers() {
  yield takeLatest(LOAD_USERS_REQUEST, loadUsers);
}

function* watchLoginUser() {
  yield takeLatest(LOGIN_USER_REQUEST, loginUser);
}

function* watchLogoutUser() {
  yield takeLatest(LOGOUT_USER_REQUEST, logoutUser);
}

function* watchDestroyUser() {
  yield takeLatest(DESTROY_USER_REQUEST, destroyUser);
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
}

function* watchCreateUser() {
  yield takeLatest(CREATE_USER_REQUEST, createUser);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadUser),
      fork(watchLoadMe),
      fork(watchLoginUser),
      fork(watchLogoutUser),
      fork(watchLoadUsers),
      fork(watchDestroyUser),
      fork(watchUpdateUser),
      fork(watchCreateUser),
  ]);
}
