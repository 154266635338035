import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  delay
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_SAMPLES_REQUEST,
    LOAD_SAMPLES_SUCCESS,
    LOAD_SAMPLES_FAILURE,
    CREATE_SAMPLE_REQUEST,
    CREATE_SAMPLE_SUCCESS,
    CREATE_SAMPLE_FAILURE,
    REMOVE_SAMPLE_REQUEST,
    REMOVE_SAMPLE_SUCCESS,
    REMOVE_SAMPLE_FAILURE,
    LOAD_SAMPLE_REQUEST,
    LOAD_SAMPLE_SUCCESS,
    LOAD_SAMPLE_FAILURE,
    UPDATE_SAMPLE_REQUEST,
    UPDATE_SAMPLE_SUCCESS,
    UPDATE_SAMPLE_FAILURE,
} from '../reducers/sample';


function loadSamplesAPI() {
  // 서버에 요청을 보내는 부분
  // const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  // return axios.get("/dashboards/countsForSideNav", {
  //   withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
  //   headers: {
  //       Brand: brand
  //   }
  // });
  const dummy = {};
  dummy.data = [
      {
          seq: 1,
          title: "제목1",
          description: "내용1",
          regdate: "등록일1",
      },
      {
          seq: 2,
          title: "제목2",
          description: "내용2",
          regdate: "등록일2",
      },
      {
          seq: 3,
          title: "제목3",
          description: "내용3",
          regdate: "등록일3",
      },
      {
          seq: 4,
          title: "제목4",
          description: "내용4",
          regdate: "등록일4",
      },
      {
          seq: 5,
          title: "제목5",
          description: "내용5",
          regdate: "등록일5",
      },
  ];
  return dummy;
}

function* loadSamples(action) {
  try {
    yield delay(1000);
    const result = yield call(loadSamplesAPI);
    yield put({
      // put은 dispatch 동일
      type: LOAD_SAMPLES_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_SAMPLES_FAILURE,
      error: e.response.data,
    });
  }
}

function createSampleAPI(data) {
  // 서버에 요청을 보내는 부분
  // const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  // return axios.get("/dashboards/countsForSideNav", {
  //   withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
  //   headers: {
  //       Brand: brand
  //   }
  // });
  const dummy = {};
  dummy.data = {...data};
  return dummy;
}

function* createSample(action) {
  try {
    yield delay(1000);
    const result = yield call(createSampleAPI, action.data);
    yield put({
      // put은 dispatch 동일
      type: CREATE_SAMPLE_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_SAMPLE_FAILURE,
      error: e.response.data,
    });
  }
}

function removeSampleAPI(data) {
  // 서버에 요청을 보내는 부분
  // const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  // return axios.get("/dashboards/countsForSideNav", {
  //   withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
  //   headers: {
  //       Brand: brand
  //   }
  // });
  const dummy = {};
  return dummy;
}

function* removeSample(action) {
  try {
    yield delay(1000);
    const result = yield call(removeSampleAPI, action.data);
    yield put({
      // put은 dispatch 동일
      type: REMOVE_SAMPLE_SUCCESS,
      data: result.data,
      seq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: REMOVE_SAMPLE_FAILURE,
      error: e.response.data,
    });
  }
}

function loadSampleAPI() {
  // 서버에 요청을 보내는 부분
  // const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  // return axios.get("/dashboards/countsForSideNav", {
  //   withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
  //   headers: {
  //       Brand: brand
  //   }
  // });
  const dummy = {};
  return dummy;
}

function* loadSample(action) {
  try {
    yield delay(1000);
    const result = yield call(loadSampleAPI);
    yield put({
      // put은 dispatch 동일
      type: LOAD_SAMPLE_SUCCESS,
      data: result.data,
      seq: action.data
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_SAMPLE_FAILURE,
      error: e.response.data,
    });
  }
}

function updateSampleAPI(seq, data) {
  // 서버에 요청을 보내는 부분
  // const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  // return axios.get("/dashboards/countsForSideNav", {
  //   withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
  //   headers: {
  //       Brand: brand
  //   }
  // });
  const dummy = {};
  dummy.data = {...data};
  return dummy;
}

function* updateSample(action) {
  try {
    yield delay(1000);
    const result = yield call(updateSampleAPI, action.seq, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_SAMPLE_SUCCESS,
      data: result.data,
      seq: action.seq
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_SAMPLE_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadSamples() {
  yield takeEvery(LOAD_SAMPLES_REQUEST, loadSamples);
}

function* watchcreateSample() {
  yield takeEvery(CREATE_SAMPLE_REQUEST, createSample);
}

function* watchRemoveSample() {
  yield takeEvery(REMOVE_SAMPLE_REQUEST, removeSample);
}

function* watchLoadSample() {
  yield takeEvery(LOAD_SAMPLE_REQUEST, loadSample);
}

function* watchUpdateSample() {
  yield takeEvery(UPDATE_SAMPLE_REQUEST, updateSample);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadSamples),
      fork(watchcreateSample),
      fork(watchRemoveSample),
      fork(watchLoadSample),
      fork(watchUpdateSample),
  ]);
}
