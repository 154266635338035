import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import List from "./List";
import Edit from "./Edit";
import CancelList from './CancelList';
import ReturnList from './ReturnList';
import ExchangeList from './ExchangeList';
import NaverPayCancelList from './NaverPayCancelList';

import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';


const Order = ({ match }) => {
    return (
        <WithSideNavLayout>
            <Switch>
                <Route exact path={`${match.path}/all`} component={List} />
                <Route exact path={`${match.path}/new`} component={Edit} />
                <Route exact path={`${match.path}/cancel`} component={CancelList} />
                <Route exact path={`${match.path}/naverpay/cancel`} component={NaverPayCancelList} />
                <Route exact path={`${match.path}/return`} component={ReturnList} />
                <Route exact path={`${match.path}/exchange`} component={ExchangeList} />
                <Route
                    exact
                    path={`${match.path}/all/:id`}
                    component={Edit}
                />
                <Redirect from="*" to="/order/all" />
            </Switch>
        </WithSideNavLayout>
    );
};

export default Order;
