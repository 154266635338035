import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIKIT from "uikit";

import { loadOrderAction } from '../../../reducers/order';

import OrderInfo from '../Order/Info';

const OrderModal = ({ openOrderModal, setOpenOrderModal, oId="", orderSeq="", model }) => {
    const dispatch = useDispatch();
    const { order, loadOrderLoading, loadOrderDone, loadOrderError } = useSelector((state) => state.order);

    useEffect(() => {
        if(openOrderModal) {
            if(oId){
                let search = {
                    oId
                };
                search = JSON.stringify(search);
                dispatch(loadOrderAction(`?search=${btoa(search)}`));
            } else if(orderSeq) {
                let search = {
                    seq: orderSeq
                };
                search = JSON.stringify(search);
                dispatch(loadOrderAction(`?search=${btoa(search)}`));
            } else {
                UIKIT.modal('#orderModal').hide();
                setOpenOrderModal(false);
                alert(`연동되지 않은 ${model}입니다.`);
            }
        }
    }, [openOrderModal]);

    useEffect(() => {
        if(openOrderModal && loadOrderDone) {
            UIKIT.modal('#orderModal').show();
            setOpenOrderModal(false);
        }

        if(openOrderModal && loadOrderError) {
            alert(loadOrderError.msg);
            setOpenOrderModal(false);
        }

    }, [loadOrderDone, loadOrderError]);


    return (
        <div id="orderModal" className="uk-modal-container uk-position-z-index-9999999" data-uk-modal="container:false;">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-header uk-padding-small-horizontal uk-padding-horizontal@m">주문 정보</div>
                <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                    <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-1@m">
                            <OrderInfo type={2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderModal;
