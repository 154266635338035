import React from 'react';
import { Link } from 'react-router-dom';
import ColorPicker from "../ColorPicker.js";

const HeaderSetting = (props) => {
	return (
		<div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">기본 레이아웃</span></h4>
				</div>
				<div>
					<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>타입 선택</span>
						</div>
						<div className="uk-width-expand">
							<select
								className={`uk-select`}
								name="template.header.type"
								onChange={props.data.onChange}
								value={props.data.value.template.header.type}
							>
								<option key={-1} value={""}>선택</option>
								<option key={1} value={"A"}>A</option>
								<option key={2} value={"B"}>B</option>
							</select>
						</div>
					</div>
					<div className="settingItem uk-grid-collapse uk-flex-top uk-margin-top" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>헤더 높이</span>
						</div>
						<div className="uk-width-expand">
							<div>
								<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_8 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">데스크탑</span></h4>
								<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
									<div style={{width:'49%'}}>
										<input
											type="text"
											className="uk-input"
											name="template.header.logoSize.desktop"
											onChange={props.data.onChange}
											value={props.data.value.template.header.logoSize.desktop}
										/>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">로고 높이</p>
									</div>
									<div style={{width:'49%'}}>
										<input
											type="text"
											className="uk-input"
											name="template.header.verticalPadding.desktop"
											onChange={props.data.onChange}
											value={props.data.value.template.header.verticalPadding.desktop}
										/>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">상하 여백</p>
									</div>
								</div>
							</div>
							<div className="uk-margin-small-top">
								<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_8 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">모바일</span></h4>
								<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
									<div style={{width:'49%'}}>
										<input
											type="text"
											className="uk-input"
											name="template.header.logoSize.mobile"
											onChange={props.data.onChange}
											value={props.data.value.template.header.logoSize.mobile}
										/>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">로고 높이</p>
									</div>
									<div style={{width:'49%'}}>
										<input
											type="text"
											className="uk-input"
											name="template.header.verticalPadding.mobile"
											onChange={props.data.onChange}
											value={props.data.value.template.header.verticalPadding.mobile}
										/>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">상하 여백</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="settingItem uk-grid-collapse uk-flex-top uk-margin-top" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>기본 색상</span>
						</div>
						<div className="uk-width-expand">
							<div className="uk-grid-small uk-flex-middle" data-uk-grid>
								<div className="uk-width-80 uk-text-right">
									<span className="uk-text-0_8">[배경]</span>
								</div>
								<div className="uk-width-expand">
									<ColorPicker
										inputNameData="template.header.color.background.default"
										onCompleteData={props.data.onChangeNameSet}
										settingData={props.data.value.template.header.color.background.default}
										pickerPosition="right"
									/>
								</div>
							</div>
							<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
								<div className="uk-width-80 uk-text-right">
									<span className="uk-text-0_8">[텍스트]</span>
								</div>
								<div className="uk-width-expand">
									<ColorPicker
										inputNameData="template.header.color.font.default"
										onCompleteData={props.data.onChangeNameSet}
										settingData={props.data.value.template.header.color.font.default}
										pickerPosition="right"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="settingItem uk-grid-collapse uk-flex-top uk-margin-top" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>내용 위치</span>
						</div>
						<div className="uk-width-expand">
							<div>
								<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_8 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">데스크탑</span></h4>
								{props.data.value.template.header.type == 'A' ? 
								(
									<>
										<div className="uk-grid-small uk-flex-middle" data-uk-grid>
											<div className="uk-width-80 uk-text-right">
												<span className="uk-text-0_8">[로고]</span>
											</div>
											<div className="uk-width-expand">
												<select
													className={`uk-select`}
													name="template.header.position.desktop.logo"
													onChange={props.data.onChange}
													value={props.data.value.template.header.position.desktop.logo}
												>
													<option key={-1} value={""}>선택</option>
													<option key={1} value={"left"}>왼쪽</option>
													<option key={2} value={"center"}>가운데</option>
													<option key={3} value={"right"}>오른쪽</option>
													<option key={4} value={"none"}>숨김</option>
												</select>
											</div>
										</div>
										<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
											<div className="uk-width-80 uk-text-right">
												<span className="uk-text-0_8">[페이지]</span>
											</div>
											<div className="uk-width-expand">
												<select
													className={`uk-select`}
													name="template.header.position.desktop.page"
													onChange={props.data.onChange}
													value={props.data.value.template.header.position.desktop.page}
												>
													<option key={-1} value={""}>선택</option>
													<option key={1} value={"left"}>왼쪽</option>
													<option key={2} value={"center"}>가운데</option>
													<option key={3} value={"right"}>오른쪽</option>
													<option key={4} value={"none"}>숨김</option>
												</select>
											</div>
										</div>
										<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
											<div className="uk-width-80 uk-text-right">
												<span className="uk-text-0_8">[기능]</span>
											</div>
											<div className="uk-width-expand">
												<select
													className={`uk-select`}
													name="template.header.position.desktop.feature"
													onChange={props.data.onChange}
													value={props.data.value.template.header.position.desktop.feature}
												>
													<option key={-1} value={""}>선택</option>
													<option key={1} value={"left"}>왼쪽</option>
													<option key={2} value={"center"}>가운데</option>
													<option key={3} value={"right"}>오른쪽</option>
													<option key={4} value={"none"}>숨김</option>
												</select>
											</div>
										</div>
									</>
								)
								:
								(
									<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
										<div className="uk-width-80 uk-text-right">
											<span className="uk-text-0_8">[전체]</span>
										</div>
										<div className="uk-width-expand">
											<select
												className={`uk-select`}
												name="template.header.position.desktop.page"
												onChange={props.data.onChange}
												value={props.data.value.template.header.position.desktop.page}
											>
												<option key={-1} value={""}>선택</option>
												<option key={1} value={"left"}>왼쪽</option>
												<option key={3} value={"right"}>오른쪽</option>
											</select>
										</div>
									</div>
								)
								}
							</div>
							<div className="uk-margin-15-top">
								<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_8 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">모바일</span></h4>
								<div className="uk-grid-small uk-flex-middle" data-uk-grid>
									<div className="uk-width-80 uk-text-right">
										<span className="uk-text-0_8">[로고]</span>
									</div>
									<div className="uk-width-expand">
										<select
											className={`uk-select`}
											name="template.header.position.mobile.logo"
											onChange={props.data.onChange}
											value={props.data.value.template.header.position.mobile.logo}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"left"}>왼쪽</option>
											<option key={2} value={"center"}>가운데</option>
											<option key={3} value={"right"}>오른쪽</option>
											<option key={4} value={"none"}>숨김</option>
										</select>
									</div>
								</div>
								<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
									<div className="uk-width-80 uk-text-right">
										<span className="uk-text-0_8">[기능]</span>
									</div>
									<div className="uk-width-expand">
										<select
											className={`uk-select`}
											name="template.header.position.mobile.feature"
											onChange={props.data.onChange}
											value={props.data.value.template.header.position.mobile.feature}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"left"}>왼쪽</option>
											<option key={2} value={"center"}>가운데</option>
											<option key={3} value={"right"}>오른쪽</option>
											<option key={4} value={"none"}>숨김</option>
										</select>
									</div>
								</div>
								<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
									<div className="uk-width-80 uk-text-right">
										<span className="uk-text-0_8">[메뉴바]</span>
									</div>
									<div className="uk-width-expand">
										<select
											className={`uk-select`}
											name="template.header.position.mobile.menu"
											onChange={props.data.onChange}
											value={props.data.value.template.header.position.mobile.menu}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"left"}>왼쪽</option>
											<option key={2} value={"right"}>오른쪽</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="settingItem uk-grid-collapse uk-flex-top uk-margin-top" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>오버랩 적용</span>
						</div>
						<div className="uk-width-expand">
							<div className="uk-child-width-1-2 uk-grid-collapse uk-flex-between" data-uk-grid>
								<div className="uk-text-center">
									<input
										type="checkbox"
										className={`uk-checkbox`}
										id="template.header.overlapping.desktop"
										name="template.header.overlapping.desktop"
										onChange={props.data.onChange}
										checked={props.data.value.template.header.overlapping.desktop}
									/>
									<p className="uk-margin-remove uk-text-0_8">
										<label htmlFor="template.header.overlapping.desktop" className="uk-text-1 uk-pointer"><span>데스크탑</span></label>
									</p>
								</div>
								<div className="uk-text-center">
									<input
										type="checkbox"
										className={`uk-checkbox`}
										id="template.header.overlapping.mobile"
										name="template.header.overlapping.mobile"
										onChange={props.data.onChange}
										checked={props.data.value.template.header.overlapping.mobile}
									/>
									<p className="uk-margin-remove uk-text-0_8">
										<label htmlFor="template.header.overlapping.mobile" className="uk-text-1 uk-pointer"><span>모바일</span></label>
									</p>
								</div>
							</div>
							{(props.data.value.template.header.overlapping.desktop || props.data.value.template.header.overlapping.mobile) && (
								<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
									<div className="uk-width-80 uk-text-right">
										<span className="uk-text-0_8">[텍스트색상]</span>
									</div>
									<div className="uk-width-expand">
										<ColorPicker
											inputNameData="template.header.color.font.overlapping"
											onCompleteData={props.data.onChangeNameSet}
											settingData={props.data.value.template.header.color.font.overlapping}
											pickerPosition="right"
										/>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className="settingItem uk-margin-top">
						<input
							type="checkbox"
							className={`uk-checkbox`}
							id="template.header.desktopExpand"
							name="template.header.desktopExpand"
							onChange={props.data.onChange}
							checked={props.data.value.template.header.desktopExpand}
						/>
						<label htmlFor="template.header.desktopExpand" className="uk-text-0_9 uk-pointer uk-vertical-top"><span>데스크탑 와이드 레이아웃 적용</span></label>
					</div>
				</div>
			</div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">기능</span></h4>
				</div>
				<div className="uk-padding-xsmall-horizontal">
					<div>
						<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_9 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">카트</span></h4>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>이름</span>
							</div>
							<div className="uk-width-expand">
								<input 
									type="text"
									className="uk-input"
									name="template.header.feature.cart.name"
									onChange={props.data.onChange}
									value={props.data.value.template.header.feature.cart.name}
								/>
							</div>
						</div>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>표시 방식</span>
							</div>
							<div className="uk-width-expand">
								<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.cart.display.desktop"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.cart.display.desktop}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
									</div>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.cart.display.mobile"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.cart.display.mobile}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="uk-margin-top">
						<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_9 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">로그인</span></h4>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>이름</span>
							</div>
							<div className="uk-width-expand">
								<input 
									type="text"
									className="uk-input"
									name="template.header.feature.login.name"
									onChange={props.data.onChange}
									value={props.data.value.template.header.feature.login.name}
								/>
							</div>
						</div>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>표시 방식</span>
							</div>
							<div className="uk-width-expand">
								<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.login.display.desktop"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.login.display.desktop}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
									</div>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.login.display.mobile"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.login.display.mobile}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="uk-margin-top">
						<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_9 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">로그아웃</span></h4>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>이름</span>
							</div>
							<div className="uk-width-expand">
								<input 
									type="text"
									className="uk-input"
									name="template.header.feature.logout.name"
									onChange={props.data.onChange}
									value={props.data.value.template.header.feature.logout.name}
								/>
							</div>
						</div>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>표시 방식</span>
							</div>
							<div className="uk-width-expand">
								<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.logout.display.desktop"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.logout.display.desktop}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
									</div>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.logout.display.mobile"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.logout.display.mobile}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="uk-margin-top">
						<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_9 uk-text-center uk-heading-line"><span className="font-NanumSquareRound">마이페이지</span></h4>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>이름</span>
							</div>
							<div className="uk-width-expand">
								<input 
									type="text"
									className="uk-input"
									name="template.header.feature.mypage.name"
									onChange={props.data.onChange}
									value={props.data.value.template.header.feature.mypage.name}
								/>
							</div>
						</div>
						<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>표시 방식</span>
							</div>
							<div className="uk-width-expand">
								<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.mypage.display.desktop"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.mypage.display.desktop}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
									</div>
									<div style={{width:'49%'}}>
										<select
											className={`uk-select`}
											name="template.header.feature.mypage.display.mobile"
											onChange={props.data.onChange}
											value={props.data.value.template.header.feature.mypage.display.mobile}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"none"}>숨김</option>
											<option key={2} value={"icon"}>아이콘</option>
											<option key={3} value={"text"}>텍스트</option>
										</select>
										<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">페이지</span></h4>
					<p className="uk-margin-remove uk-text-right">
						<button
							className='uk-button uk-button-default uk-button-small'
							type='button'
							onClick={props.data.onClickPageArrAdd}
						>
							항목 추가
						</button>
					</p>
				</div>
				<div className="uk-padding-xsmall-horizontal">
					{
						props.data.value.template.header.page &&
						props.data.value.template.header.page.map((page, index) => (
							<div key={index} className="uk-position-relative uk-margin-bottom">
								<p className="uk-position-top-right uk-vertical-middle uk-width-13 uk-margin-xsmall-left uk-background-white uk-pointer uk-position-z-index-2" style={{top:4}} data-uk-close onClick={() => props.data.onClickPageArrRemove(index)} />
								<h4 className="uk-margin-remove-top uk-margin-xsmall-bottom uk-text-0_9 uk-text-center uk-heading-line">
									<span className="font-NanumSquareRound">페이지{index+1}</span>
								</h4>
								<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
									<div className='uk-width-100 uk-text-0_9'>
										<span>이름</span>
									</div>
									<div className="uk-width-expand">
										<input
											type="text"
											className="uk-input"
											name={`template.header.page[${index}].name`}
											onChange={props.data.onChange}
											value={page.name}
										/>
									</div>
								</div>
								<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
									<div className='uk-width-100 uk-text-0_9'>
										<span>타입</span>
									</div>
									<div className="uk-width-expand">
										<select
											className={`uk-select`}
											name={`template.header.page[${index}].type`}
											onChange={props.data.onChange}
											value={page.type}
										>
											<option key={-1} value={""}>선택</option>
											<option key={1} value={"product"}>상품리스트</option>
											<option key={2} value={"page"}>커스텀</option>
											<option key={3} value={"external"}>외부링크</option>
										</select>
									</div>
								</div>
								{page.type != "product" && (
									<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
										<div className='uk-width-100 uk-text-0_9'>
											<span>경로</span>
										</div>
										<div className="uk-width-expand">
											<input
												type="text"
												className="uk-input"
												name={`template.header.page[${index}].href`}
												onChange={props.data.onChange}
												value={page.href}
											/>
										</div>
									</div>
								)}
							</div>
						))
					}
				</div>
			</div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">스티키</span></h4>
				</div>
				<div>
					<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>기본</span>
						</div>
						<div className="uk-width-expand">
							<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
								<div style={{width:'49%'}}>
									<select
										className={`uk-select`}
										name="template.header.sticky.desktop.default"
										onChange={props.data.onChange}
										value={props.data.value.template.header.sticky.desktop.default}
									>
										<option key={-1} value={""}>선택</option>
										<option key={1} value={"none"}>미사용</option>
										<option key={2} value={"always"}>항상</option>
										<option key={3} value={"scrollUp"}>스크롤 업</option>
									</select>
									<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
								</div>
								<div style={{width:'49%'}}>
									<select
										className={`uk-select`}
										name="template.header.sticky.mobile.default"
										onChange={props.data.onChange}
										value={props.data.value.template.header.sticky.mobile.default}
									>
										<option key={-1} value={""}>선택</option>
										<option key={1} value={"none"}>미사용</option>
										<option key={2} value={"always"}>항상</option>
										<option key={3} value={"scrollUp"}>스크롤 업</option>
									</select>
									<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
								</div>
							</div>
						</div>
					</div>
					<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>상품 상세 페이지</span>
						</div>
						<div className="uk-width-expand">
							<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
								<div style={{width:'49%'}}>
									<select
										className={`uk-select`}
										name="template.header.sticky.desktop.productDetail"
										onChange={props.data.onChange}
										value={props.data.value.template.header.sticky.desktop.productDetail}
									>
										<option key={-1} value={""}>선택</option>
										<option key={1} value={"none"}>미사용</option>
										<option key={2} value={"always"}>항상</option>
										<option key={3} value={"scrollUp"}>스크롤 업</option>
									</select>
									<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
								</div>
								<div style={{width:'49%'}}>
									<select
										className={`uk-select`}
										name="template.header.sticky.mobile.productDetail"
										onChange={props.data.onChange}
										value={props.data.value.template.header.sticky.mobile.productDetail}
									>
										<option key={-1} value={""}>선택</option>
										<option key={1} value={"none"}>미사용</option>
										<option key={2} value={"always"}>항상</option>
										<option key={3} value={"scrollUp"}>스크롤 업</option>
									</select>
									<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
								</div>
							</div>
						</div>
					</div>
					{(
						props.data.value.template.header.sticky.desktop.default == 'always' || props.data.value.template.header.sticky.desktop.default == 'scrollUp' ||
						props.data.value.template.header.sticky.mobile.default == 'always' || props.data.value.template.header.sticky.mobile.default == 'scrollUp' ||
						props.data.value.template.header.sticky.desktop.productDetail == 'always' || props.data.value.template.header.sticky.desktop.productDetail == 'scrollUp' ||
						props.data.value.template.header.sticky.mobile.productDetail == 'always' || props.data.value.template.header.sticky.mobile.productDetail == 'scrollUp'
					) && (
						<div className="settingItem uk-grid-collapse uk-flex-top" data-uk-grid>
							<div className='uk-width-100 uk-text-0_9'>
								<span>색상</span>
							</div>
							<div className="uk-width-expand">
								<div className="uk-grid-small uk-flex-middle" data-uk-grid>
									<div className="uk-width-80 uk-text-right">
										<span className="uk-text-0_8">[배경]</span>
									</div>
									<div className="uk-width-expand">
										<ColorPicker
											inputNameData="template.header.color.background.sticky"
											onCompleteData={props.data.onChangeNameSet}
											settingData={props.data.value.template.header.color.background.sticky}
											pickerPosition="right"
										/>
									</div>
								</div>
								<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
									<div className="uk-width-80 uk-text-right">
										<span className="uk-text-0_8">[텍스트]</span>
									</div>
									<div className="uk-width-expand">
										<ColorPicker 
											inputNameData="template.header.color.font.sticky"
											onCompleteData={props.data.onChangeNameSet}
											settingData={props.data.value.template.header.color.font.sticky}
											pickerPosition="right"
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">모바일 사이드메뉴</span></h4>
				</div>
				<div>
					<div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>타입 선택</span>
						</div>
						<div className="uk-width-expand">
							<select
								className={`uk-select`}
								name="template.header.mobileMenuType"
								onChange={props.data.onChange}
								value={props.data.value.template.header.mobileMenuType}
							>
								<option key={-1} value={""}>선택</option>
								<option key={1} value={"A"}>A</option>
								<option key={2} value={"B"}>B</option>
                <option key={3} value={"C"}>C</option>
							</select>
						</div>
					</div>
					<div className="settingItem uk-grid-collapse uk-flex-top uk-margin-top" data-uk-grid>
						<div className='uk-width-100 uk-text-0_9'>
							<span>기본 색상</span>
						</div>
						<div className="uk-width-expand">
							<div className="uk-grid-small uk-flex-middle" data-uk-grid>
								<div className="uk-width-80 uk-text-right">
									<span className="uk-text-0_8">[배경]</span>
								</div>
								<div className="uk-width-expand">
									<ColorPicker
										inputNameData="template.header.color.background.mobileMenu"
										onCompleteData={props.data.onChangeNameSet}
										settingData={props.data.value.template.header.color.background.mobileMenu}
										pickerPosition="right"
									/>
								</div>
							</div>
							<div className="uk-grid-small uk-flex-middle uk-margin-small-top" data-uk-grid>
								<div className="uk-width-80 uk-text-right">
									<span className="uk-text-0_8">[텍스트]</span>
								</div>
								<div className="uk-width-expand">
									<ColorPicker
										inputNameData="template.header.color.font.mobileMenu"
										onCompleteData={props.data.onChangeNameSet}
										settingData={props.data.value.template.header.color.font.mobileMenu}
										pickerPosition="right"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default HeaderSetting;