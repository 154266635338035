import Button from "./Button";
import Card from "./Card";
import Div from "./Div";
import Grid from "./Grid";
import Spinner from "./Spinner";
import Table from "./Table";
import InputText from "./InputText";
import InputImage from "./InputImage";
import DatePicker from './DatePicker';
import DateRangePicker from './DateRangePicker';

export default {
    Button,
    Card,
    Div,
    Grid,
    Spinner,
    Table,
    InputText,
    InputImage,
    DatePicker,
    DateRangePicker
};
