import produce from "immer";
import {
    USER_ORDER_MEMO_UPDATE
} from '../actions/user/type';

export const initialState = {
    loadUserLoading: false,
    loadUserDone: false,
    loadUserError: null,
    user: null,
    loadMeLoading: false,
    loadMeDone: false,
    loadMeError: null,
    me: null,
    loadUsersLoading: false,
    loadUsersDone: false,
    loadUsersError: null,
    users: [],
    selectedBrand: localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : 'all',
    loginUserLoading: false,
    loginUserDone: false,
    loginUserError: null,
    logoutUserLoading: false,
    logoutUserDone: false,
    logoutUserError: null,
    destroyUserLoading: false,
    destroyUserDone: false,
    destroyUserError: null,
    destroyUser: null,
    updateUserLoading: false,
    updateUserDone: false,
    updateUserError: null,
    updateUser: null,
    createUserLoading: false,
    createUserDone: false,
    createUserError: null,
    createUser: null,
};

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";

export const LOAD_ME_REQUEST = "LOAD_ME_REQUEST";
export const LOAD_ME_SUCCESS = "LOAD_ME_SUCCESS";
export const LOAD_ME_FAILURE = "LOAD_ME_FAILURE";

export const LOAD_USERS_REQUEST = "LOAD_USERS_REQUEST";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_FAILURE = "LOAD_USERS_FAILURE";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";

export const DESTROY_USER_REQUEST = "DESTROY_USER_REQUEST";
export const DESTROY_USER_SUCCESS = "DESTROY_USER_SUCCESS";
export const DESTROY_USER_FAILURE = "DESTROY_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";


export const USER_INIT = "USER_INIT";
export const ONCHANGE_SELECTED_BRAND = "ONCHANGE_SELECTED_BRAND";

export const userInitAction = () => ({
    type: USER_INIT
});

export const createUserAction = (formData) => ({
    type: CREATE_USER_REQUEST,
    data: formData,
});

export const updateUserAction = (userSeq, formData) => ({
    type: UPDATE_USER_REQUEST,
    userSeq,
    data: formData,
});

export const destroyUserAction =  (userSeq) =>  ({
    type: DESTROY_USER_REQUEST,
    data: userSeq,
});

export const loadMeAction = () => ({
    type: LOAD_ME_REQUEST,
});

export const loadUserAction = (userSeq) => ({
    type: LOAD_USER_REQUEST,
    data: userSeq
});

export const loadUsersAction = (query) => ({
    type: LOAD_USERS_REQUEST,
    data: query,
});

export const loginUserAction = (code) => ({
    type: LOGIN_USER_REQUEST,
    code,
});

export const logoutUserAction = () => ({
    type: LOGOUT_USER_REQUEST,
});

export const onchangeSelectedBrandAction = (brandSeq) => ({
    type: ONCHANGE_SELECTED_BRAND,
    data: brandSeq
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case USER_ORDER_MEMO_UPDATE: {
                const orderIndex = draft.user.order.findIndex((order) => order.seq === action.orderSeq);
                draft.user.order[orderIndex] = {...draft.user.order[orderIndex], memo: action.memo};
                break;
            }
            case USER_INIT : {
                draft.loadUserLoading = true;
                draft.loadUserDone = false;
                draft.loadUserError = null;
                draft.updateUser = null;
                draft.updateUserDone = false;
                draft.updateUserLoading = true;
                break;
            }
            case CREATE_USER_REQUEST: {
                draft.createUserLoading = true;
                draft.createUserDone = false;
                draft.createUserError = null;
                break;
            }
            case CREATE_USER_SUCCESS: {
                draft.createUserLoading = false;
                draft.createUserDone = true;
                draft.createUser = {userSeq: action.userSeq, data: action.data};
                break;
            }
            case CREATE_USER_FAILURE: {
                draft.createUserLoading = false;
                draft.createUserError = action.error;
                break;
            }
            case UPDATE_USER_REQUEST: {
                draft.updateUserLoading = true;
                draft.updateUserDone = false;
                draft.updateUserError = null;
                break;
            }
            case UPDATE_USER_SUCCESS: {
                draft.updateUserLoading = false;
                draft.updateUserDone = true;
                draft.updateUser = {userSeq: action.userSeq, data: action.data};

                // const userIndex = draft.users.data.findIndex((user) => user.seq === action.userSeq);
                // draft.users.data[userIndex] = {...draft.users.data[userIndex], memo: action.memo};
                break;
            }
            case UPDATE_USER_FAILURE: {
                draft.updateUserLoading = false;
                draft.updateUserError = action.error;
                break;
            }
            case DESTROY_USER_REQUEST: {
                draft.destroyUserLoading = true;
                draft.destroyUserDone = false;
                draft.destroyUserError = null;
                break;
            }
            case DESTROY_USER_SUCCESS: {
                draft.destroyUserLoading = false;
                draft.destroyUserDone = true;
                draft.destroyUser = {userSeq: action.userSeq, data: action.data};
                break;
            }
            case DESTROY_USER_FAILURE: {
                draft.destroyUserLoading = false;
                draft.destroyUserError = action.error;
                break;
            }
            case ONCHANGE_SELECTED_BRAND: {
                draft.selectedBrand = action.data
            }
            case LOGOUT_USER_REQUEST: {
                draft.logoutUserLoading = true;
                draft.logoutUserDone = false;
                draft.logoutUserError = null;
                break;
            }
            case LOGOUT_USER_SUCCESS: {
                //draft.me = null;
                draft.logoutUserLoading = false;
                draft.logoutUserDone = true;
                break;
            }
            case LOGOUT_USER_FAILURE: {
                draft.logoutUserLoading = false;
                draft.logoutUserError = action.error;
                break;
            }
            case LOGIN_USER_REQUEST: {
                draft.loginUserLoading = true;
                draft.loginUserDone = false;
                draft.loginUserError = null;
                break;
            }
            case LOGIN_USER_SUCCESS: {
                // draft.me = action.data;
                draft.loginUserLoading = false;
                draft.loginUserDone = true;
                break;
            }
            case LOGIN_USER_FAILURE: {
                draft.loginUserLoading = false;
                draft.loginUserError = action.error;
                break;
            }
            case LOAD_USERS_REQUEST: {
                draft.loadUsersLoading = true;
                draft.loadUsersDone = false;
                draft.loadUsersError = null;
                draft.destroyUserDone = false;
                draft.createUserError = null;
                draft.updateUserError = null;
                break;
            }
            case LOAD_USERS_SUCCESS: {
                draft.users = action.data;
                draft.loadUsersLoading = false;
                draft.loadUsersDone = true;
                break;
            }
            case LOAD_USERS_FAILURE: {
                draft.loadUsersLoading = false;
                draft.loadUsersError = action.error;
                break;
            }
            case LOAD_USER_REQUEST: {
                draft.loadUserLoading = true;
                draft.loadUserDone = false;
                draft.loadUserError = null;
                draft.updateUserDone = false;
                draft.updateUserLoading = true;
                break;
            }
            case LOAD_USER_SUCCESS: {
                draft.user = action.data;
                draft.loadUserLoading = false;
                draft.loadUserDone = true;
                break;
            }
            case LOAD_USER_FAILURE: {
                draft.loadUserLoading = false;
                draft.loadUserError = action.error;
                break;
            }
            case LOAD_ME_REQUEST: {
                draft.loadMeLoading = true;
                draft.loadMeDone = false;
                draft.loadMeError = null;
                break;
            }
            case LOAD_ME_SUCCESS: {
                draft.me = action.data;
                draft.loadMeLoading = false;
                draft.loadMeDone = true;
                break;
            }
            case LOAD_ME_FAILURE: {
                draft.loadMeLoading = false;
                draft.loadMeError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
