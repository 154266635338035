import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import List from "./List";
import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';

const Review = ({ match }) => {
    return (
        <WithSideNavLayout>
            <Switch>
                <Route exact path={`${match.path}/all`} component={List} />
                <Redirect from="*" to="/cs/qna/all" />
            </Switch>
        </WithSideNavLayout>
    );
};

export default Review;
