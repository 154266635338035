import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../reducers/user';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

import { useFormData } from '../../hook';

import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";
import QnaListStyle from '../../Components/Widget/Qna/ListStyle';
import SearchHead from "../../Components/Widget/SearchHead";
import SearchModal from '../../Components/Widget/SearchModal';
import SearchForm from '../../Components/Widget/SearchForm';

const initDatas = {
    useSearchComponents: {
        dateSearch: {
            isUse: true,
            title: '조회기간',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'regDate',
                    name: '등록일'
                }
            ]
        },
        informationSearch: {
            isUse: true,
            title: '문의 정보',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'title',
                    name: '문의 제목'
                }, {
                    value: 'content',
                    name: '문의 내용'
                }, {
                    value: 'userName',
                    name: '고객명',
                }
            ]
        },
        statusSearch: {
            isUse: true,
            title: '사용여부'
        },
    }
}

const List = ({ history }) => {
    const buttons = [];
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone } = useSelector((state) => state.user);
    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all'
    });

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"문의 목록"} buttons={buttons} />
            </UIKit.Div>
            <div>
                <SearchHead modalId={"qnaSearchModal"}  />
                <SearchModal modalId={"qnaSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>
                <QnaListStyle />
            </div>
        </div>
    );
};

export default List;
