import React, {useRef, useEffect, useMemo} from 'react';
import JoditEditor from "jodit-react";
import { Jodit } from 'jodit'

const Editor = ({ name, formData, value, onChange, preset='none', backgroundImageUrl = null }) => {
	const textArea = useRef(null);
	const config = {
		preset,
        buttons: ['fontsize', 'brush', 'align', '|', 'image', '|', 'link'],
		extraButtons: [],
        uploader: { insertImageAsBase64URI: true },
        readonly: preset === 'inline' ? true : false,
        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarAdaptive: false,
        language: 'ko',
        i18n: {
	          ko: {
                'Insert Image': '이미지 삽입',
                'Font size': '글자 크기',
                'Font family': '폰트',
                'undo': '이전',
                'redo': '이후',
                'Upload': '업로드',
                'Drop image': '이미지를 올리거나',
                'or click': '클릭해주세요.',
				'Delete': '삭제',
				'Edit': '수정',
				'Horizontal align': '정렬',
				'Align': '글자 정렬',
				'Align Center': '중앙 정렬',
				'Align Left': '왼쪽 정렬',
				'Align Right': '오른쪽 정렬',
				'Align Justify': '저스티파이',
				'Left': '왼쪽',
				'Right': '오른쪽',
				'Center': '중앙',
				'Normal': '기본',
				'Image properties': '이미지 속성',
				'Ok': '확인',
				'Background': '배경색',
				'Text': '텍스트',
				'Fill color or set the text color': '색상변경',
				'Unlink': '삭제',
				'Insert': '추가',
				'Update': '수정',
				'Insert link': '버튼 추가'
	          }
         },
         minHeight: '300px',
		 placeholder: '',
		 toolbarButtonSize: 'large',
		 imageDefaultWidth: 'initial'
    }

    const editor = useRef(null);

    return useMemo(() => (
        <JoditEditor ref={editor} value={value} config={config} tabIndex={1} onChange={newContent => onChange(newContent, "jodit")} />
    ), [formData && formData.timestamp && formData.timestamp]);
}

export default Editor;
