import produce from "immer";

export const initialState = {
    loadAdminsLoading: false,
    loadAdminsDone: false,
    loadAdminsError: null,
    admins: null,
    destroyAdminLoading: false,
    destroyAdminDone: false,
    destroyAdminError: null,
    destroyAdmin: null,
    loadAdminLoading: false,
    loadAdminDone: false,
    loadAdminError: null,
    admin: null,
    updateAdminLoading: false,
    updateAdminDone: false,
    updateAdminError: null,
    updateAdmin: null,
    createAdminLoading: false,
    createAdminDone: false,
    createAdminError: null,
    createAdmin: null,
};

export const LOAD_ADMINS_REQUEST = "LOAD_ADMINS_REQUEST";
export const LOAD_ADMINS_SUCCESS = "LOAD_ADMINS_SUCCESS";
export const LOAD_ADMINS_FAILURE = "LOAD_ADMINS_FAILURE";

export const DESTROY_ADMIN_REQUEST = "DESTROY_ADMIN_REQUEST";
export const DESTROY_ADMIN_SUCCESS = "DESTROY_ADMIN_SUCCESS";
export const DESTROY_ADMIN_FAILURE = "DESTROY_ADMIN_FAILURE";

export const LOAD_ADMIN_REQUEST = "LOAD_ADMIN_REQUEST";
export const LOAD_ADMIN_SUCCESS = "LOAD_ADMIN_SUCCESS";
export const LOAD_ADMIN_FAILURE = "LOAD_ADMIN_FAILURE";

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILURE = "CREATE_ADMIN_FAILURE";



export const createAdminAction = (formData) => ({
    type: CREATE_ADMIN_REQUEST,
    data: formData,
});

export const updateAdminAction = (adminSeq, formData) => ({
    type: UPDATE_ADMIN_REQUEST,
    adminSeq,
    data: formData,
});

export const loadAdminsAction = (query) => ({
    type: LOAD_ADMINS_REQUEST,
    data: query,
});

export const destroyAdminAction = (seq) => ({
    type: DESTROY_ADMIN_REQUEST,
    data: seq,
});

export const loadAdminAction = (seq) => ({
    type: LOAD_ADMIN_REQUEST,
    data: seq,
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case CREATE_ADMIN_REQUEST: {
                draft.createAdminLoading = true;
                draft.createAdminDone = false;
                draft.createAdminError = null;
                break;
            }
            case CREATE_ADMIN_SUCCESS: {
                draft.createAdminLoading = false;
                draft.createAdminDone = true;
                draft.createAdmin = {data: action.data};
                break;
            }
            case CREATE_ADMIN_FAILURE: {
                draft.createAdminLoading = false;
                draft.createAdminError = action.error;
                break;
            }
            case UPDATE_ADMIN_REQUEST: {
                draft.updateAdminLoading = true;
                draft.updateAdminDone = false;
                draft.updateAdminError = null;
                break;
            }
            case UPDATE_ADMIN_SUCCESS: {
                draft.updateAdminLoading = false;
                draft.updateAdminDone = true;
                draft.updateAdmin = {adminSeq: action.adminSeq, data: action.data};
                break;
            }
            case UPDATE_ADMIN_FAILURE: {
                draft.updateAdminLoading = false;
                draft.updateAdminError = action.error;
                break;
            }
            case LOAD_ADMIN_REQUEST: {
                draft.loadAdminLoading = true;
                draft.loadAdminDone = false;
                draft.loadAdminError = null;
                break;
            }
            case LOAD_ADMIN_SUCCESS: {
                draft.admin = action.data;
                draft.loadAdminLoading = false;
                draft.loadAdminDone = true;
                break;
            }
            case LOAD_ADMIN_FAILURE: {
                draft.loadAdminLoading = false;
                draft.loadAdminError = action.error;
                break;
            }
            case DESTROY_ADMIN_REQUEST: {
                draft.destroyAdminLoading = true;
                draft.destroyAdminDone = false;
                draft.destroyAdminError = null;
                break;
            }
            case DESTROY_ADMIN_SUCCESS: {
                draft.destroyAdmin = {adminSeq: action.adminSeq, data: action.data};
                draft.destroyAdminLoading = false;
                draft.destroyAdminDone = true;
                break;
            }
            case DESTROY_ADMIN_FAILURE: {
                draft.destroyAdminLoading = false;
                draft.destroyAdminError = action.error;
                break;
            }
            case LOAD_ADMINS_REQUEST: {
                draft.loadAdminsLoading = true;
                draft.loadAdminsDone = false;
                draft.loadAdminsError = null;
                draft.destroyAdminDone = false;
                draft.createAdminError = null;
                draft.updateAdminError = null;
                break;
            }
            case LOAD_ADMINS_SUCCESS: {
                draft.admins = action.data;
                draft.loadAdminsLoading = false;
                draft.loadAdminsDone = true;
                break;
            }
            case LOAD_ADMINS_FAILURE: {
                draft.loadAdminsLoading = false;
                draft.loadAdminsError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
