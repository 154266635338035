import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionLoader from '../../Widget/ActionLoader';

import { updateOrderAction } from '../../../reducers/order';

const MemoFormTextAreaStyle = ({ orderSeq, orderMemo = null, isUserModal = 0 }) => {
	const dispatch = useDispatch();
	const [ memo, setMemo ] = useState(orderMemo ? orderMemo : "");
	const [ isUpdate, setIsUpdate ] =  useState(false);

	const {updateOrderDone, updateOrderLoading } = useSelector((state) => state.order);

	const onClickIsUpdate = useCallback(() => {
		setIsUpdate(!isUpdate);
		if(isUpdate === false) {
			setMemo(orderMemo);
		}
	},[isUpdate]);

	const onSubmitMemo = useCallback((e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("memo", memo);
		formData.append("_method", "PUT");
		dispatch(updateOrderAction(formData, orderSeq, isUserModal));
	}, [memo, orderSeq, isUserModal]);

	const onDestroyMemo = useCallback((e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("memo", "NULL");
		formData.append("_method", "PUT");
		dispatch(updateOrderAction(formData, orderSeq));
	}, [memo, orderSeq, isUserModal]);

	useEffect(() => {
		if(updateOrderDone) {
			setIsUpdate(false);
		}
	}, [updateOrderDone]);

	return (
		<div className="uk-card uk-card-default uk-card-body uk-background-yellow uk-height-max-100 uk-padding-15">
			{
				isUpdate ? (
					updateOrderLoading ? (
						<ActionLoader />
					) : (
						<form onSubmit={onSubmitMemo}>
							<textarea
							className="uk-textarea"
							value={memo}
							onChange={(e) => setMemo(e.target.value)}
						/>
							<div className="uk-text-right">
								<button className="uk-button uk-button-text uk-margin-small-right" type="button" onClick={onClickIsUpdate}>취소</button>
								<button className="uk-button uk-button-text uk-text-primary" type="submit">저장</button>
							</div>
						</form>
					)
				) : (
					<Fragment>
						{/* <div>
							<button className="uk-button uk-button-small uk-position-absolute uk-position-top-right uk-background-yellow" type="button">...</button>
						</div> */}
						<div className="uk-text-pre-wrap uk-height-70 uk-overflow-y-scroll">
							<p>{memo}</p>
						</div>
						<div className="uk-padding-remove" data-uk-dropdown="mode: click; pos:center;">
							<ul className="uk-nav uk-dropdown-nav uk-list-padding-small uk-list-hover-default">
								<li onClick={onClickIsUpdate}><span >수정</span></li>
							</ul>
						</div>
					</Fragment>
				)
			}
		</div>
	);
};

export default MemoFormTextAreaStyle;
