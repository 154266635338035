import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    loadProductAction,
    updateProductAction,
    createProductAction,
    destroyProductAction,
    loadCategoriesAction,
    loadSkusAction,
    onChangeAction,
    loadProductResetAction,
    loadProductInfosResetAction,
    loadProductInfoSavesResetAction,
} from '../../../actions/product/action';

import UseFormForProduct from '../../../hook/UseFormForProduct';
import useConfirm from '../../../hook/UseConfirm';

import Header from "../Header";
import Editor from '../Editor';
import OptionCard from './OptionCard';
import InfoCard from './InfoCard';
import ProductInfoCard from './ProductInfoCard';
import ImageCard from './ImageCard';
import PriceCard from './PriceCard';
import ActionLoader from '../ActionLoader';
import UIKit from "../../UIKit";
import Loader from '../Loader';

const FormStyle = ({ paramId }) => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const {
        product,
        loadProductDone,
        updateProduct,
        updateProductLoading,
        updateProductDone,
        updateProductError,
        createProductLoading,
        createProductError,
        createProductDone,
        createProduct,
    } = useSelector((state) => state.product);
    const { selectedBrand } = useSelector((state) => state.user);
    const history = useHistory();

    const [imgArray, setImgArray] = useState([]);
    const [initLoading, setInitLoading] = useState(true);

    useEffect(() => {
        if(paramId) {
            dispatch(loadProductAction(paramId));
        } else {
            setInitLoading(false);
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(loadProductResetAction());
            dispatch(loadProductInfosResetAction());
            dispatch(loadProductInfoSavesResetAction());
        }
    }, []);

    useEffect(() => {
        dispatch(loadCategoriesAction());
    }, [selectedBrand, product.brand_seq]);

    const onSubmit = useCallback(() => {
        const target = document.getElementById("productForm");
        const formData = new FormData(target);
        const price = JSON.stringify(JSON.parse(product.price));
        const cost = JSON.stringify(JSON.parse(product.cost));
        if (paramId) {
            formData.append("_method", "PUT");
        }

        if (price.replace(/,/g, "")) {
            formData.append('price', price.replace(/,/g, ""));
        }

        if (cost.replace(/,/g, "")) {
            formData.append('cost', cost.replace(/,/g, ""));
        }

        formData.append('content', product.content);

        for (let img of imgArray) {
            if(img.division == "preview"){
                formData.append('img[]', img.fileData);
            }else{
                formData.append('imgOrig[]', img.src);
            }
        }
        formData.append('option', JSON.stringify(product.option));
        formData.append('optionList', JSON.stringify(product.optionList));
        formData.append('productInfo', JSON.stringify(product.productInfo));
        if (paramId) {
            dispatch(updateProductAction(paramId, formData));
        } else {
            dispatch(createProductAction(formData));
        }
    }, [paramId, product, imgArray]);

    useEffect(() => {
        if(loadProductDone) {
            if(paramId) {
                setInitLoading(true);
            }
            setInitLoading(false);
        }

    }, [loadProductDone]);

    useEffect(() => {
        if(updateProductDone) {
            alert(updateProduct.data.msg);
            window.location.reload();
        }

        if(createProductDone) {
            alert(createProduct.data.msg);
            window.location.href = `/product/item/all/${createProduct.data.seq}`;
        }
    }, [updateProductDone, createProductDone]);

    useEffect(() => {
        if(updateProductError) {
            alert(Object.values(updateProductError)[0]);
            if(formRef?.current[Object.keys(updateProductError)[0]]) {
                formRef.current[Object.keys(updateProductError)[0]].focus();
            }

        }

        if(createProductError) {
            alert(Object.values(createProductError)[0]);
            if(formRef?.current[Object.keys(createProductError)[0]]) {
                formRef.current[Object.keys(createProductError)[0]].focus();
            }
        }
    }, [updateProductError, createProductError]);

    const destroyAction = useCallback(() => {
        dispatch(destroyProductAction(paramId));
    }, [paramId, history]);

    const onClickConfirmDestroy = useConfirm('삭제된 항목은 복원할 수 없습니다.\n선택항목을 삭제하시겠습니까?', destroyAction);

    const onChangeForJodit = useCallback((newContent) => {
        dispatch(onChangeAction(newContent, "jodit"));
    }, []);

    let buttons = [];

    if(paramId) {
        buttons = [
            {
                buttonOptions:
                    "uk-button uk-button-small uk-button-radius uk-margin-small-right",
                buttonName: '수정',
                onClickAction: onSubmit
            },
            {
                buttonOptions:
                    "uk-button uk-button-small uk-button-transparent uk-button-radius",
                buttonName: "삭제",
                onClickAction: onClickConfirmDestroy
            }
        ];
    } else {
        buttons = [
            {
                buttonOptions:
                    "uk-button uk-button-small uk-button-radius uk-margin-small-right",
                buttonName: '등록',
                onClickAction: onSubmit
            },
        ]
    }

    return initLoading ? (
        <Loader />
    ) : (
        <div data-uk-grid>
            {
                updateProductLoading || createProductLoading ? (
                    <ActionLoader message={updateProductLoading ? '상품을 수정중입니다.' : '상품을 등록중입니다.'} />
                ) : (
                    <div className='uk-width-1-1'>
                        <div data-uk-grid>
                            <form onSubmit={onSubmit} id="productForm" className='uk-width-1-1' ref={formRef}>
                                <div className='uk-width-1-1'>
                                    <div>
                                        <Header
                                            title={`상품 ${paramId ? '수정' : '등록'}`}
                                            buttons={buttons}
                                        />
                                    </div>
                                </div>
                                <div data-uk-grid>
                                    <UIKit.Div classOptions={"uk-width-1-2@m"}>
                                        <ProductInfoCard />
                                    </UIKit.Div>

                                    <UIKit.Div classOptions={"uk-width-1-2@m"}>
                                        <ImageCard imgArray={imgArray} setImgArray={setImgArray} />
                                    </UIKit.Div>
                                    <UIKit.Div classOptions={"uk-width-1-1"}>
                                        <UIKit.Card
                                            title={"상세 설명"}
                                            cardOptions={"uk-card-default uk-card-style test-editor"}
                                            cardBodyOptions={""}
                                        >
                                            {
                                                <Editor name="content" value={product.content} onChange={onChangeForJodit}  />
                                            }

                                        </UIKit.Card>
                                    </UIKit.Div>
                                    <UIKit.Div classOptions={"uk-width-1-1"}>
                                        <PriceCard />
                                    </UIKit.Div>
                                </div>
                            </form>
                            <InfoCard />
                            <OptionCard />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

FormStyle.propTypes = {
    paramId: PropTypes.string.isRequired,
};

export default FormStyle;
