import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from 'react-redux';

import { loadOrderLogAction } from '../../../reducers/log';

import Loader from "../../../Components/Widget/Loader";
import Header from "../../../Components/Widget/Header";
import UIKit from "../../../Components/UIKit";
import OrderLogModal from '../../../Components/Widget/OrderLog/OrderLogModal';

const Edit = ({ match }) => {
    const dispatch = useDispatch();
    const { orderLog, loadOrderLogLoading, loadOrderLogDone } = useSelector((state) => state.log);

    const [paramId, setParamId] = useState("");
    const history = useHistory();

    useEffect(() => {
        const {
            params: { id }
        } = match;

        dispatch(loadOrderLogAction(id));
        if (id) {
            setParamId(id);
        }
    }, [match]);


    return loadOrderLogLoading ? <Loader /> : loadOrderLogDone && orderLog && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header
                    title={"주문로그 상세리스트"}
                    buttons={null}
                />
            </UIKit.Div>
            <UIKit.Grid>
                <div className="uk-width-1-1">
                    <table className="uk-table uk-table-pointer uk-table-hover uk-table-divider">
                        <thead className={`uk-text-center`}>
                            <tr>
                                <th>
                                    최초등록일
                                </th>
                                <th>
                                    변경된날짜
                                </th>
                                <th>
                                    결제상태
                                </th>
                            </tr>
                        </thead>
                        <tbody className={`uk-text-center`}>
                            {
                                orderLog.log.map((log, index) => (
                                    <tr key={index} data-uk-toggle={`target: #orderLogModal_${index}`}>
                                        <td>
                                            {log.regdate}
                                        </td>
                                        <td>
                                            {log.moddate}
                                        </td>
                                        <td>
                                            {log.statusName}
                                        </td>
                                        <OrderLogModal modalId={`orderLogModal_${index}`} orderLogDetail={log} />
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </UIKit.Grid>
        </div>
    );
};

export default Edit;
