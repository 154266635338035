import produce from "immer";
import { getWeekday } from "../common";

export const initialState = {
    loadCountsForSidenavLoading: false,
    loadCountsForSidenavDone: false,
    loadCountsForSidenavError: null,
    countsForSideNav: {
        total: 0,
        paid: 0,
        cancel: 0,
        naverpayCancel: 0,
        returns: 0,
        exchange: 0,
        qna: 0,
    },
    loadSummaryLoading: false,
    loadSummaryDone: false,
    loadSummaryError: null,
    loadSummary2Loading: false,
    loadSummary2Done: false,
    loadSummary2Error: null,
    loadStockLoading: false,
    loadStockDone: false,
    loadStockError: null,
    stock: null,
    summary: null,
    dates: null,
    pageviews: null,
    visitors: null,
    revenue: null,
    orderCount: null,
};

export const LOAD_COUNTS_FOR_SIDENAV_REQUEST =
    "LOAD_COUNTS_FOR_SIDENAV_REQUEST";
export const LOAD_COUNTS_FOR_SIDENAV_SUCCESS =
    "LOAD_COUNTS_FOR_SIDENAV_SUCCESS";
export const LOAD_COUNTS_FOR_SIDENAV_FAILURE =
    "LOAD_COUNTS_FOR_SIDENAV_FAILURE";

export const LOAD_SUMMARY_REQUEST = "LOAD_SUMMARY_REQUEST";
export const LOAD_SUMMARY_SUCCESS = "LOAD_SUMMARY_SUCCESS";
export const LOAD_SUMMARY_FAILURE = "LOAD_SUMMARY_FAILURE";

export const LOAD_SUMMARY2_REQUEST = "LOAD_SUMMARY2_REQUEST";
export const LOAD_SUMMARY2_SUCCESS = "LOAD_SUMMARY2_SUCCESS";
export const LOAD_SUMMARY2_FAILURE = "LOAD_SUMMARY2_FAILURE";

export const LOAD_STOCK_REQUEST = "LOAD_STOCK_REQUEST";
export const LOAD_STOCK_SUCCESS = "LOAD_STOCK_SUCCESS";
export const LOAD_STOCK_FAILURE = "LOAD_STOCK_FAILURE";

export const loadCountsForSidenavAction = () => ({
    type: LOAD_COUNTS_FOR_SIDENAV_REQUEST,
});

export const loadSummaryAction = (query) => ({
    type: LOAD_SUMMARY_REQUEST,
    data: query,
});

export const loadSummary2Action = (query) => ({
    type: LOAD_SUMMARY2_REQUEST,
    data: query,
});

export const loadStockAction = (query) => ({
    type: LOAD_STOCK_REQUEST,
    data: query,
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOAD_COUNTS_FOR_SIDENAV_REQUEST: {
                draft.loadCountsForSidenavLoading = true;
                draft.loadCountsForSidenavDone = false;
                draft.loadCountsForSidenavError = null;
                break;
            }
            case LOAD_COUNTS_FOR_SIDENAV_SUCCESS: {
                draft.countsForSideNav = action.data;
                draft.loadCountsForSidenavLoading = false;
                draft.loadCountsForSidenavDone = true;
                break;
            }
            case LOAD_COUNTS_FOR_SIDENAV_FAILURE: {
                draft.loadCountsForSidenavLoading = false;
                draft.loadCountsForSidenavError = action.error;
                break;
            }
            case LOAD_SUMMARY_REQUEST: {
                draft.loadSummaryLoading = true;
                draft.loadSummaryDone = false;
                draft.loadSummaryError = null;
                break;
            }
            case LOAD_SUMMARY_SUCCESS: {
                let datesArray = [];
                let pageviewsArray = [];
                let visitorsArray = [];
                let revenueArray = [];
                let orderCountArray = [];

                if (Object.keys(action.data).length > 0) {
                    let deepCopyTotalByItem = JSON.parse(
                        JSON.stringify(action.data)
                    );
                    for (let dataKey of Object.keys(
                        deepCopyTotalByItem.reverse()
                    )) {
                        const item = deepCopyTotalByItem[dataKey];
                        datesArray.push(
                            item.date + "(" + getWeekday(item.date, true) + ")"
                        );
                        pageviewsArray.push(item.googleAnalyticsData.pageviews);
                        visitorsArray.push(item.googleAnalyticsData.visitors);
                        revenueArray.push(
                            parseInt(item.totalPrice - item.cancelPrice, 10)
                        );
                        orderCountArray.push(item.orderCount);
                    }
                }

                draft.dates = datesArray;
                draft.pageviews = pageviewsArray;
                draft.visitors = visitorsArray;
                draft.revenue = revenueArray;
                draft.orderCount = orderCountArray;
                draft.summary = action.data;
                draft.loadSummaryLoading = false;
                draft.loadSummaryDone = true;
                break;
            }
            case LOAD_SUMMARY_FAILURE: {
                draft.loadSummaryLoading = false;
                draft.loadSummaryError = action.error;
                break;
            }
            case LOAD_SUMMARY2_REQUEST: {
                draft.loadSummary2Loading = true;
                draft.loadSummary2Done = false;
                draft.loadSummary2Error = null;
                break;
            }
            case LOAD_SUMMARY2_SUCCESS: {
                draft.summary = action.data;
                draft.loadSummary2Loading = false;
                draft.loadSummary2Done = true;
                break;
            }
            case LOAD_SUMMARY2_FAILURE: {
                draft.loadSummary2Loading = false;
                draft.loadSummary2Error = action.error;
                break;
            }
            case LOAD_STOCK_REQUEST: {
                draft.loadStockLoading = true;
                draft.loadStockDone = false;
                draft.loadStockError = null;
                break;
            }
            case LOAD_STOCK_SUCCESS: {
                draft.stock = action.data;
                draft.loadStockLoading = false;
                draft.loadStockDone = true;
                break;
            }
            case LOAD_STOCK_FAILURE: {
                draft.loadStockLoading = false;
                draft.loadStockError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    });
};

export default reducer;
