import React, {} from 'react';

const MobileMenuB = (props) => {
    return (
        <div id="mobile-nav-bar" className="uk-height-1-1" style={{backgroundColor: props.color.background, color: props.color.font}}>
            <div className="uk-position-medium uk-position-top">
                <a className="uk-modal-close uk-link-reset uk-text-1_2">close</a>
            </div>
            <div className="uk-position-medium uk-position-center uk-text-center uk-text-1_3 uk-width-1-1 uk-max-height-75p uk-margin-small-top uk-overflow-auto">
                <div>SHOP</div>
                <div>ABOUT</div>
                <div>CART</div>
                <div>LOGIN</div>
            </div>
            <div className="uk-position-medium uk-position-bottom uk-text-center">
                <div className="uk-margin-auto" style={{width:"30px",height: "30px"}}>
                    <img src="/image/design/kakao_ch.png" style={{width:30}} />
                </div>
            </div>
        </div>
    )
}

export default MobileMenuB;