import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSampleAction, updateSampleAction } from '../../reducers/sample';

import { useFormData } from '../../hook';

const Form = () => {
    const dispatch = useDispatch();
    const { createSampleDone, createSampleLoading, loadSampleDone, sample } = useSelector((state) => state.sample);
    const [isUpdate, setIsUpdate] = useState(false);
    const formRef = useRef();
    const formData = useFormData({
        seq: -1,
        title: '',
        description: '',
        regdate: '',
    });
    const onSubmit = useCallback((e) => {
        e.preventDefault();
        const data = {
            title: formData.value.title,
            description: formData.value.description,
            regdate: formData.value.regdate,
        };
        if(isUpdate) {
            dispatch(updateSampleAction(formData.value.seq, data));
        } else {
            dispatch(createSampleAction(data));
        }

    },[formData, isUpdate]);

    useEffect(() => {
        if(createSampleDone) {
            formData.clearValues();
        }
    }, [createSampleDone]);

    useEffect(() => {
        if(loadSampleDone) {
            formData.init(sample);
            setIsUpdate(true);
        }
    }, [loadSampleDone]);
    return (
        <form onSubmit={onSubmit} ref={formRef}>
            <div>
                <label htmlFor="title">제목 : </label>
                <input type="text" name="title" id="title" onChange={formData.onChange} value={formData.value.title} />

                <label htmlFor="description">내용 : </label>
                <input type="text" name="description" id="description" onChange={formData.onChange} value={formData.value.description} />

                <label htmlFor="regdate">등록일 : </label>
                <input type="text" name="regdate" id="regdate" onChange={formData.onChange} value={formData.value.regdate} />
                <button type="submit">{isUpdate ? "수정" : "등록"}</button>
            </div>
        </form>
    )
};

export default Form;
