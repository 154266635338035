import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UIKIT from 'uikit';

import { useInput } from '../../../hook';
import { useFormData } from '../../../hook';
import {
    managerChangeOrderBasedStatusAction,
    managerChangeOrderBasedStatusResetAction
} from '../../../actions/order/action';
import { loadOrdersAction } from '../../../reducers/order';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';
import _ from 'lodash';

const RequestReturnOrderModal = ({ openRequestReturnOrderModal, setOpenRequestReturnOrderModal, targetOrder }) => {
    const dispatch = useDispatch();

    const {
        updateManagerChangeOrderBasedStatusDone,
        updateManagerChangeOrderBasedStatusError,
        updateManagerChangeOrderBasedStatus
    } = useSelector((state) => state.order);

    const location = useLocation();

    const requestReturnDetailInfo = useFormData({});

    const onSubmit = useCallback((e) => {
      e.preventDefault();
      if(_.find(_.values(requestReturnDetailInfo.value), function(o) { return o.requestReason == ""; })){
        alert('반품 사유를 선택해 주세요');
        return false;
      }

      UIKIT.modal('#requestReturnOrderModal').hide();
      setOpenRequestReturnOrderModal(false);
      const formData = new FormData();
      formData.append("details", JSON.stringify(requestReturnDetailInfo.value));
      formData.append("detailStatus", 3);
      formData.append("_method", "PUT");
      dispatch(managerChangeOrderBasedStatusAction(targetOrder.seq, formData));
    }, [requestReturnDetailInfo]);

    useEffect(() => {
        if(openRequestReturnOrderModal && targetOrder) {
            UIKIT.modal('#requestReturnOrderModal').show();
            let orderDetailObject = {};
            for(let detail of targetOrder.detail) {
                if(!orderDetailObject[`request_detail_${detail.seq}`]) {
                    orderDetailObject[`request_detail_${detail.seq}`] = {};
                }

                orderDetailObject[`request_detail_${detail.seq}`] = {requestReason: '', seq: detail.seq};

            }
            requestReturnDetailInfo.init(orderDetailObject);

            setOpenRequestReturnOrderModal(false);
        }
    }, [openRequestReturnOrderModal, targetOrder]);


    useEffect(() => {
        if(updateManagerChangeOrderBasedStatusDone) {
            alert(updateManagerChangeOrderBasedStatus.msg);
            window.location.reload();
        }

        if(updateManagerChangeOrderBasedStatusError) {
            alert(updateManagerChangeOrderBasedStatusError.msg);
            dispatch(managerChangeOrderBasedStatusResetAction());
        }
    }, [updateManagerChangeOrderBasedStatusDone, updateManagerChangeOrderBasedStatusError]);


    return (
        <div id="requestReturnOrderModal" className="uk-position-z-index-9999999" data-uk-modal="container:false;">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                    <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-1@m">
                            <form onSubmit={onSubmit}>
                                {
                                    requestReturnDetailInfo && targetOrder && targetOrder.detail.map((detail) => (
                                        <div key={detail.seq}>
                                            <div className="uk-margin">
                                                <div>
                                                    <span>{targetOrder.name} {detail.option_name}</span>
                                                </div>
                                                <div className="uk-form-label">
                                                    <span>반품요청 사유</span>
                                                </div>
                                                <div className="uk-form-controls">
                                                    <select
                                                        className="uk-select"
                                                        id={`request_detail_${detail.seq}`}
                                                        name={`request_detail_${detail.seq}.requestReason`}
                                                        value={requestReturnDetailInfo.value[`request_detail_${detail.seq}`] ? requestReturnDetailInfo.value[`request_detail_${detail.seq}`].requestReason : -1}
                                                        onChange={requestReturnDetailInfo.onChange}
                                                    >
                                                        <option value={-1}>반품요청 사유를 선택해주세요.</option>
                                                        <option value={"INTENT_CHANGED"}>단순 변심 등 구매자 귀책 사유</option>
                                                        <option value={"BROKEN"}>상품 파손 등 판매자 귀책 사유</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <p className="uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button">취소</button>
                                    <button className="uk-button uk-button-primary" type="submit">반품요청 처리</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestReturnOrderModal;
