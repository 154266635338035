import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ActionLoader from '../../Widget/ActionLoader';
import { HashLink } from 'react-router-hash-link';
import StarRatingComponent from 'react-star-rating-component';

const Type1 = ({instance, isDispatch=false}) => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);

    useEffect(() => {
        if(isDispatch){
            dispatch(instance.reducer.action("?"+instance.queryString));
        }
    }, [selectedBrand, instance.queryString]);

    const linkArea = (child, item, index) => {
        if(instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.itemLink && instance.data.list1.element.itemLink.to){
            return <HashLink key={index} to={instance.data.list1.element.itemLink.to(item)} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, instance.data.list1.element.itemLink.scroll ? instance.data.list1.element.itemLink.scroll(index) : 0) }} className="uk-link-reset">{child}</HashLink>;
        }else{
            return child;
        }
    }

    return (
        instance.reducer.loading ? (
            // 로딩 중 처리 START
            <ActionLoader />
            // 로딩 중 처리 END
        ) : (
            // 로딩 후 처리 START
            instance.reducer.done ? (
                // 데이터 처리 성공 START
                instance.reducer.obj && instance.reducer.obj.data && instance.reducer.obj.data.length > 0 ? (
                    // 데이터 존재 START
                    instance.reducer.obj.data.map((item, index) => (
                        <div key={index}>
                            {linkArea((
                                <div className="uk-margin-small-bottom uk-flex-top uk-grid-collapse uk-padding-5 uk-hover" data-uk-grid>
                                    {
                                        instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.image && (
                                            <div className="uk-width-auto">
                                                <img src={instance.data.list1.element.image(item)} className="uk-object-cover uk-width-50 uk-height-50 uk-margin-xsmall-right" />
                                            </div>
                                        )
                                    }
                                    <div className="uk-width-expand uk-text-truncate">
                                        <p className="uk-margin-remove">
                                            {
                                                instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.status && instance.data.list1.element.status.class && instance.data.list1.element.status.value && (
                                                    <span className={`uk-text-0_75 uk-display-inline-block uk-text-top uk-margin-1-top ${instance.data.list1.element.status.class(item)}`}>[{instance.data.list1.element.status.value(item)}]</span>
                                                )
                                            }
                                            {
                                                instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.naverPayBadge && instance.data.list1.element.naverPayBadge(item) && (
                                                    <img src="/image/naver_simple.png" className="uk-object-cover uk-width-13 uk-height-auto uk-margin-small-left uk-text-top uk-margin-xsmall-top" />
                                                )
                                            }
                                            {
                                                instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.title && (
                                                    <span className="uk-text-0_85 uk-vertical-middle uk-margin-xsmall-left uk-text-top">{instance.data.list1.element.title(item)}</span>
                                                )
                                            }
                                        </p>
                                        <div className="uk-display-block uk-text-0_75 uk-margin-xsmall-top uk-text-truncate">
                                            {
                                                instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.score && (
                                                    <div className="uk-text-middle uk-text-0_6 uk-margin-2-right uk-display-inline-block">
                                                        <StarRatingComponent
                                                            disabled
                                                            id="ReviewScore"
                                                            name="ReviewScore"
                                                            starCount={5}
                                                            value={instance.data.list1.element.score(item)}
                                                            renderStarIcon={() => <span className="mdi mdi-heart"></span>}
                                                            starColor={"#ff2e55"}
                                                        />
                                                    </div>
                                                )
                                            }
                                            {
                                                instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.writer && (
                                                    <span>{instance.data.list1.element.writer(item)}</span>
                                                )
                                            }
                                            {
                                                instance.data && instance.data.list1 && instance.data.list1.element && instance.data.list1.element.regdate && (
                                                    <span>{instance.data.list1.element.regdate(item, true)}</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ), item, index)}
                        </div>
                    ))
                    // 데이터 존재 END
                ) : (
                    // 데이터 없음 START
                    <div className="uk-text-0_8 uk-text-center">
                        데이터가 없습니다.
                    </div>
                    // 데이터 없음 END
                )
                // 데이터 처리 성공 END
            ) : (
                // 데이터 처리 실패 START
                <div className="uk-text-0_8 uk-text-center">
                    데이터 조회가 실패하였습니다.
                </div>
                // 데이터 처리 실패 END
            )
            // 로딩 후 처리 END
        )
    )
};

export default Type1;
