import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { loadMeAction } from '../../reducers/user';
import {loadStockAction} from "../../reducers/analytics";

import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";
import Loader  from '../../Components/Widget/Loader';

const Stock = () => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone } = useSelector((state) => state.user);
    const { stock, loadStockLoading, loadStockDone } = useSelector((state) => state.analytics);
    const [values, setValues] = useState({ startDate: "", endDate: "" });
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        let query = location.search;
        dispatch(loadMeAction());
        dispatch(loadStockAction(query));
    }, [location]);

    useEffect(() => {
        let search = "";
        if(values.startDate){
            search += ("startDate="+values.startDate);
        }

        if(values.endDate){
            search += (search!=""?"&":"");
            search += ("endDate="+values.endDate);
        }

        history.push({
            pathname: location.pathname,
            search: search
        });
    }, [values]);

    const handleChange = (event) => {
        if(event.startDate){
            setValues({ ...values, ["startDate"]: event.startDate });
        }else if(event.endDate){
            setValues({ ...values, ["endDate"]: event.endDate });
        }
    }

    return loadMeLoading || loadStockLoading ? (
        <div>
            <Loader />
        </div>
    ) : loadMeDone && loadStockDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"배송/재고 분석"} />
            </UIKit.Div>
            <div id="search-head" className="uk-position-fixed uk-background-white uk-padding-small-vertical">
                <div className="uk-container uk-container-expand uk-padding-horizontal uk-padding-medium-horizontal@m">
                    <nav className="uk-navbar">
                        <div className="uk-navbar-left">
                            <span className="uk-text-0_9">기간 선택</span>
                            <div className="uk-margin-small-left">
                                <a href="#">
                                    <input
                                        className="uk-input uk-form-small"
                                        id="startDate"
                                        name="startDate"
                                        type="text"
                                        readOnly
                                        value={values.startDate}
                                        placeholder={"시작 날짜"}
                                    />
                                </a>
                                <UIKit.DatePicker target={"startDate"} onChange={handleChange} />
                            </div>
                            <span className="uk-margin-small-left">~</span>
                            <div className="uk-margin-small-left">
                                <a href="#">
                                    <input
                                        className="uk-input uk-form-small"
                                        id="endDate"
                                        name="endDate"
                                        type="text"
                                        readOnly
                                        value={values.endDate}
                                        placeholder={"종료 날짜"}
                                    />
                                </a>
                                <UIKit.DatePicker target={"endDate"} onChange={handleChange} />
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="uk-child-width-1-1 uk-child-width-1-2@m uk-margin-medium-top" data-uk-grid>
                <div>
                    <div className="uk-card uk-card-small uk-card-default uk-width-1-2 uk-align-center">
                        <div className="uk-card-header uk-text-0_9 uk-text-center">
                            배송 현황
                        </div>
                        <div className="uk-card-body uk-padding-small-vertical">
                            <ul className="uk-list uk-text-center">
                                <li>
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-primary uk-border-width-2 uk-border-none-right uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-primary uk-text-bold">브랜드</span></div>
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-primary uk-border-width-2 uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-primary uk-text-bold">발송</span></div>
                                </li>
                                <li className="uk-margin-remove-top">
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-right uk-border-none-top uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-bold">전체</span></div>
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-top uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-bold">{stock.delivery.sum.toLocaleString()}건</span></div>
                                </li>
                                {
                                    stock && stock.delivery.data.map((obj) => (
                                        <li className="uk-margin-remove-top">
                                            <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-right uk-border-none-top uk-padding-7-vertical"><span className="uk-text-0_8">{obj.name}</span></div>
                                            <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-top uk-padding-7-vertical"><span className="uk-text-0_8">{obj.count.toLocaleString()}건</span></div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="uk-card uk-card-small uk-card-default uk-width-1-2 uk-align-center">
                        <div className="uk-card-header uk-text-0_9 uk-text-center">
                            반출 현황
                        </div>
                        <div className="uk-card-body uk-padding-small-vertical">
                            <ul className="uk-list uk-text-center uk-margin-remove-bottom">
                                <li>
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-primary uk-border-width-2 uk-border-none-right uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-primary uk-text-bold">브랜드(상품명)</span></div>
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-primary uk-border-width-2 uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-primary uk-text-bold">반출</span></div>
                                </li>
                                <li className="uk-margin-remove-top">
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-right uk-border-none-top uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-bold">전체</span></div>
                                    <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-top uk-padding-small-vertical"><span className="uk-text-0_85 uk-text-bold">{stock.sku.sum.toLocaleString()}건</span></div>
                                </li>
                            </ul>
                            <ul className="uk-margin-remove-top uk-text-center" data-uk-accordion="multiple: true">
                                {stock && Object.keys(stock.sku.data).map((key) => (
                                    <li className="uk-margin-remove-top">
                                        <p class="uk-accordion-title uk-accordion-before-none uk-text-1 uk-margin-remove uk-pointer" style={{lineHeight: "unset"}}>
                                            <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-right uk-border-none-top uk-padding-7-vertical"><span className="uk-text-0_8">{key}</span></div>
                                            <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-top uk-padding-7-vertical"><span className="uk-text-0_8">{stock.sku.data[key].sum.toLocaleString()}건</span></div>
                                        </p>
                                        <div class="uk-accordion-content uk-margin-remove-top">
                                            <ul className="uk-list uk-text-center uk-margin-remove-bottom">
                                                {stock.sku.data[key].data && stock.sku.data[key].data.map((obj) => (
                                                    <li className="uk-margin-remove-top uk-background-yellow">
                                                        <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-right uk-border-none-top uk-padding-7-vertical"><span className="uk-text-0_8">{obj.memo}</span></div>
                                                        <div className="uk-display-inline-block uk-width-1-2 uk-border uk-border-gray uk-border-none-top uk-padding-7-vertical"><span className="uk-text-0_8">{obj.count ? obj.count.toLocaleString() : 0}건</span></div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stock;
