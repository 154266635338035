import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Edit from "./Edit";

const Category = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}/edit`} component={Edit} />
        </Switch>
    );
};

export default Category;
