import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';


const TabStyle = ({ nowTab, setNowTab }) => {
    const {
        countByStatus,
        loadCountByStatusLoading,
        loadCountByStatusDone,
        loadCountByStatusError,
    } = useSelector((state) => state.order);

    const history = useHistory();
    const location = useLocation();

    const onClickTab = (status) => {
        let query=`page=1&status=${status}`;
        setNowTab(status);
        history.push({
            pathname: location.pathname,
            search: query
        });
    }

    return (
        <div>
            <ul data-uk-tab>
                <li className={nowTab === 'all' ? "uk-active" : ""} onClick={() => onClickTab('all')}>
                    <a>
                        <span className="uk-margin-small-right">전체</span>
                        <span className="uk-badge">{countByStatus.allCount}</span>
                    </a>
                </li>
                <li className={nowTab === 1 ? "uk-active" : ""} onClick={() => onClickTab(1)}>
                    <a>
                        <span className="uk-margin-small-right">입금대기</span>
                        <span className="uk-badge">{countByStatus.depositWaitingCount}</span>
                    </a>
                </li>
                <li className={nowTab === 2 ? "uk-active" : ""} onClick={() => onClickTab(2)}>
                    <a>
                        <span className="uk-margin-small-right">결제완료</span>
                        <span className="uk-badge">{countByStatus.payedCount}</span>
                    </a>
                </li>
                <li className={nowTab === 3 ? "uk-active" : ""} onClick={() => onClickTab(3)}>
                    <a>
                        <span className="uk-margin-small-right">배송준비</span>
                        <span className="uk-badge">{countByStatus.prepareDeliveryCount}</span>
                    </a>
                </li>
                <li className={nowTab === 4 ? "uk-active" : ""} onClick={() => onClickTab(4)}>
                    <a>
                        <span className="uk-margin-small-right">배송중</span>
                        <span className="uk-badge">{countByStatus.deliveryingCount}</span>
                    </a>
                </li>
                <li className={nowTab === 5 ? "uk-active" : ""} onClick={() => onClickTab(5)}>
                    <a>
                        <span className="uk-margin-small-right">배송완료</span>
                        <span className="uk-badge">{countByStatus.deliveredCount}</span>
                    </a>
                </li>
                <li className={nowTab === 6 ? "uk-active" : ""} onClick={() => onClickTab(6)}>
                    <a>
                        <span className="uk-margin-small-right">취소완료</span>
                        <span className="uk-badge">{countByStatus.canceledCount}</span>
                    </a>
                </li>
                {/* <li className={nowTab === 7 ? "uk-active" : ""} onClick={() => onClickTab(7)}>
                    <a>
                        <span className="uk-margin-small-right">반품완료</span>
                        <span className="uk-badge">{countByStatus.returnedCount}</span>
                    </a>
                </li>
                <li className={nowTab === 8 ? "uk-active" : ""} onClick={() => onClickTab(8)}>
                    <a>
                        <span className="uk-margin-small-right">교환완료</span>
                        <span className="uk-badge">{countByStatus.exchangedCount}</span>
                    </a>
                </li> */}
            </ul>
        </div>
    )
};

TabStyle.propTypes = {
    nowTab: PropTypes.number.isRequired,
    setNowTab: PropTypes.func.isRequired
};

export default TabStyle;
