import React, { useCallback, useState } from 'react';

const UseTwoDepthCheckboxForm = (initValue) => {
    const [value, setValue] = useState(initValue);

    const reset = useCallback(() => {
        setValue({});
    }, []);

    const init = useCallback((datas) => {
        const makeCheckboxs = {
            total: {
                checked: false
            },
            isShowDetailOption: false
        };
        if(datas.data) {
            for(let data of datas.data) {
                makeCheckboxs[data.seq] = {
                  value: data.seq,
                  checked: false,
                  payMethod: data.payLog && data.payLog.result && data.payLog.result.payMethod ? data.payLog.result.payMethod : "",
                  detailCheckbox: {}
                };
                for(let detail of data.detail) {
                    let detailCheckbox = {
                        value: detail.seq,
                        checked: false
                    };
                    makeCheckboxs[data.seq].detailCheckbox = { ...makeCheckboxs[data.seq].detailCheckbox, [detail.seq] : detailCheckbox };
                }
            }
        }
        setValue({ ...makeCheckboxs });
    }, [value]);

    const onChangeCheckboxAll = useCallback(() => {
        const keys = Object.keys(value);
        value.total.checked = !value.total.checked;
        for(let key of keys) {
            if(key === 'isShowDetailOption' || key === 'total') {
                continue;
            }
            value[key].checked = value.total.checked ? true : false;
            for(let detailKey of Object.keys(value[key].detailCheckbox)) {
                value[key].detailCheckbox[detailKey].checked = value.total.checked ? true : false;
            }
        }
        value.total.value = value.total.checked === true ?  keys.length - 2 : 0;
        value.isShowDetailOption = value.total.checked;
        setValue({ ...value });
    }, [value]);

    const first = useCallback((seq) => {
        const keys = Object.keys(value);
        let falseCount = 0;
        let trueCount = 0;

        value[seq].checked = !value[seq].checked;
        const detailKeys = Object.keys(value[seq].detailCheckbox);
        for(let detailKey of detailKeys) {
            value[seq].detailCheckbox[detailKey].checked = value[seq].checked ? true : false;
        }
        for(let key of keys) {
            if(key !== 'total' && key !== 'isShowDetailOption') {
                value[key].checked === false ? falseCount++ : trueCount++;
            }
        }

        falseCount === 0 ? value.total.checked = true : value.total.checked = false;
        if(trueCount > 0) {
            value.total.value = trueCount;
            value.isShowDetailOption = true;
        } else {
            value.isShowDetailOption = false;
        }
        setValue({ ...value });
    }, [value]);

    const second = useCallback((orderSeq, detailSeq) => {
        const detailKeys = Object.keys(value[orderSeq].detailCheckbox);
        let falseCount = 0;
        let trueCount = 0;

        value[orderSeq].detailCheckbox[detailSeq].checked = !value[orderSeq].detailCheckbox[detailSeq].checked;
        for(let detailKey of detailKeys) {
            if(detailKey !== 'total' && detailKey !== 'isShowDetailOption') {
                value[orderSeq].detailCheckbox[detailKey].checked === false ? falseCount++ : trueCount++;
            }
        }
        trueCount > 0 ? value[orderSeq].checked = true : value[orderSeq].checked = false;

        ///////////////////////////////////////////////////////////////////////////

        const keys = Object.keys(value);
        falseCount = 0;
        trueCount = 0;

        for(let key of keys) {
            if(key !== 'total' && key !== 'isShowDetailOption') {
                value[key].checked === false ? falseCount++ : trueCount++;
            }
        }

        falseCount === 0 ? value.total.checked = true : value.total.checked = false;
        if(trueCount > 0) {
            value.total.value = trueCount;
            value.isShowDetailOption = true;
        } else {
            value.isShowDetailOption = false;
        }


        setValue({ ...value });
    }, [value]);

    return {
        reset,
        value,
        init,
        onChangeCheckboxAll,
        first,
        second
    }
};

export default UseTwoDepthCheckboxForm;
