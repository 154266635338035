import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const PercelRegistTableStyle = () => {
    const { percelRegists } = useSelector((state) => state.order);

    const getResult = (percelRegist) => {
        let content = "";
        if(percelRegist) {
            if(percelRegist.success === 0 && percelRegist.failure === 0 && !percelRegist.errorLog && !percelRegist.errorByOrder && !percelRegist.successByOrder) {
                content = "처리중입니다...";
            }  else {
                if(percelRegist.errorLog) {
                    return (
                        <td>
                            <p>{percelRegist.errorLog}</p>
                            <dl className="uk-description-list">
                                <dt>에러 발생전까지 성공한 주문 번호</dt>
                                    {
                                        percelRegist.successByOrder && percelRegist.successByOrder.length > 0 ?
                                            percelRegist.successByOrder.map((successByOrder) => (
                                                <dd key={successByOrder}>{successByOrder}</dd>
                                            ))
                                         : (
                                            <dd>시작하자마자 바로 발생</dd>
                                        )
                                    }
                            </dl>
                        </td>
                    )
                } else if((percelRegist.errorByOrder && Object.keys(percelRegist.errorByOrder).length > 0) || (percelRegist.overlapByOrder && Object.keys(percelRegist.overlapByOrder).length > 0)) {
                    return (
                            <td>
                                <p className="uk-text-danger">실패 주문번호</p>
                                <ul data-uk-accordion="multiple: true" className={"uk-flex uk-flex-column uk-overflow-auto"}>
                                    {
                                        percelRegist.errorByType && Object.keys(percelRegist.errorByType).map((errorByTypeKey) => (
                                            <li key={errorByTypeKey} className="uk-flex-1">
                                                <a className="uk-accordion-title uk-text-0_8" href="#">{errorByTypeKey}</a>
                                                <div className="uk-accordion-content">
                                                    <table className="uk-table uk-table-divider uk-text-0_8">
                                                        <thead>
                                                            <tr>
                                                                <td>주문번호</td>
                                                                <td>주문상세번호</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                percelRegist.errorByType[errorByTypeKey] && Object.keys(percelRegist.errorByType[errorByTypeKey]).map((orderKey) => (
                                                                    <tr key={orderKey}>
                                                                        <td>{orderKey}</td>
                                                                        <td>{
																			percelRegist.errorByType[errorByTypeKey][orderKey] && Object.keys(percelRegist.errorByType[errorByTypeKey][orderKey]).map((detailKey) => (
                                                                    <p key={detailKey}>{percelRegist.errorByType[errorByTypeKey][orderKey][detailKey]}</p>
                                                                ))

                                                                        }</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                {

                                    percelRegist.overlapByOrder && Object.keys(percelRegist.overlapByOrder) && Object.keys(percelRegist.overlapByOrder).length > 0 && (
                                        <Fragment>
                                            <p className="uk-text-warning">중복 주문번호</p>
                                            <ul data-uk-accordion="multiple: true" className="uk-flex uk-flex-column uk-overflow-auto">
                                                {
                                                    Object.keys(percelRegist.overlapByOrder).map((overlapByOrderKey) => (
                                                        <li key={overlapByOrderKey} className="uk-flex-1">
                                                            <a className="uk-accordion-title uk-text-0_8" href="#">주문번호 : {overlapByOrderKey}</a>
                                                            <div className="uk-accordion-content">
                                                                <table className="uk-table uk-table-divider uk-text-0_8">
                                                                    <thead>
                                                                        <tr>
                                                                            <td>상세번호</td>
                                                                            <td>사유</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            percelRegist.overlapByOrder[overlapByOrderKey] && Object.keys(percelRegist.overlapByOrder[overlapByOrderKey]).map((detailKey) => (
                                                                                <tr key={detailKey}>
                                                                                    <td>{detailKey}</td>
                                                                                    <td>{percelRegist.overlapByOrder[overlapByOrderKey][detailKey]}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </Fragment>
                                    )
                                }

                            </td>
                    )
                } else {
                    return (
                        <td></td>
                    )
                }
            }
        }
    };
    return (
        <table className="uk-table uk-table-divider uk-table-small uk-table-border uk-text-0_8 uk-text-center">
            <thead>
                <tr>
                    <td>요청 일시</td>
                    <td>요청한 전체주문</td>
                    <td>요청한 전체상세주문</td>
                    <td>성공</td>
                    <td>실패</td>
                    <td>중복</td>
                    <td>요청결과</td>
                </tr>
            </thead>
            <tbody>
                {
                    percelRegists ? percelRegists.data.map((percelRegist) => (
                        <tr key={percelRegist.seq}>
                            <td>{percelRegist.regdate}</td>
                            <td className="uk-text-primary">{percelRegist.totalOrder}</td>
                            <td className="uk-text-primary">{percelRegist.totalOrderDetail}</td>
                            <td className="uk-text-success">{percelRegist.success}</td>
                            <td className="uk-text-danger">{percelRegist.failure}</td>
                            <td className="uk-text-warning">{percelRegist.overlap}</td>
                            {getResult(percelRegist)}
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="7">요청된 데이터가 없습니다.</td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
};

export default PercelRegistTableStyle;
