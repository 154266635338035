import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { loadCategoriesAction } from '../../../actions/product/action';
import { loadBrandsAction } from '../../../reducers/brand';

import UIKit from "../../UIKit";
import Loader from '../Loader';
import CategoryCard from './CategoryCard';

const CategoryForm = ({ formDatas }) => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);
    const { brands, loadBrandsLoading, loadBrandsDone } = useSelector((state) => state.brand);
    const { categories, loadCategoriesLoading, loadCategoriesError, loadCategoriesDone, updateCategoriesDone } = useSelector((state) => state.product);

    useEffect(() => {
        dispatch(loadCategoriesAction());
        dispatch(loadBrandsAction());
    }, [updateCategoriesDone]);

    useEffect(() => {
        if(loadCategoriesDone) {
            formDatas.setInitValue(categories);
        }
    }, [loadCategoriesDone]);

    useEffect(() => {
        if(selectedBrand !== 'all') {
            formDatas.onChangeSelectedBrand(selectedBrand);
        } else {
            formDatas.onChangeSelectedBrand(-1);
        }
    }, [selectedBrand]);

    return loadCategoriesLoading | loadBrandsLoading ? <Loader /> : loadCategoriesDone && loadBrandsDone && (
        <form onSubmit={(e) => e.preventDefault()} id="productForm" className='uk-width-1-1'>
            <div data-uk-grid>
                <UIKit.Div classOptions={"uk-width-1-2@m"}>
                    {
                        Object.keys(formDatas.value.brandAndCategories).length > 0 && Object.keys(formDatas.value.brandAndCategories).map((brandCategoriesKey, index) => (
                            <CategoryCard key={index} brandCategoriesKey={brandCategoriesKey} formDatas={formDatas} />
                        ))
                    }
                </UIKit.Div>
                <UIKit.Div classOptions={"uk-width-1-2@m"}>
                    <div className="uk-card uk-card-default uk-card-style">
                        <div className="uk-card-header uk-position-relative">
                            <div data-uk-grid>
                                <div className="uk-width-1-2 uk-text-left">
                                    <h3 className="uk-card-title uk-margin-remove-vertical">상세설정</h3>
                                </div>
                                <div className="uk-width-1-2">
                                    <div data-uk-grid>
                                        <div className="uk-width-1-2">
                                            <select
                                                className={selectedBrand === "all" ? "uk-select" : "uk-select uk-hidden"}
                                                name="selectedBrand"
                                                id="selectedBrand"
                                                value={formDatas.value.selectedBrand}
                                                onChange={formDatas.onChangeSelectedBrand}

                                            >
                                                <option value={-1}>브랜드를 선택해주세요</option>
                                                {
                                                    brands.data.map((brand) => (
                                                        <option key={brand.seq} value={brand.seq}>{brand.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="uk-width-1-2 uk-text-right">
                                            <button className="uk-button uk-button-small uk-button-radius" onClick={() => formDatas.onClickAddCategory(formDatas.value.selectedBrand)}>추가</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                          className="uk-card-body uk-position-relative uk-overflow-auto uk-height-248@m uk-height-198@l uk-min-height-140 uk-max-height-270"
                        >
                            <div data-uk-grid>
                                <div className="uk-width-1-1">
                                    <div data-uk-grid>
                                        {
                                            Object.keys(formDatas.value.selectedCategory).length === 0 ? (
                                                <p>선택된 카테고리가 없습니다.</p>
                                            ) : (
                                                <div className="uk-width-1-1 uk-margin">
                                                    <label className="uk-form-label" htmlFor="name">카테고리명</label>
                                                    <div className="uk-form-controls">
                                                        <input
                                                            className="uk-input"
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={formDatas.value.selectedCategory.name}
                                                            placeholder="카테고리명을 입력해주세요"
                                                            onChange={(e) => formDatas.onChange(e,formDatas.value.selectedCategory.brand_seq, formDatas.value.selectedCategory.index)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </UIKit.Div>
            </div>
        </form>
    )
};

CategoryForm.propTypes = {
    formDatas: PropTypes.object.isRequired,
};

export default CategoryForm;
