import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadAdminAction, updateAdminAction, createAdminAction } from '../../../reducers/admin';

import UseFormImage from '../../../hook/UseFormImage';

const FormStyle = ({ paramId }) => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const {
        loadAdminLoading,
        admin,
        loadAdminDone,
        updateAdmin,
        updateAdminDone,
        updateAdminError,
        createAdminError,
        createAdminDone,
        createAdmin
    } = useSelector((state) => state.admin);
    const formDatas = UseFormImage({
        seq: -1,
        id: '',
        name: '',
        img: '',
        status: 1,
        regdate: '',
        content: ''
    });
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            if (paramId) {
                formData.append("_method", "PUT");
            }
            if (paramId) {
                dispatch(updateAdminAction(paramId, formData));
            } else {
                dispatch(createAdminAction(formData));
            }
        }, [paramId]);

        useEffect(() => {
            if(paramId) {
                dispatch(loadAdminAction(paramId));
            }
        }, []);

        useEffect(() => {
            if(loadAdminDone) {
                if(paramId) {
                    formDatas.setInitValue(admin);
                }
            }
        }, [loadAdminDone]);

        useEffect(() => {
            if(updateAdminDone) {
                alert(updateAdmin.data.msg);
                window.location.reload();
            }

            if(createAdminDone) {
                alert(createAdmin.data.msg);
                window.location.href = `/setting/admin/all/${createAdmin.data.seq}`;
            }
        }, [updateAdminDone, createAdminDone]);

        useEffect(() => {
            if(updateAdminError) {
                setErrorMessage(Object.values(updateAdminError)[0]);
                if(formRef?.current[Object.keys(updateAdminError)[0]]) {
                    formRef.current[Object.keys(updateAdminError)[0]].focus();
                }

            }

            if(createAdminError) {
                setErrorMessage(Object.values(createAdminError)[0]);
                if(formRef?.current[Object.keys(createAdminError)[0]]) {
                    formRef.current[Object.keys(createAdminError)[0]].focus();
                }

            }
        }, [updateAdminError, createAdminError]);
    return (
        <form
            className="toggle-class"
            onSubmit={onSubmit}
            encType="multipart/form-data"
            ref={formRef}
        >
            {errorMessage && (
                <div className="uk-alert-danger" data-uk-alert>
                    <Link
                        to="#"
                        className="uk-alert-close"
                        data-uk-close
                        onClick={() => setErrorMessage("")}
                    />
                    <p>{errorMessage}</p>
                </div>
            )}

            <div data-uk-grid>
                <div className="uk-width-1-1 uk-text-center">
                    <div>
                        <label
                            className="uk-form-label uk-text-meta uk-margin-remove-top uk-margin-small-bottom uk-display-block"
                            htmlFor="file1"
                        >
                            사진
                        </label>
                        <div className="uk-width-small uk-height-small uk-align-center">
                            <img
                                className="uk-width-1-1 uk-height-1-1 uk-border-circle uk-object-cover"
                                src={formDatas.value.preview ?
                                        formDatas.value.preview : formDatas.value.img ?
                                            formDatas.value.img : require("../../../resource/uikit/avatar.svg")
                                }
                                alt={formDatas.value.preview ? formDatas.value.preview : formDatas.value.img}
                            />
                        </div>
                        <div
                            className="uk-form-custom uk-margin-small-top"
                            data-uk-form-custom
                        >
                            <input
                                type="file"
                                id="file1"
                                name="img"
                                data-preview="image1"
                                onChange={formDatas.onChangeImage}
                            />
                            <button
                                className="uk-button uk-button-default uk-button-small"
                                type="button"
                            >
                                수정
                            </button>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <div className="uk-margin-top">
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="id"
                        >
                            아이디
                        </label>
                        <div className="uk-form-controls">
                            <input
                                className="uk-input uk-form-small"
                                id="id"
                                name="id"
                                type="text"
                                placeholder="아이디 입력"
                                value={formDatas.value.id}
                                onChange={formDatas.onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <div className="uk-margin-top">
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="name"
                        >
                            이름
                        </label>
                        <div className="uk-form-controls">
                            <input
                                className="uk-input uk-form-small"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="이름 입력"
                                value={formDatas.value.name}
                                onChange={formDatas.onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <div className="uk-margin-top">
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="status"
                        >
                            사용여부
                        </label>
                        <div className="uk-form-controls">
                            <label className="uk-form-small uk-pointer uk-padding-remove-left">
                                <input
                                    className="uk-radio"
                                    type="radio"
                                    name="status"
                                    value={1}
                                    checked={parseInt(formDatas.value.status,10) === 1 ? true : false}
                                    onChange={formDatas.onChange}
                                />사용
                            </label>
                            <label className="uk-form-small uk-pointer">
                                <input
                                    className="uk-radio"
                                    type="radio"
                                    name="status"
                                    value={0}
                                    checked={parseInt(formDatas.value.status,10) === 0 ? true : false}
                                    onChange={formDatas.onChange}
                                />미사용
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-align-right uk-margin-medium-top">
                <button
                    type="submit"
                    className="uk-button uk-button-primary"
                >
                    저장
                </button>
            </div>
        </form>
    )
}

FormStyle.propTypes = {
    paramId: PropTypes.string.isRequired,
};

export default FormStyle;
