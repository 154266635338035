import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UIKIT from 'uikit';

import { useInput } from '../../../hook';
import {
    managerChangeOrderDetailStatusAction,
    managerChangeOrderDetailStatusResetAction
} from '../../../actions/order/action';
import { loadOrdersAction } from '../../../reducers/order';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

const ExchangeModal = ({ openExchangeModal, setOpenExchangeModal, targetOrderDetail }) => {
    const dispatch = useDispatch();

    const {
        updateManagerChangeOrderDetailStatusDone,
        updateManagerChangeOrderDetailStatusError,
        updateManagerChangeOrderDetailStatus
    } = useSelector((state) => state.order);

    const location = useLocation();

    const reDeliveryInvoice = useInput("");
    const isReturnDeliveryFee = useInput("0");
    const etcFee = useInput(0);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('reDeliveryInvoice', reDeliveryInvoice.value);
        formData.append("isExchangeDeliveryFee", isReturnDeliveryFee.value);
        formData.append("etcFee", etcFee.value);
        formData.append('status', 7);
        formData.append("_method", "PUT");
        dispatch(managerChangeOrderDetailStatusAction(targetOrderDetail.seq, formData));
    }, [isReturnDeliveryFee, etcFee]);

    useEffect(() => {
        if(openExchangeModal && targetOrderDetail) {
            UIKIT.modal('#exchangeModal').show();
            setOpenExchangeModal(false);
        }
    }, [openExchangeModal, targetOrderDetail]);


    useEffect(() => {
        if(updateManagerChangeOrderDetailStatusDone) {
            // setOpenReturnModal(false);
            // UIKIT.modal('#returnModal').hide();
            alert(updateManagerChangeOrderDetailStatus.msg);
            // dispatch(managerChangeOrderDetailStatusResetAction());
            // dispatch(loadCountsForSidenavAction());
            // let query = location.search;
            // dispatch(loadOrdersAction(`/details${query}&status=3`));
            window.location.reload();

            // 수정필요.
        }

        if(updateManagerChangeOrderDetailStatusError) {
            alert(updateManagerChangeOrderDetailStatusError.msg);
            dispatch(managerChangeOrderDetailStatusResetAction());
        }
    }, [updateManagerChangeOrderDetailStatusDone, updateManagerChangeOrderDetailStatusError]);

    return (
        <div id="exchangeModal" className="uk-position-z-index-9999999" data-uk-modal="container:false;">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-header uk-padding-small-horizontal uk-padding-horizontal@m">교환 정보 확인</div>
                <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                    <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-1@m">
                            <form onSubmit={onSubmit}>
                                <div className="uk-margin">
                                    <div className="uk-form-label">재배송 송장번호</div>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            id="reDeliveryInvoice"
                                            type="text"
                                            placeholder="재배송 송장번호를 입력해주세요."
                                            value={reDeliveryInvoice.value}
                                            onChange={reDeliveryInvoice.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div className="uk-form-label">
                                        <span>교환 배송비 동봉 여부</span>
                                        <span className="uk-text-danger">('아니오' 로 체크시 교환 배송비가 자동 차감됩니다.)</span>
                                    </div>
                                    <div className="uk-form-controls">
                                        <label className="uk-margin-small-right">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="isReturnDeliveryFee"
                                                value={1}
                                                onChange={isReturnDeliveryFee.onChange}
                                                checked={isReturnDeliveryFee.value === "1" ? true : false}
                                            /> 예
                                        </label>
                                        <label>
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="isReturnDeliveryFee"
                                                value={0}
                                                onChange={isReturnDeliveryFee.onChange}
                                                checked={isReturnDeliveryFee.value === "0" ? true : false}
                                            /> 아니오
                                        </label>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="etcFee">기타청구금액</label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            name="etcFee"
                                            id="etcFee"
                                            type="text"
                                            placeholder="금액을 입력해주세요."
                                            value={etcFee.value.toLocaleString()}
                                            onChange={(event) => etcFee.onChange(event, 1)}
                                        />
                                    </div>
                                </div>
                                <p className="uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button">취소</button>
                                    <button className="uk-button uk-button-primary" type="submit">재발송 처리</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExchangeModal;
