import React from "react";
import PropTypes from "prop-types";

const Table = ({
    isTotalView = false,
    tableOptions = "",
    tableHeadOptions = "",
    tableBodyOptions = "",
    onClickAction,
    data
}) => {
    return (
        <div>
            {data ? (
                <div>
                    {isTotalView && <p>총 {data.total}건</p>}
                    <table className={`uk-table ${tableOptions}`}>
                        <thead className={`${tableHeadOptions}`}>
                            <tr>
                                {Object.entries(data.column).map(
                                    (val, index) => (
                                        <th key={index}>{val[1]}</th>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody className={`${tableBodyOptions}`}>
                            {data.list && data.list.length > 0 ? (
                                data.list.map(list => (
                                    <tr
                                        key={list.seq}
                                        onClick={() => onClickAction(list.seq)}
                                    >
                                        {Object.entries(data.column).map(
                                            (val, index) => (
                                                <td key={index}>
                                                    {val[0] === "img" ? (
                                                        list[val[0]] ? (
                                                            <img
                                                                className="uk-border-circle profile-img"
                                                                src={
                                                                    list[val[0]]
                                                                }
                                                                alt={
                                                                    list[val[0]]
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                className="uk-border-circle profile-img"
                                                                src={require("../../resource/uikit/avatar.svg")}
                                                                alt={
                                                                    list[val[0]]
                                                                }
                                                            />
                                                        )
                                                    ) : (
                                                        list[val[0]]
                                                    )}
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colspan={
                                            Object.keys(data.column).length
                                        }
                                        class="uk-text-center"
                                    >
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>데이터가 없습니다.</div>
            )}
        </div>
    );
};

Table.propTypes = {
    tableOptions: PropTypes.string,
    tableHeadOptions: PropTypes.string,
    tableBodyOptions: PropTypes.string,
    isTotalView: PropTypes.bool,
    onClickAction: PropTypes.func,
    data: PropTypes.object
};

export default Table;
