import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UIKIT from 'uikit';

import { useInput } from '../../../hook';
import { useFormData } from '../../../hook';
import {
    managerChangeOrderBasedStatusAction,
    managerChangeOrderBasedStatusResetAction
} from '../../../actions/order/action';
import { loadOrdersAction } from '../../../reducers/order';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

const ReturnOrderModal = ({ openReturnOrderModal, setOpenReturnOrderModal, targetOrder }) => {
    const dispatch = useDispatch();

    const {
        updateManagerChangeOrderBasedStatusDone,
        updateManagerChangeOrderBasedStatusError,
        updateManagerChangeOrderBasedStatus
    } = useSelector((state) => state.order);

    const location = useLocation();

    const orderDetailReturnInfo = useFormData({});
    const isReturnDeliveryFee = useInput("0");
    const etcFee = useInput(0);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        UIKIT.modal('#returnOrderModal').hide();
        setOpenReturnOrderModal(false);
        const formData = new FormData();
        formData.append("details", JSON.stringify(orderDetailReturnInfo.value));
        formData.append("detailStatus", 8);
        formData.append("_method", "PUT");
        dispatch(managerChangeOrderBasedStatusAction(targetOrder.seq, formData));
    }, [orderDetailReturnInfo]);

    useEffect(() => {
        if(openReturnOrderModal && targetOrder) {
            UIKIT.modal('#returnOrderModal').show();
            let orderDetailObject = {};
            for(let detail of targetOrder.detail) {
                if(!orderDetailObject[`detail_${detail.seq}`]) {
                    orderDetailObject[`detail_${detail.seq}`] = {};
                }

                orderDetailObject[`detail_${detail.seq}`] = {isReturnDeliveryFee: '0', etcFee: 0, seq: detail.seq};

            }
            orderDetailReturnInfo.init(orderDetailObject);

            setOpenReturnOrderModal(false);
        }
    }, [openReturnOrderModal, targetOrder]);


    useEffect(() => {
        if(updateManagerChangeOrderBasedStatusDone) {
            alert(updateManagerChangeOrderBasedStatus.msg);
            window.location.reload();

            // 수정필요.
        }

        if(updateManagerChangeOrderBasedStatusError) {
            alert(updateManagerChangeOrderBasedStatusError.msg);
            dispatch(managerChangeOrderBasedStatusResetAction());
        }
    }, [updateManagerChangeOrderBasedStatusDone, updateManagerChangeOrderBasedStatusError]);


    return (
        <div id="returnOrderModal" className="uk-position-z-index-9999999" data-uk-modal="container:false;">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                    <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-1@m">
                            <form onSubmit={onSubmit}>
                                {
                                    orderDetailReturnInfo && targetOrder && targetOrder.detail.map((detail) => (
                                        <div key={detail.seq}>
                                            <div className="uk-margin">
                                                <div>
                                                    <span>{targetOrder.name} {detail.option_name}</span>
                                                </div>
                                                <div className="uk-form-label">
                                                    <span>반품 배송비 동봉 여부</span>
                                                    <span className="uk-text-danger">('아니오' 로 체크시 반품 배송비가 자동 차감됩니다.)</span>
                                                </div>
                                                <div className="uk-form-controls">
                                                    <label className="uk-margin-small-right">
                                                        <input
                                                            className="uk-radio"
                                                            type="radio"
                                                            name={`detail_${detail.seq}.isReturnDeliveryFee`}
                                                            value={"1"}
                                                            onChange={orderDetailReturnInfo.onChange}
                                                            checked={(orderDetailReturnInfo.value[`detail_${detail.seq}`] && orderDetailReturnInfo.value[`detail_${detail.seq}`].isReturnDeliveryFee) === "1" ? true : false}
                                                        /> 예
                                                    </label>
                                                    <label>
                                                        <input
                                                            className="uk-radio"
                                                            type="radio"
                                                            name={`detail_${detail.seq}.isReturnDeliveryFee`}
                                                            value={"0"}
                                                            onChange={orderDetailReturnInfo.onChange}
                                                            checked={(orderDetailReturnInfo.value[`detail_${detail.seq}`] && orderDetailReturnInfo.value[`detail_${detail.seq}`].isReturnDeliveryFee) === "0" ? true : false}
                                                        /> 아니오
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="uk-margin">
                                                <label className="uk-form-label" htmlFor="etcFee">기타청구금액</label>
                                                <div className="uk-form-controls">
                                                    <input
                                                        className="uk-input"
                                                        name={`detail_${detail.seq}.etcFee`}
                                                        id={`detail_${detail.seq}.etcFee`}
                                                        type="text"
                                                        placeholder="금액을 입력해주세요."
                                                        value={orderDetailReturnInfo.value[`detail_${detail.seq}`] && orderDetailReturnInfo.value[`detail_${detail.seq}`].etcFee ? orderDetailReturnInfo.value[`detail_${detail.seq}`].etcFee.toLocaleString() : 0}
                                                        onChange={orderDetailReturnInfo.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <p className="uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button">취소</button>
                                    <button className="uk-button uk-button-primary" type="submit">반품확인 처리</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReturnOrderModal;
