import { useSelector } from "react-redux";
import { loadSummary2Action } from "../reducers/analytics";

import ConfigData from "./ConfigData";

class Analytics2 extends ConfigData {
    constructor() {
        super(useSelector((state) => state.analytics));
    }

    get(type, query = "") {
        if (type == "list") {
            this.makeQuery(query);
            this.rtn.reducer = {
                obj: this.state.summary,
                action: loadSummary2Action,
                loading: this.state.loadSummary2Loading,
                done: this.state.loadSummary2Done,
            };
        }
        return this.rtn;
    }
}

export default Analytics2;
