import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UIkit from 'uikit';

import { loadPgInfoAction, updatePgInfoAction, updatePgInfoResetAction} from '../../../reducers/pgInfo';

import Loader  from '../Loader';
import PgInfo from './PgInfo';

const PgInfoModal = ({ openPgInfoModal, setOpenPgInfoModal, propsPgInfo, payInfo}) => {
  const dispatch = useDispatch();
  const {
    pgInfo,
    loadPgInfoDone,
    updatePgInfoLoading,
    updatePgInfo,
    updatePgInfoDone,
    updatePgInfoError
  } = useSelector((state) => state.pgInfo);
  
  const [pgInfoValue, setPgInfoValue] = useState(null);
  
  const payInfoArr = [
    {idx:0, value:"mooncorp_innopay"},
    {idx:1, value:"mooncorp_inicis"},
    {idx:2, value:"inis_innopay"},
    {idx:3, value:"inis_inicis"}
  ];
  
  //모달창이 열릴때 선택된 pg탭 선택용 idx
  const tabIndex = payInfoArr.find(element => element.value == payInfo.col).idx;

  const onChange = useCallback((event) => {
    if(event.target) {
      let targetValue = event.target.value;
      let targetName = event.target.name;

      targetName = event.target.name.split(".");

      let temp = {...pgInfoValue[targetName[0]]};
      temp[targetName[1]] = {...temp[targetName[1]], [targetName[2]] : targetValue};
      setPgInfoValue({...pgInfoValue, [targetName[0]] : temp});
    }
  }, [pgInfoValue]);

  const onSubmit = useCallback(() => {
    const formData = new FormData();

    formData.append("mooncorp_innopay", JSON.stringify(pgInfoValue.mooncorp_innopay));
    formData.append("mooncorp_inicis", JSON.stringify(pgInfoValue.mooncorp_inicis));
    formData.append("inis_innopay", JSON.stringify(pgInfoValue.inis_innopay));
    formData.append("inis_inicis", JSON.stringify(pgInfoValue.inis_inicis));

    formData.append("_method", "PUT");
    
		dispatch(updatePgInfoAction(pgInfoValue.seq, formData));
  }, [pgInfoValue]);

  useEffect(() => {
      dispatch(loadPgInfoAction(propsPgInfo.seq));
  },[]);

  useEffect(() => {
    setPgInfoValue(pgInfo);
  },[pgInfo]);

  useEffect(() => {
    if(openPgInfoModal && loadPgInfoDone) {
      UIkit.modal('#PgInfoModal').show();
      UIkit.tab("#switcher-nav").show(tabIndex);
      setOpenPgInfoModal(false);  
    }
  }, [openPgInfoModal, loadPgInfoDone]);

  useEffect(() => {
    //에러 예외처리
    if(openPgInfoModal && updatePgInfoError) {
        alert(updatePgInfoError.msg);
        setOpenPgInfoModal(false);
    }
  }, [openPgInfoModal, updatePgInfoError]);

  useEffect(() => {
    if(updatePgInfoDone) {
      alert(updatePgInfo.data.msg);
      UIkit.tab("#switcher-nav").show(tabIndex);
      dispatch(updatePgInfoResetAction());
    }
  }, [updatePgInfoDone]);

  useEffect(() => {
    return () => {
      dispatch(updatePgInfoResetAction());
    }
  }, []);

  return (
    <div id="PgInfoModal" className="uk-modal-container uk-position-z-index-9999999" data-uk-modal="container:false;">
      <div className="uk-modal-dialog uk-width-800">
        <button className="uk-modal-close-default uk-close" type="button" data-uk-close onClick={() => setOpenPgInfoModal(false)}></button>
        <div className="uk-modal-header uk-padding-small-horizontal uk-padding-horizontal@m">PG정보수정</div>
        <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m" style={{minHeight:"600px"}}>
          {
            updatePgInfoLoading ? 
            <Loader /> 
            :
            pgInfoValue && (
            <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
              <div className="uk-width-1-1">
                <ul id="switcher-nav" className="uk-child-width-expand" data-uk-tab="connect:.uk-switcher; animation: uk-animation-fade">
                  <li><a href="#">문코퍼레이션-이노페이</a></li>
                  <li><a href="#">문코퍼레이션-이니시스</a></li>
                  <li><a href="#">이니스컴퍼니-이노페이</a></li>
                  <li><a href="#">이니스컴퍼니-이니시스</a></li>
                </ul>
                <ul className="uk-switcher uk-margin uk-margin-remove-top">
                  <li className="uk-overflow-auto">
                    <PgInfo 
                      pg="mooncorp_innopay"
                      pgInfoValue={pgInfoValue}
                      onChange={(e) => onChange(e)}
                    />
                  </li>
                  <li className="uk-overflow-auto">
                    <PgInfo 
                      pg="mooncorp_inicis"
                      pgInfoValue={pgInfoValue}
                      onChange={(e) => onChange(e)}
                    />
                  </li>
                  <li className="uk-overflow-auto">
                    <PgInfo 
                      pg="inis_innopay"
                      pgInfoValue={pgInfoValue}
                      onChange={(e) => onChange(e)}
                    />
                  </li>
                  <li className="uk-overflow-auto">
                    <PgInfo 
                      pg="inis_inicis"
                      pgInfoValue={pgInfoValue}
                      onChange={(e) => onChange(e)}
                    />
                  </li>
                </ul>
                <div className="uk-text-align-center">
                  <input className="uk-button uk-background-blue2 uk-text-white" type="button" value="저장" onClick={() => onSubmit()} />
                </div>
              </div>
            </div>
            )
          }
        </div>
      </div>
    </div>
  )
};

export default PgInfoModal;
