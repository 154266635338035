import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../reducers/user';

import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';
import List from './List';
import Form from './Form';

const Sample = () => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone, me, loadMeError } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(loadMeAction());
    }, []);

    return loadMeLoading ? null : loadMeDone && (
        <WithSideNavLayout>
            <Form />
            <List />
        </WithSideNavLayout>
    )
};

export default Sample;
