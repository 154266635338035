import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIkit from 'uikit';

import { loadProductInfosAction, loadProductInfoSavesAction } from '../../../actions/product/action';

import Pagination from "../Pagination";

const ProductInfoSavesModal = () => {
    const dispatch = useDispatch();
    const { productInfoSaves, productInfo } = useSelector((state) => state.product);

    const clickProductInfoSave = useCallback((productInfoSave, productInfoSaveIndex) => {
        dispatch(loadProductInfosAction(productInfoSave.productInfo.code, productInfoSaveIndex));
        UIkit.modal('#productInfoSavesModal').hide();
    }, []);

    const clickProductSaveInfoPageChange = useCallback((page) => {
        let query = `page=${page}`;
        dispatch(loadProductInfoSavesAction(query));
    }, []);
    return (
        <div id="productInfoSavesModal" data-uk-modal="container:false">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-header">
                    <h2 className="uk-modal-title">저장된 상품정보고시 정보</h2>
                </div>
                <div className="uk-modal-body" data-uk-grid>
                    {
                        productInfo &&
                        productInfo.productInfoSave &&
                        (
                            <div className="uk-width-1-1 uk-margin-small-top" data-uk-overflow-auto>
                                <table className="uk-table uk-table-hover uk-table-divider">
                                    <thead className="uk-text-center">
                                        <tr>
                                            <th>이름</th>
                                        </tr>
                                    </thead>
                                    <tbody className="uk-text-center">
                                        {
                                            productInfo &&
                                            productInfo.productInfoSave &&
                                            productInfo.productInfoSave.data.length > 0 ?
                                            productInfo.productInfoSave.data.map((productInfoSave, productInfoSaveIndex) => (
                                                <tr
                                                    key={productInfoSave.seq}
                                                    onClick={() => clickProductInfoSave(productInfoSave, productInfoSaveIndex)}
                                                    className="uk-pointer"
                                                >
                                                    <td>{productInfoSave.productInfoSaveName}</td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={2}>저장된 상품정보고시가 존재하지않습니다.</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div>
                                    <Pagination
                                        currentPage={productInfo.productInfoSave.current_page}
                                        pageLast={productInfo.productInfoSave.last_page}
                                        onClick={clickProductSaveInfoPageChange}
                                    />
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
};

export default ProductInfoSavesModal;
