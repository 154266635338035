import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector , useDispatch } from 'react-redux';

import { loadMeAction } from '../../reducers/user';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

import { useFormData } from '../../hook';

import Loader from "../../Components/Widget/Loader";
import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";
import UserTableStyle from '../../Components/Widget/User/TableStyle';
import SearchHead from "../../Components/Widget/SearchHead";
import SearchModal from '../../Components/Widget/SearchModal';
import SearchForm from '../../Components/Widget/SearchForm';

const initDatas = {
    useSearchComponents: {
        dateSearch: {
            isUse: true,
            title: '조회기간',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'regDate',
                    name: '등록일'
                }
            ]
        },
        informationSearch: {
            isUse: true,
            title: '회원 정보',
            options: [
                {
                    value: 'all',
                    name: '전체',
                }, {
                    value: 'name',
                    name: '이름',
                }, {
                    value: 'phone',
                    name: '휴대폰 번호',
                }
            ]
        },
        statusSearch: {
            isUse: true,
            title: '사용여부'
        }
    }
}

const List = ({ history }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone, loadMeError } = useSelector((state) => state.user);
    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all'
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-transparent uk-button-radius",
            buttonName: "등록",
            onClickAction: () => history.push("/user/new")
        },
    ];

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"회원 목록"} buttons={buttons} />
            </UIKit.Div>
            <div>
                <SearchHead modalId={"userSearchModal"}  />
                <SearchModal modalId={"userSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>
                <UserTableStyle />
            </div>
        </div>
    );
};

export default List;
