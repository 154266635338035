import {
    LOAD_SKUS_REQUEST,
    LOAD_ALL_SKUS_REQUEST,
    LOAD_CATEGORIES_REQUEST,
    UPDATE_CATEGORIES_REQUEST,
    UPDATE_CATEGORIES_RESET,
    LOAD_PRODUCTS_REQUEST,
    LOAD_PRODUCT_REQUEST,
    DESTROY_PRODUCT_REQUEST,
    UPDATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_REQUEST,
    UPDATE_SKU_REQUEST,
    UPDATE_SKU_RESET,
    ON_CHANGE,
    ON_CHANGE_IMAGE,
    REMOVE_IMAGE,
    ADD_OPTION,
    ON_CHANGE_FOR_OPTION,
    ADD_OPTION_VALUE,
    REMOVE_OPTION_VALUE,
    ON_CHANGE_ESSENTIAL,
    REMOVE_OPTION,
    CHANGE_OPTION_LIST,
    ADD_SKU,
    REMOVE_SKU,
    LOAD_PRODUCT_RESET,
    LOAD_PRODUCT_INFO_REQUEST,
    LOAD_PRODUCT_INFOS_REQUEST,
    CHANGE_PRODUCT_INFO,
    CHANGE_ALL_PRODCUT_INFO_REFERENCE,
    LOAD_PRODUCT_INFO_SAVES_REQUEST,
    CREATE_PRODUCT_INFO_SAVES_REQUEST,
    LOAD_PRODUCT_INFO_SAVES_RESET,
    LOAD_PRODUCT_INFOS_RESET,
    CREATE_PRODUCT_INFO_SAVES_RESET,
} from './type';

export const createProductInfoSavesResetAction = () => ({
    type: CREATE_PRODUCT_INFO_SAVES_RESET,
});

export const loadProductInfosResetAction = () => ({
    type: LOAD_PRODUCT_INFOS_RESET
});
export const loadProductInfoSavesResetAction = () => ({
    type: LOAD_PRODUCT_INFO_SAVES_RESET
});

export const createProductInfoSavesAction = (data) => ({
    type: CREATE_PRODUCT_INFO_SAVES_REQUEST,
    data,
});

export const loadProductInfoSavesAction = (query) => ({
    type: LOAD_PRODUCT_INFO_SAVES_REQUEST,
    query,
});

export const changeAllProductInfoReferenceAction = (isAllReference) => ({
    type: CHANGE_ALL_PRODCUT_INFO_REFERENCE,
    isAllReference,
});

export const changeProductInfoAction = (value, productInfoSeq) => ({
    type: CHANGE_PRODUCT_INFO,
    value,
    productInfoSeq,
});

export const loadProductInfosAction = (code, productInfoSaveIndex = -1) => ({
    type: LOAD_PRODUCT_INFOS_REQUEST,
    code,
    productInfoSaveIndex,
});

export const loadProductInfoAction = (query) => ({
    type: LOAD_PRODUCT_INFO_REQUEST,
    query,
});

export const updateSkuAction = (skuSeq, formData) => ({
    type: UPDATE_SKU_REQUEST,
    skuSeq,
    data: formData,
});

export const updateSkuResetAction = () => ({
    type: UPDATE_SKU_RESET,
});

export const createProductAction = (formData) => ({
    type: CREATE_PRODUCT_REQUEST,
    data: formData,
});

export const updateProductAction = (productSeq, formData) => ({
    type: UPDATE_PRODUCT_REQUEST,
    productSeq,
    data: formData,
});

export const loadCategoriesAction = (seq) => ({
    type: LOAD_CATEGORIES_REQUEST,
    data: seq,
});

export const updateCategoriesAction = (data) => ({
    type: UPDATE_CATEGORIES_REQUEST,
    data,
});

export const loadProductsAction = (query) => ({
    type: LOAD_PRODUCTS_REQUEST,
    data: query,
});

export const loadProductAction = (productSeq) => ({
    type: LOAD_PRODUCT_REQUEST,
    data: productSeq,
});

export const loadProductResetAction = () => ({
    type: LOAD_PRODUCT_RESET,
});

export const destroyProductAction =  (productSeq) =>  ({
    type: DESTROY_PRODUCT_REQUEST,
    data: productSeq,
});

export const loadSkusAction = (seq) => ({
    type: LOAD_SKUS_REQUEST,
    data: seq,
});

export const loadAllSkusAction = (seq) => ({
    type: LOAD_ALL_SKUS_REQUEST,
    data: seq,
});

export const updateCategoriesResetAction = () => ({
    type: UPDATE_CATEGORIES_RESET
});


// formData action시작 //
export const onChangeAction = (data, editor="") => ({
    type: ON_CHANGE,
    data,
    editor,
});

export const onChangeImageAction = (imageFile, resultForPreview) => ({
    type: ON_CHANGE_IMAGE,
    imageFile,
    resultForPreview,
});

export const removeImageAction = (division, index) => ({
    type: REMOVE_IMAGE,
    division,
    index,
});

export const addOptionAction = () => ({
    type: ADD_OPTION,
});

export const onChangeForOption = (division, parentIndex="", childIndex="", event) => ({
    type: ON_CHANGE_FOR_OPTION,
    division,
    parentIndex,
    childIndex,
    event
});

export const addOptionValueAction = (parentIndex, childIndex, data) => ({
    type: ADD_OPTION_VALUE,
    parentIndex,
    childIndex,
    data,
});

export const removeOptionValueAction = (parentIndex, childIndex) => ({
    type: REMOVE_OPTION_VALUE,
    parentIndex,
    childIndex
});

export const removeOptionAction = (parentIndex) => ({
    type: REMOVE_OPTION,
    parentIndex,
});

export const onChangeEssentialAction = (parentIndex) => ({
    type: ON_CHANGE_ESSENTIAL,
    parentIndex,
});

export const changeOptionListAction = (inputName, parentIndex, event) => ({
    type: CHANGE_OPTION_LIST,
    inputName,
    parentIndex,
    event
});

export const addSkuAction = (modalIndex, skuSeq, skuName) => ({
    type: ADD_SKU,
    modalIndex,
    skuSeq,
    skuName,
});

export const removeSkuAction = (modalIndex, targetIndex) => ({
    type: REMOVE_SKU,
    modalIndex,
    targetIndex,
});
