import React, { useEffect, useCallback, Fragment, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import UIKIT from "uikit";
import axios from 'axios';

import { loadOrderAction } from '../../../reducers/order';

import {phoneFormat} from '../../../utils';

const OrderSheetModal = ({openOrderSheetModal, setOpenOrderSheetModal, orderSeq}) => {
    const dispatch = useDispatch();
    const modalRef = useRef();
    const { order, loadOrderLoading, loadOrderDone, loadOrderError } = useSelector((state) => state.order);

    useEffect(() => {
        if(openOrderSheetModal) {
            if(orderSeq) {
                let search = {
                    seq: orderSeq
                };
                search = JSON.stringify(search);
                dispatch(loadOrderAction(`?search=${btoa(search)}`));
            } else {
                UIKIT.modal('#orderSheetModal').hide();
                setOpenOrderSheetModal(false);
                alert(`연동되지 않은 주문입니다.`);
            }
        }
    }, [openOrderSheetModal]);

    useEffect(() => {
        if(openOrderSheetModal && loadOrderDone) {
            UIKIT.modal('#orderSheetModal').show();
            setOpenOrderSheetModal(false);
        }

        if(openOrderSheetModal && loadOrderError) {
            alert(loadOrderError.msg);
            setOpenOrderSheetModal(false);
        }

    }, [loadOrderDone, loadOrderError]);

    const onPrint = useCallback(async() => {
        let cssText = '';
        let scripts = '';

        for(const node of document.getElementsByTagName('style')) {
            cssText += node.innerHTML;
        }
        const printContent = modalRef.current;
        const popupWindow = window.open('', 'printPopup', 'width=700, height=800');
        popupWindow.document.writeln('<!DOCTYPE html>');
        popupWindow.document.writeln('<html>');
        popupWindow.document.writeln('<head>');
        for(const link of document.getElementsByTagName('link')) {
            const href = link.getAttribute('href');
            const splitedHref = href.split('.');
            if(splitedHref[splitedHref.length - 1] === 'css') {
                const cssData = await axios(`${window.location.protocol}//${window.location.hostname}${href}`);
                if(cssData.status === 200) {
                    cssText += cssData.data;
                }
            }
        }
        popupWindow.document.writeln('<style>');
        popupWindow.document.writeln(cssText);
        popupWindow.document.writeln('</style>');
        popupWindow.document.writeln('</head>');
        popupWindow.document.writeln('<body>');
        popupWindow.document.writeln('<div class="uk-overflow-auto uk-padding-large">');
        popupWindow.document.writeln(printContent.innerHTML);
        popupWindow.document.writeln('</div>');
        popupWindow.document.writeln('<script>');
        popupWindow.document.writeln('document.getElementById("printButton").style.display="none"');
        popupWindow.document.writeln('</script>');
        popupWindow.document.writeln('</body>');
        popupWindow.document.writeln('</html>');

        popupWindow.document.close();
        popupWindow.focus();
        popupWindow.print();
        popupWindow.close();
    }, [modalRef]);
    return (
        useMemo(() => {
            return (
                <div id="orderSheetModal" className="uk-modal-container uk-overflow-auto uk-position-z-index-9999999" data-uk-modal="container: false;">
                    <div ref={modalRef} className="uk-modal-dialog uk-width-800 uk-height-min-900 uk-margin-auto-vertical uk-padding-large" data-uk-overflow-auto>
                        {
                            order && (
                                <Fragment>
                                    <div className="uk-modal-header uk-padding-small-horizontal uk-padding-horizontal@m">
                                        <header className="uk-text-bold">
                                            <h2 className="uk-margin-remove-bottom">{order.brand && order.brand.name ? order.brand.name : ''}</h2>
                                            <p className="uk-text-0_7 uk-margin-remove uk-text-meta uk-text-normal">{order.brand && order.brand.domain ? order.brand.domain : ''}</p>
                                        </header>

                                    </div>
                                    <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                                        <div className="uk-flex uk-flex-wrap">
                                            <div className="uk-width-1-6">
                                                <span className="uk-text-0_75 uk-text-bold uk-text-secondary">주문번호</span>
                                            </div>
                                            <div className="uk-width-5-6">
                                                <span className="uk-text-0_75">{order.seq}</span>
                                            </div>
                                            <div className="uk-width-1-6">
                                                <span className="uk-text-0_75 uk-text-bold uk-text-secondary">주문일자</span>
                                            </div>
                                            <div className="uk-width-5-6">
                                                <span className="uk-text-0_75">{order.regdate.slice(0, 16)}</span>
                                            </div>
                                            <div className="uk-width-1-6">
                                                <span className="uk-text-0_75 uk-text-bold uk-text-secondary">결제수단</span>
                                            </div>
                                            <div className="uk-width-5-6">
                                                <span className="uk-text-0_75">
                                                    {order.payType === 2 ? '네이버 페이' : order.payType === 3 ? '아임웹' : order.payLog && order.payLog.result.payMethodName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="uk-flex uk-margin-medium-top">
                                            <div className="uk-width-2-3 uk-padding-small">
                                                <p className="uk-text-0_75 uk-text-bold uk-margin-small-bottom uk-text-secondary">배송정보</p>
                                                <span className="uk-text-0_75">{`${order.delivery.address} ${order.delivery.addressDetail}(우편번호: ${order.delivery.postcode})`}</span>
                                                <br/>
                                                <span className="uk-text-0_75">{phoneFormat(order.delivery.phone)}</span>
                                                <br/>
                                                <span className="uk-text-0_75">수취인명 : {order.delivery.name}</span>
                                            </div>
                                            <div className="uk-width-1-3 uk-padding-small">
                                                <p className="uk-text-0_75 uk-text-bold uk-margin-small-bottom uk-text-secondary">주문자 정보</p>
                                                <span className="uk-text-0_75">{order.buyer.name}</span>
                                                <br/>
                                                {
                                                    order.buyer.email ? (
                                                        <Fragment>
                                                            <span className="uk-text-0_75">{order.buyer.email ? order.buyer.email : '이메일이 존재하지않습니다.'}</span>
                                                            <br/>
                                                        </Fragment>
                                                    ) : null
                                                }
                                                <span className="uk-text-0_75">{phoneFormat(order.buyer.phone)}</span>
                                            </div>
                                        </div>
                                        <div className="uk-margin-medium-top uk-overflow-auto">
                                            <table className="uk-table border-none uk-table-divider uk-table-small uk-table-middle uk-text-0_85">
                                                <thead className="border-top-2px-solid uk-text-0_85">
                                                    <tr>
                                                        <th className="uk-font-weight-bold uk-text-secondary">주문내역</th>
                                                        <th className="uk-font-weight-bold uk-text-secondary">옵션</th>
                                                        <th className="uk-font-weight-bold uk-text-secondary">수량</th>
                                                        <th className="uk-font-weight-bold uk-text-secondary uk-text-right">단가</th>
                                                        <th className="uk-font-weight-bold uk-text-secondary uk-text-right">소계</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="uk-text-0_85">
                                                    {
                                                        order.detail.map((detail, index) => (
                                                            <tr key={detail.seq}>
                                                                {
                                                                    index === 0 && (
                                                                        <td rowSpan={order.detail.length}>
                                                                            <span className="uk-text-secondary uk-text-bold">
                                                                                {detail.product_name}
                                                                            </span>
                                                                        </td>
                                                                    )
                                                                }

                                                                <td>
                                                                    <span>
                                                                        {detail.option_name}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {detail.qty.toLocaleString()}
                                                                    </span>
                                                                </td>
                                                                <td className="uk-text-right">
                                                                    <span>
                                                                        {detail.price.toLocaleString()}원
                                                                    </span>
                                                                </td>
                                                                <td className="uk-text-right">
                                                                    <span>
                                                                        {(detail.price * detail.qty).toLocaleString()}원
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot className="border-bottom-2px-solid">
                                                    <tr>
                                                        <td className='border-right-1px-solid-gray' rowSpan={8} colSpan={2}></td>
                                                        <td colSpan={3}>
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-0_85">합계(상품금액)</span>
                                                                <span className="uk-text-0_85">{order.eachPrice.totalPrice.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-0_85">배송비</span>
                                                                <span className="uk-text-0_85">{order.eachPrice.deliveryPrice.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-0_85">취소금액</span>
                                                                <span className="uk-text-0_85">{order.eachPrice.canceledPrice.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-0_85">반품금액</span>
                                                                <span className="uk-text-0_85">{order.eachPrice.returned.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-0_85">반품 왕복 배송비</span>
                                                                <span className="uk-text-0_85">{order.eachPrice.returnDeliveryPrice.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-0_85">반품 기타 청구 비용</span>
                                                                <span className="uk-text-0_85">{order.eachPrice.returnEtcPrice.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3} className="uk-text-right uk-text-bold">
                                                            <div className="uk-flex uk-flex-between">
                                                                <span className="uk-text-bold uk-text-secondary uk-text-0_85">결제금액</span>
                                                                <span className="uk-text-bold uk-text-secondary uk-text-0_85">{order.eachPrice.calculatedPrice.toLocaleString()}원</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="uk-flex uk-margin-medium-top uk-flex-center uk-flex-wrap">
                                            <div className="uk-width-1-1 uk-text-center" id="printButton">
                                                <button className="uk-button uk-button-default uk-button-small" onClick={onPrint}>인쇄하기</button>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }

                    </div>
                </div>
            )
        },[openOrderSheetModal, setOpenOrderSheetModal, orderSeq])
    )
};

export default OrderSheetModal;
