import {
	all,
	fork,
	takeEvery,
	call,
	put,
	takeLatest,
	select
} from "redux-saga/effects";

import axios from "axios";
import {
	LOAD_DESIGN_REQUEST,
	LOAD_DESIGN_SUCCESS,
	LOAD_DESIGN_FAILURE,
	UPDATE_DESIGN_REQUEST,
	UPDATE_DESIGN_SUCCESS,
	UPDATE_DESIGN_FAILURE,
} from '../actions/design/type';

const getMe = state => {
	return state.user.me;
}

function loadDesignAPI(user, brandSeq) {
	// 서버에 요청을 보내는 부분
	let token = "";
	if(user && user.token) {
		token = `bearer${user.token}`;
	} else {
		token = `bearer${localStorage.getItem("token")}`;
	}
	// const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

	return axios.get(`/brands/${brandSeq}`, {
		withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
		headers: {
			Authorization : token,
			Brand: brandSeq
		}
	});
}

function* loadDesign(action) {
	try {
		const user =  yield select(getMe);
		const result = yield call(loadDesignAPI, user, action.data);
		yield put({
			// put은 dispatch 동일
			type: LOAD_DESIGN_SUCCESS,
			data: result.data,
		});
	} catch (e) {
		// loginAPI 실패
		yield put({
			type: LOAD_DESIGN_FAILURE,
			error: e.response.data,
		});
	}
}

function updateDesignAPI(user, brandSeq, data) {
	// 서버에 요청을 보내는 부분
	let token = "";
	if(user && user.token) {
		token = `bearer${user.token}`;
	} else {
		token = `bearer${localStorage.getItem("token")}`;
	}
	const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
	return axios.post(`/designs/${brandSeq}`, data, {
		withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
		headers: {
			Authorization : token,
			Brand: brand
		}
	});
}

function* updateDesign(action) {
	try {
		const user =  yield select(getMe);
		const result = yield call(updateDesignAPI, user, action.brandSeq, action.data);
		yield put({
			// put은 dispatch 동일
			type: UPDATE_DESIGN_SUCCESS,
			data: result.data,
		});
	} catch (e) {
		// loginAPI 실패
		yield put({
			type: UPDATE_DESIGN_FAILURE,
			error: e.response.data,
		});
	}
}

function* watchUpdateDesign() {
	yield takeLatest(UPDATE_DESIGN_REQUEST, updateDesign);
}

function* watchLoadDesign() {
	yield takeEvery(LOAD_DESIGN_REQUEST, loadDesign);
}

export default function* userSaga() {
	yield all([
		fork(watchLoadDesign),
		fork(watchUpdateDesign),
	]);
}
