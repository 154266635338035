import React, { useState, useCallback, useEffect } from "react";
import { userApi } from "./api";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserAction } from './reducers/user';
import Router from './router';


const useForm = (initValue) => {
    const [value, setValue] = useState(initValue);

    const onChange = useCallback((data) => {
        if(data.target) {
            if(data.target.name === 'selectedBrand') {
                localStorage.setItem('selectedBrand', data.target.value);
                value.selectedBrand = data.target.value;
            }
            setValue({...value});
        } else {
            setValue({...value, ...data});
        }
    }, [value]);

    return {value, onChange};
}
const App = () => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{process.env.NODE_ENV === "development" ? "이니스 관리자 페이지 개발서버" : "이니스 관리자 페이지"}</title>
            </Helmet>
            <div>
                <div className="App">
                    <Router />
                </div>
            </div>
        </div>

    );
}
export default App;
