import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadProductInfoAction,
    loadProductInfosAction,
    changeProductInfoAction,
    changeAllProductInfoReferenceAction,
    loadProductInfoSavesAction,
    createProductInfoSavesAction,
    createProductInfoSavesResetAction,
} from '../../../actions/product/action';

import ProductInfoSavesModal from './ProductInfoSavesModal';
import UIKit from "../../UIKit";

const InfoCard = () => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const {
        productInfo,
        productInfos,
        product,
        createProductInfoSavesError,
        createProductInfoSavesDone,
        createProductInfoSaves,
    } = useSelector((state) => state.product);

    const [productInfoSaveName, setProductInfoSaveName] = useState("");

    useEffect(() => {
        dispatch(loadProductInfoAction("page=1"));
        // dispatch(loadProductInfoSavesAction("page=1"));
    }, []);

    const changeProductInfoCode = useCallback((e) => {
        const code = e.target.value;
        dispatch(loadProductInfosAction(code));
    }, []);

    const changeInput = useCallback((e, productInfoSeq) => {
        const value = e.target.value;
        dispatch(changeProductInfoAction(value, productInfoSeq));
    }, []);

    const changeCheckbox = useCallback((e) => {
        if(product.productInfo && product.productInfo.code && product.productInfo.code !== -1) {
            dispatch(changeAllProductInfoReferenceAction(e.target.checked));
        } else {
            alert('상품군을 먼저 선택해주세요');
            e.target.checked = false;
            return;
        }
    }, [product]);

    const submitProductInfoSave = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData;
        formData.append('productInfoSaveName', productInfoSaveName);
        formData.append('productInfo', JSON.stringify(product.productInfo));
        dispatch(createProductInfoSavesAction(formData));
        setProductInfoSaveName("");
    }, [product, productInfoSaveName]);

    useEffect(() => {
        if(createProductInfoSavesError) {
            alert(Object.values(createProductInfoSavesError)[0]);
            if(formRef?.current[Object.keys(createProductInfoSavesError)[0]]) {
                formRef.current[Object.keys(createProductInfoSavesError)[0]].focus();
            }
        }

        if(createProductInfoSavesDone) {
            alert(createProductInfoSaves.msg);
            dispatch(createProductInfoSavesResetAction());
            dispatch(loadProductInfoSavesAction("page=1"));
        }
    }, [createProductInfoSavesDone, createProductInfoSavesError]);

    return (
        <UIKit.Div classOptions={"uk-width-1-1"}>
            <UIKit.Card
                title={"상품정보고시"}
                cardOptions={"uk-card-default uk-card-style"}
                cardBodyOptions={""}
            >
                <form onSubmit={submitProductInfoSave} ref={formRef}>
                    <div data-uk-grid>
                        <div className="uk-width-1-1" data-uk-grid>
                            <div className="uk-width-2-4 uk-text-left">
                                <span className="uk-text-muted">모든 항목은 필수 입력사항입니다.</span>
                            </div>
                            <div className="uk-width-2-4 uk-text-right">
                                <input
                                    id="checkbox"
                                    className="uk-checkbox"
                                    type="checkbox"
                                    onChange={changeCheckbox}
                                />
                                <label htmlFor="checkbox">상품상세 참조로 일괄입력</label>
                            </div>
                        </div>
                        <div className="uk-width-1-5 uk-text-right">
                            <span>상품군</span>
                        </div>
                        <div className="uk-width-medium">
                            <select
                                className="uk-select"
                                onChange={changeProductInfoCode}
                                value={product?.productInfo?.code === undefined ? "-1" : product.productInfo.code}
                            >
                                <option value="-1">선택</option>
                                {
                                    productInfo?.code?.data &&
                                    Object.keys(productInfo.code.data).length > 0 &&
                                    Object.keys(productInfo.code.data).map((key) => (
                                        <option key={key} value={key}>{productInfo.code.data[key]}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="uk-width-1-1">
                            {
                                productInfos &&
                                productInfos.map((productInfo) => (
                                    <div className="uk-width-1-1" data-uk-grid key={productInfo.seq}>
                                        <div className="uk-width-1-5 uk-text-right">
                                            <span>{productInfo.name}</span>
                                        </div>
                                        <div className="uk-width-4-5 uk-text-right">
                                            <input
                                                id={productInfo.seq}
                                                type="text"
                                                className="uk-input"
                                                value={product?.productInfo?.[productInfo.seq] === undefined ? "" : product.productInfo[productInfo.seq]}
                                                onChange={(e) => changeInput(e, productInfo.seq)}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="uk-width-1-1 uk-text-right">
                            <button
                                type="button"
                                className="uk-button uk-button-default uk-margin-small-right"
                                data-uk-toggle="target:#productInfoSavesModal"
                            >
                                자주쓰는 정보 불러오기
                            </button>
                            <button
                                type="button"
                                className="uk-button uk-button-primary"
                            >
                                자주쓰는 정보로 등록
                            </button>
                            <div data-uk-drop="mode: click">
                                <div className="uk-card uk-card-body uk-card-default">

                                        <div className="uk-margin">
                                            <label
                                                className="uk-form-label"
                                                htmlFor="productInfoSaveName"
                                            >
                                                상품명
                                            </label>
                                            <div className="uk-form-controls">
                                                <input

                                                    className="uk-input"
                                                    id="productInfoSaveName"
                                                    type="text"
                                                    placeholder="상품명"
                                                    value={productInfoSaveName}
                                                    onChange={(e) => setProductInfoSaveName(e.target.value)}
                                                />
                                            </div>
                                            <div className="uk-text-right">
                                                <button className="uk-button uk-button-text uk-margin-small-right uk-drop-close" type="button">취소</button>
                                                <button className="uk-button uk-button-text uk-text-primary" type="submit">저장</button>
                                            </div>
                                        </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </form>
                <ProductInfoSavesModal />
            </UIKit.Card>
        </UIKit.Div>
    );
};

export default InfoCard;
