import produce from "immer";

export const initialState = {
  loadPgInfosLoading: false,
  loadPgInfosDone: false,
  loadPgInfosError: null,
  pgInfos: null,
  loadPgInfoLoading: false,
  loadPgInfoDone: false,
  loadPgInfoError: null,
  pgInfo: null,
  updatePgInfoLoading: false,
  updatePgInfoDone: false,
  updatePgInfoError: null,
  updatePgInfo: null,
};

export const LOAD_PGINFOS_REQUEST = "LOAD_PGINFOS_REQUEST";
export const LOAD_PGINFOS_SUCCESS = "LOAD_PGINFOS_SUCCESS";
export const LOAD_PGINFOS_FAILURE = "LOAD_PGINFOS_FAILURE";

export const LOAD_PGINFO_REQUEST = "LOAD_PGINFO_REQUEST";
export const LOAD_PGINFO_SUCCESS = "LOAD_PGINFO_SUCCESS";
export const LOAD_PGINFO_FAILURE = "LOAD_PGINFO_FAILURE";

export const UPDATE_PGINFO_REQUEST = "UPDATE_PGINFO_REQUEST";
export const UPDATE_PGINFO_SUCCESS = "UPDATE_PGINFO_SUCCESS";
export const UPDATE_PGINFO_FAILURE = "UPDATE_PGINFO_FAILURE";
export const UPDATE_PGINFO_RESET = "UPDATE_PGINFO_RESET";

export const loadPgInfosAction = (query) => ({
  type: LOAD_PGINFOS_REQUEST,
  data: query,
});

export const loadPgInfoAction = (seq) => ({
  type: LOAD_PGINFO_REQUEST,
  data: seq,
});

export const updatePgInfoAction = (seq, formData) => ({
  type: UPDATE_PGINFO_REQUEST,
  seq,
  data: formData,
});

export const updatePgInfoResetAction = (seq) => ({
  type: UPDATE_PGINFO_RESET,
});

const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {

      case LOAD_PGINFOS_REQUEST: {
        draft.loadPgInfosLoading = true;
        draft.loadPgInfosDone = false;
        draft.loadPgInfosError = null;
        break;
      }
      case LOAD_PGINFOS_SUCCESS: {
        draft.pgInfos = action.data;
        draft.loadPgInfosLoading = false;
        draft.loadPgInfosDone = true;
        break;
      }
      case LOAD_PGINFOS_FAILURE: {
        draft.loadPgInfosLoading = false;
        draft.loadPgInfosError = action.error;
        break;
      }

      case LOAD_PGINFO_REQUEST: {
        draft.loadPgInfoLoading = true;
        draft.loadPgInfoDone = false;
        draft.loadPgInfoError = null;
        draft.pgInfo = null;
        break;
      }
      case LOAD_PGINFO_SUCCESS: {
        draft.pgInfo = action.data;
        draft.loadPgInfoLoading = false;
        draft.loadPgInfoDone = true;
        break;
      }
      case LOAD_PGINFO_FAILURE: {
        draft.loadPgInfoLoading = false;
        draft.loadPgInfoError = action.error;
        break;
      }

      case UPDATE_PGINFO_REQUEST: {
        draft.updatePgInfoLoading = true;
        draft.updatePgInfoDone = false;
        draft.updatePgInfoError = null;
        break;
      }
      case UPDATE_PGINFO_SUCCESS: {
        draft.updatePgInfoLoading = false;
        draft.updatePgInfoDone = true;
        draft.updatePgInfo = {pgInfoSeq: action.pgInfoSeq, data: action.data};
        break;
      }
      case UPDATE_PGINFO_FAILURE: {
        draft.updatePgInfoLoading = false;
        draft.updatePgInfoError = action.error;
        break;
      }
      case UPDATE_PGINFO_RESET: {
        draft.updatePgInfoLoading = false;
        draft.updatePgInfoDone = false;
        draft.updatePgInfo = null;
        break;
      }
      default: {
        break;
      }
    }
  })
}

export default reducer;
