import React, { useState, useCallback } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import UIKit from "../../UIKit";

import HeaderTemplate from './Header';
import MobileMenuTemplate from './MobileMenu';
import ProductDetailTemplate from './ProductDetail';
import Icon from '../../Widget/Icon'

const PreviewSetting = (props) => {
	const [model, setModel] = useState("desktop");
	const [nowTab, setNowTab] = useState(1);

	const onClickTab = (tabIndex) => {
        setNowTab(tabIndex);
    }

	const onChangeModel = (v) => {
		setModel(v);
	}

	const listListing = useCallback(() => {
		const result = [];
		
		for (let i = 0; i < props.data.template.productList.numberOfItem[model]; i++) {
			result.push(
				<div key={i} className={`${"uk-width-1-"+props.data.template.productList.numberOfItem[model]}`}>
					<div className="uk-card uk-card-default uk-cover-container uk-ratio-1-1">
						<img alt="" src="/image/design/product_sample.jpeg" data-uk-cover />
					</div>
				</div>
			);
		}
		return result;
	},[props, model]);

	const styles = {
        price : {
            color: props.data.template.basic.color.font.price
        },
        button: {
            backgroundColor: props.data.template.basic.color.background.button,
            color: props.data.template.basic.color.font.button
        }
    }

	const HeaderSample = HeaderTemplate[props.data.template.header.type];
	const MobileMenuSample = MobileMenuTemplate[props.data.template.header.mobileMenuType];
	const ProductDetailSample = ProductDetailTemplate[props.data.template.productDetail.type];

	const kakaoChatBanner = 
		props.data.template.basic.option.kakaoChat.status ? (
			<div className="uk-flex uk-flex-middle uk-flex-center uk-pointer" style={{backgroundColor: props.data.template.basic.option.kakaoChat.backgroundColor, width:25, height:25, padding:5, borderRadius:100}}>
				<Icon name={"comment"} color={props.data.template.basic.option.kakaoChat.color} size={20} />
			</div>
		) : null;

	return (
		<div>	
			<div className="uk-flex uk-flex-middle uk-flex-between">
				<div>
					<div className="uk-inline-block">
						<div className="uk-flex uk-flex-middle uk-flex-center uk-pointer" style={{border:"1px solid black", borderColor: (model === "desktop" ? "#1e87f0" : "#666666"), backgroundColor: (model === "desktop" ? "#1e87f0" : "#ffffff"), width:25, height:25, padding:5, borderRadius:100}} onClick={() => onChangeModel("desktop")}>
							<Icon name={"desktop"} color={model === "desktop" ? "#ffffff" : "#666666"} size={20} />
						</div>
					</div>
					<div className="uk-inline-block uk-margin-small-left">
						<div className="uk-flex uk-flex-middle uk-flex-center uk-pointer" style={{border:"1px solid black", borderColor: (model === "mobile" ? "#1e87f0" : "#666666"), backgroundColor: (model === "mobile" ? "#1e87f0" : "#ffffff"), width:25, height:25, padding:5, borderRadius:100}} onClick={() => onChangeModel("mobile")}>
							<Icon name={"mobile"} color={model === "mobile" ? "#ffffff" : "#666666"} size={20} />
						</div>
					</div>
				</div>
				<div>
					<ul className="uk-margin-remove-bottom" data-uk-tab>
						<li className={nowTab === 1 ? "uk-active" : ""} onClick={() => onClickTab(1)}><a href="#">메인</a></li>
						<li className={nowTab === 2 ? "uk-active" : ""} onClick={() => onClickTab(2)}><a href="#">상품 리스트</a></li>
						<li className={nowTab === 3 ? "uk-active" : ""} onClick={() => onClickTab(3)}><a href="#">상품 상세</a></li>
					</ul>
				</div>
			</div>
			<UIKit.Card
				cardOptions={"uk-card-default uk-card-style uk-text-0_8 uk-margin-small-top"}
				cardBodyOptions={"uk-padding-remove"}
			>
				<div className={`font-${props.data.font.ko}`}>
					<div className={model === "desktop" ? "uk-visible" : "uk-hidden"} style={{position:"relative"}}>
						<div className="uk-position-medium uk-position-top-right uk-position-z-index-10" style={{top:590}}>
							{kakaoChatBanner}
						</div>
						<div className={`desktopDesignBox ${nowTab === 1 ? "uk-visible" : "uk-hidden"}`}>
							<HeaderSample
								data={props.data}
								logo={props.brandData.logo}
								model={model}
								tab={nowTab}
							>
								<div className="content">
									<img src="/image/design/main.jpg" />
								</div>
							</HeaderSample>
						</div>
						<div className={`desktopDesignBox ${nowTab === 2 ? "uk-visible" : "uk-hidden"}`} style={{minHeight:"550px"}}>
							<HeaderSample
								data={props.data}
								logo={props.brandData.logo}
								model={model}
								tab={nowTab}
							>
								<div className="uk-container uk-padding-remove-horizontal uk-margin-medium-top">
									<div className={`uk-grid-small uk-text-center uk-padding-small-horizontal `} data-uk-grid>
										{listListing()}
									</div>
								</div>
										
							</HeaderSample>
						</div>
						<div className={`desktopDesignBox ${nowTab === 3 ? "uk-visible" : "uk-hidden"}`}>
							<HeaderSample data={props.data} logo={props.brandData.logo} model={model} tab={nowTab}>
								<ProductDetailSample data={props.data} />
							</HeaderSample>
						</div>
					</div>

					<div className={model === "mobile" ? "uk-visible" : "uk-hidden"}>
						<div className={`mobileDesignBox ${(nowTab === 1 ? "uk-visible" : "uk-hidden")}`}>
							<div className="uk-position-small uk-position-top-right uk-position-z-index-10" style={{top:620}}>
								{kakaoChatBanner}
							</div>
							<HeaderSample
								data={props.data}
								logo={props.brandData.logo}
								model={model}
								tab={nowTab}
							>
								<div className="content">
									<img src="/image/design/m_main.jpg" />
								</div>
							</HeaderSample>

						</div>
						<div className={`mobileDesignBox ${(nowTab === 2 ? "uk-visible" : "uk-hidden")}`}>
							<div className="uk-position-small uk-position-top-right uk-position-z-index-10" style={{top:620}}>
								{kakaoChatBanner}
							</div>
							<HeaderSample
								data={props.data}
								logo={props.brandData.logo}
								model={model}
								tab={nowTab}
							>
								<div className="uk-container uk-padding-remove-horizontal">
									<div className={`uk-grid-small uk-text-center uk-padding-small-horizontal `} data-uk-grid>
										{listListing()}
									</div>
								</div>
							</HeaderSample>
						</div>
						<div className={`mobileDesignBox ${(nowTab === 3 ? "uk-visible" : "uk-hidden")}`}>
							<div className="uk-position-small uk-position-top-right uk-position-z-index-10" style={{top:620}}>
								{kakaoChatBanner}
							</div>
							<HeaderSample
								data={props.data}
								logo={props.brandData.logo}
								model={model}
								tab={nowTab}
							>
								<div className="uk-container uk-padding-large-bottom uk-padding-remove-horizontal">
									<div id="productInfo" className="uk-child-width-1-1 uk-margin-bottom" data-uk-grid>
										<div>
											<img src="/image/design/product_sample.jpeg" />
										</div>
										<div className="uk-padding-medium-left">
											<div className="uk-padding-small-horizontal">
												<p className="uk-text-1_2 uk-margin-remove-bottom">
													<span className="uk-text-middle uk-margin-small-right">CANARIA / 카나리아</span>
													<span className="uk-label uk-text-0_6 uk-text-middle uk-margin-2-top" style={styles.button}>SALE</span>
												</p>
												<p className="uk-margin-small-top">
													<span className="uk-vertical-middle uk-text-1_1" style={styles.price}>23,500원</span>
													<s className="uk-text-1_1 uk-margin-left uk-vertical-middle">39,900원</s>
												</p>
												<hr className="uk-margin-bottom" />
												<div className="uk-min-height-20 uk-text-0_9 uk-margin-bottom uk-margin-small-top">
													<p>설레는 복숭아 꽃을 담아 노래하는 카나리아 향수</p>
												</div>
												<div>
													<div id="optionArea">
														<div className="uk-padding-small-vertical uk-padding-remove-horizontal">
															<div id="optionBoxArea">
																<div>
																	<label className="uk-form-label uk-text-0_8">향 선택(필수)</label>
																	<div className="uk-form-controls uk-text-0_9 uk-margin-bottom">
																		<select className="uk-select" defaultValue="1" disabled={true}>
																			<option value="none">향선택</option>
																			<option value="1">카나리아 (23,500원)</option>
																		</select>
																	</div>
																</div>
															</div>
															<div className="uk-padding-small uk-margin-remove-bottom uk-margin-small-top uk-text-0_8" data-uk-alert>
																<p className="uk-margin-remove">향 선택 : 카나리아</p>
																<hr className="uk-border-dashed uk-margin-small-top uk-margin-small-bottom" />
																<div className="uk-flex uk-flex-middle uk-flex-between">
																	<div className="uk-button-group uk-child-width-expand uk-padding-remove-horizontal uk-background-white">
																		<button className="uk-button uk-button-default uk-button-small uk-width-40 uk-padding-remove-horizontal uk-text-0_8 uk-line-height-initial"><Icon name="minus" size={14} /></button>
																		<input className="uk-input uk-form-small uk-width-40 uk-height-33 uk-text-center" type="text" value="1" readOnly />
																		<button className="uk-button uk-button-default uk-button-small uk-width-40 uk-padding-remove-horizontal uk-text-0_8 uk-line-height-initial" id="test"><Icon name="plus" size={14} /></button>
																	</div>
																	<div>
																		<span>23,500원</span>
																	</div>
																</div>
															</div>
															<div>
																<div id="totalPriceArea" className="uk-margin-top uk-flex uk-flex-middle uk-flex-between">
																	<span>총 금액(1개)</span>
																	<span className="uk-text-1_2" style={styles.price}>23,500원</span>
																</div>

																<div>
																	<div className="uk-margin-top uk-button-group uk-width-1-1 uk-child-width-expand uk-height-65">
																		<button className="uk-button uk-button-default uk-text-1">장바구니</button>
																		<button className="uk-button uk-text-1"style={styles.button}>구매하기</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="bestReview" className="uk-width-1-1 uk-padding-small-horizontal uk-margin-large-top uk-margin-medium-bottom uk-position-relative">
									<div className="uk-text-left uk-margin-small-bottom">
										<span className="uk-text-0_75">Photo review</span>
									</div>
									{/* PC START */}
									<div>
										<ul className="uk-padding-remove">
											<li className="uk-margin-remove uk-padding-xsmall-vertical">
												<div className="uk-text-0_8 uk-grid-collapse uk-padding-xsmall-vertical uk-margin-remove uk-flex-top uk-position-relative" data-uk-grid>
													<div className="uk-width-50 previewImg">
														<div className="uk-width-auto" data-uk-lightbox>
															<div style={{ width:"50px", height:"50px"}}>image</div>
														</div>
													</div>
													<div className="contentArea uk-width-expand uk-text-left uk-margin-small-left uk-padding-small-bottom">
														<div>내용</div>
													</div>
													<div className="uk-position-bottom uk-text-0_9">
														<span className="uk-vertical-middle">평점</span>
														<StarRatingComponent
															disabled
															id="ReviewScore"
															name="ReviewScore"
															renderStarIcon={() => <Icon name={props.data.template.basic.icon.reviewScore} size={13} className="uk-padding-1-horizontal" />}
															starColor={props.data.template.basic.color.font.reviewScore}
															emptyStarColor={props.data.template.basic.color.font.reviewScore}
															starCount={5}
															value={5}
															className="reviewScore uk-text-0_8 uk-margin-xsmall-left uk-vertical-middle"
														/>
														<span className="uk-vertical-middle uk-margin-small-left">asdf****</span>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</HeaderSample>
						</div>
						<div className="mobileDesignBox">
							<MobileMenuSample
								data={props.data}
								logo={props.brandData.logo2}
								className={("headerElement")}
								text={props.data.template.header.feature.text}
								logoSize={props.data.template.header.logoSize["mobile"]}
								color={{background: props.data.template.header.color.background.mobileMenu, font: props.data.template.header.color.font.mobileMenu}}
							/>
						</div>
					</div>
				</div>
			</UIKit.Card>
		</div>
	)
}
export default PreviewSetting;