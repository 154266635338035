import React, {Fragment} from 'react';
import Icon from '../../../Widget/Icon'

const HeaderA = (props) => {
	
	const data = props.data.template.header;
	
    const getLogo = (position) => {
		return data.position[props.model].logo == position ? (
			<div className="headerElement">
				<div style={{height: data.logoSize[props.model == "desktop" ? "desktop" : "mobile"]}} >
					<a><img src={props.logo} className="uk-height-1-1 uk-vertical-top" /></a>
				</div>
			</div>
		)
		:
		null;
	}
	
	const getPage = (position) => {
		return (data.position[props.model].page == position) ?
		(
			<ul className="headerElement uk-navbar-nav uk-flex-middle">
				{
					//미리보기에선 미리보기 링크 동일
					data.page.map((page, index) => {
						return (
							<li key={index}>
								{page.type == 'external' ? (
									<a className="uk-link-reset uk-font-family-inherit">
										<span className="uk-text-none uk-text-0_8">{page.name}</span>
									</a>
								)
								:
								(
									<a className="uk-link-reset uk-font-family-inherit">
										<span className="uk-text-none uk-text-0_8">{page.name}</span>
									</a>
								)}
							</li>
						)
					})
				}
			</ul>
		)
		:
		null;
	}
	
	const getFeature = (position) => {
		return data.position[props.model].feature == position ?
		(
			<ul className="headerElement uk-navbar-nav uk-flex-middle">
				{(data.feature.cart.display[props.model]!=='none') && (
					<li>
						<a className="uk-link-reset uk-font-family-inherit">
							{
								(data.feature.cart.display[props.model]==='text') ? (
									<span className="uk-text-none uk-text-0_8">{data.feature.cart.name}</span>
								):
								(
									<Icon name={props.data.template.basic.icon.cart} />
								)
							}
						</a>
					</li>
				)}
				{
					<Fragment>
						{(data.feature.mypage.display[props.model]!=='none') && (
							<li>
								<a className="uk-link-reset uk-font-family-inherit">
									{
										(data.feature.mypage.display[props.model]==='text') ? (
											<span className="uk-text-none uk-text-0_8">{data.feature.mypage.name}</span>
										):
										(
											<Icon name={props.data.template.basic.icon.mypage} />
										)
									}
								</a>
							</li>
						)}
						{(data.feature.logout.display[props.model]!=='none') && (
							<li>
								<a className="uk-link-reset uk-font-family-inherit">
									{
										(data.feature.logout.display[props.model]==='text') ? (
											<span className="uk-text-none uk-text-0_8">{data.feature.logout.name}</span>
										):
										(
											<Icon name={props.data.template.basic.icon.logout} />
										)
									}
								</a>
							</li>
						)}
					</Fragment>
				}
			</ul>
		)
		:
		null;
	}
	
	const getMobileMenu = (position) => {
		return data.position.mobile.menu == position ?
		(
			<div className={("headerElement uk-navbar-"+position)} >
				<div className="uk-padding-remove">
					<a className="uk-link-reset uk-navbar-toggle uk-padding-remove">
						<Icon name={props.data.template.basic.icon.menuBar} />
					</a>
				</div>
			</div>
		)
		:
		null;
	}

    let headerClass = data.overlapping[props.model] && props.tab == 1 ? "uk-position-absolute" : "";
	return (
		<div>
			{props.model == "desktop" ? (
				<header 
					id="header"
					className={headerClass}
					style={{background:"initial", width:"100%", left:"0", right:"0", color: data.color.font[(data.overlapping.desktop ? (props.tab == "1") ? "overlapping" : "default" : "default")] }}
				>
					<div id="webHeader" className={`uk-offcanvas-content uk-position-z-index-990 ${(data.overlapping.desktop) ? "uk-background-transparent" : ""}`} style={{height:'auto', backgroundColor: data.color.background.default}}>
						<nav className={`uk-navbar uk-height-1-1 uk-navbar-container uk-navbar-transparent ${data.desktopExpand ? "uk-padding-horizontal" : "uk-container uk-padding-remove-horizontal"}`} style={{minHeight:(data.logoSize.desktop + data.verticalPadding.desktop)}} data-uk-navbar>
							<div className="uk-navbar-left uk-flex-middle">
								{getLogo("left")}
								{getPage("left")}
								{getFeature("left")}
							</div>
							<div className="uk-navbar-center uk-flex-middle">
								{getPage("center")}
								{getLogo("center")}
								{getFeature("center")}
							</div>
							<div className="uk-navbar-right uk-flex-middle">
								{getPage("right")}
								{getFeature("right")}
								{getLogo("right")}
							</div>
						</nav>
					</div>
				</header>
			) : (
				<header 
					id="header" 
					className={headerClass}
					style={{background:"initial", width:"100%", left:"0", right:"0", color: data.color.font[(data.overlapping.mobile ? (props.tab == "1") ? "overlapping" : "default" : "default")] }}
				>
					<div id="mobileHeader" className={`uk-offcanvas-content uk-position-z-index-990 ${(data.overlapping.mobile) ? "uk-background-transparent" : ""}`} style={{backgroundColor: data.color.background.default, height:'auto'}}>
						<nav className="uk-navbar uk-height-1-1 uk-navbar-container uk-navbar-transparent uk-padding-15-horizontal" style={{minHeight:(data.logoSize.mobile + data.verticalPadding.mobile)}} data-uk-navbar>
							<div className="uk-navbar-left uk-flex-middle">
								{getMobileMenu("left")}
								{getLogo("left")}
								{getFeature("left")}
							</div>
							<div className="uk-navbar-center uk-flex-middle">
								{getLogo("center")}
								{getFeature("center")}
							</div>
							<div className="uk-navbar-right uk-flex-middle">
								{getFeature("right")}
								{getLogo("right")}
								{getMobileMenu("right")}
							</div>
						</nav>
					</div>
				</header>
			) }
            {props.children}
		</div>
	)
}
export default HeaderA;