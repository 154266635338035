import React, {useEffect, useState, useCallback} from "react";
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../../reducers/user';
import { naverPayTestExcelAction } from '../../../reducers/brand';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import UIKit from "../../../Components/UIKit";
import Header from "../../../Components/Widget/Header";

const NaverPayTest = ({ match }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone } = useSelector((state) => state.user);
    const [naverPayAccessLicense, setNaverPayAccessLicense] = useState("");
    const [naverPayAccessKey, setNaverPayAccessKey] = useState("");

    const {
        naverPayTestExcel,
        naverPayTestExcelDone
    } = useSelector((state) => state.brand);

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    const makeTestFile = useCallback(async () => {
        const naverData = JSON.parse(JSON.stringify({naverPayAccessLicense: naverPayAccessLicense, naverPayAccessKey: naverPayAccessKey}));
        dispatch(naverPayTestExcelAction(naverData));
    }, [naverPayAccessLicense, naverPayAccessKey]);

    useEffect(() => {
        if(naverPayTestExcelDone) {
            if(naverPayTestExcel.file && naverPayTestExcel.name) {
                const link = document.createElement('a');
                link.href = naverPayTestExcel.file;
                link.setAttribute('download', naverPayTestExcel.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }, [naverPayTestExcelDone]);

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"네이버페이 검수"} />
            </UIKit.Div>
            <div className="uk-width-1-2" data-uk-grid>
                <label className="uk-width-1-4">액세스 라이센스</label>
                <input className="uk-width-expand" type='text' value={naverPayAccessLicense} onChange={(event) => setNaverPayAccessLicense(event.target.value)} />
            </div>
            <div className="uk-width-1-2" data-uk-grid>
                <label className="uk-width-1-4">시크릿 키</label>
                <input className="uk-width-expand" type='text' value={naverPayAccessKey} onChange={(event) => setNaverPayAccessKey(event.target.value)} />
            </div>
            <div className="uk-width-1-2 uk-text-right uk-margin-top">
                <button onClick={makeTestFile}>검수파일 생성</button>
            </div>
        </div>
    );
};

export default NaverPayTest;
