import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSampleAction, loadSampleAction } from '../../reducers/sample';

const Item = ({ sample }) => {
    const dispatch = useDispatch();
    const { removeSampleLoading, removeSampleDone } = useSelector((state) => state.sample);

    const [isButtonClick, setIsButtonClick] = useState(false);

    const onClickRemove = useCallback(() => {
        setIsButtonClick(true);
        dispatch(removeSampleAction(sample.seq));
    }, [sample]);

    const onClickItem = useCallback(() => {
        dispatch(loadSampleAction(sample.seq));
    }, [sample]);

    return (
        <tr>
            <td onClick={onClickItem} className="uk-pointer">{sample.seq}</td>
            <td>{sample.title}</td>
            <td>{sample.description}</td>
            <td>{sample.regdate}</td>
            <td>
                {
                    isButtonClick && removeSampleLoading ? (
                        <button classNam="uk-button uk-button-default uk-button-danger" disabled>삭제중...</button>
                    ) : (
                        <button className="uk-button uk-button-danger" onClick={onClickRemove}>삭제</button>
                    )
                }

            </td>
        </tr>
    );
};

export default Item;
