import { combineReducers } from "redux";

import user from "./user";
import qna from "./qna";
import analytics from './analytics';
import review from './review';
import brand from './brand';
import admin from './admin';
import devSetting from './devSetting';
import order from './order';
import product from './product';
import log from './log';
import sample from './sample';
import design from './design';
import pgInfo from './pgInfo';

// (이전상태, 액션) => 다음상태
const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    default: {
      const combinedReducer = combineReducers({
        user,
        qna,
        analytics,
        review,
        brand,
        admin,
        devSetting,
        order,
        product,
        log,
        sample,
        design,
        pgInfo
      });
      return combinedReducer(state, action);
    }
  }
};

export default rootReducer;
