import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import UIkit from "uikit";
import { useSelector, useDispatch } from 'react-redux';

import SideNavAside from './SideNavAside';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

const SideNav = () => {
    const dispatch = useDispatch();
    const { me, logoutUserDone, loadMeLoading, loadMeDone } = useSelector((state) => state.user);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if(logoutUserDone) {
            history.push('/login');
        }
    }, [logoutUserDone]);

    useEffect(() => {
        if(!loadMeLoading && loadMeDone) {
            if(!me) {
                history.push('/login');
            }
        }
    },[loadMeLoading === false && loadMeDone]);

    useEffect(() => {
      dispatch(loadCountsForSidenavAction());
    },[location]);

    const aside = [
        <SideNavAside key={1} />
    ];

    return (
        <div>
            <div className="uk-visible@m">{aside}</div>
            <div
                id="offcanvas-nav"
                className="uk-hidden@m"
                data-uk-offcanvas="overlay: true"
            >
                <div
                    id="sidebarMobile"
                    className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide uk-inline uk-padding-remove uk-height-1-1"
                >
                    {aside}
                </div>
            </div>
        </div>
    );
};

export default SideNav;
