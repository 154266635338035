import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Summary from './Summary';
import Stock from './Stock';
import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';


const Setting = ({ match }) => {
    return (
        <WithSideNavLayout>
            <Switch>
                <Route exact path={`${match.path}/summary`} component={Summary} />
                <Route exact path={`${match.path}/stock`} component={Stock} />
                <Redirect from="*" to="/analytics/summary" />
            </Switch>
        </WithSideNavLayout>
    );
};

export default Setting;
