import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

const LiStyle = () => {
    const { user } = useSelector((state) => state.user);
    return (
        <div>
            {
                user && user.qna && user.qna.length>0 ? (
                    <ul className="uk-list-striped" data-uk-accordion="multiple: true">
                        {
                            user.qna.map((item, index) => (
                                <li key={index}>
                                    <a className="uk-accordion-title uk-text-1" href="#">
                                        <p className="uk-margin-remove">
                                            <span className="uk-text-1_1 uk-margin-small-right">{item.title}</span>
                                            {
                                                item.qnaType === 2 && (
                                                    <img src="/image/naver_simple.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-small-right uk-text-top uk-margin-xsmall-top" />
                                                )
                                            }
                                            <span className={`uk-text-0_8 ${item.status==0 ? "uk-text-warning" : "uk-text-primary" }`}>[{item.statusName}]</span>
                                        </p>
                                        <p className="uk-margin-remove-bottom uk-margin-xsmall-top uk-text-0_8">{item.regdate}</p>
                                    </a>
                                    <div className="uk-accordion-content uk-margin-small-top uk-padding-small-top uk-border-top uk-border-gray2 uk-padding-small-horizontal">
                                        <div className="uk-text-0_9 htmlContentArea">
                                            {ReactHtmlParser(item.content ? item.content : "")}
                                        </div>
                                        <div className="uk-border uk-margin-top uk-border-gray uk-padding-small-horizontal uk-padding-small-vertical">
                                            <p className="uk-margin-remove uk-text-0_9 uk-text-bold">관리자 답변</p>
                                            <p className="uk-margin-small-top uk-margin-remove-bottom uk-text-0_85">{item.answer}</p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                )
                :
                (
                    <p className="uk-text-center uk-margin-medium-top">데이터가 없습니다.</p>
                )
            }
        </div>
    );
};

export default LiStyle;
