import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import MemoForm from './MemoForm';
import MemoFormTextAreaStyle from './MemoFormTextAreaStyle';

import { phoneNumberDash } from '../../../common';

const LiStyle = ({ isUserModal = 0 }) => {
    const { user } = useSelector((state) => state.user);


    const getProductAllMoney = (item) => {
        let price = 0;
        for(let orderDetail of item.detail) {
            price = price + (orderDetail.price * orderDetail.qty);
        }
        return price;
    };

    const getDeliveryMoney = (item) => {
        let price = 0;
        const productAllMoney = getProductAllMoney(item);
        price = productAllMoney === 0 ? 0 : item.deliveryPrice; //배송비
        return price;
    };

    const getAddDeliveryPrice = (item) => {
        let price = 0;
        const productAllMoney = getProductAllMoney(item);
        price = productAllMoney === 0 ? 0 : item.addDeliveryPrice; //배송비
        return price;
    };

    const getCancelPrice = (item) => {
        let cancelPrice = 0;
        for(let payCancel of item.payCancelLog) {
            cancelPrice += Number(payCancel.cancelPrice);
        }
        return cancelPrice;
    };

    const getTotalMoney = (order) => {
        let price = 0;
        const productAllMoney = getProductAllMoney(order);
        const cancelPrice = getCancelPrice(order);
        price = order.price>=order.brand.freeShippingStandard ? order.price-cancelPrice : order.price+order.deliveryPrice+order.addDeliveryPrice-cancelPrice;

        return price;
    };

    return (
        <div data-uk-filter="target: .js-filter">
            <ul className="uk-subnav uk-subnav-pill uk-subnav-border uk-child-width-1-2 uk-child-width-expand@m uk-text-center uk-width-1-1 uk-margin-remove-left">
                <li data-uk-filter-control="*" className="uk-active uk-padding-remove"><a href="#" className="uk-text-0_8">전체<br/>({user && user.orderAnalytics.total.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='2']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">결제완료<br/>({user && user.orderAnalytics.status2.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='3']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">배송준비<br/>({user && user.orderAnalytics.status3.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='4']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">배송중<br/>({user && user.orderAnalytics.status4.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='5']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">배송완료<br/>({user && user.orderAnalytics.status5.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='6']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">취소완료<br/>({user && user.orderAnalytics.status6.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='7']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">반품완료<br/>({user && user.orderAnalytics.status7.count.toLocaleString()}건)</a></li>
                <li data-uk-filter-control="[data-status='8']" className="uk-padding-remove"><a href="#" className="uk-text-0_8">교환완료<br/>({user && user.orderAnalytics.status8.count.toLocaleString()}건)</a></li>
            </ul>
            {
                user && user.order && user.order.length>0 ? (
                    <ul className="uk-list-striped js-filter uk-transition-none uk-height-max-large uk-overflow-auto" data-uk-accordion="multiple: true">
                        {
                            user.order.map((item, index) => (
                                <li key={index} data-status={item.status}>
                                    <a className="uk-accordion-title uk-accordion-before-absolute uk-text-1 uk-position-relative" href="#">
                                        <table className="uk-table uk-table-divider uk-table-middle uk-table-responsive uk-border-bottom uk-margin-remove-bottom">
                                            <tbody>
                                                <tr>
                                                    <td className="uk-table-shrink uk-text-nowrap uk-text-center">
                                                        <div className="uk-hidden@m uk-clearfix">
                                                            <div className="uk-float-left">
                                                                <span className="uk-text-warning uk-text-0_9">{item.seq}</span>
                                                            </div>
                                                            <div className="uk-float-right">
                                                                <span className="uk-text-secondary uk-text-0_9">{item.regdate.substring(0,10)}</span>
                                                            </div>
                                                        </div>
                                                        <span className="uk-text-warning uk-text-0_9 uk-visible@m">{item.seq}</span>
                                                    </td>
                                                    <td className="uk-table-expand uk-text-truncate">
                                                        <a href={"/product/all/"+item.detail[0].product.seq} className="uk-link-reset">
                                                            <div className="uk-grid-collapse" data-uk-grid>
                                                                <div className="uk-width-auto">
                                                                    <img alt="" src={item.detail[0].product.img[0]} className="uk-object-cover uk-width-70 uk-height-70 uk-preserve-width uk-margin-small-right" />
                                                                </div>
                                                                <div className="uk-width-expand uk-text-truncate">
                                                                    <span className="uk-display-block uk-text-truncate">{item.name}</span>
                                                                    <span className="uk-display-block uk-margin-small-top uk-text-0_9">{(item.price+item.deliveryPrice+item.addDeliveryPrice).toLocaleString()}원</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td className="uk-table-shrink uk-text-nowrap uk-text-center uk-text-0_9">
                                                        <div className="uk-hidden@m uk-clearfix uk-height-30 uk-line-height-30 uk-margin-bottom">
                                                            <div className="uk-float-left">
                                                                <span className={`${item.status === 6 ? "uk-text-danger" : "uk-text-primary"} uk-margin-remove`}>{item.statusName}</span>
                                                            </div>
                                                            <div className="uk-float-right">
                                                            </div>
                                                        </div>
                                                        <div className="uk-visible@m">
                                                            <p className={`${item.status === 6 ? "uk-text-danger" : "uk-text-primary"} uk-margin-remove`}>{item.statusName}</p>
                                                        </div>
                                                    </td>
                                                    <td className="uk-table-shrink uk-text-nowrap uk-text-0_9 uk-visible@m">
                                                        {item.regdate.substring(0,10)}
                                                    </td>
                                                    <td className="uk-table-shrink uk-text-nowrap uk-visible@m">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </a>
                                    <div className="uk-accordion-content uk-margin-small-top uk-padding-small-top uk-border-top uk-border-gray2 uk-padding-small-horizontal uk-text-0_9">
                                        <div className="uk-grid-divider uk-grid-collapse" data-uk-grid>
                                            <div className="uk-width-1-1 uk-width-1-2@m">
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">주문번호</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    <p className="uk-margin-remove">{item.seq}</p>
                                                </div>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">주문자</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    {
                                                        item.buyer && (
                                                            <div>
                                                                <p className="uk-margin-remove">{item.buyer.name} / {phoneNumberDash(item.buyer.phone)}</p>
                                                                <p className="uk-margin-remove">{item.buyer.email}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">배송 정보</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    {
                                                        item.delivery && (
                                                            <div>
                                                                <p className="uk-margin-remove">{item.delivery.name} / {phoneNumberDash(item.delivery.phone)}</p>
                                                                <p className="uk-margin-remove">({item.delivery.postcode}) {item.delivery.address} {item.delivery.addressDetail}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">배송 메모</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    {
                                                        item.delivery && (
                                                            <p className="uk-margin-remove">{item.delivery.message ? item.delivery.message : "없음"}</p>
                                                        )
                                                    }

                                                </div>
                                                {
                                                    (item.payType !== 2 && item.payType !== 3) ? (
                                                        item.payLog && item.payLog.result && (
                                                            <Fragment>
                                                                <label className="uk-form-label uk-text-muted uk-text-0_8">결제 방법</label>
                                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                                    <p className="uk-margin-remove">
                                                                        {item.payLog.result.payMethodName}
                                                                    </p>
                                                                </div>
                                                                {
                                                                    item.payLog.result.payMethod === "VBANK" ? (
                                                                        <Fragment>
                                                                            <label className="uk-form-label uk-text-muted uk-text-0_8">계좌 정보</label>
                                                                            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                                                <p className="uk-margin-remove">{item.payLog.result.vbankName}</p>
                                                                                <p className="uk-margin-remove">{item.payLog.result.vbankNum}</p>
                                                                                <p className="uk-margin-remove">예금주 : 주식회사 이니스컴퍼니</p>
                                                                            </div>
                                                                            <label className="uk-form-label uk-text-muted uk-text-0_8">입금 금액</label>
                                                                            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                                                <p className="uk-margin-remove">{(item.price + item.deliveryPrice).toLocaleString()}원</p>
                                                                            </div>

                                                                            <label className="uk-form-label uk-text-muted uk-text-0_8">입금 기간</label>
                                                                            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                                                <p className="uk-margin-remove">
                                                                                    {`${item.payLog.result.vbankExpDate.substring(0,4)}년 ${item.payLog.result.vbankExpDate.substring(4, 6)}월 ${item.payLog.result.vbankExpDate.substring(6, 8)}일 까지`}
                                                                                </p>
                                                                            </div>
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <label className="uk-form-label uk-text-muted uk-text-0_8">결제 금액</label>
                                                                            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                                                <p className="uk-margin-remove">{(parseInt(item.payLog.result.paidMoney, 10)).toLocaleString()}원</p>
                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        )
                                                    ) : (
                                                        <Fragment>
                                                            <label className="uk-form-label uk-text-muted uk-text-0_8">결제 방법</label>
                                                            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                                <p className="uk-margin-remove">
                                                                    {item.payType === 2 && `네이버 페이(주문번호: ${item.oId && item.oId.split('_').length > 1 ? item.oId.split('_')[1] : item.oId.split('_')[0]})`}
                                                                </p>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                }
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">송장 번호</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    {
                                                        item.invoice ? (
                                                            <p className="uk-margin-remove uk-pointer" onClick={() => window.open(item.parcelCompany==='cj' ? `http://nexs.cjgls.com/web/service02_01.jsp?slipno=${item.invoice}` : `http://www.hanjinexpress.hanjin.net/customer/hddcw18.tracking?w_num=${item.invoice}`, "_blank", "width = 550, height = 500, top = 100, left = 200")}>
                                                                {item.invoice}
                                                            </p>
                                                        ) : (
                                                            <p className="uk-margin-remove">
                                                                송장번호가 등록되지 않은 주문건입니다.
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">관리자 주문 메모</label>
                                                <div>
                                                    {
                                                        item.memo ? (
                                                            <MemoFormTextAreaStyle orderSeq={item.seq} orderMemo={item.memo} isUserModal={isUserModal} />
                                                        ) : (
                                                            <Fragment>
                                                                <button
                                                                    className="uk-button uk-button-default uk-button-small"
                                                                    type="button"
                                                                >
                                                                    관리자 메모
                                                                </button>
                                                                <div data-uk-drop="mode: click">
                                                                    <div className="uk-card uk-card-body uk-card-default">
                                                                        <MemoForm orderSeq={item.seq} isUserModal={isUserModal} />
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1 uk-width-1-2@m">
                                                {
                                                    item && item.detail.map((orderDetail, index) => (
                                                        <div className="uk-width-1-1 uk-margin-remove-top" key={index}>
                                                            <div className="uk-text-0_9 uk-grid-collapse uk-flex-middle" data-uk-grid>
                                                                <div className="uk-width-auto">
                                                                    <img alt="" className="uk-margin-remove-vertical uk-object-cover uk-width-50 uk-height-50" src={orderDetail.product.img[0]} />
                                                                </div>
                                                                <div className="uk-width-expand uk-position-relative uk-margin-left">
                                                                    <p className="uk-margin-remove-bottom">{orderDetail.product_name}</p>
                                                                    <p className="uk-margin-remove-bottom uk-margin-remove-top uk-text-0_8">{orderDetail && `- ${orderDetail.option_name}`} / {orderDetail.qty}개</p>
                                                                    <div className="uk-margin-remove-bottom uk-margin-xsmall-top uk-clearfix">
                                                                        <div className="uk-float-right">{Number(orderDetail.price * orderDetail.qty).toLocaleString()}원</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="uk-margin-small-top uk-margin-small-bottom uk-text-right">
                                                                {
                                                                    orderDetail.status === 5 ||  orderDetail.status === 2 ? (
                                                                        <span className="uk-text-danger uk-text-0_9">{orderDetail.statusName}된 상품입니다.</span>
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                {
                                                                    item.detail.length-1 !== index &&
                                                                    <hr />
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <hr/>
                                                <div>
                                                    <div className="uk-clearfix">
                                                        <div className="uk-float-right uk-width-1-1 uk-text-right">
                                                            <div className="uk-flex-middle" data-uk-grid>
                                                                <div className="uk-text-0_9 uk-width-160">총 상품가격</div>
                                                                <div className="uk-width-expand">{getProductAllMoney(item).toLocaleString() }원</div>
                                                            </div>
                                                            <div className="uk-flex-middle uk-margin-small-top" data-uk-grid>
                                                                <div className="uk-text-0_9 uk-width-160">배송비</div>
                                                                <div className="uk-width-expand">{getDeliveryMoney(item).toLocaleString()}원</div>
                                                            </div>
                                                            {
                                                                getAddDeliveryPrice(item) > 0 && (
                                                                    <div className="uk-flex-middle uk-margin-small-top" data-uk-grid>
                                                                        <div className="uk-text-0_9 uk-width-160">도서산간배송 추가비용</div>
                                                                        <div className="uk-width-expand">{getAddDeliveryPrice(item).toLocaleString()}원</div>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                getCancelPrice(item) > 0 && (
                                                                    <div className="uk-flex-middle uk-margin-small-top" data-uk-grid>
                                                                        <div className="uk-text-0_9 uk-width-160">취소/환불 금액</div>
                                                                        <div className="uk-width-expand">{getCancelPrice(item).toLocaleString()}원</div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="uk-clearfix uk-margin-small-bottom">
                                                        <div className="uk-float-right uk-width-1-1@m uk-text-right">
                                                            <div data-uk-grid>
                                                                <div className="uk-width-1-2">최종 결제금액</div>
                                                                <div className="uk-width-1-2 uk-text-primary uk-text-bold">{getTotalMoney(item).toLocaleString()}원</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                )
                :
                (
                    <p className="uk-text-center uk-margin-medium-top">데이터가 없습니다.</p>
                )
            }
        </div>
    );
};

export default LiStyle;
