import React, { Fragment } from 'react';
import {useSelector} from 'react-redux';

import OrderLiStyle from '../Order/LiStyle';
import ReviewLiStyle from '../Review/LiStyle';
import QnaLiStyle from '../Qna/LiStyle';
import OrderDetailStyle from './OrderDetailStyle';

const Info = ({ type = 1, isUserModal = 0}) => {
    const { user } = useSelector((state) => state.user);
    return type === 1 ? (
        <Fragment>
            <ul className="uk-child-width-expand" data-uk-tab="connect:.uk-switcher;">
                <li>
                    <a href="#">
                        주문내역
                        <span className="uk-text-primary">({user && user.order.length})</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        구매평
                        <span className="uk-text-primary">({user && user.review.length})</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        문의
                        <span className="uk-text-primary">({user && user.qna.length})</span>
                    </a>
                </li>
            </ul>

            <ul className="uk-switcher uk-margin">
                <li>
                    <OrderLiStyle isUserModal={isUserModal} />
                </li>
                <li className="uk-height-max-large uk-overflow-auto">
                    <ReviewLiStyle />
                </li>
                <li className="uk-height-max-large uk-overflow-auto">
                    <QnaLiStyle />
                </li>
            </ul>
        </Fragment>
    ) : (
        <OrderDetailStyle />
    )
};

export default Info;
