export const LOAD_SKUS_REQUEST = "LOAD_SKUS_REQUEST";
export const LOAD_SKUS_SUCCESS = "LOAD_SKUS_SUCCESS";
export const LOAD_SKUS_FAILURE = "LOAD_SKUS_FAILURE";

export const LOAD_ALL_SKUS_REQUEST = "LOAD_ALL_SKUS_REQUEST";
export const LOAD_ALL_SKUS_SUCCESS = "LOAD_ALL_SKUS_SUCCESS";
export const LOAD_ALL_SKUS_FAILURE = "LOAD_ALL_SKUS_FAILURE";

export const LOAD_CATEGORIES_REQUEST = "LOAD_CATEGORIES_REQUEST";
export const LOAD_CATEGORIES_SUCCESS = "LOAD_CATEGORIES_SUCCESS";
export const LOAD_CATEGORIES_FAILURE = "LOAD_CATEGORIES_FAILURE";

export const UPDATE_CATEGORIES_REQUEST = "UPDATE_CATEGORIES_REQUEST";
export const UPDATE_CATEGORIES_SUCCESS = "UPDATE_CATEGORIES_SUCCESS";
export const UPDATE_CATEGORIES_FAILURE = "UPDATE_CATEGORIES_FAILURE";
export const UPDATE_CATEGORIES_RESET = "UPDATE_CATEGORIES_RESET";

export const LOAD_PRODUCTS_REQUEST = "LOAD_PRODUCTS_REQUEST";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCTS_FAILURE = "LOAD_PRODUCTS_FAILURE";

export const LOAD_PRODUCT_REQUEST = "LOAD_PRODUCT_REQUEST";
export const LOAD_PRODUCT_SUCCESS = "LOAD_PRODUCT_SUCCESS";
export const LOAD_PRODUCT_FAILURE = "LOAD_PRODUCT_FAILURE";
export const LOAD_PRODUCT_RESET = "LOAD_PRODUCT_RESET";

export const DESTROY_PRODUCT_REQUEST = "DESTROY_PRODUCT_REQUEST";
export const DESTROY_PRODUCT_SUCCESS = "DESTROY_PRODUCT_SUCCESS";
export const DESTROY_PRODUCT_FAILURE = "DESTROY_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_SKU_REQUEST = "UPDATE_SKU_REQUEST";
export const UPDATE_SKU_SUCCESS = "UPDATE_SKU_SUCCESS";
export const UPDATE_SKU_FAILURE = "UPDATE_SKU_FAILURE";
export const UPDATE_SKU_RESET = "UPDATE_SKU_RESET";

export const CREATE_SKU_SUCCESS = "CREATE_SKU_SUCCESS";

export const LOAD_PRODUCT_INFO_REQUEST = "LOAD_PRODUCT_INFO_REQUEST";
export const LOAD_PRODUCT_INFO_SUCCESS = "LOAD_PRODUCT_INFO_SUCCESS";
export const LOAD_PRODUCT_INFO_FAILURE = "LOAD_PRODUCT_INFO_FAILURE";

export const LOAD_PRODUCT_INFOS_REQUEST = "LOAD_PRODUCT_INFOS_REQUEST";
export const LOAD_PRODUCT_INFOS_SUCCESS = "LOAD_PRODUCT_INFOS_SUCCESS";
export const LOAD_PRODUCT_INFOS_FAILURE = "LOAD_PRODUCT_INFOS_FAILURE";
export const LOAD_PRODUCT_INFOS_RESET = "LOAD_PRODUCT_INFOS_RESET";

export const LOAD_PRODUCT_INFO_SAVES_REQUEST = "LOAD_PRODUCT_INFO_SAVES_REQUEST";
export const LOAD_PRODUCT_INFO_SAVES_SUCCESS = "LOAD_PRODUCT_INFO_SAVES_SUCCESS";
export const LOAD_PRODUCT_INFO_SAVES_FAILURE = "LOAD_PRODUCT_INFO_SAVES_FAILURE";
export const LOAD_PRODUCT_INFO_SAVES_RESET = "LOAD_PRODUCT_INFO_SAVES_RESET";

export const CREATE_PRODUCT_INFO_SAVES_REQUEST = "CREATE_PRODUCT_INFO_SAVES_REQUEST";
export const CREATE_PRODUCT_INFO_SAVES_SUCCESS = "CREATE_PRODUCT_INFO_SAVES_SUCCESS";
export const CREATE_PRODUCT_INFO_SAVES_FAILURE = "CREATE_PRODUCT_INFO_SAVES_FAILURE";
export const CREATE_PRODUCT_INFO_SAVES_RESET = "CREATE_PRODUCT_INFO_SAVES_RESET";

export const ON_CHANGE = "ON_CHANGE";
export const ON_CHANGE_IMAGE = "ON_CHANGE_IMAGE";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const ADD_OPTION = "ADD_OPTION";
export const ADD_OPTION_VALUE = "ADD_OPTION_VALUE";
export const ON_CHANGE_FOR_OPTION = "ON_CHANGE_FOR_OPTION";
export const REMOVE_OPTION_VALUE = "REMOVE_OPTION_VALUE";
export const REMOVE_OPTION = "REMOVE_OPTION";
export const ON_CHANGE_ESSENTIAL = "ON_CHANGE_ESSENTIAL";
export const CHANGE_OPTION_LIST = "CHANGE_OPTION_LIST";
export const ADD_SKU = "ADD_SKU";
export const REMOVE_SKU = "REMOVE_SKU";
export const CHANGE_PRODUCT_INFO = "CHANGE_PRODUCT_INFO";
export const CHANGE_PRODUCT_INFO_ALL = "CHANGE_PRODUCT_INFO_ALL";
export const CHANGE_ALL_PRODCUT_INFO_REFERENCE = "CHANGE_ALL_PRODCUT_INFO_REFERENCE";
