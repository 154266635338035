import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_ADMINS_REQUEST,
    LOAD_ADMINS_SUCCESS,
    LOAD_ADMINS_FAILURE,
    DESTROY_ADMIN_REQUEST,
    DESTROY_ADMIN_SUCCESS,
    DESTROY_ADMIN_FAILURE,
    LOAD_ADMIN_REQUEST,
    LOAD_ADMIN_SUCCESS,
    LOAD_ADMIN_FAILURE,
    UPDATE_ADMIN_REQUEST,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_FAILURE,
    CREATE_ADMIN_REQUEST,
    CREATE_ADMIN_SUCCESS,
    CREATE_ADMIN_FAILURE,
} from '../reducers/admin';

const getMe = state => {
    return state.user.me;
}

function loadAdminsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `admins${query}`
  } else {
      endPoint = "admins";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadAdmins(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadAdminsAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ADMINS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ADMINS_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyAdminAPI(user, adminSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.delete(`/admins/${adminSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyAdmin(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(destroyAdminAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: DESTROY_ADMIN_SUCCESS,
      data: result.data,
      adminSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_ADMIN_FAILURE,
      error: e.response.data,
    });
  }
}

function loadAdminAPI(user, adminSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.get(`/admins/${adminSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadAdmin(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadAdminAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ADMIN_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ADMIN_FAILURE,
      error: e.response.data,
    });
  }
}

function updateAdminAPI(user, adminSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/admins/${adminSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateAdmin(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateAdminAPI, user, action.adminSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_ADMIN_SUCCESS,
      data: result.data,
      adminSeq: action.adminSeq,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_ADMIN_FAILURE,
      error: e.response.data,
    });
  }
}

function createAdminAPI(user, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/admins', formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createAdmin(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(createAdminAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: CREATE_ADMIN_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_ADMIN_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadAdmins() {
  yield takeEvery(LOAD_ADMINS_REQUEST, loadAdmins);
}

function* watchDestroyAdmin() {
  yield takeEvery(DESTROY_ADMIN_REQUEST, destroyAdmin);
}

function* watchLoadAdmin() {
  yield takeEvery(LOAD_ADMIN_REQUEST, loadAdmin);
}

function* watchUpdateAdmin() {
  yield takeLatest(UPDATE_ADMIN_REQUEST, updateAdmin);
}

function* watchCreateAdmin() {
  yield takeLatest(CREATE_ADMIN_REQUEST, createAdmin);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadAdmins),
      fork(watchDestroyAdmin),
      fork(watchLoadAdmin),
      fork(watchUpdateAdmin),
      fork(watchCreateAdmin),
  ]);
}
