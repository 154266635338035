import { useSelector } from 'react-redux';
import { loadQnasAction  } from '../reducers/qna';

import ConfigData from "./ConfigData";

class Qna extends ConfigData {
    data = {
        /*********** 리스트타입1 START ***********/
        list1 : {
            element: {
                itemLink: {
                    to : function(item){
                        return "/cs/qna/all#qna"+item.seq;
                    },
                    scroll : function(index){
                        return index==0?-1000:-115;
                    }
                },
                status: {
                    class : function (item) {
                        return item.status==0 ? "uk-text-warning" : "uk-text-primary";
                    },
                    value : function (item) {
                        return item.statusName;
                    }
                },
               naverPayBadge: function (item) {
                   return item.qnaType == 2;
               },
               title: function (item) {
                   return item.title;
               },
               writer: function (item) {
                   return item.writer;
               },
               regdate: function (item, separator) {
                   return (separator ? " | " : "") + item.regdate;
               },
               image: function (item) {
                   return item.product && item.product.img && item.product.img[0] ? item.product.img[0] : "";
               }
           }
       }
       /*********** 리스트타입1 END ***********/
    };

    constructor() {
        super(useSelector((state) => state.qna));
        this.rtn.data = this.data;
    }

    get(type, query=""){
        if(type=="list"){
            this.makeQuery(query);
            this.rtn.reducer = {obj:this.state.qnas, action:loadQnasAction, loading:this.state.loadQnasLoading, done:this.state.loadQnasDone};
        }
        return this.rtn;
    }
};

export default Qna;
