import React, { useState, useCallback } from 'react';

const UseFormForCategory = (initValue, validation) => {
    const [value, setValue] = useState(initValue);

    const setInitValue = useCallback((data) => {
        if(data && Object.keys(data).length > 0){
            value.brandAndCategories = JSON.parse(JSON.stringify(data));
        } else {
            value.brandAndCategories = {};
        }

        value.selectedCategory = {};

        setValue({ ...value });
    },[value]);

    const onChange = useCallback((e, brandSeq, index) => {
        value.brandAndCategories[brandSeq][index][e.target.name] = e.target.value;
        setValue({ ...value, selectedCategory:  {...value.selectedCategory, [e.target.name] : e.target.value } });
    }, [value])

    const onChangeSelectedBrand = useCallback((e) => {
        if(e.target) {
            value.selectedBrand = e.target.value;
            value.selectedCategory = {};
        } else {
            value.selectedBrand = e;
            value.selectedCategory = {};
        }

        setValue({ ...value });
    }, [value]);

    const onChangeSelectedCategory = useCallback((category, index) => {
        category.index = index;
        value.selectedCategory = category;
        value.selectedBrand = -1;
        setValue({ ...value });
    }, [value]);

    const onClickAddCategory = useCallback((selectedBrand) => {
        if(selectedBrand === -1 || selectedBrand === "-1") {
            alert("브랜드를 선택해주세요");
            return;
        }

        let lastSeq = 0;
        if(value.brandAndCategories[selectedBrand]) {
            for(let category of value.brandAndCategories[selectedBrand]) {
                lastSeq = lastSeq < category.seq ? category.seq : lastSeq;
            }
        }
        const newData = {
            brand_seq: parseInt(selectedBrand, 10),
            name: '',
            order: value.brandAndCategories[selectedBrand] ? value.brandAndCategories[selectedBrand].length + 1 : 1,
            seq: -1,
            index: value.brandAndCategories[selectedBrand] ? value.brandAndCategories[selectedBrand].length : 0,
        }
        if(value.brandAndCategories[selectedBrand]) {
            value.brandAndCategories[selectedBrand].push(newData);
        } else {

            value.brandAndCategories[selectedBrand]= [];
            value.brandAndCategories[selectedBrand].push(newData);

        }
        value.selectedCategory = newData;

        setValue({ ...value });
    }, [value]);

    const onClickRemoveCategory = useCallback((category, index) => {
        delete value.brandAndCategories[category.brand_seq][index];
        value.brandAndCategories[category.brand_seq].splice(index, 1);
        if(value.selectedCategory && value.selectedCategory.seq === category.seq) {
            value.selectedCategory = {};
        }
        setValue({ ...value });
    }, [value]);

    return {
        value,
        onChange,
        setInitValue,
        onChangeSelectedCategory,
        onClickAddCategory,
        onChangeSelectedBrand,
        onClickRemoveCategory
    };
};

export default UseFormForCategory;
