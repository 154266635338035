import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { destroyProductAction, loadProductsAction } from '../../../actions/product/action';

import { useFormData } from '../../../hook';
import useCheckboxForm from '../../../hook/UseCheckboxForm';

import UIKit from "../../../Components/UIKit";
import Pagination from "../Pagination";
import Loader  from '../Loader';
import HeaderDetailOptions from '../HeaderDetailOptions';
import ActionLoader from '../ActionLoader';

const TableStyle = () => {
    const dispatch = useDispatch();
    const {
        products,
        loadProductsLoading,
        loadProductsDone,
        loadProductError,
        destroyProductDone,
        destroyProduct,
        destroyProductLoading,
    } = useSelector((state) => state.product);
    const { selectedBrand } = useSelector((state) => state.user);

    const [isShowDetailOptions, setIsShowDetailOptions] = useState(false);

    const checkboxForm =  useCheckboxForm(null);
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const sortForm = useFormData({
        column: defaultColumn ? defaultColumn :'regdate',
        sort: 'desc'
    });


    useEffect(() => {
        let query = location.search;
        dispatch(loadProductsAction(query));
    }, [location, destroyProductDone, selectedBrand]);

    useEffect(() => {
        if(loadProductError) {

        }
    }, [loadProductError]);

    useEffect(() => {
        if(loadProductsDone) {
            checkboxForm.init(products);
        }
    }, [loadProductsDone]);

    useEffect(() => {
        if(checkboxForm && checkboxForm.value) {
            if(checkboxForm.value.total.checked) {
                setIsShowDetailOptions(true);
                return;
            }

            const keys = Object.keys(checkboxForm.value);
            for(let key of keys) {
                if(key !== 'total' && key !== 'isShowDetailOption') {
                    if(checkboxForm.value[key].checked) {
                        setIsShowDetailOptions(true);
                        return;
                    }
                }
            }
            setIsShowDetailOptions(false);
        }
    }, [checkboxForm.value]);

    const onClickDetail = useCallback((seq) => {
        window.location.href = `/product/item/all/${seq}`;
        // history.push(`/product/item/all/${seq}`);
    }, []);

    const onClickColumn = useCallback((clickedColumn) => {
        let sort = {};
        let sortQuery = '';
        if (sortForm.value.column !== clickedColumn) {
            sortForm.onChange({column : clickedColumn, sort: 'desc'});
            sort[clickedColumn] = 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        } else {
            sortForm.onChange({
                sort: sortForm.value.sort === 'desc' ? 'asc' : 'desc'
            });
            sort[clickedColumn] = sortForm.value.sort === 'desc' ? 'asc' : 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        }

        let nextQueryString = sortQuery;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.sort;
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${sortQuery}`;
            }
        }

        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
    }, [history, location, sortForm]);

    useEffect(() => {
        if(destroyProductDone) {
            alert(destroyProduct.data.msg);
            setIsShowDetailOptions(false);
            checkboxForm.remove(destroyProduct.productSeq);
        }
    }, [destroyProductDone]);

    return loadProductsLoading ? (
        <div>
            <Loader />
        </div>
    ) : destroyProductLoading ? (
        <div>
            <ActionLoader message={"상품을 일괄 삭제중입니다."} />
        </div>

        ) : loadProductsDone && (
            <div>
                {
                    checkboxForm && checkboxForm.value && isShowDetailOptions && (
                        <HeaderDetailOptions
                            checkboxForm={checkboxForm}
                            destroyModelAction={destroyProductAction}
                        />
                    )
                }

                <UIKit.Card
                    title={""}
                    cardOptions={"uk-card-default uk-card-style uk-margin-medium-top uk-text-0_8"}
                    cardBodyOptions={"uk-padding-remove-top"}
                >

                        <div>
                            <span className="uk-padding-12-left uk-margin-top">
                                <span className="uk-hidden@m uk-padding-xsmall-right">
                                    <input type="checkbox" className="uk-checkbox" />
                                </span>
                                <span>총 {products.total}건</span>
                            </span>
                            <div className='uk-overflow-auto'>
                                <table className="uk-table uk-table-pointer uk-table-small uk-table-hover uk-table-divider uk-table-middle">
                                    <thead className="uk-text-center">
                                        <tr>
                                            <th className="uk-table-shrink">
                                                {
                                                    checkboxForm && checkboxForm.value && (
                                                        <input
                                                            className="uk-checkbox"
                                                            type="checkbox"
                                                            checked={checkboxForm.value.total.checked}
                                                            onChange={checkboxForm.onChangeCheckboxAll}
                                                        />
                                                    )
                                                }

                                            </th>
                                            <th className="uk-pointer uk-table-shrink uk-text-nowrap" onClick={() => onClickColumn('seq')}>
                                                <span>상품번호</span>
                                                {sortForm.value.column === 'seq' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                            <th className="uk-pointer uk-table-expand">
                                                <span>상품 이미지</span>
                                            </th>
                                            <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('name')}>
                                                <span>상품명</span>
                                                {sortForm.value.column === 'name' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                            <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('price')}>
                                                <span>판매가</span>
                                                {sortForm.value.column === 'price' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                            <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('description')}>
                                                <span>설명</span>
                                                {sortForm.value.column === 'description' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                            <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('status')}>
                                                <span>상태</span>
                                                {sortForm.value.column === 'status' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                            <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('regdate')}>
                                                <span>등록일</span>
                                                {sortForm.value.column === 'regdate' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                            <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('moddate')}>
                                                <span>수정일</span>
                                                {sortForm.value.column === 'moddate' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products && products.data.map((product) => (
                                                <tr key={product.seq}>
                                                    <td>
                                                        {
                                                            checkboxForm && checkboxForm.value && checkboxForm.value[product.seq] && (
                                                                <input
                                                                    className="uk-checkbox"
                                                                    type="checkbox"
                                                                    checked={checkboxForm.value[product.seq].checked}
                                                                    onChange={() => checkboxForm.onChangeCheckbox(product.seq)}
                                                                />
                                                            )
                                                        }


                                                    </td>
                                                    <td
                                                        className="uk-text-center uk-table-shrink uk-text-nowrap"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.seq}
                                                    </td>
                                                    <td onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        <img
                                                            className="list-img uk-preserve-width"
                                                            src={product.img[0] ? product.img[0] : require("../../../resource/uikit/avatar.svg")}
                                                            alt={"사진"}
                                                            width="60"
                                                        />
                                                    </td>
                                                    <td
                                                        className="uk-text-center"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.name}
                                                    </td>
                                                    <td
                                                        className="uk-text-center"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.price && product.price.toLocaleString()}
                                                    </td>
                                                    <td
                                                        className="uk-text-center"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.description}
                                                    </td>
                                                    <td
                                                        className="uk-text-center"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.statusName}
                                                    </td>
                                                    <td
                                                        className="uk-text-center"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.regdate}
                                                    </td>
                                                    <td
                                                        className="uk-text-center"
                                                        onClick={() => onClickDetail(product.seq)}
                                                    >
                                                        {product.moddate}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <Pagination
                                    currentPage={products.current_page}
                                    pageLast={products.last_page}
                                />
                            </div>
                        </div>
                </UIKit.Card>
            </div>
        )
};

export default TableStyle;
