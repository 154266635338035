export const UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST = "UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST";
export const UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_SUCCESS = "UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_SUCCESS";
export const UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_FAILURE = "UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_FAILURE";
export const UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_RESET = "UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_RESET";
export const ORDER_MEMO_UPDATE = 'ORDER_MEMO_UPDATE';
export const UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST = "UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST";
export const UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_SUCCESS = "UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_SUCCESS";
export const UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_FAILURE = "UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_FAILURE";
export const UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_RESET = "UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_RESET";

export const LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST = "LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST";
export const LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS = "LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS";
export const LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE = "LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE";

export const UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST = "UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST";
export const UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_SUCCESS = "UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_SUCCESS";
export const UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_FAILURE = "UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_FAILURE";
export const UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_RESET = "UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_RESET";