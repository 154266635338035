import { all, call } from "redux-saga/effects";
import axios from "axios";

import user from "./user";
import qna from "./qna";
import analytics from "./analytics";
import review from './review';
import brand from './brand';
import admin from './admin';
import devSetting from './devSetting';
import order from './order';
import product from './product';
import log from './log';
import sample from './sample';
import design from './design';
import pgInfo from './pgInfo';

axios.defaults.baseURL =
    process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API_BASE_URL
    : process.env.REACT_APP_REAL_API_BASE_URL

export default function* rootSaga() {
    yield all([
        call(user),
        call(qna),
        call(analytics),
        call(review),
        call(brand),
        call(admin),
        call(devSetting),
        call(order),
        call(product),
        call(log),
        call(sample),
        call(design),
        call(pgInfo)
    ]);
}
