import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Item from './Item';
import Category from './Category';
import Review from './Review';
import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';

const Product = ({ match }) => {
    return (
        <WithSideNavLayout>
            <Switch>
                <Route
                    exact
                    path={'/product/item'}
                    render={() => <Redirect to="/product/item/all" />}
                />
                <Route path={`${match.path}/item`} component={Item} />

                <Route
                    exact
                    path={'/product/category'}
                    render={() => <Redirect to="/product/category/edit" />}
                />
                <Route path={`${match.path}/category`} component={Category} />

                <Route
                    exact
                    path={'/product/review'}
                    render={() => <Redirect to="/product/review/all" />}
                />
                <Route path={`${match.path}/review`} component={Review} />
                <Redirect from="*" to="/product/item" />

            </Switch>
        </WithSideNavLayout>
    );
};

export default Product;
