import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { loadMeAction } from '../../reducers/user';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

import WithSideNavLayout from '../../Components/Layout/WithSideNavLayout';
import Header from "../../Components/Widget/Header";
import ConfigData from "../../ConfigData";
import Module from '../../Components/Module';

import {getAgoDate} from '../../common.js';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone, me } = useSelector((state) => state.user);
    const { countsForSideNav } = useSelector((state) => state.analytics);
    const configData = {
        qna : { object: new ConfigData.Qna(), query: useState({per_page:5}) },
        review : { object: new ConfigData.Review(), query: useState({per_page:5}) },
        analytics : { object: new ConfigData.Analytics(), query: useState({date:btoa(JSON.stringify(getAgoDate(6)))}) }
    };

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    return loadMeLoading ? null : loadMeDone && (
        <WithSideNavLayout>
            <div>
                <div className="uk-width-1-1">
                    <Header title={"대시보드"} />
                </div>
                <div className="uk-width-1-1">
                    <div>
                        <div className="uk-grid uk-grid-divider uk-grid-collapse uk-child-width-1-2 uk-child-width-1-6@m" data-uk-grid>
                            <div>
                                <Link to="/order/all?page=1&status=2" className="uk-link-reset">
                                    <p className="uk-margin-remove-bottom"><i className="mdi mdi-cart-outline menu-icon uk-margin-xsmall-right uk-text-1_2"></i><span className="uk-vertical-middle">신규 주문</span></p>
                                    <h2 className="uk-heading-primary uk-margin-remove uk-text-primary">{countsForSideNav && countsForSideNav.paid.toLocaleString()}</h2>
                                </Link>
                            </div>
                            <div>
                                <Link to="/order/naverpay/cancel?page=1&status=2&payMethod=CARD" className="uk-link-reset">
                                    <p className="uk-margin-remove-bottom"><i className="mdi mdi-cart-remove menu-icon uk-margin-xsmall-right uk-text-1_2"></i><span className="uk-vertical-middle">취소 요청</span></p>
                                    <h2 className="uk-heading-primary uk-margin-remove uk-text-primary">{countsForSideNav && countsForSideNav.naverpayCancel.toLocaleString()}</h2>
                                </Link>
                            </div>
                            <div>
                                <Link to="/order/exchange?page=1&status=4" className="uk-link-reset">
                                    <p className="uk-margin-remove-bottom"><i className="mdi mdi-autorenew menu-icon uk-margin-xsmall-right uk-text-1_2"></i><span className="uk-vertical-middle">교환 요청</span></p>
                                    <h2 className="uk-heading-primary uk-margin-remove uk-text-primary">{countsForSideNav && countsForSideNav.exchange.toLocaleString()}</h2>
                                </Link>
                            </div>
                            <div>
                                <Link to="/order/return?page=1&status=3" className="uk-link-reset">
                                    <p className="uk-margin-remove-bottom"><i className="mdi mdi-cart-arrow-up menu-icon uk-margin-xsmall-right uk-text-1_2"></i><span className="uk-vertical-middle">반품 요청</span></p>
                                    <h2 className="uk-heading-primary uk-margin-remove uk-text-primary">{countsForSideNav && countsForSideNav.returns.toLocaleString()}</h2>
                                </Link>
                            </div>
                            <div>
                                <Link to="/order/cancel?page=1&status=2&payMethod=VBANK" className="uk-link-reset">
                                    <p className="uk-margin-remove-bottom"><i className="mdi mdi-cash-refund menu-icon uk-margin-xsmall-right uk-text-1_2"></i><span className="uk-vertical-middle">입금 요청</span></p>
                                    <h2 className="uk-heading-primary uk-margin-remove uk-text-primary">{countsForSideNav && countsForSideNav.cancel.toLocaleString()}</h2>
                                </Link>
                            </div>
                            <div>
                                <Link to="/cs/qna" className="uk-link-reset">
                                    <p className="uk-margin-remove-bottom"><i className="mdi mdi-comment-question-outline menu-icon uk-margin-xsmall-right uk-text-1_2"></i><span className="uk-vertical-middle">신규 문의</span></p>
                                    <h2 className="uk-heading-primary uk-margin-remove uk-text-primary">{countsForSideNav && countsForSideNav.qna.toLocaleString()}</h2>
                                </Link>
                            </div>
                        </div>
                        <hr />
                        {
                            me.permission===1 && (
                                <div className="uk-grid uk-grid-small" data-uk-grid data-uk-height-match="target: > div > .uk-card">
                                    <div className="uk-width-1-1 uk-width-1-2@m">
                                        <div className="uk-card uk-card-default uk-card-small">
                                            <div className="uk-card-header uk-text-0_9">
                                                <p>방문자 현황</p>
                                            </div>
                                            <div className="uk-card-body uk-overflow-auto uk-height-medium uk-height-large@m uk-flex@m uk-flex-middle uk-position-relative">
                                                <Module.Chart.Type1 instance={configData.analytics.object.get("list", configData.analytics.query[0])} isDispatch={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-1 uk-width-1-2@m">
                                        <div className="uk-card uk-card-default uk-card-small">
                                            <div className="uk-card-header uk-text-0_9 uk-grid-collapse uk-flex-middle uk-padding-small-horizontal uk-padding-horizontal@m" data-uk-grid>
                                                <div className="uk-width-1-2">일자별 요약</div>
                                                <div className="uk-width-1-2 uk-text-right">
                                                    <a className="uk-button uk-button-default uk-button-small uk-text-0_8" href="/analytics/summary">더보기</a>
                                                </div>
                                            </div>
                                            <div className="uk-card-body uk-padding-xsmall-horizontal uk-padding-horizontal@m uk-position-relative uk-overflow-auto">
                                                <Module.List.Type2 instance={configData.analytics.object.get("list")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="uk-grid uk-grid-small" data-uk-grid data-uk-height-match="target: > div > .uk-card">
                            <div className="uk-width-1-1 uk-width-1-2@m">
                                <div className="uk-card uk-card-default uk-card-small">
                                    <div className="uk-card-header uk-text-0_9 uk-grid-collapse uk-flex-middle uk-padding-small-horizontal uk-padding-horizontal@m" data-uk-grid>
                                        <div className="uk-width-1-2">문의</div>
                                        <div className="uk-width-1-2 uk-text-right">
                                            <a className="uk-button uk-button-default uk-button-small uk-text-0_8" href="/cs/qna/all">더보기</a>
                                        </div>
                                    </div>
                                    <div className="uk-card-body uk-padding-xsmall-horizontal uk-padding-horizontal@m uk-position-relative">
                                        <Module.List.Type1 instance={configData.qna.object.get("list", configData.qna.query[0])} isDispatch={true} />
                                    </div>
                                </div>
                            </div>
                            {
                            me.seq !== 5 && (
                            <div className="uk-width-1-1 uk-width-1-2@m">
                                <div className="uk-card uk-card-default uk-card-small">
                                    <div className="uk-card-header uk-text-0_9 uk-grid-collapse uk-flex-middle uk-padding-small-horizontal uk-padding-horizontal@m" data-uk-grid>
                                        <div className="uk-width-1-2">구매평</div>
                                        <div className="uk-width-1-2 uk-text-right">
                                            <a className="uk-button uk-button-default uk-button-small uk-text-0_8" href="/product/review/all">더보기</a>
                                        </div>
                                    </div>
                                    <div className="uk-card-body uk-padding-xsmall-horizontal uk-padding-horizontal@m uk-position-relative">
                                        <Module.List.Type1 instance={configData.review.object.get("list", configData.review.query[0])} isDispatch={true} />
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </WithSideNavLayout>
    );
};

export default Dashboard;
