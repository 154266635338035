import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIKIT from 'uikit';

import { uploadExcelForReviewAction, createExcelConvertDataAction } from '../../../reducers/review';

import { useFormForReview } from '../../../hook';

import ExcelConvertItem from './ExcelConvertItem';

const ExcelConvertModal = () => {
    const dispatch = useDispatch();
    const {
        uploadExcelForReviewLoading,
        uploadExcelForReview,
        uploadExcelForReviewDone,
        uploadExcelForReviewError,
        createExcelConvertDataReviewLoading,
        createExcelConvertDataReview,
        createExcelConvertDataReviewDone,
        createExcelConvertDataReviewError,
    } = useSelector((state) => state.review);
    const reviewForm = useFormForReview([]);

    const excelConvertSubmit = useCallback(async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            dispatch(uploadExcelForReviewAction(formData));
    },[]);

    useEffect(() => {
        if(uploadExcelForReviewDone) {
            if(uploadExcelForReview.length == 0){
                alert("등록할 데이터가 없습니다.");
            }else{
                reviewForm.serverDataInit(uploadExcelForReview);
            }
        }
        if(uploadExcelForReviewError) {
            if(uploadExcelForReviewError.msg) {
                alert(uploadExcelForReviewError.msg);
            } else {
                alert(uploadExcelForReviewError.message);
            }
        }
    }, [uploadExcelForReviewDone, uploadExcelForReviewError]);

    const onSubmit = useCallback(async () => {
        const copyReview = JSON.parse(JSON.stringify(reviewForm.value));
        for(let review of copyReview) {
            delete review.preview;
            delete review.product;
            delete review.writer;
        }
        dispatch(createExcelConvertDataAction(copyReview));
    }, [reviewForm]);

    useEffect(() => {
        if(createExcelConvertDataReviewDone) {
            alert(createExcelConvertDataReview.msg);
            UIKIT.modal(`#excelConvertModal`).hide();
            window.location.reload();
        }
    }, [createExcelConvertDataReviewDone]);

    return (
        <div id="excelConvertModal" className="uk-modal-container" data-uk-modal="stack: true">
            <div className="uk-modal-body uk-modal-dialog uk-width-800 uk-margin-auto-vertical uk-padding-xsmall-horizontal uk-padding-xsmall-top">
                <div className="uk-padding-small uk-height-300">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <div className="uk-width-1-1">
                        <h4 className="uk-margin-small-bottom">구매평 일괄등록</h4>
                    </div>
                    <hr className="uk-margin-small-top uk-border-gray" />
                    <form className="uk-padding-xsmall-horizontal" onSubmit={excelConvertSubmit} encType="multipart/form-data" data-uk-grid>
                        <label className="uk-form-label uk-text-meta">1. 엑셀파일 업로드(1회 최대 500건)</label>
                        <div className="uk-width-1-1 uk-margin-small-top">
                            <div data-uk-form-custom="target: true">
                                <input type="file" id="reviewFile" name="reviewFile" />
                                <input className="uk-input uk-form-width-medium" type="text" placeholder="파일선택" disabled />
                                <button className="uk-button uk-button-default" type="button" tabIndex="-1">파일선택</button>
                            </div>
                            {
                                uploadExcelForReviewLoading ? (
                                    <button type="button" className="uk-button uk-button-green">
                                        <div data-uk-spinner></div>
                                    </button>
                                ) : (
                                    <button type="submit" className="uk-margin-left uk-button uk-button-green">업로드</button>
                                )
                            }
                        </div>
                    </form>
                    <div className="uk-padding-xsmall-horizontal uk-margin-medium-top" data-uk-grid>
                        <label className="uk-form-label uk-text-meta uk-text-0_8">2. 미리보기 및 수정</label>
                        <div className="uk-width-1-1 uk-margin-small-top">
                            <div className="uk-width-1-1 uk-max-height-300 uk-overflow-auto">
                                <table className="uk-table uk-table-small uk-table-hover uk-table-divider uk-table-middle">
                                    <thead className="uk-text-0_8 uk-text-center">
                                        <tr>
                                            <th>상품</th>
                                            <th>내용</th>
                                            <th>작성자</th>
                                            <th>평점</th>
                                        </tr>
                                    </thead>
                                    <tbody className="uk-text-0_8 uk-text-center">
                                        {
                                            reviewForm.value &&
                                            reviewForm.value.length > 0 && (
                                                reviewForm.value.map((review, index) => (
                                                    <ExcelConvertItem review={review} reviewForm={reviewForm} index={index} />
                                                ))
                                            )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            <p data-uk-margin className="uk-text-center uk-margin-remove-bottom uk-margin-medium-top">
                                {
                                    createExcelConvertDataReviewLoading ? (
                                        <button type="button" className="uk-button uk-button-primary">
                                            <div data-uk-spinner></div>
                                        </button>
                                    ) : (
                                        <button type="submit" className="uk-button uk-button-primary" onClick={onSubmit}>등록</button>
                                    )
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ExcelConvertModal;
