import React from "react";

const Spinner = () => {
    return (
        <div className="uk-position-center uk-overlay uk-overlay-default uk-background-transparent">
            <div className="uk-background-contain uk-background-size-50p" data-uk-spinner="ratio: 5" style={{backgroundImage: "url(/image/logo_transparent.png)"}}></div>
        </div>
    );
};

export default Spinner;
