import React, { useEffect, useCallback, Fragment } from 'react';
import { Link, useLocation } from "react-router-dom";
import UIkit from "uikit";
import { useDispatch, useSelector } from 'react-redux';

import { logoutUserAction, onchangeSelectedBrandAction} from '../../reducers/user';

const SideNavAside = () => {
    const dispatch = useDispatch();
    const { me, loginUserDone, logoutUserDone, loadUserLoading, loadUserDone, selectedBrand } = useSelector((state) => state.user);
    const { countsForSideNav } = useSelector((state) => state.analytics);
    const location = useLocation();
    let getNavLinkClass = path => {
        var count = 0;
        path.split(",").includes(window.location.pathname);
        path.split(",").forEach(function(item, index) {
            if (window.location.pathname.indexOf(item) !== -1) {
                count++;
            }
        });
        return count > 0 ? " uk-active uk-open" : "";
    };

    const onClickHide = () => {
        if(UIkit.modal(".uk-modal")){
            UIkit.modal(".uk-modal").hide();
        }
        document.getElementById("sidebarMobile").scrollTo(0, 0);
        UIkit.offcanvas("#offcanvas-nav").hide();
    };

    const onClickLogout = async(updateMe) => {
      if(window.confirm("로그아웃 하시겠습니까?")){
        dispatch(logoutUserAction());
      }
    }

    useEffect(() => {
      if(logoutUserDone){
        window.location.href = "/login";
      }
    },[logoutUserDone]);

    useEffect(() => {
        getNavLinkClass(location.pathname);
    },[location]);

    const onChangeBrand = useCallback((e) => {
        if(window.location.pathname == "/design" && localStorage.getItem("selectedBrand") != "all" && localStorage.getItem("selectedBrand") != null){
            if(window.confirm("저장하지 않은 내용은 손실됩니다.\n브랜드를 변경하시겠습니까?")){
                localStorage.setItem('selectedBrand', e.target.value);
                dispatch(onchangeSelectedBrandAction(e.target.value));
            }
        }else{
            localStorage.setItem('selectedBrand', e.target.value);
            dispatch(onchangeSelectedBrandAction(e.target.value));
        }
    }, []);

    return (
        <aside
            id="left-col"
            className="uk-light uk-position-fixed@s"
            key={"11"}
        >
            <div className="left-logo uk-flex uk-flex-middle">
                <img
                    className="custom-logo"
                    src="/image/logo.png"
                    alt=""
                />
            <span className="uk-margin-small-left">이니스컴퍼니</span>
            </div>
            <div className="left-content-box  content-box-dark">
                <img
                    src={
                        me && me.img
                            ? me.img
                            : require("../../resource/uikit/avatar.svg")
                    }
                    alt=""
                    className="uk-border-circle profile-img"
                />
                <h4 className="uk-text-center uk-margin-remove-vertical text-light">
                    {me && me.name}
                </h4>
            </div>
            <div className="uk-margin-small-left uk-margin-small-right uk-margin-bottom uk-position-relative uk-text-center uk-display-block uk-margin-top uk-margin-bottom">
                <select className="uk-select" name="selectedBrand" value={selectedBrand} onChange={onChangeBrand}>
                    <option value={'all'}>전체</option>
                    {
                        me && me.brand && me.brand.map(data => (
                            <option key={data.seq} value={data.seq}>{data.name}</option>
                        ))
                    }
                </select>
            </div>
            <div className="left-nav-wrap">
                <ul
                    className="uk-nav uk-nav-default uk-nav-main uk-nav-parent-icon uk-margin-medium-bottom"
                    data-uk-nav
                >
                    <li
                        className={getNavLinkClass("/dashboard")}
                        onClick={onClickHide}
                    >
                        <Link to="/">
                            <i className="mdi mdi-monitor-dashboard menu-icon uk-margin-small-right"></i>
                            <span className="uk-vertical-middle">대시보드</span>
                        </Link>
                    </li>
                    {
                        me && me.seq !== 5 && (
                            <Fragment>

                                <li
                                    className={getNavLinkClass("/design")}
                                    onClick={onClickHide}
                                >
                                    <Link to="/design">
                                        <i className="mdi mdi-auto-fix menu-icon uk-margin-small-right"></i>
                                        <span className="uk-vertical-middle">디자인 관리</span>
                                    </Link>
                                </li>
                                <li className={"uk-parent" + getNavLinkClass("/user")}>
                                    <Link to="#">
                                        <i className="mdi mdi-account menu-icon uk-margin-small-right"></i>
                                        <span className="uk-vertical-middle">
                                            회원 관리
                                        </span>
                                    </Link>
                                    <ul className="uk-nav-sub">
                                        <li
                                            className={getNavLinkClass("/user")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/user">회원 목록</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/user/group")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/user/group">회원그룹 설정</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/user/point")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/user/point">적립금 설정</Link>
                                        </li>
                                    </ul>
                                </li>
                            </Fragment>
                        )
                    }

                    {me && me.seq !== 5 && (
                    <li className={"uk-parent" + getNavLinkClass("/product")}>
                        <Link to="#">
                            <i className="mdi mdi-gift-outline menu-icon uk-margin-small-right"></i>
                            <span className="uk-vertical-middle">
                                상품 관리
                            </span>
                        </Link>
                        <ul className="uk-nav-sub">
                                    <Fragment>
                                        <li
                                            className={getNavLinkClass("/product/item")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/product/item/all">상품 목록</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/product/category")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/product/category">상품분류 설정</Link>
                                        </li>
                                    </Fragment>
                            <li
                                className={getNavLinkClass("/product/review")}
                                onClick={onClickHide}
                            >
                                <Link to="/product/review/all">
                                    <span className="uk-vertical-middle">구매평 목록</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.review && countsForSideNav.review.toLocaleString()}</span>
                                </Link>

                            </li>
                        </ul>
                    </li>
                    )}
                    <li className={"uk-parent" + getNavLinkClass("/order")}>
                        <Link to="#">
                            <i className="mdi mdi-cart-outline menu-icon uk-margin-small-right"></i>
                            <span className="uk-vertical-middle">주문 관리</span>
                            <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold uk-margin-1-top">{countsForSideNav && countsForSideNav.total.toLocaleString()}</span>
                        </Link>
                        <ul className="uk-nav-sub">

                            <li
                                className={getNavLinkClass("/order/all")}
                                onClick={onClickHide}
                            >
                                <Link to="/order/all?page=1&status=2">
                                    <span className="uk-vertical-middle">주문 목록</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.paid.toLocaleString()}</span>
                                </Link>
                            </li>
                            <li
                                className={getNavLinkClass("/order/naverpay/cancel")}
                                onClick={onClickHide}
                            >
                                <Link to="/order/naverpay/cancel?page=1&status=2&payMethod=CARD">
                                    <span className="uk-vertical-middle">취소 관리</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.naverpayCancel.toLocaleString()}</span>
                                </Link>
                            </li>
                            <li
                                className={getNavLinkClass("/order/exchange")}
                                onClick={onClickHide}
                            >
                                <Link to="/order/exchange?page=1&status=4">
                                    <span className="uk-vertical-middle">교환 관리</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.exchange.toLocaleString()}</span>
                                </Link>
                            </li>
                            <li
                                className={getNavLinkClass("/order/return")}
                                onClick={onClickHide}
                            >
                                <Link to="/order/return?page=1&status=3">
                                    <span className="uk-vertical-middle">반품 관리</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.returns.toLocaleString()}</span>
                                </Link>
                            </li>
                            <li
                                className={getNavLinkClass("/order/cancel")}
                                onClick={onClickHide}
                            >
                                <Link to="/order/cancel?page=1&status=2&payMethod=VBANK">
                                    <span className="uk-vertical-middle">입금요청 관리</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.cancel.toLocaleString()}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={"uk-parent" + getNavLinkClass("/cs")}>
                        <Link to="#">
                            <i className="mdi mdi-chat menu-icon uk-margin-small-right"></i>
                            <span className="uk-vertical-middle">CS</span>
                            <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold uk-margin-1-top">{countsForSideNav && countsForSideNav.qna.toLocaleString()}</span>
                        </Link>
                        <ul className="uk-nav-sub">
                            <li
                                className={getNavLinkClass("/cs/qna")}
                                onClick={onClickHide}
                            >
                                <Link to="/cs/qna">
                                    <span className="uk-vertical-middle">문의 관리</span>
                                    <span className="uk-badge uk-margin-small-left uk-text-0_8 uk-height-14 uk-text-bold">{countsForSideNav && countsForSideNav.qna.toLocaleString()}</span>
                                </Link>

                            </li>
                        </ul>
                    </li>
                    {
                        me && me.permission === 1 && (
                            <Fragment>
                                <li className={"uk-parent" + getNavLinkClass("/analytics")}>
                                    <Link to="#">
                                        <i className="mdi mdi-chart-line menu-icon uk-margin-small-right"></i>
                                        <span className="uk-vertical-middle">통계</span>
                                    </Link>
                                    <ul className="uk-nav-sub">
                                        <li
                                            className={getNavLinkClass("/analytics/summary")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/analytics/summary">
                                                <span className="uk-vertical-middle">개요</span>
                                            </Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/analytics/stock")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/analytics/stock">
                                                <span className="uk-vertical-middle">배송/재고 분석</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={"uk-parent" + getNavLinkClass("/setting")}>
                                    <Link to="#">
                                        <i className="mdi mdi-cogs menu-icon uk-margin-small-right"></i>
                                        <span className="uk-vertical-middle">환경설정</span>
                                    </Link>
                                    <ul className="uk-nav-sub">
                                        <li
                                            className={getNavLinkClass("/setting/admin")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/setting/admin/all">
                                                운영진 설정
                                            </Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/setting/brand")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/setting/brand">브랜드 설정</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/setting/devSetting")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/setting/devSetting">개발 설정</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/setting/naverPayTest")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/setting/naverPayTest">네이버페이 검수</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/setting/log")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/setting/log/all">주문로그</Link>
                                        </li>
                                        <li
                                            className={getNavLinkClass("/sample")}
                                            onClick={onClickHide}
                                        >
                                            <Link to="/sample">샘플</Link>
                                        </li>
                                    </ul>
                                </li>
                            </Fragment>
                        )
                    }

                </ul>
            </div>
            <div className="uk-position-bottom bar-bottom uk-position-fixed uk-width-1-1 uk-padding-small-vertical">
                <ul
                    className="uk-subnav uk-flex uk-flex-center uk-child-width-1-2 uk-text-center"
                    data-uk-grid
                >
                    <li>
                        <Link
                            to='#'
                            title="알림"
                            className="uk-padding-remove"
                            uk-tooltip="알림"
                        >
                            <i className="mdi mdi-bell menu-icon uk-text-1 uk-text-white"></i>
                        </Link>
                    </li>
                    <li onClick={onClickLogout}>
                        <Link
                            to='#'
                            title="로그아웃"
                            className="uk-padding-remove"
                            uk-tooltip="로그아웃"
                        >
                            <i className="mdi mdi-power menu-icon uk-text-1 uk-text-white"></i>
                        </Link>
                    </li>
                </ul>
            </div>
        </aside>
    )
};

export default SideNavAside;
