import React from "react";
import PropTypes from "prop-types";

const InputText = ({ data, tags, className, onChangeData }) => {
    return (
        <div className={className}>
            {tags &&
                tags.map((tag, index) => (
                    <div key={index}>
                        <label
                            className={tag.label.class}
                            {...tag.label.options}
                        >
                            {tag.label.name}
                        </label>
                        <div className="uk-form-controls">
                            <input
                                className={tag.input.class}
                                {...tag.input.options}
                                value=""
                                onChange={onChangeData}
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
};

InputText.propTypes = {
    data: PropTypes.object,
    tags: PropTypes.array,
    className: PropTypes.string,
    onChangeData: PropTypes.func
};
export default InputText;
