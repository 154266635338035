import React, { useState, useEffect, useCallback} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";

import { loadMeAction } from '../../../reducers/user';
import { destroyAdminAction } from '../../../reducers/admin';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import useConfirm from '../../../hook/UseConfirm';

import Loader from "../../../Components/Widget/Loader";
import Header from "../../../Components/Widget/Header";
import UIKit from "../../../Components/UIKit";
import AdminFormStyle from '../../../Components/Widget/Admin/FormStyle';

const Edit = ({ match }) => {
    const dispatch = useDispatch();
    const {
        loadMeLoading,
        me,
        loadMeDone,
        loadMeError,
     } = useSelector((state) => state.user);

     const {
         destroyAdmin,
         destroyAdminDone,
     } = useSelector((state) => state.admin);

    const [paramId, setParamId] = useState("");
    const history = useHistory();

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    useEffect(() => {
        const {
            params: { id }
        } = match;

        if (id) {
            setParamId(id);
        }
    }, [match]);

    useEffect(() => {
        if(destroyAdminDone) {
            alert(destroyAdmin.data.msg);
            window.location.href = "/user/all";
        }
    }, [destroyAdminDone]);

    const destroyAction = useCallback(async () => {
        dispatch(destroyAdminAction(paramId));
    }, [paramId, history]);

    const onClickConfirmDestroy = useConfirm('삭제된 항목은 복원할 수 없습니다.\n선택항목을 삭제하시겠습니까?', destroyAction);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-radius uk-button-danger",
            buttonName: "삭제",
            onClickAction: onClickConfirmDestroy
        }
    ];

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header
                    title={"운영진 편집 및 추가"}
                    buttons={paramId ? buttons : null}
                />
            </UIKit.Div>
            <UIKit.Grid>
                <UIKit.Div classOptions={"uk-width-1-3@xl"}>
                    <UIKit.Card
                        title={"기본 정보"}
                        cardOptions={"uk-card-default uk-card-style"}
                        cardBodyOptions={""}
                    >
                        <AdminFormStyle paramId={paramId} />
                    </UIKit.Card>
                </UIKit.Div>
            </UIKit.Grid>
        </div>
    );
};

export default Edit;
