import React, { useState, useCallback, useEffect } from "react";
import { DateRange } from "react-date-range";
import { ko } from "date-fns/locale";
import UIKIT from "uikit";

const DateRangePickers = ({
    startDate: initialStartDate,
    endDate: initialEndDate,
    onDateChange,
}) => {
    const [startDate, setStartDate] = useState(initialStartDate || new Date());
    const [endDate, setEndDate] = useState(initialEndDate || new Date());
    const [focusedRange, setFocusedRange] = useState([0, 0]);
    const [datePickerKey, setDatePickerKey] = useState(0);

    useEffect(() => {
        setStartDate(initialStartDate || new Date());
        setEndDate(initialEndDate || new Date());
    }, [initialStartDate, initialEndDate]);

    const handleDateRangeChange = useCallback(
        (ranges) => {
            const { startDate, endDate } = ranges.selection;
            setStartDate(startDate);
            setEndDate(endDate);

            if (onDateChange) {
                onDateChange({
                    startDate: startDate,
                    endDate: endDate,
                });
            }
        },
        [startDate, endDate]
    );

    const handleRangeFocusChange = useCallback(
        (focusedRange) => {
            setFocusedRange(focusedRange);
            if (focusedRange[1] === 1) {
                setDatePickerKey((prevKey) => prevKey + 1);
            }
        },
        [endDate]
    );

    return (
        <div
            uk-dropdown="mode:click; boundary:#boundary1; boundary-align:true; pos:bottom-center;"
            className="uk-padding-small uk-padding-remove-top uk-padding-remove-horizontal"
        >
            <DateRange
                key={datePickerKey}
                ranges={[{ startDate, endDate, key: "selection" }]}
                onChange={handleDateRangeChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={true}
                focusedRange={focusedRange}
                onRangeFocusChange={handleRangeFocusChange}
                locale={ko}
                direction="horizontal"
                dateDisplayFormat="yyyy-MM-dd"
                minDate={new Date(2010, 0, 1)}
            />
        </div>
    );
};

export default DateRangePickers;
