import React, { useState, useCallback, useEffect } from "react";
import { ChromePicker } from 'react-color';
import reactCSS from 'reactcss'

const ColorPicker = (props) => {
	const [state, setState] = useState(false);
	const [colorData, setColorData] = useState((props.settingData) ? props.settingData : "" );
	
	const handleClick = useCallback(() => {
		document.body.style.userSelect = 'none';
		setState(true);
	}, []);

	const handleClose = useCallback(() => {
		document.body.style.userSelect = 'auto';
		setState(false);
	}, []);

	const handleChange = useCallback((color, e) => {
		if(e.buttons == 0){
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
			setState(false);
			setState(true);
		}else{
			setColorData(color.hex);
		}
	}, [colorData]);

	useEffect(() => {
		props.onCompleteData(colorData, props.inputNameData);
	},[colorData]);

	useEffect(() => {
		setColorData(props.settingData);
	},[props.settingData]);

	const styles = reactCSS({
		'default': {
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
			icon: {
				border: "1px solid #ddd",
				borderRadius: "50%",
				width: "20px",
				height: "20px",
				background: colorData,
			},
			input: {
				border: "none",
				paddingLeft: 0,
				cursor: "pointer",
			},
			itemDiv: {
				borderBottom: "1px solid #ddd",
				cursor: "pointer",
			}
			
		},
	});

	let style = props.pickerPosition == "right" ? {right:0} : {left:0};
	return (
		<div className="uk-position-relative">
			<div className="uk-grid-collapse uk-flex-middle" style={styles.itemDiv} onClick={handleClick} data-uk-grid>
				<div className="uk-width-expand">
					<input
						type="text"
						className="uk-input"
						name={props.inputNameData}
						value={colorData}
						autoComplete="off"
						readOnly
						style={styles.input}
					/>
				</div>
				<div style={styles.icon} />
			</div>

			<div className="uk-position-absolute uk-position-z-index-9999999" style={style}>
				{state ?
					<div>
						<div style={styles.cover} onClick={handleClose}/>
						<ChromePicker color={colorData} disableAlpha={true} onChange={handleChange} />
					</div> : null 
				}
			</div> 
		</div>
	)
};

export default ColorPicker;
