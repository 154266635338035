import React, { Fragment } from 'react';

const PgInfo = ({ pg, pgInfoValue, onChange}) => {
  return (
    <Fragment>
      <p className="uk-margin-top uk-margin-remove-bottom uk-text-0_9">PG정보</p>

      <div className="uk-margin-small-bottom uk-flex-top uk-padding-5" data-uk-grid>
        <div className="uk-width-1-2">
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.pg.id`}
            >
              아이디
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.pg.id`}
                name={`${pg}.pg.id`}
                type="text"
                placeholder="id"
                value={pgInfoValue[pg] ? pgInfoValue[pg].pg.id : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.pg.apiKey`}
            >
              API 키
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.pg.apiKey`}
                name={`${pg}.pg.apiKey`}
                type="text"
                placeholder="apiKey"
                value={pgInfoValue[pg] ? pgInfoValue[pg].pg.apiKey : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-2">
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.pg.key`}
            >
              키값
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.pg.key`}
                name={`${pg}.pg.key`}
                type="text"
                placeholder="key"
                value={pgInfoValue[pg] ? pgInfoValue[pg].pg.key : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
      </div>

      <hr />
      
      <p className="uk-margin-top uk-margin-remove-bottom uk-text-0_9">네이버페이 정보</p>
      <div className="uk-margin-small-bottom uk-flex-top uk-padding-5" data-uk-grid>
        <div className="uk-width-1-2">
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.naverPay.shopId`}
            >
              shop 아이디
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.naverPay.shopId`}
                name={`${pg}.naverPay.shopId`}
                type="text"
                placeholder="shopId"
                value={pgInfoValue[pg] ? pgInfoValue[pg].naverPay.shopId : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.naverPay.clientKey`}
            >
              client Key
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.naverPay.clientKey`}
                name={`${pg}.naverPay.clientKey`}
                type="text"
                placeholder="clientKey"
                value={pgInfoValue[pg] ? pgInfoValue[pg].naverPay.clientKey : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.naverPay.secretKey`}
            >
              secret Key
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.naverPay.secretKey`}
                name={`${pg}.naverPay.secretKey`}
                type="text"
                placeholder="secretKey"
                value={pgInfoValue[pg] ? pgInfoValue[pg].naverPay.secretKey : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-2">
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.naverPay.buttonKey`}
            >
              button Key
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.naverPay.buttonKey`}
                name={`${pg}.naverPay.buttonKey`}
                type="text"
                placeholder="buttonKey"
                value={pgInfoValue[pg] ? pgInfoValue[pg].naverPay.buttonKey : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.naverPay.publicKey`}
            >
              public Key
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.naverPay.publicKey`}
                name={`${pg}.naverPay.publicKey`}
                type="text"
                placeholder="publicKey"
                value={pgInfoValue[pg] ? pgInfoValue[pg].naverPay.publicKey : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="uk-margin-top">
            <label
              className="uk-form-label uk-text-meta"
              htmlFor={`${pg}.naverPay.accessLicense`}
            >
              access License
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                id={`${pg}.naverPay.accessLicense`}
                name={`${pg}.naverPay.accessLicense`}
                type="text"
                placeholder="accessLicense"
                value={pgInfoValue[pg] ? pgInfoValue[pg].naverPay.accessLicense : ""}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default PgInfo;