import produce from "immer";

export const initialState = {
    loadQnasLoading: false,
    loadQnasDone: false,
    loadQnasError: null,
    qnas: null,
    destroyQnaLoading: false,
    destroyQnaDone: false,
    destroyQnaError: null,
    destroyQna: null,
    updateQnaLoading: false,
    updateQnaDone: false,
    updateQnaError: null,
    updateQna: null,
};

export const LOAD_QNAS_REQUEST = "LOAD_QNAS_REQUEST";
export const LOAD_QNAS_SUCCESS = "LOAD_QNAS_SUCCESS";
export const LOAD_QNAS_FAILURE = "LOAD_QNAS_FAILURE";

export const DESTROY_QNA_REQUEST = "DESTROY_QNA_REQUEST";
export const DESTROY_QNA_SUCCESS = "DESTROY_QNA_SUCCESS";
export const DESTROY_QNA_FAILURE = "DESTROY_QNA_FAILURE";

export const ANSWER_QNA_REQUEST = "ANSWER_QNA_REQUEST";
export const ANSWER_QNA_SUCCESS = "ANSWER_QNA_SUCCESS";
export const ANSWER_QNA_FAILURE = "ANSWER_QNA_FAILURE";
export const ANSWER_QNA_RESET = "ANSWER_QNA_RESET";

export const UPDATE_QNA_REQUEST = "UPDATE_QNA_REQUEST";
export const UPDATE_QNA_SUCCESS = "UPDATE_QNA_SUCCESS";
export const UPDATE_QNA_FAILURE = "UPDATE_QNA_FAILURE";
export const UPDATE_QNA_RESET = "UPDATE_QNA_RESET";

export const updateQnaAction = (qnaSeqs, data) => ({
    type: UPDATE_QNA_REQUEST,
    qnaSeqs,
    data
});

export const updateReviewResetAction = () => ({
    type: UPDATE_QNA_RESET,
});

export const loadQnasAction = (query) => ({
    type: LOAD_QNAS_REQUEST,
    data: query,
});

export const destroyQnaAction = (qnaSeq) => ({
    type: DESTROY_QNA_REQUEST,
    data: qnaSeq,
});

export const answerQnaAction = (data) => ({
    type: ANSWER_QNA_REQUEST,
    data,
});

export const answerQnaResetAction = () => ({
    type: ANSWER_QNA_RESET
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case UPDATE_QNA_RESET: {
                draft.updateQnaLoading = false;
                draft.updateQnaDone = false;
                draft.updateQnaError = null;
                draft.updateQna = null;
                break;
            }
            case UPDATE_QNA_REQUEST: {
                draft.updateQnaLoading = true;
                draft.updateQnaDone = false;
                draft.updateQnaError = null;
                break;
            }
            case UPDATE_QNA_SUCCESS: {
                draft.updateQna = action.data;
                draft.updateQnaLoading = false;
                draft.updateQnaDone = true;
                const qnaIndex = draft.qnas.data.findIndex((qna) => qna.seq === action.qnaSeq);
                // draft.qnas.data[qnaIndex] = {...draft.qnas.data[qnaIndex], status: 1, statusName: '답변완료', update: action.update};
                // draft.qnas = {...draft.qnas};
                break;
            }
            case UPDATE_QNA_FAILURE: {
                draft.updateQnaLoading = false;
                draft.updateQnaError = action.error;
                break;
            }
            case ANSWER_QNA_RESET: {
                draft.answerQnaLoading = false;
                draft.answerQnaDone = false;
                draft.answerQnaError = null;
                draft.answerQna = null;
                break;
            }
            case ANSWER_QNA_REQUEST: {
                draft.answerQnaLoading = true;
                draft.answerQnaDone = false;
                draft.answerQnaError = null;
                break;
            }
            case ANSWER_QNA_SUCCESS: {
                draft.answerQna = action.data;
                draft.answerQnaLoading = false;
                draft.answerQnaDone = true;
                if(action.data.seq) {
                    const qnaIndex = draft.qnas.data.findIndex((qna) => qna.seq === action.qnaSeq);
                    draft.qnas.data[qnaIndex] = {...draft.qnas.data[qnaIndex], status: 1, statusName: '답변완료', answer: action.answer};
                }

                if(action.data.seqs) {
                    for(let seq of action.data.seqs) {
                        const qnaIndex = draft.qnas.data.findIndex((qna) => qna.seq === seq);
                        draft.qnas.data[qnaIndex] = {...draft.qnas.data[qnaIndex], status: 1, statusName: '답변완료', answer: ' '};
                    }
                }
                // draft.qnas = {...draft.qnas};
                break;
            }
            case ANSWER_QNA_FAILURE: {
                draft.answerQnaLoading = false;
                draft.answerQnaError = action.error;
                break;
            }
            case DESTROY_QNA_REQUEST: {
                draft.destroyQnaLoading = true;
                draft.destroyQnaDone = false;
                draft.destroyQnaError = null;
                break;
            }
            case DESTROY_QNA_SUCCESS: {
                draft.destroyQna = {qnaSeq: action.qnaSeq, data: action.data};
                draft.destroyQnaLoading = false;
                draft.destroyQnaDone = true;
                break;
            }
            case DESTROY_QNA_FAILURE: {
                draft.destroyQnaLoading = false;
                draft.destroyQnaError = action.error;
                break;
            }
            case LOAD_QNAS_REQUEST: {
                draft.loadQnasLoading = true;
                draft.loadQnasDone = false;
                draft.loadQnasError = null;
                draft.answerQnaDone = false;
                draft.answerQnaError = null;
                draft.updateQnaDone = false;
                draft.destroyQnaDone = false;
                draft.destroyQna = null;
                break;
            }
            case LOAD_QNAS_SUCCESS: {
                draft.qnas = action.data;
                draft.loadQnasLoading = false;
                draft.loadQnasDone = true;
                break;
            }
            case LOAD_QNAS_FAILURE: {
                draft.loadQnasLoading = false;
                draft.loadQnasError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
