import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionLoader from '../../Widget/ActionLoader';

import { updateOrderAction } from '../../../reducers/order';

const MemoForm = ({ orderSeq, orderMemo = null, isUserModal = 0 }) => {
    const dispatch = useDispatch();
    const [memo, setMemo] = useState(orderMemo ? orderMemo : "");
    const { updateOrderLoading } = useSelector((state) => state.order);

    const onSubmitMemo = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("memo", memo);
        formData.append("_method", "PUT");
        dispatch(updateOrderAction(formData, orderSeq, isUserModal));

    }, [memo, orderSeq, isUserModal]);


    return (
        updateOrderLoading ? (
            <ActionLoader />
        ) : (
            <form onSubmit={onSubmitMemo}>
                <textarea
                    className="uk-textarea"
                    placeholder="관리자끼리만 볼 수 있는 메모입니다. 구매자에겐 전달되지않습니다."
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                />
                <div className="uk-text-right">
                    <button className="uk-button uk-button-text uk-margin-small-right uk-drop-close" type="button">취소</button>
                    <button className="uk-button uk-button-text uk-text-primary" type="submit">저장</button>
                </div>

            </form>
        )
    )
};

export default MemoForm;
