import React, { useState, useEffect, useCallback, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";

import { loadMeAction, destroyUserAction } from '../../reducers/user';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

import useConfirm from '../../hook/UseConfirm';

import UserFormStyle from '../../Components/Widget/User/FormStyle';
import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";

const Edit = ({ match }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone, loadMeError, destroyUserDone, destroyUser } = useSelector((state) => state.user);
    const [paramId, setParamId] = useState("");
    const history = useHistory();

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    useEffect(() => {
        const {
            params: { id }
        } = match;

        if (id) {
            setParamId(id);
        }
    }, [match]);

    useEffect(() => {
        if(destroyUserDone) {
            alert(destroyUser.data.msg);
            window.location.href = "/user/all";
        }
    }, [destroyUserDone]);

    const destroyAction = useCallback(async () => {
        dispatch(destroyUserAction(paramId));
    }, [paramId, history]);

    const onClickConfirmDestroy = useConfirm('삭제된 항목은 복원할 수 없습니다.\n선택항목을 삭제하시겠습니까?', destroyAction);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-radius uk-button-danger",
            buttonName: "삭제",
            onClickAction: onClickConfirmDestroy
        }
    ];



    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header
                    title={"회원 편집 및 추가"}
                    buttons={paramId ? buttons : null}
                />
            </UIKit.Div>
            <UIKit.Grid>
                <UIKit.Div classOptions={"uk-width-1-3@xl"}>
                    <UIKit.Card
                        title={"기본 정보"}
                        cardOptions={"uk-card-default uk-card-style"}
                        cardBodyOptions={""}
                    >
                        <UIKit.Grid>
                            <div data-uk-grid>
                                <div className='uk-width-1-1'>
                                    <div data-uk-grid>
                                        <UserFormStyle paramId={paramId} />
                                    </div>
                                </div>
                            </div>
                        </UIKit.Grid>
                    </UIKit.Card>
                </UIKit.Div>
            </UIKit.Grid>
        </div>
    );
};

export default Edit;
