import React, { useContext } from "react";
import { useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";
import Login from "../pages/Login";
import User from "../pages/User";
import Point from "../pages/Point";
import Setting from "../pages/Setting";
import Product from '../pages/Product';
import Home from '../pages/Home';
import Design from '../pages/Design';
import Order from '../pages/Order';
import Qna from '../pages/Qna';
import Analytics from '../pages/Analytics';
import Dashboard from '../pages/Dashboard';
import Smaple from '../pages/Sample';

const Index = () => {
    const { me } = useSelector((state) => state.user);
    return (
        <Router>
            <Switch>
                <Route
                    path="/test"
                    exact
                    component={Home}
                />
                <Route
                    path="/"
                    exact
                    render={() => (
                        me ? <Redirect to="/dashboard" /> : <Redirect to="login" />
                    )}
                />
                <Route
                    path="/dashboard"
                    exact
                    component={Dashboard}
                />
                <Route path="/user" component={User} />
                <Route path="/product" component={Product} />
                <Route
                    path="/point"
                    component={Point}
                />
                <Route
                    path="/setting"
                    component={Setting}
                />
                <Route
                    path="/design"
                    component={Design}
                />

                <Route
                    path="/cs/qna"
                    component={Qna}
                />

                <Route
                    path="/order"
                    component={Order}
                />

                <Route
                    path="/analytics"
                    component={Analytics}
                />
                <Route path="/login" component={Login} />
                <Route path="/sample" component={Smaple} />

                <Redirect from="*" to="/" />
            </Switch>

        </Router>
    );
};

export default Index;
