import React from 'react';

const ProductSetting = (props) => {
	return (
		<div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">타입</span></h4>
				</div>
				<div>
					<select
						className={`uk-select`}
						name="template.productList.type"
						onChange={props.data.onChange}
						value={props.data.value.template.productList.type}
					>
						<option key={-1} value={""}>선택</option>
						<option key={1} value={"A"}>A</option>
					</select>
				</div>
			</div>
			<div className="uk-margin-medium-bottom">
				<div className="uk-position-relative uk-margin-small-bottom">
					<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">노출 아이템 수</span></h4>
				</div>
				<div className="uk-grid-collapse uk-flex-between" data-uk-grid>
					<div style={{width:'49%'}}>
						<select
							className={`uk-select`}
							name="template.productList.numberOfItem.desktop"
							onChange={props.data.onChange}
							value={props.data.value.template.productList.numberOfItem.desktop}
						>
							<option key={-1} value={""}>선택</option>
							{
								[...Array(6)].map((n, index) => {
									const i = index + 1;
									return <option key={i} value={i}>{i}</option>;
								})
							}
						</select>
						<p className="uk-margin-remove uk-text-0_8 uk-text-center">데스크탑</p>
					</div>
					<div style={{width:'49%'}}>	
					<select
							className={`uk-select`}
							name="template.productList.numberOfItem.mobile"
							onChange={props.data.onChange}
							value={props.data.value.template.productList.numberOfItem.mobile}
						>
							<option key={-1} value={""}>선택</option>
							{
								[...Array(6)].map((n, index) => {
									const i = index + 1;
									return <option key={i} value={i}>{i}</option>;
								})
							}
						</select>
						<p className="uk-margin-remove uk-text-0_8 uk-text-center">모바일</p>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ProductSetting;