import produce from "immer";

export const initialState = {
    loadOrderLogsLoading: false,
    loadOrderLogsDone: false,
    loadOrderLogsError: null,
    orderLogs: null,
    loadOrderLogLoading: false,
    loadOrderLogDone: false,
    loadOrderLogError: null,
    orderLog: null,
};

export const LOAD_ORDERLOGS_REQUEST = "LOAD_ORDERLOGS_REQUEST";
export const LOAD_ORDERLOGS_SUCCESS = "LOAD_ORDERLOGS_SUCCESS";
export const LOAD_ORDERLOGS_FAILURE = "LOAD_ORDERLOGS_FAILURE";

export const LOAD_ORDERLOG_REQUEST = "LOAD_ORDERLOG_REQUEST";
export const LOAD_ORDERLOG_SUCCESS = "LOAD_ORDERLOG_SUCCESS";
export const LOAD_ORDERLOG_FAILURE = "LOAD_ORDERLOG_FAILURE";

export const DESTROY_ORDERLOG_REQUEST = "DESTROY_ORDERLOG_REQUEST";
export const DESTROY_ORDERLOG_SUCCESS = "DESTROY_ORDERLOG_SUCCESS";
export const DESTROY_ORDERLOG_FAILURE = "DESTROY_ORDERLOG_FAILURE";

export const loadOrderLogsAction = (query) => ({
    type: LOAD_ORDERLOGS_REQUEST,
    data: query,
});

export const loadOrderLogAction = (query) => ({
    type: LOAD_ORDERLOG_REQUEST,
    data: query,
});

export const destroyOrderLogAction = (seq) => ({
    type: DESTROY_ORDERLOG_REQUEST,
    data: seq,
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOAD_ORDERLOG_REQUEST: {
                draft.loadOrderLogLoading = true;
                draft.loadOrderLogDone = false;
                draft.loadOrderLogError = null;
                break;
            }
            case LOAD_ORDERLOG_SUCCESS: {
                draft.orderLog = action.data;
                draft.loadOrderLogLoading = false;
                draft.loadOrderLogDone = true;
                break;
            }
            case LOAD_ORDERLOG_FAILURE: {
                draft.loadOrderLogLoading = false;
                draft.loadOrderLogError = action.error;
                break;
            }
            case LOAD_ORDERLOGS_REQUEST: {
                draft.loadOrderLogsLoading = true;
                draft.loadOrderLogsDone = false;
                draft.loadOrderLogsError = null;
                draft.destroyOrderLogDone = false;
                draft.createOrderLogError = null;
                draft.updateOrderLogError = null;
                break;
            }
            case LOAD_ORDERLOGS_SUCCESS: {
                draft.orderLogs = action.data;
                draft.loadOrderLogsLoading = false;
                draft.loadOrderLogsDone = true;
                break;
            }
            case LOAD_ORDERLOGS_FAILURE: {
                draft.loadOrderLogsLoading = false;
                draft.loadOrderLogsError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
