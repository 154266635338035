import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import UIKit from "../../UIKit";

const CategoryCard = ({ formDatas, brandCategoriesKey }) => {
    const { brands } = useSelector((state) => state.brand);

    const onClickCategory = useCallback((category, index) => {
        formDatas.onChangeSelectedCategory(category, index);
    }, [formDatas]);

    const getBrandName = (brandSeq) => {
            for(let brand of brands.data) {
                 if(brand.seq === parseInt(brandSeq, 10)) {
                     return brand.name;
                 }
            }
        };

        const onClickRemoveCategory = useCallback((category, index) => {
            formDatas.onClickRemoveCategory(category, index);
        }, [formDatas]);

    return (
        <div className="uk-margin-bottom">
            <UIKit.Card
                title={getBrandName(brandCategoriesKey)}
                cardOptions={"uk-card-default uk-card-style"}
            >

                <div data-uk-grid>
                    <div className="uk-width-1-1">
                        <div data-uk-grid>
                            <div className="uk-form-controls uk-width-1-1">
                                <div data-uk-sortable="handle: .uk-sortable-handle" id={`categorySort_${brandCategoriesKey}`}>
                                    {
                                        formDatas.value.brandAndCategories[brandCategoriesKey] && formDatas.value.brandAndCategories[brandCategoriesKey].map((category, index) => (
                                            <div
                                                className="uk-margin"
                                                data-category-seq={category.seq}
                                                key={category.seq}

                                            >
                                                <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                                    <div data-uk-grid>
                                                        <div className="uk-width-3-4 uk-pointer" onClick={() => onClickCategory(category, index)}>
                                                            <i className="mdi mdi-format-align-justify menu-icon uk-margin-small-right uk-sortable-handle"></i>
                                                            <span>{category.name}</span>
                                                        </div>
                                                        <div className="uk-width-1-4 uk-text-right">
                                                            <button data-uk-close onClick={() => onClickRemoveCategory(category, index)}></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UIKit.Card>
        </div>
    )
};

CategoryCard.propTypes = {
    formDatas: PropTypes.object.isRequired,
    brandCategoriesKey: PropTypes.string.isRequired
};

export default CategoryCard;
