import React, { useEffect, useCallback, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import UIKIT from 'uikit';
import { phoneFormat } from '../../../utils';

import { destroyUserAction, loadUsersAction, loadUserAction } from '../../../reducers/user';

import { useFormData } from '../../../hook';
import useCheckboxForm from '../../../hook/UseCheckboxForm';

import UIKit from "../../../Components/UIKit";
import Pagination from "../Pagination";
import Loader  from '../Loader';
import HeaderDetailOptions from '../HeaderDetailOptions';
import ActionLoader from '../ActionLoader';
import MemoModal from './MemoModal';

const TableStyle = () => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);
    const {
        users,
        loadUsersLoading,
        loadUsersDone,
        loadUserError,
        destroyUserLoading,
        destroyUserDone,
        destroyUser,
        updateUserDone,
        updateUser,
        loadUserDone,
    } = useSelector((state) => state.user);

    const [isShowDetailOptions, setIsShowDetailOptions] = useState(false);
    const [openMemoModal, setOpenMemoModal] = useState(false);

    const checkboxForm =  useCheckboxForm(null);
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const sortForm = useFormData({
        column: defaultColumn ? defaultColumn :'regdate',
        sort: 'desc'
    });

    useEffect(() => {
        let query = location.search;
        dispatch(loadUsersAction(query));
    }, [location, destroyUserDone, selectedBrand, updateUserDone]);

    useEffect(() => {
        if(loadUserError) {

        }
    }, [loadUserError]);

    useEffect(() => {
        if(loadUsersDone) {
            checkboxForm.init(users);
        }
    }, [loadUsersDone]);

    useEffect(() => {
        if(checkboxForm && checkboxForm.value) {
            if(checkboxForm.value.total.checked) {
                setIsShowDetailOptions(true);
                return;
            }

            const keys = Object.keys(checkboxForm.value);
            for(let key of keys) {
                if(key !== 'total' && key !== 'isShowDetailOption') {
                    if(checkboxForm.value[key].checked) {
                        setIsShowDetailOptions(true);
                        return;
                    }
                }
            }
            setIsShowDetailOptions(false);
        }
    }, [checkboxForm.value]);

    const onClickDetail = useCallback((seq) => {
        history.push(`/user/all/${seq}`);
    }, []);

    const onClickColumn = useCallback((clickedColumn) => {
        let sort = {};
        let sortQuery = '';
        if (sortForm.value.column !== clickedColumn) {
            sortForm.onChange({column : clickedColumn, sort: 'desc'});
            sort[clickedColumn] = 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        } else {
            sortForm.onChange({
                sort: sortForm.value.sort === 'desc' ? 'asc' : 'desc'
            });
            sort[clickedColumn] = sortForm.value.sort === 'desc' ? 'asc' : 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        }

        let nextQueryString = sortQuery;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.sort;
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${sortQuery}`;
            }
        }

        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
    }, [history, location, sortForm]);

    useEffect(() => {
        if(destroyUserDone) {
            alert(destroyUser.data.msg);
            setIsShowDetailOptions(false);
            checkboxForm.remove(destroyUser.userSeq);
        }
    }, [destroyUserDone]);

    const onClickShowMemoModal = useCallback((user) => {
        dispatch(loadUserAction(user.seq));
    }, []);

    useEffect(() => {
        if(loadUserDone) {
            setOpenMemoModal(true);
        }
    }, [loadUserDone]);



    return (
        <div>
            {
                loadUsersLoading ? (
                    <div>
                        <Loader />
                    </div>
                ) : destroyUserLoading ? (
                    <div>
                        <ActionLoader message={"회원 일괄 삭제중..."} />
                    </div>
                ) : loadUsersDone && (
                    <div>
                        {
                            checkboxForm && checkboxForm.value && isShowDetailOptions && (
                                <HeaderDetailOptions
                                    checkboxForm={checkboxForm}
                                    destroyModelAction={destroyUserAction}
                                />
                            )
                        }

                        <UIKit.Card
                            title={""}
                            cardOptions={"uk-card-default uk-card-style uk-margin-medium-top uk-text-0_8"}
                            cardBodyOptions={"uk-padding-remove-top"}
                        >

                                <div>
                                    <span className="uk-padding-12-left uk-margin-top">
                                        <span className="uk-hidden@m uk-padding-xsmall-right">
                                            <input type="checkbox" className="uk-checkbox" />
                                        </span>
                                        <span>총 {users.total}건</span>
                                    </span>
                                    <div className='uk-overflow-auto'>
                                        <table className="uk-table uk-table-pointer uk-table-small uk-table-hover uk-table-divider uk-table-middle">
                                            <thead className="uk-text-center">
                                                <tr>
                                                    <th className="uk-table-shrink">
                                                        {
                                                            checkboxForm && checkboxForm.value && (
                                                                <input
                                                                    className="uk-checkbox"
                                                                    type="checkbox"
                                                                    checked={checkboxForm.value.total.checked}
                                                                    onChange={checkboxForm.onChangeCheckboxAll}
                                                                />
                                                            )
                                                        }

                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('brand_name')}>
                                                        <span>브랜드명</span>
                                                        {sortForm.value.column === 'brand_name' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('id')}>
                                                        <span>아이디</span>
                                                        {sortForm.value.column === 'id' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('name')}>
                                                        <span>이름</span>
                                                        {sortForm.value.column === 'name' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('phone')}>
                                                        <span>휴대폰번호</span>
                                                        {sortForm.value.column === 'phone' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('loginType')}>
                                                        <span>SNS로그인 종류</span>
                                                        {sortForm.value.column === 'loginType' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('status')}>
                                                        <span>상태</span>
                                                        {sortForm.value.column === 'status' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                    <th className="uk-table-expand">
                                                        <span>메모</span>
                                                    </th>
                                                    <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('regdate')}>
                                                        <span>등록일</span>
                                                        {sortForm.value.column === 'regdate' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users && users.data.map((user) => (
                                                        <tr key={user.seq}>
                                                            <td>
                                                                {
                                                                    checkboxForm && checkboxForm.value && checkboxForm.value[user.seq] && (
                                                                        <input
                                                                            className="uk-checkbox"
                                                                            type="checkbox"
                                                                            checked={checkboxForm.value[user.seq].checked}
                                                                            onChange={() => checkboxForm.onChangeCheckbox(user.seq)}
                                                                        />
                                                                    )
                                                                }


                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.brand && user.brand.name}
                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.id}
                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.name}
                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.phone ? phoneFormat(user.phone) : ''}
                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.loginType}
                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.statusName}
                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                            >
                                                                {
                                                                    user.memo ? (
                                                                        <div className="uk-inline">
                                                                            <button
                                                                                className="mdi mdi-note menu-icon uk-background-default uk-button uk-button-link uk-text-emphasis"
                                                                                type="button"
                                                                                onClick={() => onClickShowMemoModal(user)}
                                                                            >
                                                                            </button>
                                                                            <div data-uk-dropdown="pos: bottom-center; delay-hide: 0">{user.memo}</div>
                                                                        </div>

                                                                    ) : (
                                                                        <button
                                                                            className="mdi mdi-note menu-icon uk-background-default uk-button uk-button-link uk-text-emphasis"
                                                                            type="button"
                                                                            onClick={() => onClickShowMemoModal(user)}
                                                                        >
                                                                        </button>
                                                                    )
                                                                }

                                                            </td>
                                                            <td
                                                                className="uk-text-center"
                                                                onClick={() => onClickDetail(user.seq)}
                                                            >
                                                                {user.regdate}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <Pagination
                                            currentPage={users.current_page}
                                            pageLast={users.last_page}
                                        />
                                    </div>

                                </div>
                        </UIKit.Card>
                    </div>
                )
            }
            <MemoModal openMemoModal={openMemoModal} setOpenMemoModal={setOpenMemoModal} />
        </div>
    )
};

export default TableStyle;
