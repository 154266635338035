import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import UIKIT from "uikit";
import ExcelFilesModal from './ExcelFilesModal';

import { deliveryExcelAction, loadDeliveryExcelListAction,deliveryExcelResetAction } from '../../../reducers/order';

import UIKit from "../../UIKit";
import PercelRegistModal from './PercelRegistModal';

const OrderHeaderStyle = () => {
    const [openPercelRegistModal, setOpenPercelRegistModal] = useState(false);
    const [openExcelFilesModal, setOpenExcelFilesModal] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {
        deliveryExcelDone,
        deliveryExcelError,
        deliveryExcel,
        loadDeliveryExcelListLoading,
        loadDeliveryExcelListDone,
        loadDeliveryExcelListError,
        deliveryExcelList,
        loadPercelRegistsDone,
    } = useSelector((state) => state.order);

    useEffect(() => {
        if(deliveryExcelDone) {
            if(deliveryExcel.file && deliveryExcel.name) {
                const linkSource = "data:application/pdf;base64,"+ deliveryExcel.file;
                const link = document.createElement('a');
                link.href = linkSource;
                link.setAttribute('download', deliveryExcel.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.location.reload();
            }
        }
    }, [deliveryExcelDone]);

    const deliveryExcelSubmit = () =>{
        dispatch(deliveryExcelAction());
    };

    useEffect(() => {
        if(loadDeliveryExcelListError) {
            alert(loadDeliveryExcelListError.msg);
        }
    }, [loadDeliveryExcelListError]);

    const onClickOpenPercelRegistModal = useCallback(() => {
        setOpenPercelRegistModal(true);
        UIKIT.modal("#percelRegistModal").show();
    }, []);

    const onClickOpenExcelFilesModal = useCallback(() => {
        setOpenExcelFilesModal(true);
        UIKIT.modal("#excelFiles").show();
    }, []);

    return (
        <header id="top-head" className="uk-position-fixed uk-position-z-index">
            <div className="uk-container uk-container-expand uk-background-primary uk-padding-horizontal uk-padding-medium-horizontal@m">
                <nav
                    className="uk-navbar"
                    data-uk-navbar="mode:click; duration: 250"
                >
                    <div className="uk-navbar-left">
                        <div className="uk-navbar-item uk-padding-remove-left uk-light">
                            <Link
                                className="uk-navbar-toggle uk-padding-remove-left uk-hidden@m"
                                data-uk-toggle="target:#offcanvas-nav"
                                data-uk-navbar-toggle-icon
                                to='#'
                            ></Link>
                            <span className="uk-text-white uk-text-1_2">
                                주문목록
                            </span>
                        </div>

                        <ul className="uk-navbar-nav uk-visible@m"></ul>
                    </div>
                    <div className="uk-navbar-right">
                        <div className="uk-navbar-item uk-padding-remove-left uk-right">
                            <UIKit.Button
                                buttonOptions={'uk-button uk-button-small uk-button-white uk-button-radius'}
                                buttonName={'주문처리 ▽'}
                            />
                            <div className="uk-padding-remove" uk-dropdown="mode: click; pos:bottom-right;">
                              <ul className="uk-nav uk-dropdown-nav uk-list-padding-small uk-list-hover-default">
                                  <li onClick={deliveryExcelSubmit}><span>배송준비 주문리스트 다운로드</span></li>
                                  <li onClick={onClickOpenPercelRegistModal}><span>송장 일괄 등록</span></li>
                              </ul>
                            </div>
                        </div>
                        <ul className="uk-navbar-nav uk-visible@m"></ul>
                    </div>
                </nav>
            </div>
            <PercelRegistModal openPercelRegistModal={openPercelRegistModal} setOpenPercelRegistModal={setOpenPercelRegistModal} />
            <ExcelFilesModal openExcelFilesModal={openExcelFilesModal} setOpenExcelFilesModal={setOpenExcelFilesModal} />
        </header>
    )
};

export default OrderHeaderStyle;
