import produce from "immer";
import {
    LOAD_SKUS_REQUEST,
    LOAD_SKUS_SUCCESS,
    LOAD_SKUS_FAILURE,
    LOAD_ALL_SKUS_REQUEST,
    LOAD_ALL_SKUS_SUCCESS,
    LOAD_ALL_SKUS_FAILURE,
    LOAD_CATEGORIES_REQUEST,
    LOAD_CATEGORIES_SUCCESS,
    LOAD_CATEGORIES_FAILURE,
    UPDATE_CATEGORIES_REQUEST,
    UPDATE_CATEGORIES_SUCCESS,
    UPDATE_CATEGORIES_FAILURE,
    UPDATE_CATEGORIES_RESET,
    LOAD_PRODUCTS_REQUEST,
    LOAD_PRODUCTS_SUCCESS,
    LOAD_PRODUCTS_FAILURE,
    LOAD_PRODUCT_REQUEST,
    LOAD_PRODUCT_SUCCESS,
    LOAD_PRODUCT_FAILURE,
    DESTROY_PRODUCT_REQUEST,
    DESTROY_PRODUCT_SUCCESS,
    DESTROY_PRODUCT_FAILURE,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    UPDATE_SKU_REQUEST,
    UPDATE_SKU_SUCCESS,
    UPDATE_SKU_FAILURE,
    UPDATE_SKU_RESET,
    CREATE_SKU_SUCCESS,
    ON_CHANGE,
    ON_CHANGE_IMAGE,
    REMOVE_IMAGE,
    ADD_OPTION,
    ON_CHANGE_FOR_OPTION,
    ADD_OPTION_VALUE,
    REMOVE_OPTION_VALUE,
    ON_CHANGE_ESSENTIAL,
    REMOVE_OPTION,
    CHANGE_OPTION_LIST,
    ADD_SKU,
    REMOVE_SKU,
    LOAD_PRODUCT_RESET,
    LOAD_PRODUCT_INFO_REQUEST,
    LOAD_PRODUCT_INFO_SUCCESS,
    LOAD_PRODUCT_INFO_FAILURE,
    LOAD_PRODUCT_INFOS_REQUEST,
    LOAD_PRODUCT_INFOS_SUCCESS,
    LOAD_PRODUCT_INFOS_FAILURE,
    CHANGE_PRODUCT_INFO,
    CHANGE_ALL_PRODCUT_INFO_REFERENCE,
    LOAD_PRODUCT_INFO_SAVES_REQUEST,
    LOAD_PRODUCT_INFO_SAVES_SUCCESS,
    LOAD_PRODUCT_INFO_SAVES_FAILURE,
    CHANGE_PRODUCT_INFO_ALL,
    CREATE_PRODUCT_INFO_SAVES_REQUEST,
    CREATE_PRODUCT_INFO_SAVES_SUCCESS,
    CREATE_PRODUCT_INFO_SAVES_FAILURE,
    LOAD_PRODUCT_INFO_SAVES_RESET,
    LOAD_PRODUCT_INFOS_RESET,
    CREATE_PRODUCT_INFO_SAVES_RESET,
} from '../actions/product/type';

export const initialState = {
    loadCategoriesLoading: false,
    loadCategoriesDone: false,
    loadCategoriesError: null,
    categories: null,
    updateCategoriesLoading: false,
    updateCategoriesDone: false,
    updateCategoriesError: null,
    updateCategories: null,
    loadProductsLoading: false,
    loadProductsDone: false,
    loadProductsError: null,
    products: null,
    loadProductLoading: false,
    loadProductDone: false,
    loadProductError: null,
    product: {
        seq: -1,
        img: [],
        previewImages: [],
        name: '',
        description: '',
        content: '',
        price: 0,
        cost: 0,
        point: 0,
        isUsePoint: 0,
        status: 1,
        brand_seq: '',
        productCategory_seq: -1,
        option: [],
        optionList: [],
        optionListTemp: [],
    },
    destroyProductLoading: false,
    destroyProductDone: false,
    destroyProductError: null,
    destroyProduct: null,
    updateProductLoading: false,
    updateProductDone: false,
    updateProductError: null,
    updateProduct: null,
    createProductLoading: false,
    createProductDone: false,
    createProductError: null,
    createProduct: null,
    loadSkusLoading: false,
    loadSkusDone: false,
    loadSkusError: null,
    skus: null,
    loadAllSkusLoading: false,
    loadAllSkusDone: false,
    loadAllSkusError: null,
    skusAll: null,
    updateSkuLoading: false,
    updateSkuDone: false,
    updateSkuError: null,
    updateSku: null,
    createSkuLoading: false,
    createSkuDone: false,
    createSkuError: null,
    createSku: null,
    loadProductInfoLoading: false,
    loadProductInfoDone: false,
    loadProductInfoError: null,
    productInfo: null,
    loadProductInfosLoading: false,
    loadProductInfosDone: false,
    loadProductInfosError: null,
    productInfos: null,
    loadProductInfoSavesLoading: false,
    loadProductInfoSavesDone: false,
    loadProductInfoSavesError: null,
    productInfoSaves: null,
    createProductInfoSavesLoading: false,
    createProductInfoSavesDone: false,
    createProductInfoSavesError: null,
    createProductInfoSaves: null,
}

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {

        const onClickOptionTotalResult = () => {
            let options = draft.product.option;
            let origOptionList = draft.product.optionList;
            draft.product.optionList = [];
            let copyList = [];
            for (let [_, option] of options.entries()) {
                if (option.essential) {
                    copyList.push(option);
                } else {
                    for (let optionValue of option.value) {
                        draft.product.optionList.push({ key: optionValue.key, price: draft.product.price ? draft.product.price : 0, status: 1, skus: [] });
                    }
                }
            }
            if (copyList.length > 0) {
                RecursiveFor(copyList, 0, '');
            }

            for (let [index, obj] of draft.product.optionList.entries()) {
                let searchJson = [];

                if (draft.product.optionListTemp) {
                    draft.product.optionListTemp.filter(function (element) {
                        return (element.key == obj.key);
                    });
                }
                if (searchJson.length > 0) {
                    draft.product.optionList[index] = searchJson[0];
                }

                let searchJson2 = origOptionList.filter(function (element) {
                    return (element.key == obj.key);
                });
                if (searchJson2.length > 0) {
                    draft.product.optionList[index] = searchJson2[0];
                }
            }
        };

        const removeOptionListValue = (key, isCorrect) => {
            var searchJson = draft.product.optionList.filter(function(element){
                if(isCorrect){
                    return (element.key == key);
                }else{
                    return (element.key.indexOf("_"+key)>-1 || element.key.indexOf(key+"_")>-1);
                }
            });
            if(searchJson.length>0){
                for (let target of searchJson) {
                    draft.product.optionList.splice(draft.product.optionList.indexOf(target), 1);
                }
            }
        }

        const RecursiveFor = (list, n, str) => {
            if (list.length <= n) {
                let searchJson = draft.product.optionList.filter(function (element) {
                    return element.key == str;
                });
                if (searchJson.length === 0) {
                    // let keys = str.split('_');
                    // for(let key of keys) {
                    //     removeOptionListValue(key, true);
                    // }
                    draft.product.optionList.push({ key: str, price: draft.product.price ? draft.product.price : 0, status: 1, skus: [] });
                }
                return;
            }
            if (list[n].value) {
                for (let i = 0; i < list[n].value.length; i++) {
                    RecursiveFor(list, n + 1, str === "" ? list[n].value[i].key : `${str}_${list[n].value[i].key}`, i);
                }
            }
        };

        switch (action.type) {
            case CREATE_PRODUCT_INFO_SAVES_RESET: {
                draft.createProductInfoSavesLoading = false;
                draft.createProductInfoSavesDone = false;
                draft.createProductInfoSavesError = null;
                draft.createProductInfoSaves = null;
                break;
            }
            case LOAD_PRODUCT_INFO_SAVES_RESET: {
                draft.loadProductInfoSavesLoading = false;
                draft.loadProductInfoSavesDone = false;
                draft.loadProductInfoSavesError = null;
                draft.productInfoSaves = null;
                break;
            }
            case LOAD_PRODUCT_INFOS_RESET: {
                draft.loadProductInfosLoading = false;
                draft.loadProductInfosDone = false;
                draft.loadProductInfosError = null;
                draft.productInfos = null;
                break;
            }
            case CREATE_PRODUCT_INFO_SAVES_REQUEST: {
                draft.createProductInfoSavesLoading = true;
                draft.createProductInfoSavesDone = false;
                draft.createProductInfoSavesError = null;
                break;
            }
            case CREATE_PRODUCT_INFO_SAVES_SUCCESS: {
                draft.createProductInfoSavesLoading = false;
                draft.createProductInfoSavesDone = true;
                draft.createProductInfoSaves = action.data;
                break;
            }
            case CREATE_PRODUCT_INFO_SAVES_FAILURE: {
                draft.createProductInfoSavesLoading = false;
                draft.createProductInfoSavesError = action.error;
                break;
            }
            case LOAD_PRODUCT_INFO_SAVES_REQUEST: {
                draft.loadProductInfoSavesLoading = true;
                draft.loadProductInfoSavesDone = false;
                draft.loadProductInfoSavesError = null;
                break;
            }
            case LOAD_PRODUCT_INFO_SAVES_SUCCESS: {
                draft.loadProductInfoSavesLoading = false;
                draft.loadProductInfoSavesDone = true;
                draft.productInfo.productInfoSave = action.data;
                break;
            }
            case LOAD_PRODUCT_INFO_SAVES_FAILURE: {
                draft.loadProductInfoSavesLoading = false;
                draft.loadProductInfoSavesError = action.error;
                break;
            }
            case CHANGE_ALL_PRODCUT_INFO_REFERENCE: {
                const { isAllReference } = action;
                if(isAllReference) {
                    for(let productInfo of draft.productInfos) {
                        draft.product.productInfo[productInfo.seq] = "상품상세 참조";
                    }
                } else {
                    for(let key of Object.keys(draft.product.productInfo)) {
                        if(key !== "code") {
                            draft.product.productInfo[key] = "";
                        }
                    }
                }
                break;
            }
            case CHANGE_PRODUCT_INFO_ALL: {
                const { productInfoSaveIndex } = action;
                draft.product.productInfo = draft.productInfo.productInfoSave.data[productInfoSaveIndex].productInfo;
                break;
            }
            case CHANGE_PRODUCT_INFO: {
                const { value, productInfoSeq } = action;
                draft.product.productInfo[productInfoSeq] = value;
                break;
            }
            case LOAD_PRODUCT_INFOS_REQUEST: {
                draft.loadProductInfosLoading = true;
                draft.loadProductInfosDone = false;
                draft.loadProductInfosError = null;
                break;
            }
            case LOAD_PRODUCT_INFOS_SUCCESS: {
                if(!action.isServerInit) {
                    draft.product.productInfo = null;
                }
                if(draft.product.productInfo) {
                    draft.product.productInfo.code = action.code;
                } else {
                    draft.product.productInfo = {};
                    draft.product.productInfo.code = action.code;
                }
                draft.loadProductInfosLoading = false;
                draft.loadProductInfosDone = true;
                draft.productInfos = action.data;
                break;
            }
            case LOAD_PRODUCT_INFOS_FAILURE: {
                draft.loadProductInfosLoading = false;
                draft.loadProductInfosError = action.error;
                break;
            }
            case LOAD_PRODUCT_INFO_REQUEST: {
                draft.loadProductInfoLoading = true;
                draft.loadProductInfoDone = false;
                draft.loadProductInfoError = null;
                break;
            }
            case LOAD_PRODUCT_INFO_SUCCESS: {
                draft.loadProductInfoLoading = false;
                draft.loadProductInfoDone = true;
                draft.productInfo = action.data;
                break;
            }
            case LOAD_PRODUCT_INFO_FAILURE: {
                draft.loadProductInfoLoading = false;
                draft.loadProductInfoError = action.error;
                break;
            }
            case ADD_SKU: {
                const { modalIndex, skuSeq, skuName } = action;

                if (!draft.product.optionList[modalIndex].sku) {
                    draft.product.optionList[modalIndex].sku = [];
                }

                if(!draft.product.optionList[modalIndex].skuInfo) {
                    draft.product.optionList[modalIndex].skuInfo = [];
                }

                draft.product.optionList[modalIndex].sku.push(skuSeq);
                draft.product.optionList[modalIndex].sku.sort();
                draft.product.optionList[modalIndex].skuInfo.push({seq: skuSeq, name: skuName, memo: '', status: 1});
                draft.product.optionList[modalIndex].skuInfo.sort(function(a,b){
                    return a["seq"]-b["seq"];
                });
                break;
            }
            case REMOVE_SKU: {
                const { modalIndex, targetIndex } = action;
                draft.product.optionList[modalIndex].sku.splice(targetIndex, 1);
                break;
            }
            case CHANGE_OPTION_LIST: {
                const { inputName, parentIndex, event } = action;
                let targetValue = event.target.value;
                if (inputName === "price") {
                    targetValue = targetValue.replace(/[^0-9]/g, '');
                    targetValue = targetValue.replace(/,/g, "");
                }
                draft.product.optionList[parentIndex][inputName] = targetValue;
                break;
            }
            case REMOVE_OPTION: {
                const { parentIndex } = action;
                draft.product.option.splice(parentIndex, 1);
                onClickOptionTotalResult();
                break;
            }
            case ON_CHANGE_ESSENTIAL: {
                const { parentIndex } = action;
                draft.product.option[parentIndex].essential = !draft.product.option[parentIndex].essential;
                // if(!draft.product.option[parentIndex].essential){
                //     for (let optionValue of draft.product.option[parentIndex].value) {
                //         removeOptionListValue(optionValue.key, false);
                //     }
                // }
                onClickOptionTotalResult();
                break;
            }
            case REMOVE_OPTION_VALUE: {
                const { parentIndex, childIndex } = action;
                draft.product.option[parentIndex].value.splice(childIndex, 1);
                onClickOptionTotalResult();
                break;
            }
            case ADD_OPTION_VALUE: {
                const { parentIndex, childIndex, data } = action;
                if (childIndex === -1) {
                    draft.product.option[parentIndex].value.push({ name: data, key: new Date().getTime() + "", status: 1 });
                }
                onClickOptionTotalResult();
                break;
            }
            case ON_CHANGE_FOR_OPTION: {
                const { division, parentIndex, childIndex, event } = action;
                if (division === "optionName") {
                    draft.product.option[parentIndex].name = event.target.value;
                } else if (division === "optionValue") {
                    draft.product.option[parentIndex].value[childIndex].name = event.target.value;
                }
                break;
            }
            case ADD_OPTION: {
                draft.product.option.push({ name: '', value: [], essential: true, status: true });
                break;
            }
            case REMOVE_IMAGE: {
                draft.product.img.splice(action.index, 1);
                // if (action.division === 'preview') {
                //     draft.product.previewImages.splice(action.index, 1);
                //     draft.product.img.splice(action.index, 1);
                // } else {
                //     draft.product.img.splice(action.index, 1);
                // }
                break;
            }
            case ON_CHANGE_IMAGE: {
                const { imageFile, resultForPreview } = action;
                draft.product.img.push(imageFile);
                break;
            }
            case ON_CHANGE: {
                let editor = action.editor;
                let data = action.data;
                if (editor == "jodit") {
                    draft.product.content = data;
                } else if (data.target) {
                    let targetValue = data.target.value;
                    let targetName = data.target.name;
                    if (targetName === 'price' || targetName === 'cost' || targetName === 'point') {
                        if (targetValue) {
                            targetValue = targetValue.replace(/[^0-9]/g, '');
                            targetValue = targetValue.replace(/,/g, "");
                        }
                    }
                    draft.product[targetName] = targetValue;
                } else {
                    draft.product[data] = data;
                }
                break;
            }
            case UPDATE_CATEGORIES_RESET: {
                draft.updateCategoriesLoading = false;
                draft.updateCategoriesDone = false;
                draft.updateCategoriesError = null;
                draft.updateCategories = null;
                break;
            }
            case UPDATE_SKU_REQUEST: {
                draft.updateSkuLoading = true;
                draft.updateSkuDone = false;
                draft.updateSkuError = null;
                break;
            }
            case UPDATE_SKU_SUCCESS: {
                draft.updateSkuLoading = false;
                draft.updateSkuDone = true;

                const arrIndex = draft.skus.data.findIndex(function (element) {
                    if(element.seq == action.data.obj.seq){ return true; }
                });
                draft.skus.data[arrIndex] = action.data['obj'];
                break;
            }
            case UPDATE_SKU_FAILURE: {
                draft.updateSkuLoading = false;
                draft.updateSkuError = action.error;
                break;
            }
            case UPDATE_SKU_RESET: {
                draft.updateSkuLoading = false;
                draft.createSkuDone = false;
                draft.updateSkuError = null;
                break;
            }
            case CREATE_SKU_SUCCESS: {
                draft.createSkuLoading = false;
                draft.createSkuDone = true;
                draft.createSku = action.data;
                break;
            }
            case LOAD_SKUS_REQUEST: {
                draft.loadSkusLoading = true;
                draft.loadSkusDone = false;
                draft.loadSkusError = null;
                break;
            }
            case LOAD_SKUS_SUCCESS: {
                draft.loadSkusLoading = false;
                draft.loadSkusDone = true;
                draft.skus = action.data;
                break;
            }
            case LOAD_SKUS_FAILURE: {
                draft.loadSkusLoading = false;
                draft.loadSkusError = action.error;
                break;
            }
            case LOAD_ALL_SKUS_REQUEST: {
                draft.loadAllSkusLoading = true;
                draft.loadAllSkusDone = false;
                draft.loadAllSkusError = null;
                break;
            }
            case LOAD_ALL_SKUS_SUCCESS: {
                draft.loadAllSkusLoading = false;
                draft.loadAllSkusDone = true;
                draft.skusAll = action.data;
                break;
            }
            case LOAD_ALL_SKUS_FAILURE: {
                draft.loadAllSkusLoading = false;
                draft.loadAllSkusError = action.error;
                break;
            }
            case CREATE_PRODUCT_REQUEST: {
                draft.createProductLoading = true;
                draft.createProductDone = false;
                draft.createProductError = null;
                break;
            }
            case CREATE_PRODUCT_SUCCESS: {
                draft.createProductLoading = false;
                draft.createProductDone = true;
                draft.createProduct = { productSeq: action.productSeq, data: action.data };
                break;
            }
            case CREATE_PRODUCT_FAILURE: {
                draft.createProductLoading = false;
                draft.createProductError = action.error;
                break;
            }
            case UPDATE_PRODUCT_REQUEST: {
                draft.updateProductLoading = true;
                draft.updateProductDone = false;
                draft.updateProductError = null;
                break;
            }
            case UPDATE_PRODUCT_SUCCESS: {
                draft.updateProductLoading = false;
                draft.updateProductDone = true;
                draft.updateProduct = { productSeq: action.productSeq, data: action.data };
                break;
            }
            case UPDATE_PRODUCT_FAILURE: {
                draft.updateProductLoading = false;
                draft.updateProductError = action.error;
                break;
            }
            case DESTROY_PRODUCT_REQUEST: {
                draft.destroyProductLoading = true;
                draft.destroyProductDone = false;
                draft.destroyProductError = null;
                break;
            }
            case DESTROY_PRODUCT_SUCCESS: {
                draft.destroyProductLoading = false;
                draft.destroyProductDone = true;
                draft.destroyProduct = { productSeq: action.productSeq, data: action.data };
                break;
            }
            case DESTROY_PRODUCT_FAILURE: {
                draft.destroyProductLoading = false;
                draft.destroyProductError = action.error;
                break;
            }
            case LOAD_PRODUCT_REQUEST: {
                draft.loadProductLoading = true;
                draft.loadProductDone = false;
                draft.loadProductError = null;
                draft.destroyProductDone = false;
                draft.createProductError = null;
                draft.updateProductError = null;
                break;
            }
            case LOAD_PRODUCT_SUCCESS: {
                draft.loadProductLoading = false;
                draft.loadProductDone = true;
                draft.product = action.data;
                break;
            }
            case LOAD_PRODUCT_FAILURE: {
                draft.loadProductLoading = false;
                draft.loadProductError = action.error;
                break;
            }
            case LOAD_PRODUCT_RESET: {
                draft.product = {
                    seq: -1,
                    img: [],
                    previewImages: [],
                    name: '',
                    description: '',
                    content: '',
                    price: 0,
                    cost: 0,
                    point: 0,
                    isUsePoint: 0,
                    status: 1,
                    brand_seq: '',
                    productCategory_seq: -1,
                    option: [],
                    optionList: [],
                    optionListTemp: [],
                };
                draft.loadProductLoading = false;
                draft.loadProductDone = false;
                draft.loadProductError = null;
            }
            case LOAD_PRODUCTS_REQUEST: {
                draft.loadProductsLoading = true;
                draft.loadProductsDone = false;
                draft.loadProductsError = null;
                break;
            }
            case LOAD_PRODUCTS_SUCCESS: {
                draft.loadProductsLoading = false;
                draft.loadProductsDone = true;
                draft.products = action.data;
                break;
            }
            case LOAD_PRODUCTS_FAILURE: {
                draft.loadProductsLoading = false;
                draft.loadProductsError = action.error;
                break;
            }
            case UPDATE_CATEGORIES_REQUEST: {
                draft.updateCategoriesLoading = true;
                draft.updateCategoriesDone = false;
                draft.updateCategoriesError = null;
                break;
            }
            case UPDATE_CATEGORIES_SUCCESS: {
                draft.updateCategoriesLoading = false;
                draft.updateCategoriesDone = true;
                draft.updateCategories = action.data;
                break;
            }
            case UPDATE_CATEGORIES_FAILURE: {
                draft.updateCategoriesLoading = false;
                draft.updateCategoriesError = action.error;
                break;
            }
            case LOAD_CATEGORIES_REQUEST: {
                draft.loadCategoriesLoading = true;
                draft.loadCategoriesDone = false;
                draft.loadCategoriesError = null;
                break;
            }
            case LOAD_CATEGORIES_SUCCESS: {
                draft.loadCategoriesLoading = false;
                draft.loadCategoriesDone = true;
                draft.categories = action.data;
                break;
            }
            case LOAD_CATEGORIES_FAILURE: {
                draft.loadCategoriesLoading = false;
                draft.loadCategoriesError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
