import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import {
    loadQnasAction,
    destroyQnaAction,
    answerQnaAction,
    answerQnaResetAction,
    updateQnaAction
} from '../../../reducers/qna';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import useCheckboxForm from '../../../hook/UseCheckboxForm';

import Loader from '../Loader';
import HeaderDetailOptions from './HeaderDetailOptions';
import QnaCardStyle from './CardStyle';
import Pagination from "../Pagination";
import Editor from '../Editor';
import UserModal from '../User/UserModal';

const ListStyle = () => {
    const dispatch = useDispatch();
    const { selectedBrand, loadUserDone } = useSelector((state) => state.user);
    const {
        qnas,
        loadQnasLoading,
        loadQnasDone,
        loadQnasError,
        answerQnaDone,
        answerQnaError,
        answerQna,
        destroyQnaDone,
        destroyQnaError,
        destroyQna,
    } = useSelector((state) => state.qna);

    const location = useLocation();
    const history = useHistory();
    const checkboxForm =  useCheckboxForm(null);
    const [isShowDetailOptions, setIsShowDetailOptions] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [targetUserSeq, setTargetUserSeq] = useState("");

    useEffect(() => {
        if(checkboxForm && checkboxForm.value) {
            if(checkboxForm.value.total.checked) {
                setIsShowDetailOptions(true);
                return;
            }

            const keys = Object.keys(checkboxForm.value);
            for(let key of keys) {
                if(key !== 'total' && key !== 'isShowDetailOption') {
                    if(checkboxForm.value[key].checked) {
                        setIsShowDetailOptions(true);
                        return;
                    }
                }
            }
            setIsShowDetailOptions(false);
        }
    }, [checkboxForm.value]);

    useEffect(() => {
        let query = location.search;
        dispatch(loadQnasAction(query));
        dispatch(loadCountsForSidenavAction());
    }, [location, destroyQnaDone, selectedBrand]);

    useEffect(() => {
        if(loadQnasDone) {
            checkboxForm.init(qnas);
        }
    }, [loadQnasDone]);

    useEffect(() => {
        if(answerQnaDone) {
            alert(answerQna.msg);
            dispatch(loadCountsForSidenavAction());
        }

        if(answerQnaError) {
            alert(Object.values(answerQnaError)[0]);
        }
    }, [answerQnaDone, answerQnaError]);

    const onClickAllAnswer = useCallback(() => {
        let answerSeqs = [];
        const keys = Object.keys(checkboxForm.value);
        for(let key of keys) {
            if(key === 'total') break;
            checkboxForm.value[key].checked === true && answerSeqs.push(checkboxForm.value[key].value);
        }
        const sendData = {
            seq: answerSeqs,
            answer: " ",
        };
        dispatch(answerQnaAction(sendData));
    }, [checkboxForm]);

    useEffect(() => {
        if(destroyQnaDone) {
            alert(destroyQna.data.msg);
            setIsShowDetailOptions(false);
            checkboxForm.remove(destroyQna.qnaSeq);
        }

        if(destroyQnaError) {
            if(destroyQnaError.message) {
                alert(destroyQnaError.message);
            } else {
                alert(destroyQnaError.msg);
            }
        }
    }, [destroyQnaDone, destroyQnaError]);

    const onClickStatusChange = useCallback((data) => {
        let query = location.search;
        let searchJson;
        const parsedQuery = queryString.parse(query);
        if(parsedQuery.search){
            searchJson = JSON.parse(atob(parsedQuery.search));
        }else{
            searchJson = {"dateSelect":"all","searchType":"all"};
        }
        searchJson.status = data;
        parsedQuery.search = btoa(JSON.stringify(searchJson));
        delete parsedQuery.page;
        query = ("?"+decodeURIComponent(queryString.stringify(parsedQuery)));

        history.push({
            pathname: location.pathname,
            search: query
        });
    }, [location, destroyQnaDone, selectedBrand]);

    const onClickOpenUserModal = useCallback((userSeq) => {
        setTargetUserSeq(userSeq);
        setOpenUserModal(true);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(answerQnaResetAction());
        }
    }, []);

    useEffect(() => {
        const findImgTagWithEventBubbling = (e) => {
            const tagName = e.target.tagName;
            if(tagName === 'IMG') {
                openImagePopup(e);
            }
        };

        const openImagePopup = (e) => {
            const lightbox = document.getElementById('lightbox');
            lightbox.setAttribute('href', e.target.getAttribute('src'));
            lightbox.click();
        };

        const cardBodys = document.querySelectorAll('.uk-card-body');
        if(qnas && cardBodys) {
            for(let cardBody of cardBodys) {
                const imgs = cardBody.getElementsByTagName('img');
                for(let img of imgs) {
                    img.style.cursor = 'pointer';
                }
                cardBody.addEventListener('click', findImgTagWithEventBubbling);
            }
        }
        return () => {
            for(let cardBody of cardBodys) {
                cardBody.removeEventListener('click', findImgTagWithEventBubbling);
            }
        }

    }, [qnas && document.querySelectorAll('.uk-card-body')]);

    return loadQnasLoading ? <Loader /> : loadQnasDone && (
        <div>
            {
                checkboxForm && checkboxForm.value && isShowDetailOptions && (
                    <HeaderDetailOptions
                        checkboxForm={checkboxForm}
                        destroyModelAction={destroyQnaAction}
                        onClickAllStatusChange={onClickAllAnswer}
                    />
                )
            }
            <div className="uk-width-1-1 uk-margin-medium-top">
                {
                    checkboxForm &&
                    checkboxForm.value &&
                    checkboxForm.value.total && (
                        <label>
                            <input
                                className="uk-checkbox"
                                type="checkbox"
                                {...checkboxForm.value.total}
                                onChange={checkboxForm.onChangeCheckboxAll}
                            />
                        <span className="uk-text-0_9">전체 문의 {qnas && qnas.total}건</span>
                        </label>
                    )
                }
            </div>

            <div className="uk-width-1-1 uk-margin-small-top">
                <div className="uk-button-group">
                    <button className={`uk-button uk-button-small uk-text-0_75 ${!queryString.parse(location.search).search || (queryString.parse(location.search).search && JSON.parse(atob(queryString.parse(location.search).search)).status=="all") ? "uk-button-primary" : "uk-button-default"}`} onClick={() => onClickStatusChange("all")}>전체</button>
                    <button className={`uk-button uk-button-small uk-text-0_75 ${(queryString.parse(location.search).search && JSON.parse(atob(queryString.parse(location.search).search)).status=="1") ? "uk-button-primary" : "uk-button-default"}`} onClick={() => onClickStatusChange("1")}>답변완료</button>
                    <button className={`uk-button uk-button-small uk-text-0_75 ${(queryString.parse(location.search).search && JSON.parse(atob(queryString.parse(location.search).search)).status=="0") ? "uk-button-primary" : "uk-button-default"}`} onClick={() => onClickStatusChange("0")}>미답변</button>
                </div>
            </div>
            <div className="uk-width-1-1 uk-margin-remove">
                {
                    qnas && checkboxForm.value && qnas.data && qnas.data.map((item, index) => (
                        <QnaCardStyle
                            key={item.seq}
                            qna={item}
                            checkboxForm={checkboxForm}
                            onClickOpenUserModal={onClickOpenUserModal}
                        />
                    ))
                }
            </div>
            <div className="uk-margin-medium-top">
                <Pagination
                    currentPage={qnas.current_page}
                    pageLast={qnas.last_page}
                />
            </div>
            <div id='lightboxContainer'>
                <div data-uk-lightbox='container: #lightboxContainer;' className="uk-hidden">
                    <a className="uk-button uk-button-default" id="lightbox" href=""></a>
                </div>
            </div>
            <UserModal
                openUserModal={openUserModal}
                setOpenUserModal={setOpenUserModal}
                userSeq={targetUserSeq}
            />
        </div>
    );
};

export default ListStyle;
