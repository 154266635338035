import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionLoader from '../../Widget/ActionLoader';
import { defaults, Line } from 'react-chartjs-2';

const Type1 = ({instance, isDispatch=false}) => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);

    useEffect(() => {
        if(isDispatch){
            dispatch(instance.reducer.action("?"+instance.queryString));
        }
    }, [selectedBrand, instance.queryString]);

    const chartDefault = {
        option: {
            yAxes : {
                type: "linear",
                display: true,
                position: "left",
                id: "y1",
                gridLines: {
                    display: true,
                },
                labels: {
                    show: true,
                },
                ticks: {
                    min: 0,
                    max: 1,
                    stepSize: 1000,
                    callback: function(value, index, values) {
                        return `${value.toLocaleString()}`;
                    }
                }
            }
        },
        dataset: {
            line: {
                label: '라벨',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(255,165,0,1)',
                borderColor: 'rgba(255,165,0,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(255,165,0,1)',
                pointBackgroundColor: 'rgba(255,165,0,1)',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(255,165,0,1)',
                pointHoverBorderColor: 'rgba(252, 95, 100,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                yAxisID: 'y1',
                data: [],
            },
            bar: {
                label: "라벨",
                fill: false,
                type: 'bar',
                borderWidth: 2,
                backgroundColor: 'rgba(113, 179, 124, 0.8)',
                borderColor: 'rgba(113, 179, 124, 0.8)',
                hoverBackgroundColor: 'rgba(113, 179, 124,1)',
                yAxisID: 'y1',
                data: [],
            }
        }
    };

    const options = {
        responsive: true,
        legend: {
            display: true,
            labels:{
                fontSize: 11,
            },
        },
        scales: {
            yAxes: [],
            xAxes: [{
              maxBarThickness: 50,
            }],
        },
        tooltips: {
            mode: 'index',
            callbacks: {
                label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString();
                    label += (instance.data.chart1.tooltipUnit && instance.data.chart1.tooltipUnit[tooltipItem.datasetIndex] ? instance.data.chart1.tooltipUnit[tooltipItem.datasetIndex] : "");
                    return label;
                }
            }
        },
        maintainAspectRatio: false
    }

    const data = {
        labels: [],
        datasets: []
    };

    const setOption = () => {
        if(instance.data.chart1.option.yAxes){
            instance.data.chart1.option.yAxes.forEach(item => {
                let optionSet = {};
                optionSet.yAxes = {};
                optionSet.yAxes.ticks = {...chartDefault.option.yAxes.ticks};
                if(item.position){optionSet.yAxes.position = item.position;}
                if(item.id){optionSet.yAxes.id = item.id;}
                if(item.max){optionSet.yAxes.ticks.max = item.max(instance.reducer.obj);}
                if(item.stepSize){optionSet.yAxes.ticks.stepSize = item.stepSize(optionSet.yAxes.ticks.max);}
                if(item.callback){optionSet.yAxes.ticks.callback = item.callback;}
                options.scales.yAxes.push({...chartDefault.option.yAxes, ...optionSet.yAxes});
            });
        }
    };

    const setLabels = () => {
        data.labels = instance.reducer.obj[instance.data.chart1.labels] ? instance.reducer.obj[instance.data.chart1.labels].slice() : [];
    };

    const setData = () => {
        instance.data.chart1.element.forEach(item => {
            let itemSet = {};
            if(item.type=="line"){
                if(item.label){itemSet.label = item.label;}
                if(item.color){itemSet.backgroundColor = item.color;itemSet.borderColor = item.color;itemSet.pointBorderColor = item.color;itemSet.pointBackgroundColor = item.color;itemSet.pointHoverBackgroundColor = item.color;}
                if(item.yAxisID){itemSet.yAxisID = item.yAxisID;}
                itemSet.data = instance.reducer.obj[item.key] ? instance.reducer.obj[item.key].slice() : [];
            }else if(item.type=="bar"){
                if(item.label){itemSet.label = item.label;}
                if(item.color){itemSet.backgroundColor = item.color;itemSet.borderColor = item.color;itemSet.hoverBackgroundColor = item.color;}
                if(item.yAxisID){itemSet.yAxisID = item.yAxisID;}
                itemSet.data = instance.reducer.obj[item.key] ? instance.reducer.obj[item.key].slice() : [];
            }
            data.datasets.push({...chartDefault.dataset[item.type], ...itemSet});
        });
    };

    if(instance.reducer.done){
        setOption();
        setLabels();
        setData();
    }

    return (
        instance.reducer.loading ? (
            <ActionLoader />
        ) : (
            <Line data={data} options={options} />
        )
    )
};

export default Type1;
