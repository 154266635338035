import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIKIT from 'uikit';

import { updateUserAction } from '../../../reducers/user';

const MemoFormStyle = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const [memo, setMemo] = useState("");

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('memo', memo);
        formData.append('_method', 'PUT');
        dispatch(updateUserAction(user.seq, formData));
    }, [memo, user]);

    useEffect(() => {
        if(user) {
            setMemo(user.memo);
        }
    }, [user]);

    return (
        <form onSubmit={onSubmit}>
            <textarea
                className="uk-textarea uk-height-small"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
            />
            <p className="uk-text-right">
                <button className="uk-button uk-button-default uk-modal-close" type="button">취소</button>
                <button className="uk-button uk-button-primary" type="submit">저장</button>
            </p>
        </form>
    );
};

export default MemoFormStyle;
