import React from 'react';
import PropTypes from 'prop-types';

const ActionLoader = () => {
    return (
        <div className="uk-min-height-140">
            <div className="uk-position-center uk-overlay uk-overlay-default uk-background-transparent">
                <div data-uk-spinner></div>
            </div>
        </div>
    );
};

export default ActionLoader;
