import produce from "immer";

export const initialState = {
    loadDevSettingsLoading: false,
    loadDevSettingsDone: false,
    loadDevSettingsError: null,
    devSettings: null,
    destroyDevSettingLoading: false,
    destroyDevSettingDone: false,
    destroyDevSettingError: null,
    destroyDevSetting: null,
    loadDevSettingLoading: false,
    loadDevSettingDone: false,
    loadDevSettingError: null,
    devSetting: null,
    updateDevSettingLoading: false,
    updateDevSettingDone: false,
    updateDevSettingError: null,
    updateDevSetting: null,
    createDevSettingLoading: false,
    createDevSettingDone: false,
    createDevSettingError: null,
    createDevSetting: null,
};

export const LOAD_DEVSETTINGS_REQUEST = "LOAD_DEVSETTINGS_REQUEST";
export const LOAD_DEVSETTINGS_SUCCESS = "LOAD_DEVSETTINGS_SUCCESS";
export const LOAD_DEVSETTINGS_FAILURE = "LOAD_DEVSETTINGS_FAILURE";

export const DESTROY_DEVSETTING_REQUEST = "DESTROY_DEVSETTING_REQUEST";
export const DESTROY_DEVSETTING_SUCCESS = "DESTROY_DEVSETTING_SUCCESS";
export const DESTROY_DEVSETTING_FAILURE = "DESTROY_DEVSETTING_FAILURE";

export const LOAD_DEVSETTING_REQUEST = "LOAD_DEVSETTING_REQUEST";
export const LOAD_DEVSETTING_SUCCESS = "LOAD_DEVSETTING_SUCCESS";
export const LOAD_DEVSETTING_FAILURE = "LOAD_DEVSETTING_FAILURE";

export const UPDATE_DEVSETTING_REQUEST = "UPDATE_DEVSETTING_REQUEST";
export const UPDATE_DEVSETTING_SUCCESS = "UPDATE_DEVSETTING_SUCCESS";
export const UPDATE_DEVSETTING_FAILURE = "UPDATE_DEVSETTING_FAILURE";

export const CREATE_DEVSETTING_REQUEST = "CREATE_DEVSETTING_REQUEST";
export const CREATE_DEVSETTING_SUCCESS = "CREATE_DEVSETTING_SUCCESS";
export const CREATE_DEVSETTING_FAILURE = "CREATE_DEVSETTING_FAILURE";

export const createDevSettingAction = (formData) => ({
    type: CREATE_DEVSETTING_REQUEST,
    data: formData,
});

export const updateDevSettingAction = (devSettingSeq, formData) => ({
    type: UPDATE_DEVSETTING_REQUEST,
    devSettingSeq,
    data: formData,
});

export const loadDevSettingsAction = (query) => ({
    type: LOAD_DEVSETTINGS_REQUEST,
    data: query,
});

export const destroyDevSettingAction = (seq) => ({
    type: DESTROY_DEVSETTING_REQUEST,
    data: seq,
});

export const loadDevSettingAction = (seq) => ({
    type: LOAD_DEVSETTING_REQUEST,
    data: seq,
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case CREATE_DEVSETTING_REQUEST: {
                draft.createDevSettingLoading = true;
                draft.createDevSettingDone = false;
                draft.createDevSettingError = null;
                break;
            }
            case CREATE_DEVSETTING_SUCCESS: {
                draft.createDevSettingLoading = false;
                draft.createDevSettingDone = true;
                draft.createDevSetting = {data: action.data};
                break;
            }
            case CREATE_DEVSETTING_FAILURE: {
                draft.createDevSettingLoading = false;
                draft.createDevSettingError = action.error;
                break;
            }
            case UPDATE_DEVSETTING_REQUEST: {
                draft.updateDevSettingLoading = true;
                draft.updateDevSettingDone = false;
                draft.updateDevSettingError = null;
                break;
            }
            case UPDATE_DEVSETTING_SUCCESS: {
                draft.updateDevSettingLoading = false;
                draft.updateDevSettingDone = true;
                draft.updateDevSetting = {devSettingSeq: action.devSettingSeq, data: action.data};
                break;
            }
            case UPDATE_DEVSETTING_FAILURE: {
                draft.updateDevSettingLoading = false;
                draft.updateDevSettingError = action.error;
                break;
            }
            case LOAD_DEVSETTING_REQUEST: {
                draft.loadDevSettingLoading = true;
                draft.loadDevSettingDone = false;
                draft.loadDevSettingError = null;
                break;
            }
            case LOAD_DEVSETTING_SUCCESS: {
                draft.devSetting = action.data;
                draft.loadDevSettingLoading = false;
                draft.loadDevSettingDone = true;
                break;
            }
            case LOAD_DEVSETTING_FAILURE: {
                draft.loadDevSettingLoading = false;
                draft.loadDevSettingError = action.error;
                break;
            }
            case DESTROY_DEVSETTING_REQUEST: {
                draft.destroyDevSettingLoading = true;
                draft.destroyDevSettingDone = false;
                draft.destroyDevSettingError = null;
                break;
            }
            case DESTROY_DEVSETTING_SUCCESS: {
                draft.destroyDevSetting = {devSettingSeq: action.devSettingSeq, data: action.data};
                draft.destroyDevSettingLoading = false;
                draft.destroyDevSettingDone = true;
                break;
            }
            case DESTROY_DEVSETTING_FAILURE: {
                draft.destroyDevSettingLoading = false;
                draft.destroyDevSettingError = action.error;
                break;
            }
            case LOAD_DEVSETTINGS_REQUEST: {
                draft.loadDevSettingsLoading = true;
                draft.loadDevSettingsDone = false;
                draft.loadDevSettingsError = null;
                draft.destroyDevSettingDone = false;
                draft.createDevSettingError = null;
                draft.updateDevSettingError = null;
                break;
            }
            case LOAD_DEVSETTINGS_SUCCESS: {
                draft.devSettings = action.data;
                draft.loadDevSettingsLoading = false;
                draft.loadDevSettingsDone = true;
                break;
            }
            case LOAD_DEVSETTINGS_FAILURE: {
                draft.loadDevSettingsLoading = false;
                draft.loadDevSettingsError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
