export const minLoadingTime = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            return resolve(true);
        }, 700);
    });
};

export const getWeekday = (data, simple) => {
    return new Array('일','월','화','수','목','금','토')[new Date(data).getDay()] + (simple ? "" : "요일");
};

export const phoneNumberDash = (num) => {
    return num.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
};

export const getAgoDate = (agoDay) => {
    let date = new Date();
    let startMonth = '' + (date.getMonth() + 1);
    let startDay = '' + date.getDate();
    let startYear = date.getFullYear();

    if (startDay.length < 2) {
        startDay = `0${startDay}`;
    }

    if (startMonth.length < 2) {
        startMonth = `0${startMonth}`;
    }

    let endDate = [startYear, startMonth, startDay].join('-');

    date.setDate(date.getDate() - agoDay);
    let endMonth = '' + (date.getMonth() + 1);
    let endDay = '' + date.getDate();
    let endYear = date.getFullYear();

    if (endDay.length < 2) {
        endDay = `0${endDay}`;
    }

    if (endMonth.length < 2) {
        endMonth = `0${endMonth}`;
    }

    let startDate = [endYear, endMonth, endDay].join('-');

    let startAndEndDate = [startDate, endDate];

    return startAndEndDate;
};

export const getDownloadDate = (date) => {
    let dateSplit = date.split("-");
    let year = dateSplit[0];
    let month = parseInt(dateSplit[1]) - 1;
    let day = dateSplit[2];

    let regdate = new Date(year, month, day);
    let getDate = regdate.getDate();
    regdate.setDate(getDate + 7);

    year = regdate.getFullYear();
    month = regdate.getMonth() + 1;
    day = regdate.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`
};

export const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export const array_move = (arr, from, to) => {
    let array = arr.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}
