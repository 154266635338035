import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";

import useConfirm from '../../hook/UseConfirm';

import Loader from "../../Components/Widget/Loader";
import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";

const Edit = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [paramId, setParamId] = useState("");
    const history = useHistory();

    useEffect(() => {
        const {
            params: { id }
        } = match;

        if (id) {
            setParamId(id);
        }
        setLoading(false);
    }, [match]);

    const destroyAction = useCallback(async () => {
    }, [paramId, history]);

    const onClickConfirmDestroy = useConfirm('삭제된 항목은 복원할 수 없습니다.\n선택항목을 삭제하시겠습니까?', destroyAction);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-radius uk-button-danger",
            buttonName: "삭제",
            onClickAction: onClickConfirmDestroy
        }
    ];

    return (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header
                    title={"브랜드 편집 및 추가"}
                />
            </UIKit.Div>
            {loading ? (
                <div>
                    <Loader />
                </div>
            ) : (
                <div>주문편집</div>
            )}
        </div>
    );
};

export default Edit;
