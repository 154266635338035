import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadSamplesAction } from '../../reducers/sample';

import Item from './Item';

const List = () => {
    const dispatch = useDispatch();
    const { samples } = useSelector((state) => state.sample);

    useEffect(() => {
        dispatch(loadSamplesAction());
    }, []);

    return (
        <table className="uk-table uk-table-hover uk-table-divider">
            <thead>
                <tr>
                    <th>seq</th>
                    <th>데이터1</th>
                    <th>데이터2</th>
                    <th>데이터3</th>
                    <th>기능</th>
                </tr>
            </thead>
            <tbody>
                {
                    samples && samples.map((sample) => (
                        <Item sample={sample} key={sample.seq} />
                    ))
                }
            </tbody>
        </table>
    )
};

export default List;
