import React from 'react';
import { Calendar } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';

const DatePicker = ({date, target, onChange}) => {
    const formatDate = (date) => {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) {
            month = `0${month}`;
        }
        if (day.length < 2) {
            day = `0${day}`;
        }

        return [year, month, day].join('-');
    };

    const handleCalendar = (value) => {
        let data = {};
        data[target] = formatDate(value);
        onChange(data);
        document.getElementById(target).click();
    };

    return (
        <div uk-dropdown="mode: click; pos:bottom-center;" className="uk-padding-small uk-padding-remove-top uk-padding-xsmall-bottom uk-padding-remove-horizontal">
            <Calendar
                date={date}
                dateDisplayFormat="YYYY-MM-DD"
                onChange={handleCalendar}
                className="uk-display-block"
                locale={rdrLocales.ko}
            />
        </div>
    );
}

export default DatePicker;
