import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import UIKIT from 'uikit';

import { loadUserAction } from '../../../reducers/user';

import AnswerForm from './AnswerForm';
import OrderModal from '../Order/OrderModal';

const CardStyle = ({ qna, checkboxForm, onClickOpenUserModal }) => {
    const dispatch = useDispatch();
    const [openOrderModal, setOpenOrderModal] = useState(false);

    const onClickOpenOrderModal = useCallback(() => {
        setOpenOrderModal(true);
    }, []);

    const onClickOpenDomain = useCallback(() => {
        if(qna.product.brand && qna.product.brand.domain) {
            window.open(`${qna.product.brand.domain}/product/all/${qna.product_seq}`, '_blank');
        }
    }, [qna]);
    return (
        <div id={"qna"+qna.seq} className="uk-card uk-card-default uk-card-small uk-margin-top">
            <div className="uk-card-header uk-padding-17-left uk-grid-collapse" data-uk-grid>
                <div className="uk-width-auto">
                    {
                        checkboxForm &&
                        checkboxForm.value &&
                        checkboxForm.value[qna.seq] && (
                            <input
                                type="checkbox"
                                className="uk-checkbox"
                                {...checkboxForm.value[qna.seq]}
                                onChange={() => checkboxForm.onChangeCheckbox(qna.seq)}
                            />
                        )
                    }
                </div>
                <div className="uk-width-expand uk-margin-small-left">
                    <p className="uk-margin-remove">
                        <span className="uk-margin-small-right uk-text-bold">{qna.title}</span>
                        {
                            qna.qnaType == 2 && (
                                <img src="/image/naver_simple.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-small-right uk-text-top uk-margin-xsmall-top" />
                            )
                        }
                        <span className={`uk-text-0_9 uk-display-inline-block uk-margin-1-top uk-text-top ${qna.status==0 ? "uk-text-warning" : "uk-text-primary" }`}>[{qna.statusName}]</span>
                    </p>
                    <div className="uk-margin-small-top">
                        <img src={qna.product && qna.product.img && qna.product.img[0] ? qna.product.img[0] : ""} className="uk-object-cover uk-width-50 uk-height-50 uk-margin-xsmall-right" />
                        <div className="uk-display-inline-block uk-text-top uk-margin-remove">
                            <p className="uk-text-0_8 uk-margin-remove uk-pointer" onClick={onClickOpenDomain}>{qna.product && qna.product.name}</p>
                            <p className="uk-text-0_8 uk-margin-small-top uk-margin-remove-bottom">
                                {
                                    qna.qnaType == 2 ? (
                                        <span className="uk-pointer" onClick={onClickOpenOrderModal}>
                                            {qna.writer}
                                        </span>
                                    ) : (
                                        <span className="uk-pointer" onClick={() => onClickOpenUserModal(qna.user_seq)}>
                                            {qna.writer}
                                        </span>
                                    )
                                }
                                {
                                    qna.user && qna.user.qna_count ? (
                                        <span className="uk-text-primary">
                                            ({qna.user.qna_count})
                                        </span>
                                    ) : null
                                }
                                | {qna.regdate}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-card-body uk-padding-50-left uk-text-0_9 htmlContentArea">
                {ReactHtmlParser(qna.content ? qna.content : "")}
            </div>
            <AnswerForm qna={qna} />
            <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} oId={qna.oId} model={"Q&A"} />
        </div>
    );
}

CardStyle.propTypes = {
    qna: PropTypes.object.isRequired,
    checkboxForm: PropTypes.object.isRequired,
};

export default CardStyle;
