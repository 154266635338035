import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import UIKIT from 'uikit';

const SearchModal = ({
    children,
    modalId,
    searchForm
}) => {
    const location = useLocation();
    const history = useHistory();

    const makeSearchQueryObj = () => {
        const searchFormValue = searchForm.value;
        const searchFormObjectKeys = Object.keys(searchFormValue);
        const searchFormObj = {};
        let regDate = [];
        if (searchFormObjectKeys) {
            for(let key of searchFormObjectKeys) {
                if (searchFormValue[key] && searchFormValue[key] !== -1 && key !== 'dateRadio' && key !== 'deliveryStatus') {
                    if(key === 'startDate' || key === 'endDate') {
                        regDate.push(searchFormValue[key]);
                    } else {
                        if(key === 'searchValue') {
                            if(searchFormValue[key].trim() && searchFormValue[key].trim()) {
                                searchFormObj[key] = encodeURIComponent(searchFormValue[key].trim());
                            }
                        } else {
                            searchFormObj[key] = searchFormValue[key];
                        }

                    }

                }
            }
        }
        if(regDate && regDate.length > 0) {
            searchFormObj.regdate = regDate;
        }
        return searchFormObj;
    };

    const onSubmitSearch = useCallback(async(e, division) => {
       e.preventDefault();
       const searchQueryJson = JSON.stringify(makeSearchQueryObj());
       const parsedQuery = queryString.parse(location.search);
       let searchQueryString= "";

       if(parsedQuery.status) {
           searchQueryString = `page=1&status=${parsedQuery.status}&search=${encodeURIComponent(btoa(searchQueryJson))}`;
       }

       if(searchForm.value.deliveryStatus) {
           searchQueryString = `page=1&status=${searchForm.value.deliveryStatus}&search=${encodeURIComponent(btoa(searchQueryJson))}`;
       }

       if(parsedQuery.isOnlyPhotoReview) {
           searchQueryString = `page=1&isOnlyPhotoReview=${parsedQuery.isOnlyPhotoReview}&search=${encodeURIComponent(btoa(searchQueryJson))}`;
       }

       if(searchQueryString === "") {
           searchQueryString = `page=1&search=${encodeURIComponent(btoa(searchQueryJson))}`;
       }
       UIKIT.modal(`#${modalId}`).hide();
       history.push({
           pathname: location.pathname,
           search: searchQueryString
       });
   }, [searchForm, location]);

    return (
        <form id={modalId} name="searchDetail" className="uk-modal-container" onSubmit={(e) => onSubmitSearch(e, 'detailSearch')} data-uk-modal="container:false; esc-close: false; bg-close:false;">
            <div className="uk-modal-body uk-modal-dialog uk-width-800 uk-margin-auto-vertical uk-padding-xsmall-horizontal uk-padding-xsmall-top">
                <div className="uk-padding-small uk-height-300">
                    <button className="uk-modal-close-default" type="button" data-uk-close onClick={() => searchForm.clearValues()}></button>
                    <div className="uk-width-1-1">
                        <h4 className="uk-margin-small-bottom">고급 검색</h4>
                    </div>
                    <hr className="uk-margin-small-top uk-border-gray" />
                    <div className="uk-padding-xsmall-horizontal" data-uk-grid>
                        {children}
                    </div>
                    <div className="uk-width-1-1">
                        <p data-uk-margin className="uk-align-right uk-margin-remove-bottom uk-margin-top">
                            <button type="submit" className="uk-button uk-button-primary" onClick={(e) => onSubmitSearch(e, 'detailSearch')}>검색</button>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    )
};

SearchModal.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]),
  modalId: PropTypes.string.isRequired,
  searchForm: PropTypes.object.isRequired
};

export default SearchModal;
