import { useSelector } from 'react-redux';
import { loadSummaryAction } from '../reducers/analytics';
import { getWeekday } from "../common";

import ConfigData from "./ConfigData";

class Analytics extends ConfigData {
    valueSum = {orderCount : 0, totalPrice : 0, visitors : 0, userCount : 0, qnaCount : 0, reviewCount : 0};
    data = {
        /*********** 차트타입1 START ***********/
        chart1 : {
            option:{
                yAxes:[
                    {
                        position:"left",
                        id: "y1",
                        max: function(obj){
                            let val = [...obj.pageviews, ...obj.visitors, ...obj.orderCount].reduce( function (previous, current) {
                                return previous > current ? previous:current;
                            });
                            return val+(100-(val%100));
                        },
                        stepSize: function(max){
                            return max/4;
                        }
                    },
                    {
                        position:"right",
                        id: "y2",
                        max: function(obj){
                            let val = obj.revenue.reduce( function (previous, current) {
                                return previous > current ? previous:current;
                            });
                            return val+(10000-(val%10000));
                        },
                        stepSize: function(max){
                            return max/4;
                        },
                        callback: function(value, index, values) {
                            return `${value.toLocaleString()}원`;
                        }
                    }
                ]
            },
            labels: "dates",
            tooltipUnit: ["회", "명", "개", "원"],
            element: [
                {
                    type : "line",
                    key : "pageviews",
                    label : "페이지뷰(회)",
                    color : "rgba(255,165,0,1)",
                    yAxisID: 'y1'
                },
                {
                    type : "line",
                    key : "visitors",
                    label : "방문자(명)",
                    color : "rgba(166,94,179,1)",
                    yAxisID: 'y1'
                },
                {
                    type : "line",
                    key : "orderCount",
                    label : "주문내역(개)",
                    color : "rgba(102,000,255,1)",
                    yAxisID: 'y1'
                },
                {
                    type : "bar",
                    key : "revenue",
                    label : "매출액(원)",
                    color : "rgba(113,179,124,0.8)",
                    yAxisID: 'y2'
                }
            ]
        },
        /*********** 차트타입1 END ***********/
        /*********** 리스트타입2 START ***********/
        list2 : {
            option:{
                isFooter : true,
                listHover : true
            },
            element: {
                // col : 필수
                col : [
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "일자";
                                },
                            }
                        ],
                        body:[
                            {
                                value : function (item) {
                                    return item.date;
                                }
                            },
                            {
                                class : function (item) {
                                    return "uk-text-0_9";
                                },
                                value : function (item) {
                                    return "("+getWeekday(item.date, true)+")";
                                }
                            }
                        ],
                        footer:[
                            {
                                value : function (item) {
                                    return "최근 1주 합계";
                                },
                            }
                        ]
                    },
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "주문수";
                                },
                            }
                        ],
                        body:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    this.valueSum.orderCount += item.orderCount;
                                    return item.orderCount+"건";
                                }
                            }
                        ],
                        footer:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    return this.valueSum.orderCount+"건";
                                },
                            }
                        ]
                    },
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "매출액";
                                },
                            }
                        ],
                        body:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    this.valueSum.totalPrice += parseInt((item.totalPrice - item.cancelPrice), 10);
                                    return parseInt((item.totalPrice - item.cancelPrice), 10).toLocaleString()+"원";
                                }
                            }
                        ],
                        footer:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    return this.valueSum.totalPrice.toLocaleString()+"원";
                                },
                            }
                        ]
                    },
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "방문자";
                                },
                            }
                        ],
                        body:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    this.valueSum.visitors += item.googleAnalyticsData.visitors;
                                    return item.googleAnalyticsData.visitors+"명";
                                }
                            }
                        ],
                        footer:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    return this.valueSum.visitors+"명";
                                },
                            }
                        ]
                    },
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "가입";
                                },
                            }
                        ],
                        body:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    this.valueSum.userCount += item.userCount;
                                    return item.userCount+"명";
                                }
                            }
                        ],
                        footer:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    return this.valueSum.userCount+"명";
                                },
                            }
                        ]
                    },
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "문의";
                                },
                            }
                        ],
                        body:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    this.valueSum.qnaCount += item.qnaCount;
                                    return item.qnaCount+"건";
                                }
                            }
                        ],
                        footer:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    return this.valueSum.qnaCount+"건";
                                },
                            }
                        ]
                    },
                    {
                        header:[
                            {
                                value : function (item) {
                                    return "후기";
                                },
                            }
                        ],
                        body:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    this.valueSum.reviewCount += item.reviewCount;
                                    return item.reviewCount+"건";
                                }
                            }
                        ],
                        footer:[
                            {
                                valueSum : this.valueSum,
                                value : function (item) {
                                    return this.valueSum.reviewCount+"건";
                                },
                            }
                        ]
                    }
                ]
           }
       }
       /*********** 리스트타입2 END ***********/
    };

    constructor() {
        super(useSelector((state) => state.analytics));
        this.rtn.data = this.data;
    }

    get(type, query=""){
        if(type=="list"){
            this.makeQuery(query);
            this.rtn.reducer = {obj:{data:this.state.summary, dates:this.state.dates, pageviews:this.state.pageviews, visitors:this.state.visitors, revenue:this.state.revenue, orderCount:this.state.orderCount}, action:loadSummaryAction, loading:this.state.loadSummaryLoading, done:this.state.loadSummaryDone};
        }
        return this.rtn;
    }
}

export default Analytics;
