import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { loadOrderLogsAction, destroyOrderLogAction } from '../../../reducers/log';

import { useFormData } from '../../../hook';
import useCheckboxForm from '../../../hook/UseCheckboxForm';

import UIKit from "../../../Components/UIKit";
import Pagination from "../Pagination";
import Loader  from '../Loader';
import HeaderDetailOptions from '../HeaderDetailOptions'

const TableStyle = () => {
    const dispatch = useDispatch();
    const {
        orderLogs,
        loadOrderLogsLoading,
        loadOrderLogsDone,
        loadOrderLogError,
        destroyOrderLogDone,
        destroyOrderLog,
    } = useSelector((state) => state.log);

    const [isShowDetailOptions, setIsShowDetailOptions] = useState(false);

    const checkboxForm =  useCheckboxForm(null);
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const sortForm = useFormData({
        column: defaultColumn ? defaultColumn :'regdate',
        sort: 'desc'
    });


    useEffect(() => {
        let query = location.search;
        dispatch(loadOrderLogsAction(query));
    }, [location, destroyOrderLogDone]);

    useEffect(() => {
        if(loadOrderLogError) {

        }
    }, [loadOrderLogError]);

    useEffect(() => {
        if(loadOrderLogsDone) {
            checkboxForm.init(orderLogs);
        }
    }, [loadOrderLogsDone]);

    useEffect(() => {
        if(checkboxForm && checkboxForm.value) {
            if(checkboxForm.value.total.checked) {
                setIsShowDetailOptions(true);
                return;
            }

            const keys = Object.keys(checkboxForm.value);
            for(let key of keys) {
                if(key !== 'total' && key !== 'isShowDetailOption') {
                    if(checkboxForm.value[key].checked) {
                        setIsShowDetailOptions(true);
                        return;
                    }
                }
            }
            setIsShowDetailOptions(false);
        }
    }, [checkboxForm.value]);

    const onClickDetail = useCallback((seq) => {
        history.push(`/setting/orderLog/all/${seq}`);
    }, []);

    const onClickColumn = useCallback((clickedColumn) => {
        let sort = {};
        let sortQuery = '';
        if (sortForm.value.column !== clickedColumn) {
            sortForm.onChange({column : clickedColumn, sort: 'desc'});
            sort[clickedColumn] = 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        } else {
            sortForm.onChange({
                sort: sortForm.value.sort === 'desc' ? 'asc' : 'desc'
            });
            sort[clickedColumn] = sortForm.value.sort === 'desc' ? 'asc' : 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        }

        let nextQueryString = sortQuery;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.sort;
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${sortQuery}`;
            }
        }

        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
    }, [history, location, sortForm]);

    useEffect(() => {
        if(destroyOrderLogDone) {
            alert(destroyOrderLog.data.msg);
            setIsShowDetailOptions(false);
            checkboxForm.remove(destroyOrderLog.orderLogSeq);
        }
    }, [destroyOrderLogDone]);

    return loadOrderLogsLoading ? (
        <div>
            <Loader />
        </div>
    ) : loadOrderLogsDone && (
        <div>
            {
                checkboxForm && checkboxForm.value && isShowDetailOptions && (
                    <HeaderDetailOptions
                        checkboxForm={checkboxForm}
                        destroyModelAction={destroyOrderLogAction}
                    />
                )
            }

            <UIKit.Card
                title={""}
                cardOptions={"uk-card-default uk-card-style uk-margin-medium-top uk-text-0_8"}
                cardBodyOptions={"uk-padding-remove-top"}
            >

                    <div>
                        <span className="uk-padding-12-left uk-margin-top">
                            <span className="uk-hidden@m uk-padding-xsmall-right">
                                <input type="checkbox" className="uk-checkbox" />
                            </span>
                            <span>총 {orderLogs.data.length}건</span>
                        </span>
                        <div className='uk-overflow-auto'>
                            <table className="uk-table uk-table-pointer uk-table-small uk-table-hover uk-table-divider uk-table-middle">
                                <thead className="uk-text-center">
                                    <tr>
                                        <th className="uk-table-shrink">
                                            {
                                                checkboxForm && checkboxForm.value && (
                                                    <input
                                                        className="uk-checkbox"
                                                        type="checkbox"
                                                        checked={checkboxForm.value.total.checked}
                                                        onChange={checkboxForm.onChangeCheckboxAll}
                                                    />
                                                )
                                            }

                                        </th>
                                        <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('seq')}>
                                            <span>seq</span>
                                            {sortForm.value.column === 'seq' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                        </th>
                                        <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('order_seq')}>
                                            <span>주문 seq</span>
                                            {sortForm.value.column === 'order_seq' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                        </th>
                                        <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('regdate')}>
                                            <span>등록일</span>
                                            {sortForm.value.column === 'regdate' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orderLogs && orderLogs.data.map((orderLog) => (
                                            <tr key={orderLog.seq}>
                                                <td>
                                                    {
                                                        checkboxForm && checkboxForm.value && checkboxForm.value[orderLog.seq] && (
                                                            <input
                                                                className="uk-checkbox"
                                                                type="checkbox"
                                                                checked={checkboxForm.value[orderLog.seq].checked}
                                                                onChange={() => checkboxForm.onChangeCheckbox(orderLog.seq)}
                                                            />
                                                        )
                                                    }


                                                </td>
                                                <td
                                                    className="uk-text-center"
                                                    onClick={() => onClickDetail(orderLog.seq)}
                                                >
                                                    {orderLog.seq}
                                                </td>
                                                <td
                                                    className="uk-text-center"
                                                    onClick={() => onClickDetail(orderLog.seq)}
                                                >
                                                    {orderLog.order_seq}
                                                </td>
                                                <td
                                                    className="uk-text-center"
                                                    onClick={() => onClickDetail(orderLog.seq)}
                                                >
                                                    {orderLog.regdate}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <Pagination
                                currentPage={orderLogs.current_page}
                                pageLast={orderLogs.last_page}
                            />
                        </div>
                    </div>
            </UIKit.Card>
        </div>
    )
};

export default TableStyle;
