import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import UIKit from "../../UIKit";

const HeaderStyle = () => {
    const history = useHistory();
    return (
        <header id="top-head" className="uk-position-fixed uk-position-z-index">
            <div className="uk-container uk-container-expand uk-background-primary uk-padding-horizontal uk-padding-medium-horizontal@m">
                <nav
                    className="uk-navbar"
                    data-uk-navbar="mode:click; duration: 250"
                >
                    <div className="uk-navbar-left">
                        <div className="uk-navbar-item uk-padding-remove-left uk-light">
                            <Link
                                className="uk-navbar-toggle uk-padding-remove-left uk-hidden@m"
                                data-uk-toggle="target:#offcanvas-nav"
                                data-uk-navbar-toggle-icon
                                to='#'
                            ></Link>
                            <span className="uk-text-white uk-text-1_2">
                                구매평목록
                            </span>
                        </div>

                        <ul className="uk-navbar-nav uk-visible@m"></ul>
                    </div>
                    <div className="uk-navbar-right">
                        <div className="uk-navbar-item uk-padding-remove-left uk-right">
                            <UIKit.Button
                                buttonOptions={'uk-button uk-button-small uk-button-white uk-button-radius'}
                                buttonName={'등록 ▽'}
                            />
                            <div className="uk-padding-remove" uk-dropdown="mode: click; pos:bottom-right;">
                              <ul className="uk-nav uk-dropdown-nav uk-list-padding-small uk-list-hover-default">
                                  <li onClick={() => history.push("product/review/new")}><span>개별등록</span></li>
                                  <li data-uk-toggle="target: #excelConvertModal"><span>일괄등록</span></li>
                              </ul>
                            </div>
                        </div>
                        <ul className="uk-navbar-nav uk-visible@m"></ul>
                    </div>
                </nav>
            </div>
        </header>
    )
};

export default HeaderStyle;
