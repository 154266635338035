import React from 'react';
import SideNav from "../Widget/SideNav";

const WithSideNavLayout = ({children}) => {
    return (
        <div>
            <SideNav />
            <div
                id="content"
                data-uk-height-viewport="expand: true"
            >
                <div className="uk-container uk-container-expand">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default WithSideNavLayout;
