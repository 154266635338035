import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { answerQnaAction } from '../../../reducers/qna';

const AnswerForm = ({ qna }) => {
    const dispatch = useDispatch();

    const [answer, setAnswer] = useState("");

    useEffect(() => {
        if(qna.answer) {
            setAnswer(qna.answer);
        }
    }, []);

    const onClickAnswer = useCallback(() => {
        const sendData = {
            seq: qna.seq,
            answer
        };
        dispatch(answerQnaAction(sendData));
    }, [answer, qna]);

    return (
        <div className="uk-card-footer uk-padding-50-left uk-text-0_9 htmlContentArea">
            <p className="uk-margin-remove-bottom uk-text-0_9">
                <span className="uk-text-bold">관리자 답변</span>
                {qna.answer &&
                    <span className="uk-margin-xsmall-left">({qna.moddate})</span>
                }
            </p>
            <textarea
                className="uk-textarea uk-margin-xsmall-top uk-height-small uk-resize-vertical"
                placeholder="답글 입력 시 자동으로 답변완료 상태로 변경됩니다."
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
            >
            </textarea>
            <div className="uk-margin-small-top">
                <button
                    className="uk-button uk-button-small uk-button-primary uk-margin-small-right"
                    onClick={onClickAnswer}
                >
                    {qna.answer ? "수정완료" : "답변등록"}
                </button>
            </div>
        </div>
    )
};

AnswerForm.propTypes = {
    qna: PropTypes.object.isRequired,
};

export default AnswerForm;
