import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";

import { loadMeAction } from '../../../reducers/user';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';
import { destroyBrandAction } from '../../../reducers/brand';

import useConfirm from '../../../hook/UseConfirm';

import Loader from "../../../Components/Widget/Loader";
import Header from "../../../Components/Widget/Header";
import UIKit from "../../../Components/UIKit";
import BrandFormStyle from '../../../Components/Widget/Brand/FormStyle';


const Edit = ({ match }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone, loadMeError } = useSelector((state) => state.user);
    const { loadBrandLoading, brand, loadBrandDone, destroyBrandDone, destroyBrand } = useSelector((state) => state.brand);
    const [paramId, setParamId] = useState("");
    const history = useHistory();

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    useEffect(() => {
        const {
            params: { id }
        } = match;

        if (id) {
            setParamId(id);
        }
    }, [match]);

    useEffect(() => {
        if(destroyBrandDone) {
            alert(destroyBrand.data.msg);
            window.location.href = "/setting/brand/all";
        }
    }, [destroyBrandDone]);

    const destroyAction = useCallback(() => {
            dispatch(destroyBrandAction(paramId));
    }, [paramId, history]);

    const onClickConfirmDestroy = useConfirm('삭제된 항목은 복원할 수 없습니다.\n선택항목을 삭제하시겠습니까?', destroyAction);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-radius uk-button-danger",
            buttonName: "삭제",
            onClickAction: onClickConfirmDestroy
        }
    ];

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header
                    title={"브랜드 편집 및 추가"}
                />
            </UIKit.Div>
            <UIKit.Grid>
                <UIKit.Div classOptions={"uk-width-1-1"}>
                    <BrandFormStyle paramId={paramId} />
                </UIKit.Div>
            </UIKit.Grid>
        </div>
    );
};

export default Edit;
