import React, { useEffect, useCallback, useState, Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {loadCountByStatusAction, loadOrdersAction} from '../../../reducers/order';
import {loadCountsForSidenavAction} from "../../../reducers/analytics";
import {managerChangeOrderDetailStatusResetAction} from "../../../actions/order/action";

import { useFormData } from '../../../hook';
import UseTwoDepthCheckboxForm from '../../../hook/UseTwoDepthCheckboxForm';

import UIKit from "../../../Components/UIKit";
import OrderHeaderStyle from '../Order/OrderHeaderStyle';
import Pagination from "../Pagination";
import Loader  from '../Loader';
import OrderTabStyle from './TabStyle';
import UserModal from '../User/UserModal';
import OrderModal from './OrderModal';
import OrderSheetModal from './OrderSheetModal';
import RequestReturnOrderModal from './RequestReturnOrderModal';
import DelayProductOrderModal from './DelayProductOrderModal';
import MemoForm from './MemoForm';
import MemoFormTextAreaStyle from './MemoFormTextAreaStyle';
import DeliveryForm from './DeliveryForm';
import ItemStyle from './ItemStyle';
import OrderStatusChangeMenu  from './OrderStatusChangeMenu';



const TableStyle = () => {
    const dispatch = useDispatch();
    const {
        orders,
        loadOrdersLoading,
        loadOrdersDone,
        loadOrderError,
        loadCountByStatusDone,
    } = useSelector((state) => state.order);

    const { selectedBrand, loadUserDone } = useSelector((state) => state.user);
    const {
        updateManagerChangeOrderDetailStatusDone,
        updateManagerChangeOrderDetailStatusError,
        updateManagerChangeOrderDetailStatus
    } = useSelector((state) => state.order);

    const { updateOrderError } = useSelector((state) => state.order);

    const [nowTab, setNowTab] = useState(2);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [targetUserSeq, setTargetUserSeq] = useState("");
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [targetOrderSeq, setTargetOrderSeq] = useState("");
    const [openOrderSheetModal, setOpenOrderSheetModal] = useState(false);
    const [targetSheetModalOrderSeq, setTargetSheetModalOrderSeq] = useState("");
    const [openRequestReturnOrderModal, setOpenRequestReturnOrderModal] = useState(false);
    const [openDelayProductOrderModal, setOpenDelayProductOrderModal] = useState(false);
    const [targetOrder, setTargetOrder] = useState(null);
    const [targetDelayProductOrders, setTargetDelayProductOrders] = useState(null);
    const [nowQuery, setNowQuery] = useState("");


    const twoDepthCheckboxForm = UseTwoDepthCheckboxForm({});
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const sortForm = useFormData({
        column: defaultColumn ? defaultColumn :'regdate',
        sort: 'desc'
    });


    useEffect(() => {
            let query = location.search;
            const parsedQuery = queryString.parse(query);
            let status = 2;
            if(parsedQuery.status) {
                status = parsedQuery.status;
                if(status !== 'all') {
                    status = parseInt(status, 10);
                }
                setNowTab(status);
            }
            setNowQuery(query);
            dispatch(loadOrdersAction(`${query}`));
            // 아래 dispatch는 loadOrdersAction saga에서 직접처리
            //dispatch(loadCountByStatusAction());
    }, [location, selectedBrand]);


    useEffect(() => {
        if(loadOrderError) {

        }
    }, [loadOrderError]);

    useEffect(() => {
        if(loadOrdersDone) {
            if(orders.current_page <= orders.last_page) {
                twoDepthCheckboxForm.init(orders);

            } else if(orders.current_page > orders.last_page) {
                let query = location.search;
                const parsedQuery = queryString.parse(query);
                parsedQuery.page = orders.last_page;
                query = queryString.stringify(parsedQuery);
                history.push({
                    pathname: location.pathname,
                    search: query
                });

            }

        }
    }, [loadOrdersDone]);

    useEffect(() => {
        if(updateManagerChangeOrderDetailStatusDone) {
            alert(updateManagerChangeOrderDetailStatus.msg);
            dispatch(managerChangeOrderDetailStatusResetAction());
            dispatch(loadCountsForSidenavAction());
            dispatch(loadOrdersAction(`${nowQuery}`));
        }

        if(updateManagerChangeOrderDetailStatusError) {
            alert(updateManagerChangeOrderDetailStatusError.msg);
            // dispatch(managerChangeOrderDetailStatusResetAction());
        }
    }, [updateManagerChangeOrderDetailStatusDone, updateManagerChangeOrderDetailStatusError]);

    useEffect(() => {
        if(updateOrderError){
            alert(Object.values(updateOrderError)[0]);
        }
    }, [updateOrderError]);

    const onClickColumn = useCallback((clickedColumn) => {
        let sort = {};
        let sortQuery = '';
        if (sortForm.value.column !== clickedColumn) {
            sortForm.onChange({column : clickedColumn, sort: 'desc'});
            sort[clickedColumn] = 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        } else {
            sortForm.onChange({
                sort: sortForm.value.sort === 'desc' ? 'asc' : 'desc'
            });
            sort[clickedColumn] = sortForm.value.sort === 'desc' ? 'asc' : 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        }

        let nextQueryString = sortQuery;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.sort;
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${sortQuery}`;
            }
        }

        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
    }, [history, location, sortForm]);

    const onClickOpenUserModal = useCallback((userSeq) => {
        setTargetUserSeq(userSeq);
        setOpenUserModal(true);
    }, []);

    const onClickOpenOrderModal = useCallback((orderSeq) => {
        setTargetOrderSeq(orderSeq);
        setOpenOrderModal(true);
    }, []);

    const onClickOpenOrderSheetModal = useCallback((orderSeq) => {
        setTargetSheetModalOrderSeq(orderSeq);
        setOpenOrderSheetModal(true);
    }, []);

    const onClickRequestReturnOrderModal = useCallback((order) => {
        setTargetOrder(order);
        setOpenRequestReturnOrderModal(true);
    }, []);

    const onClickDelayProdcutOrderModal = useCallback((data) => {
        setTargetDelayProductOrders(data);
        setOpenDelayProductOrderModal(true);
    }, []);

    return loadOrdersLoading ? (
        <div>
            <Loader />
        </div>
    ) : loadOrdersDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <OrderHeaderStyle />
            </UIKit.Div>
            {
                loadCountByStatusDone && <OrderTabStyle nowTab={nowTab} setNowTab={setNowTab} />
            }

            <div>
                <div className="uk-card uk-card-default uk-card-body uk-card-small uk-padding-remove">
                    {
                        <OrderStatusChangeMenu nowTab={nowTab} nowQuery={nowQuery} twoDepthCheckboxForm={twoDepthCheckboxForm} onClickDelayProdcutOrderModal={onClickDelayProdcutOrderModal} />
                    }

                    <div className="uk-overflow-auto uk-margin-top uk-panel-scrollable@m uk-border-none uk-padding-remove uk-resize-none" data-uk-height-viewport="offset-top: true; offset-bottom:120px;">
                        <table className="uk-table uk-table-divider uk-table-small uk-table-border uk-overflow-auto">
                            <thead className="uk-text-0_8 uk-text-center">
                                <tr>
                                    <th className="uk-text-left uk-width-215">
                                        <input
                                            type="checkbox"
                                            className="uk-checkbox uk-margin-small-right"
                                            onChange={twoDepthCheckboxForm.onChangeCheckboxAll}
                                            checked={
                                                twoDepthCheckboxForm.value.total &&
                                                twoDepthCheckboxForm.value.total.checked ?
                                                twoDepthCheckboxForm.value.total.checked :
                                                false
                                            }
                                        />
                                        <span>주문번호</span>
                                    </th>
                                    <th className="uk-text-nowrap uk-table-expand">주문상품</th>
                                    <th className="uk-table-shrink uk-text-nowrap">상품금액</th>
                                    <th className="uk-table-shrink uk-text-nowrap">수량</th>
                                    <th className="uk-table-shrink uk-text-nowrap">상태</th>
                                    <th className="uk-table-shrink uk-text-nowrap">배송</th>
                                    <th className="uk-width-auto uk-min-width-200">배송정보</th>
                                    {
                                        (nowTab === 7) && (
                                            <Fragment>
                                                <th className="uk-table-shrink uk-text-nowrap">반품정보</th>
                                                <th className="uk-table-shrink uk-text-nowrap">환불정보</th>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        nowTab === 6 && (
                                          <th className="uk-table-shrink uk-text-nowrap">취소정보</th>
                                        )

                                    }
                                    <th className="uk-table-shrink uk-text-nowrap">결제내역</th>
                                </tr>
                            </thead>
                            <tbody className="uk-text-0_8">
                                {
                                    orders && Object.keys(orders).length > 0 ? (
                                        orders.data.map((order,index) => ((
                                                order.detail.map((detail, detailIndex) => (
                                                    <ItemStyle
                                                        key={detail.seq}
                                                        order={order}
                                                        detail={detail}
														orderIndex={index}
                                                        detailIndex={detailIndex}
                                                        twoDepthCheckboxForm={twoDepthCheckboxForm}
                                                        onClickOpenUserModal={onClickOpenUserModal}
                                                        MemoForm={MemoForm}
                                                        MemoFormTextAreaStyle={MemoFormTextAreaStyle}
                                                        DeliveryForm={DeliveryForm}
                                                        onClickOpenOrderModal={onClickOpenOrderModal}
                                                        onClickOpenOrderSheetModal={onClickOpenOrderSheetModal}
                                                        onClickRequestReturnOrderModal={onClickRequestReturnOrderModal}
                                                        nowTab={nowTab}
                                                    />
                                                ))
                                            ))
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={14} className="uk-text-center">목록이 존재하지않습니다.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="uk-margin-top">
                    <Pagination
                        currentPage={orders.current_page ? orders.current_page : 1}
                        pageLast={orders.last_page ? orders.last_page : 1}
                    />
                </div>
                <OrderSheetModal openOrderSheetModal={openOrderSheetModal} setOpenOrderSheetModal={setOpenOrderSheetModal} orderSeq={targetSheetModalOrderSeq} />
                <UserModal openUserModal={openUserModal} setOpenUserModal={setOpenUserModal} userSeq={targetUserSeq} />
                <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} orderSeq={targetOrderSeq} />
                <RequestReturnOrderModal openRequestReturnOrderModal={openRequestReturnOrderModal} setOpenRequestReturnOrderModal={setOpenRequestReturnOrderModal} targetOrder={targetOrder} />
                <DelayProductOrderModal openDelayProductOrderModal={openDelayProductOrderModal} setOpenDelayProductOrderModal={setOpenDelayProductOrderModal} targetDelayProductOrders={targetDelayProductOrders} />
            </div>


        </div>
    )
};

export default TableStyle;
