import React, {} from 'react';
import Icon from '../../../Widget/Icon';

const MobileMenuA = (props) => {
    return (
        <div id="mobile-nav-bar" className={`uk-padding-remove uk-height-1-1`} style={{width:"65%", position:"relative", backgroundColor: props.color.background}}>
            <button className="uk-button uk-button-small uk-font-color-white closebtn" type="button" style={{backgroundColor:"inherit"}}>&times;</button>
            <aside className="uk-padding-small" style={{color: props.color.font}}>
                <a href="/"><img src={props.logo} style={{height: props.logoSize}} /></a>
                <div className="uk-margin-top">    
                    <div className="uk-grid uk-grid-small uk-flex-middle uk-padding-15-vertical" style={{cursor:"pointer"}}>
                        <div className="uk-width-auto">
                            <Icon name={props.data.template.basic.icon.mypage} size={35} />
                        </div>
                        <div className="uk-width-expand">
                            <span className="uk-text-small">로그인이 필요합니다</span>
                        </div>
                    </div>
                </div>
                <hr className="uk-margin-remove-top" style={{borderColor: props.color.font}} />
                <div className="left-nav-wrap uk-padding-3-left">
                    <div>SHOP</div>
                    <div>ABOUT</div>
                    <div>CART</div>
                    <div>LOGIN</div>
                </div>
            </aside>
        </div>
    )
}
export default MobileMenuA;