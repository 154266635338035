import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import {loadOrdersAction, updateOrderDetailAction, updateOrderDetailResetAction} from '../../../reducers/order'
import {loadCountsForSidenavAction} from "../../../reducers/analytics";

const OrderStatusChangeMenu = ({ nowTab, nowQuery, twoDepthCheckboxForm, onClickDelayProdcutOrderModal }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { me } = useSelector((state) => state.user);
    const { updateOrderDetailDone, updateOrderDetailError, updateOrderDetail } = useSelector((state) => state.order);
    const [detailStatus, setDetailStatus] = useState('all');

    const onSubmitOrderStatus = useCallback((orderStatus) => {
        const copyTwoDepthCheckboxFormValue = JSON.parse(JSON.stringify(twoDepthCheckboxForm.value)); //깊은복사
        delete copyTwoDepthCheckboxFormValue.total;
        delete copyTwoDepthCheckboxFormValue.isShowDetailOption;


        // 서버에 호출하기전 체크박스 체크안된 주문,주문상세 전부 제거
        for(let copyParentCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue)) {
            if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].checked === false) {
                delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey];
                continue;
            }

            for(let copyChildCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox)) {
                if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey].checked === false) {
                    delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey];
                }
            }
        }

        const stringifyedData = Object.keys(copyTwoDepthCheckboxFormValue).length > 0 ? JSON.stringify(copyTwoDepthCheckboxFormValue) : ""
    }, [twoDepthCheckboxForm]);

    const onSubmitOrderDetailStatus = useCallback((orderStatus) => {

        const copyTwoDepthCheckboxFormValue = JSON.parse(JSON.stringify(twoDepthCheckboxForm.value)); //깊은복사
        delete copyTwoDepthCheckboxFormValue.total;
        delete copyTwoDepthCheckboxFormValue.isShowDetailOption;
        if(Object.keys(copyTwoDepthCheckboxFormValue).length==0){
          alert('처리할 주문을 선택해 주세요.');
          return;
        }

        if(orderStatus === 2) {
            alert('네이버페이 주문건은 즉시 취소완료 처리 됩니다.');
        }

        // 서버에 호출하기전 체크박스 체크안된 주문,주문상세 전부 제거
        for(let copyParentCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue)) {
            if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].checked === false) {
                delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey];
                continue;
            }

            for(let copyChildCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox)) {
                if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey].checked === false) {
                    delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey];
                }
            }
        }

        const data = {
            data: JSON.stringify(copyTwoDepthCheckboxFormValue),
            status: orderStatus
        };

        if(orderStatus === 12) {
            onClickDelayProdcutOrderModal(data.data);
        } else {
            dispatch(updateOrderDetailAction('admin/orders/details', data));
        }
    }, [twoDepthCheckboxForm]);

    useEffect(() => {
        if(updateOrderDetailDone) {
            dispatch(loadOrdersAction(nowQuery));
            dispatch(loadCountsForSidenavAction());
            alert(updateOrderDetail.msg);
            dispatch(updateOrderDetailResetAction());
        }

        if(updateOrderDetailError) {
            if(updateOrderDetailError && Object.values(updateOrderDetailError)[0]) {
                alert(Object.values(updateOrderDetailError)[0]);
                dispatch(updateOrderDetailResetAction());
            }

        }
    }, [updateOrderDetailDone, updateOrderDetailError]);

    useEffect(() => {
        if(location.search) {
            const parsedQuery = queryString.parse(location.search);
            if(parsedQuery.detailStatus) {
                setDetailStatus(parsedQuery.detailStatus);
            }
        }
    }, []);

    const checkDisabled = useCallback(() => {
        const keys = Object.keys(twoDepthCheckboxForm.value);
        let count = keys.length - 2;
        let firstTrue = 0;
        let firstFalse = 0;
        let disabled = false;
        let checked = {};
        for(let key of keys) {
            if(key === 'isShowDetailOption' || key === 'total') {
                continue;
            }
            twoDepthCheckboxForm.value[key].checked === true ? firstTrue++ : firstFalse++;
            checked[key] = twoDepthCheckboxForm.value[key];

        }

        let detailTrue = 0;
        let detailFalse = 0;
        let detailCount = 0;
        if(firstFalse === count) {
            disabled = true;
        } else {
            for(let key of Object.keys(checked)) {
                if(checked[key].checked === true) {
                    let detailKeys = Object.keys(checked[key].detailCheckbox);
                    detailCount += detailKeys.length;
                    for(let detailKey of detailKeys) {
                        checked[key].detailCheckbox[detailKey].checked === true ? detailTrue++ : detailFalse++;

                    }
                }
            }

            if(detailCount === detailTrue) {
                disabled = false;
            } else {
                disabled = true;
            }
        }

        return (
            <Fragment>
                <button
                    className="uk-button uk-button-default uk-button-small uk-hidden"
                    uk-tooltip="배송을 시작합니다."
                    onClick={() => onSubmitOrderStatus(4)}
                    disabled={disabled}
                >
                    발송처리
                </button>
                <button
                    className="uk-button uk-button-default uk-button-small uk-hidden"
                    uk-tooltip="배송준비처리시 구매자취소시 판매자 승인이 필요합니다."
                    onClick={() => onSubmitOrderStatus(3)}
                    disabled={disabled}
                >
                    배송준비처리
                </button>
                <button
                    className="uk-button uk-button-default uk-button-small uk-hidden"
                    uk-tooltip="입금대기 주문의 입금확인을 처리합니다."
                    onClick={() => onSubmitOrderStatus(2)}
                    disabled={disabled}
                >
                    입금확인처리
                </button>
                <button
                    className="uk-button uk-button-default uk-button-small uk-hidden"
                    type="button"
                    disabled={disabled}
                >
                    기타주문처리
                </button>
            </Fragment>

        )
    }, [twoDepthCheckboxForm, onSubmitOrderStatus]);

    const onClickDetailStatusChange = useCallback((orderDetailStatus) => {
        setDetailStatus(orderDetailStatus);
        const parsedQuery = queryString.parse(location.search);
        let query = `page=1&status=3&detailStatus=${orderDetailStatus}`;
        if(parsedQuery.search) {
            query = `${query}&search=${parsedQuery.search}`
        }
        history.push({
            pathname: location.pathname,
            search: query
        });
    }, []);
    return (
        <div data-uk-grid>
            <div className="uk-width-2-3">
                {
                    checkDisabled()
                }
                <div data-uk-dropdown="mode: click">
                    <ul className="uk-nav uk-dropdown-nav">
                        <li uk-tooltip="결제완료인 무통장입금 주문을 입금대기로 되돌립니다. (Npay불가)" onClick={() => onSubmitOrderStatus(1)}><a>입금대기로 되돌리기</a></li>
                        <li><a>착불결제로 되돌리기</a></li>
                        <li uk-tooltip="배송중, 배송완료를 배송준비로 되돌립니다. (Npay불가)" onClick={() => onSubmitOrderStatus(3)}><a>배송준비로 되돌리기</a></li>
                        <li uk-tooltip="배송준비중, 배송중 결제완료로 되돌립니다. (Npay불가)" onClick={() => onSubmitOrderStatus(2)}><a>결제완료로 되돌리기</a></li>
                        <li uk-tooltip="배송완료를 배송중으로 되돌립니다. (Npay불가)" onClick={() => onSubmitOrderStatus(4)}><a>배송중으로 되돌리기</a></li>
                        <li onClick={() => onSubmitOrderStatus(5)}><a>강제 배송완료 처리</a></li>
                        <li><a>발송지연 처리(Npay용)</a></li>
                    </ul>
                </div>
                <div className={`uk-width-1-1 ${nowTab === 3 ? '' : 'uk-hidden'}`}>
                    <div className="uk-button-group">
                        <button className={`uk-button uk-button-small uk-text-0_75 ${detailStatus === 'all' ? 'uk-button-primary' : 'uk-button-default'}`} onClick={() => onClickDetailStatusChange("all")}>전체</button>
                        <button className={`uk-button uk-button-small uk-text-0_75 ${detailStatus === "12" ? 'uk-button-primary' : 'uk-button-default'}`} onClick={() => onClickDetailStatusChange("12")}>배송지연</button>
                    </div>
                </div>
                <button
                    className={`uk-button uk-button-default uk-button-small uk-hidden`}
                    uk-tooltip="주문을 취소요청으로 변경합니다."
                    onClick={() => onSubmitOrderDetailStatus(2)}
                >
                    취소요청
                </button>
                <button
                    className={`uk-button uk-button-default uk-button-small uk-margin-small-top ${nowTab === 3 ? '' : 'uk-hidden'}`}
                    uk-tooltip="주문을 배송지연으로 변경합니다."
                    onClick={() => onSubmitOrderDetailStatus(12)}
                >
                    배송지연 처리
                </button>
                <button
                    className={`uk-button uk-button-default uk-button-small uk-hidden`}
                    uk-tooltip="배송중, 배송완료건만 처리할 수 있으며 반품처리시 반품관리에서 추가 처리가 필요합니다."
                    onClick={() => onSubmitOrderDetailStatus(3)}
                >
                    반품처리 요청
                </button>
                <button
                    className="uk-button uk-button-default uk-button-small uk-hidden"
                    uk-tooltip="배송중, 배송완료건만 처리할 수 있으며 교환처리시 교환관리에서 추가 처리가 필요합니다."
                    onClick={() => onSubmitOrderDetailStatus(4)}
                >
                    교환처리 요청
                </button>
            </div>
        </div>
    )
};

export default OrderStatusChangeMenu;
