import React, {  } from 'react';

import { SortableContainer, SortableElement} from 'react-sortable-hoc';
import { array_move } from '../../../common';

const ImageDnd = (props) => {  

  const SortableListItem = SortableElement((data) => {
    return(
      <div className="list-group-item" style={getStyle}>
        <div className="uk-position-top-right uk-position-z-index-2">
            <button type="button" className="uk-margin-xsmall-right uk-background-white" onClick={() => props.onClickRemoveImage(data.item.src, data.idx)} data-uk-close></button>
        </div>
        <img src={data.item.src} style={{display:"block", margin:"0 auto", objectFit:"contain"}} />
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="list-group">
        {items.map((item, index) => {
          return (
            <SortableListItem axis="xy" key={index} index={index} idx={index} item={item} />
          );
        })}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let result = array_move(props.imgArray, oldIndex, newIndex);
    props.setImgArray(result);
  };

  const getStyle = {
    position: "relative",
    width: "100px",
    height: "100px",
    display: "inline-flex",
    textAlign: "center",
    userSelect: "none",
    marginRight: "5px",
    border: "1px solid #ddd",
  }

  return (
    <div className="container">
      <SortableList axis={"xy"} distance={1} lockToContainerEdges={true} lockOffset={["0%", "0%"]} items={props.imgArray} onSortEnd={onSortEnd} />
    </div>
  );
}

export default ImageDnd;