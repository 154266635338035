import produce from "immer";

export const initialState = {
    loadSamplesLoading: false,
    loadSamplesDone: false,
    loadSamplesError: null,
    samples: null,
    createSampleLoading: false,
    createSampleDone: false,
    createSampleError: null,
    createSample: null,
    removeSampleLoading: false,
    removeSampleDone: false,
    removeSampleError: null,
    removeSample: null,
    loadSampleLoading: false,
    loadSampleDone: false,
    loadSampleError: null,
    sample: null,
};

export const LOAD_SAMPLES_REQUEST = "LOAD_SAMPLES_REQUEST";
export const LOAD_SAMPLES_SUCCESS = "LOAD_SAMPLES_SUCCESS";
export const LOAD_SAMPLES_FAILURE = "LOAD_SAMPLES_FAILURE";

export const CREATE_SAMPLE_REQUEST = "CREATE_SAMPLE_REQUEST";
export const CREATE_SAMPLE_SUCCESS = "CREATE_SAMPLE_SUCCESS";
export const CREATE_SAMPLE_FAILURE = "CREATE_SAMPLE_FAILURE";

export const REMOVE_SAMPLE_REQUEST = "REMOVE_SAMPLE_REQUEST";
export const REMOVE_SAMPLE_SUCCESS = "REMOVE_SAMPLE_SUCCESS";
export const REMOVE_SAMPLE_FAILURE = "REMOVE_SAMPLE_FAILURE";

export const LOAD_SAMPLE_REQUEST = "LOAD_SAMPLE_REQUEST";
export const LOAD_SAMPLE_SUCCESS = "LOAD_SAMPLE_SUCCESS";
export const LOAD_SAMPLE_FAILURE = "LOAD_SAMPLE_FAILURE";

export const UPDATE_SAMPLE_REQUEST = "UPDATE_SAMPLE_REQUEST";
export const UPDATE_SAMPLE_SUCCESS = "UPDATE_SAMPLE_SUCCESS";
export const UPDATE_SAMPLE_FAILURE = "UPDATE_SAMPLE_FAILURE";

export const loadSamplesAction = (query) => ({
    type: LOAD_SAMPLES_REQUEST,
    data: query,
});

export const createSampleAction = (data) => ({
    type: CREATE_SAMPLE_REQUEST,
    data,
});

export const removeSampleAction = (seq) => ({
    type: REMOVE_SAMPLE_REQUEST,
    data: seq,
});

export const loadSampleAction = (seq) => ({
    type: LOAD_SAMPLE_REQUEST,
    data: seq,
});

export const updateSampleAction = (seq, data) => ({
    type: UPDATE_SAMPLE_REQUEST,
    seq,
    data,
});


const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOAD_SAMPLE_REQUEST: {
                draft.loadSampleLoading = true;
                draft.loadSampleDone = false;
                draft.loadSampleError = null;
                break;
            }
            case LOAD_SAMPLE_SUCCESS: {
                draft.sample = draft.samples.find((sample) => sample.seq === action.seq);
                draft.loadSampleLoading = false;
                draft.loadSampleDone = true;
                break;
            }
            case LOAD_SAMPLE_FAILURE: {
                draft.loadSampleLoading = false;
                draft.loadSampleError = action.error;
                break;
            }
            case REMOVE_SAMPLE_REQUEST: {
                draft.removeSampleLoading = true;
                draft.removeSampleDone = false;
                draft.removeSampleError = null;
                break;
            }
            case REMOVE_SAMPLE_SUCCESS: {
                draft.samples = draft.samples.filter((sample) => sample.seq !== action.seq);
                draft.removeSampleLoading = false;
                draft.removeSampleDone = true;
                break;
            }
            case REMOVE_SAMPLE_FAILURE: {
                draft.removeSampleLoading = false;
                draft.removeSampleError = action.error;
                break;
            }
            case CREATE_SAMPLE_REQUEST: {
                draft.createSampleLoading = true;
                draft.createSampleDone = false;
                draft.createSampleError = null;
                break;
            }
            case CREATE_SAMPLE_SUCCESS: {
                action.data.seq = draft.samples[draft.samples.length - 1].seq + 1;
                draft.samples = draft.samples.concat(action.data);
                draft.createSampleLoading = false;
                draft.createSampleDone = true;
                break;
            }
            case CREATE_SAMPLE_FAILURE: {
                draft.createSampleLoading = false;
                draft.createSampleError = action.error;
                break;
            }
            case LOAD_SAMPLES_REQUEST: {
                draft.loadSamplesLoading = true;
                draft.loadSamplesDone = false;
                draft.loadSamplesError = null;
                break;
            }
            case LOAD_SAMPLES_SUCCESS: {
                draft.samples = action.data;
                draft.loadSamplesLoading = false;
                draft.loadSamplesDone = true;
                break;
            }
            case LOAD_SAMPLES_FAILURE: {
                draft.loadSamplesLoading = false;
                draft.loadSamplesError = action.error;
                break;
            }
            case UPDATE_SAMPLE_REQUEST: {
                draft.updateSampleLoading = true;
                draft.updateSampleDone = false;
                draft.updateSampleError = null;
                break;
            }
            case UPDATE_SAMPLE_SUCCESS: {
                const sampleIndex = draft.samples.findIndex((sample) => sample.seq === action.seq);
                draft.samples[sampleIndex] = {...draft.samples[sampleIndex], ...action.data};
                draft.updateSampleLoading = false;
                draft.updateSampleDone = true;
                break;
            }
            case UPDATE_SAMPLE_FAILURE: {
                draft.updateSampleLoading = false;
                draft.updateSampleError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
