import React from 'react';
import { useSelector } from 'react-redux';

import { phoneFormat } from '../../../utils';

import MemoFormStyle from './MemoFormStyle';

const Info = () => {
    const { user } = useSelector((state) => state.user);

    return (
        <div className="uk-padding-small-horizontal uk-padding-remove-horizontal@m">
            <label className="uk-form-label uk-text-muted uk-text-0_8">이름</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <p className="uk-margin-remove">{user && user.name}</p>
            </div>

            <label className="uk-form-label uk-text-muted uk-text-0_8">휴대폰 번호</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <p className="uk-margin-remove">{user && user.phone ? phoneFormat(user.phone) : '휴대폰번호가 존재하지않습니다.'}</p>
            </div>

            <label className="uk-form-label uk-text-muted uk-text-0_8">아이디</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <p className="uk-margin-remove">{user && user.id}</p>
            </div>

            <label className="uk-form-label uk-text-muted uk-text-0_8">계정유형</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <p className="uk-margin-remove">{user && user.loginType}</p>
            </div>

            <label className="uk-form-label uk-text-muted uk-text-0_8">상태</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <p className="uk-margin-remove">{user && user.statusName}</p>
            </div>

            <label className="uk-form-label uk-text-muted uk-text-0_8">회원가입일</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <p className="uk-margin-remove">{user && user.regdate}</p>
            </div>

            <label className="uk-form-label uk-text-muted uk-text-0_8">활동</label>
            <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                <table className="uk-table uk-table-small uk-table-divider uk-text-0_8 uk-text-center uk-border uk-border-gray2">
                    <thead>
                        <tr>
                            <th className="uk-text-center uk-width-1-3">결제</th>
                            <th className="uk-text-center uk-width-1-3">구매평</th>
                            <th className="uk-text-center uk-width-1-3">문의</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="uk-table-shrink uk-text-nowrap">
                                <p className="uk-margin-remove-top uk-margin-xsmall-bottom">{user && user.orderAnalytics.total.count.toLocaleString()}건</p>
                                <p className="uk-margin-remove-vertical">({user && user.orderAnalytics.total.price.toLocaleString()}원)</p>
                            </td>
                            <td>{user && user.review.length.toLocaleString()}건</td>
                            <td>{user && user.qna.length.toLocaleString()}건</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <label className="uk-form-label uk-text-muted uk-text-0_8">관리자 메모</label>
            <div>
                <MemoFormStyle />
            </div>

        </div>
    );
};

export default Info;
