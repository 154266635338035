import React, {useEffect, useRef} from "react";
import useWindowDimensions from '../../hook/UseWindowDimensions';

const Icon = ({name="", size=22, color="inherit", mobile={}, className=""}) => {
    const iconRef = useRef();
    const { isDesktopSize } = useWindowDimensions();
    
    let style = {color:color, width:size, height:size};
    if(!isDesktopSize && Object.keys(mobile).length > 0){
        style.color = (mobile.color ? mobile.color : style.color);
        style.width = (mobile.size ? mobile.size : style.width);
        style.height = (mobile.size ? mobile.size : style.height);
    }

    useEffect(()=>{
        async function getData(name) {
            const svg = await (await fetch('https://cdn.jsdelivr.net/gh/mooncorp-icon/icon@latest/svg/'+name+'.svg')).text();
            if(iconRef.current){
                iconRef.current.innerHTML = svg.substring(svg.indexOf('<svg'));
            }
        }
        if(name != ""){
            getData(name);
        }
    }, [name]);

    return name=="" ? null : (
        <span className={`svgIconArea uk-display-inline-block ${className}`} ref={iconRef} style={style}></span>
    );
};

export default Icon;
