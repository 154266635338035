import React, {} from 'react';
import StarRatingComponent from 'react-star-rating-component';
import Icon from '../../../Widget/Icon';

const PrductDetailA = (props) => {
    const styles = {
        price : {
            color: props.data.template.basic.color.font.price
        },
        button: {
            backgroundColor: props.data.template.basic.color.background.button,
            color: props.data.template.basic.color.font.button
        }
    }

    return (
        <>
            <div className="uk-container uk-padding-large-bottom uk-padding-remove-horizontal uk-margin-medium-top@m">
                <div id="productInfo" className="uk-child-width-1-1 uk-child-width-1-2@m uk-margin-bottom" data-uk-grid>
                    <div>
                        <img src="/image/design/product_sample.jpeg" />
                    </div>
                    <div className="uk-margin-top">
                        <div className="uk-padding-small-horizontal">
                            <p className="uk-text-1 uk-margin-remove-bottom">
                                <span className="uk-text-middle uk-margin-small-right">CANARIA / 카나리아</span>
                                <span className="uk-label uk-text-0_6 uk-text-middle uk-margin-2-top" style={styles.button}>SALE</span>
                            </p>
                            <p className="uk-margin-xsmall-vertical">
                                <span className="uk-vertical-middle uk-text-1_1" style={styles.price}>23,500원</span>
                                <s className="uk-text-1_1 uk-margin-left uk-vertical-middle">39,900원</s>
                            </p>
                            <hr className="uk-margin-remove-bottom" />
                            <div className="uk-min-height-20 uk-text-0_9 uk-margin-bottom uk-margin-small-top">
                                <p>설레는 복숭아 꽃을 담아 노래하는 카나리아 향수</p>
                            </div>
                            <div>
                                <div id="optionArea">
                                    <div className="uk-padding-small-vertical uk-padding-small-horizontal">
                                        <div id="optionBoxArea">
                                            <div>
                                                <label className="uk-form-label uk-text-0_8">향 선택(필수)</label>
                                                <div className="uk-form-controls uk-text-0_9 uk-margin-bottom">
                                                    <select className="uk-select" defaultValue="1" disabled={true} >
                                                        <option value="none">향선택</option>
                                                        <option value="1">카나리아 (23,500원)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-padding-small uk-margin-remove-bottom uk-margin-small-top uk-text-0_8" data-uk-alert>
                                            <p className="uk-margin-remove">향 선택 : 카나리아</p>
                                            <hr className="uk-border-dashed uk-margin-small-top uk-margin-small-bottom" />
                                            <div className="uk-flex uk-flex-middle uk-flex-between">
                                                <div className="uk-button-group uk-child-width-expand uk-padding-remove-horizontal uk-background-white">
                                                    <button className="uk-button uk-button-default uk-button-small uk-width-40 uk-padding-remove-horizontal uk-text-0_8 uk-line-height-initial"><Icon name="minus" size={14} /></button>
                                                    <input className="uk-input uk-form-small uk-width-40 uk-height-33 uk-text-center" type="text" value="1" readOnly />
                                                    <button className="uk-button uk-button-default uk-button-small uk-width-40 uk-padding-remove-horizontal uk-text-0_8 uk-line-height-initial" id="test"><Icon name="plus" size={14} /></button>
                                                </div>
                                                <div>
                                                    <span>23,500원</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div id="totalPriceArea" className="uk-margin-top uk-flex uk-flex-middle uk-flex-between">
                                                <span>총 금액(1개)</span>
                                                <span className="uk-text-1_2" style={styles.price}>23,500원</span>
                                            </div>

                                            <div>
                                                <div className="uk-margin-top uk-button-group uk-width-1-1 uk-child-width-expand uk-height-65">
                                                    <button className="uk-button uk-button-default uk-text-1">장바구니</button>
                                                    <button className="uk-button uk-text-1"style={styles.button}>구매하기</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="bestReview" className="uk-width-1-1 uk-padding-small-horizontal uk-margin-large-bottom uk-position-relative">
                <div className="uk-text-left uk-margin-small-bottom">
                    <span className="uk-text-0_75">Photo review</span>
                </div>
                {/* PC START */}
                <div>
                    <div className="uk-text-0_8 uk-text-center">
                        <div className="uk-grid-collapse uk-background-gray uk-padding-xsmall-vertical uk-text-bold" data-uk-grid>
                            <div className="uk-width-50">사진</div>
                            <div className="uk-width-expand uk-text-truncate uk-text-nowrap">내용</div>
                            <div className="uk-width-small">평점</div>
                            <div className="uk-width-small">작성자</div>
                        </div>
                        <ul className="uk-margin-remove" data-uk-accordion="">
                            <li className="uk-margin-remove">
                                <a className="uk-accordion-title" href="#">
                                    <div className="uk-text-0_63 uk-grid-collapse uk-padding-7-vertical uk-margin-remove uk-flex-middle" data-uk-grid>
                                        <div className="uk-width-50">
                                            <Icon name={props.data.template.basic.icon.photoReview} size={13} />
                                        </div>
                                        <div className="uk-width-expand uk-text-left uk-text-truncate uk-text-nowrap">
                                        제가 진짜 복숭아 덕후라 웬만한 복숭아향수 유명한 건 다 써본 거 같은데 얜진짜애요...찐...찐입니다!향수가 알콜냄새가 너무 심하거나 인위적 향이 너무강하면 바로 갖다버리는데 얘는 알콜내도 싹날아가고 진짜 생복숭아를 짜서 만든 거 같아요 계속 제가 킁킁대게 만드는 그런 향수 이거 딱 뿌리면 십리밖 친구도 절 알아봅니다. 그만큼 향도 확산력도 지속력도 존좋!!!
                                        </div>
                                        <div className="uk-width-small">
                                            <StarRatingComponent
                                                disabled
                                                id="ReviewScore"
                                                name="ReviewScore"
                                                renderStarIcon={() => <Icon name={props.data.template.basic.icon.reviewScore} size={13} className="uk-padding-1-horizontal" />}
                                                starColor={props.data.template.basic.color.font.reviewScore}
                                                emptyStarColor={props.data.template.basic.color.font.reviewScore}
                                                starCount={5}
                                                value={5}
                                                className="reviewScore"
                                            />
                                        </div>
                                        <div className="uk-width-small">작성자</div>
                                    </div>
                                </a>
                                <div className="uk-accordion-content uk-margin-remove uk-padding-small uk-text-left">
                                    제가 진짜 복숭아 덕후라 웬만한 복숭아향수 유명한 건 다 써본 거 같은데 얜진짜애요...찐...찐입니다!향수가 알콜냄새가 너무 심하거나 인위적 향이 너무강하면 바로 갖다버리는데 얘는 알콜내도 싹날아가고 진짜 생복숭아를 짜서 만든 거 같아요 계속 제가 킁킁대게 만드는 그런 향수 이거 딱 뿌리면 십리밖 친구도 절 알아봅니다. 그만큼 향도 확산력도 지속력도 존좋!!!
                                </div>
                            </li>
                            <li className="uk-margin-remove">
                                <a className="uk-accordion-title" href="#">
                                    <div className="uk-text-0_63 uk-grid-collapse uk-padding-7-vertical uk-margin-remove uk-flex-middle" data-uk-grid>
                                        <div className="uk-width-50">
                                            <Icon name={props.data.template.basic.icon.photoReview} size={13} />
                                        </div>
                                        <div className="uk-width-expand uk-text-left uk-text-truncate uk-text-nowrap">
                                        제가 진짜 복숭아 덕후라 웬만한 복숭아향수 유명한 건 다 써본 거 같은데 얜진짜애요...찐...찐입니다!향수가 알콜냄새가 너무 심하거나 인위적 향이 너무강하면 바로 갖다버리는데 얘는 알콜내도 싹날아가고 진짜 생복숭아를 짜서 만든 거 같아요 계속 제가 킁킁대게 만드는 그런 향수 이거 딱 뿌리면 십리밖 친구도 절 알아봅니다. 그만큼 향도 확산력도 지속력도 존좋!!!
                                        </div>
                                        <div className="uk-width-small">
                                            <StarRatingComponent
                                                disabled
                                                id="ReviewScore"
                                                name="ReviewScore"
                                                renderStarIcon={() => <Icon name={props.data.template.basic.icon.reviewScore} size={13} className="uk-padding-1-horizontal" />}
                                                starColor={props.data.template.basic.color.font.reviewScore}
                                                emptyStarColor={props.data.template.basic.color.font.reviewScore}
                                                starCount={5}
                                                value={5}
                                                className="reviewScore"
                                            />
                                        </div>
                                        <div className="uk-width-small">작성자</div>
                                    </div>
                                </a>
                                <div className="uk-accordion-content uk-margin-remove uk-padding-small uk-text-left">
                                    제가 진짜 복숭아 덕후라 웬만한 복숭아향수 유명한 건 다 써본 거 같은데 얜진짜애요...찐...찐입니다!향수가 알콜냄새가 너무 심하거나 인위적 향이 너무강하면 바로 갖다버리는데 얘는 알콜내도 싹날아가고 진짜 생복숭아를 짜서 만든 거 같아요 계속 제가 킁킁대게 만드는 그런 향수 이거 딱 뿌리면 십리밖 친구도 절 알아봅니다. 그만큼 향도 확산력도 지속력도 존좋!!!
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PrductDetailA;