import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import UIKIT from 'uikit';

import { percelRegistAction, loadPercelRegists, percelRegistResetAction } from '../../../reducers/order';

const PercelRegistFormModal = () => {
    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {
        percelRegistLoading,
        percelRegistDone,
        percelRegistError,
        percelRegist,
    } = useSelector((state) => state.order);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(percelRegistAction(formData));
    },[]);

    useEffect(() => {
        if(percelRegistDone) {
            if(fileInputRef?.current?.value) {
                fileInputRef.current.value = "";
            }
            let query=`page=1&status=4&percelRegist=1`;
            dispatch(percelRegistResetAction());
            window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query}`;
            // history.push({
            //     pathname: location.pathname,
            //     search: query
            // });
        }

        if(percelRegistError) {
            alert(Object.values(percelRegistError)[0]);
        }
    }, [percelRegistDone, percelRegistError]);

    return (
        <form onSubmit={onSubmit} encType="multipart/form-data">
            <div>
                <div className="uk-padding-small uk-height-300">
                    <div className="uk-padding-xsmall-horizontal" data-uk-grid>
                        <div className="uk-width-1-1 uk-text-center">
                            <div data-uk-form-custom="target: true">
                                <input type="file" id="percelFile" name="percelFile" ref={fileInputRef} />
                                <input className="uk-input uk-form-width-medium" type="text" placeholder="파일선택" disabled />
                                <button className="uk-button uk-button-default" type="button" tabIndex="-1">파일선택</button>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <p data-uk-margin className="uk-text-center uk-margin-remove-bottom uk-margin-medium-top">
                            {
                                percelRegistLoading ? (
                                    <button type="button" className="uk-button uk-button-primary">
                                        <div data-uk-spinner></div>
                                    </button>
                                ) : (
                                    <button type="submit" className="uk-button uk-button-primary">등록</button>
                                )
                            }
                        </p>
                    </div>
                </div>
            </div>
        </form>
    )
};

export default PercelRegistFormModal;
