import React, {useEffect, useCallback, useState} from "react";


const Home = () => {
    const [showIframe, setShowIframe] = useState(true);
    const onMessageReceivedFromIframe = useCallback(
    event => {
      console.log("onMessageReceivedFromIframe : ", event.data);
      document.querySelector('#iframe').style.display = 'none';
    },
    []
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);
  return (
    <div>
        {
            showIframe && (
                <iframe id="iframe" className="uk-position-absolute uk-position-center-left uk-position-z-index-2 inipay-iframe-size" src="https://namapi.monthlycosmetics.com/pay" />
            )
        }

    </div>
  );
};

export default Home;
