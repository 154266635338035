import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { onChangeAction } from '../../../actions/product/action';

import UseOnChange from '../../../hook/UseOnChange';

import UIKit from "../../UIKit";

const ProductInfoCard = () => {
    const { me, selectedBrand } = useSelector((state) => state.user);
    const { categories, loadCategoriesDone, product } = useSelector((state) => state.product);
    const useOnChange = UseOnChange(onChangeAction);

    const [category, setCategory] = useState(null);

    useEffect(() => {
        if(loadCategoriesDone) {
            const brandSeq = selectedBrand === 'all' ? product.brand_seq : selectedBrand;
            setCategory(categories[brandSeq]);
        }

    }, [loadCategoriesDone, product]);

    return (
        <UIKit.Card
            title={"기본 정보"}
            cardOptions={"uk-card-default uk-card-style"}
            cardBodyOptions={"uk-height-300@m uk-height-250@l"}
        >
            <div data-uk-grid>
                <div className="uk-width-1-1">
                    <div data-uk-grid>
                        {
                            selectedBrand === 'all' ? (
                                <div className='uk-width-1-3'>
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="name"
                                    >
                                        브랜드
                                    </label>
                                    <div className="uk-form-controls">
                                        <select
                                            className="uk-select uk-form-small"
                                            name='brand_seq'
                                            id='brand_seq'
                                            value={product.brand_seq ? product.brand_seq : ""}
                                            onChange={useOnChange.dispatchAction}
                                        >
                                            <option key={-1} value={""}>선택</option>
                                            {
                                                me.brand && me.brand.map(data => (
                                                    <option key={data.seq} value={data.seq}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            ) :
                            (
                                <input type='hidden' id='brand_seq' name='brand_seq' value={selectedBrand} />
                            )
                        }
                        {
                            category && (
                                <div className="uk-form-controls uk-width-1-3">
                                    <label
                                        className="uk-form-label uk-text-meta"
                                        htmlFor="productCategory_seq"
                                    >
                                        카테고리
                                    </label>
                                    <div className="uk-form-controls">
                                        <select
                                            className="uk-select uk-form-small"
                                            name='productCategory_seq'
                                            id='productCategory_seq'
                                            value={product.productCategory_seq ? product.productCategory_seq : ""}
                                            onChange={useOnChange.dispatchAction}
                                        >
                                            <option key={-1} value={""}>선택</option>
                                            {
                                                category.map((category) => (
                                                    <option key={category.seq} value={category.seq}>{category.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            )
                        }

                        <div className="uk-form-controls uk-width-1-3">
                            <label
                                className="uk-form-label uk-text-meta"
                            >
                                상품상태
                            </label>
                            <div className="uk-form-controls">
                                <label className="uk-form-small uk-pointer uk-padding-remove-left">
                                    <input
                                        className="uk-radio"
                                        type="radio"
                                        name="status"
                                        value={1}
                                        checked={parseInt(product.status, 10) === 1 ? true : false}
                                        onChange={useOnChange.dispatchAction}
                                    />판매중
                                </label>
                                <label className="uk-form-small uk-pointer">
                                    <input
                                        className="uk-radio"
                                        type="radio"
                                        name="status"
                                        value={2}
                                        checked={parseInt(product.status, 10) === 2 ? true : false}
                                        onChange={useOnChange.dispatchAction}
                                    />품절
                                </label>
                                <label className="uk-form-small uk-pointer">
                                    <input
                                        className="uk-radio"
                                        type="radio"
                                        name="status"
                                        value={0}
                                        checked={parseInt(product.status, 10) === 0 ? true : false}
                                        onChange={useOnChange.dispatchAction}
                                    />비노출
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <div data-uk-grid>
                        <div className="uk-width-1-2">
                            <label
                                className="uk-form-label uk-text-meta"
                                htmlFor="name"
                            >
                                상품명
                            </label>
                            <div className="uk-form-controls">
                                <input
                                    className="uk-input uk-form-small"
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="상품명 입력"
                                    value={product.name}
                                    onChange={useOnChange.dispatchAction}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <label
                                className="uk-form-label uk-text-meta"
                                htmlFor="name2"
                            >
                                상품명(서브)
                            </label>
                            <div className="uk-form-controls">
                                <input
                                    className="uk-input uk-form-small"
                                    id="name2"
                                    name="name2"
                                    type="text"
                                    placeholder="상품명 입력"
                                    value={product.name2}
                                    onChange={useOnChange.dispatchAction}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2">
                    <div>
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="etc"
                        >
                            기타 정보(용량/크기 등)
                        </label>
                        <div className="uk-form-controls">
                            <input
                                className="uk-input uk-form-small"
                                id="etc"
                                name="etc"
                                type="text"
                                placeholder="기타 정보(용량/크기 등) 입력"
                                value={product.etc}
                                onChange={useOnChange.dispatchAction}
                            />
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1">
                    <div>
                        <label
                            className="uk-form-label uk-text-meta"
                            htmlFor="description"
                        >
                            요약 설명
                        </label>
                        <div className="uk-form-controls">
                            <textarea
                                className="uk-textarea uk-form-small"
                                id="description"
                                name="description"
                                type="text"
                                placeholder="요약 설명 입력"
                                value={(product.description) ? product.description : ""}
                                onChange={useOnChange.dispatchAction}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </UIKit.Card>
    );
};

export default ProductInfoCard;
