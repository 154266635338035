import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UIKIT from 'uikit';
import UIKit from "../../../Components/UIKit";

import { useInput } from '../../../hook';
import { useFormData } from '../../../hook';
import { updateOrderDetailAction, updateOrderDetailResetAction } from '../../../reducers/order';

const DelayProductOrderModal = ({ openDelayProductOrderModal, setOpenDelayProductOrderModal, targetDelayProductOrders }) => {
    const dispatch = useDispatch();

    const {
        updateOrderDetailDone,
        updateOrderDetailError,
        updateOrderDetail
    } = useSelector((state) => state.order);

    const location = useLocation();

    const [dispatchDelayReasonCode, setDispatchDelayReasonCode] = useState("");
    const [dispatchDelayDetailReason, setDispatchDelayDetailReason] = useState("");
    const [dispatchDueDate, setDispatchDueDate] = useState("");

    const onChangeDispatchDelayReasonCode = useCallback((e) => {
        setDispatchDelayReasonCode(e.target.value);
    }, []);

    const onChangeDispatchDelayDetailReason = useCallback((e) => {
        setDispatchDelayDetailReason(e.target.value);
    }, []);

    const onChangeDispatchDueDate = useCallback((e) => {
        setDispatchDueDate(e.dispatchDueDate);
    }, []);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        UIKIT.modal('#delayProductOrderModal').hide();
        const data = {
            data: targetDelayProductOrders,
            dispatchDelayDetailReason,
            dispatchDelayReasonCode,
            dispatchDueDate,
            status: 12
        };
        setOpenDelayProductOrderModal(false);
        dispatch(updateOrderDetailAction('admin/orders/details', data));

    }, [targetDelayProductOrders, dispatchDelayDetailReason, dispatchDelayReasonCode, dispatchDueDate]);

    useEffect(() => {
        if(openDelayProductOrderModal && targetDelayProductOrders) {
            UIKIT.modal('#delayProductOrderModal').show();

            setOpenDelayProductOrderModal(false);
        }
    }, [openDelayProductOrderModal, targetDelayProductOrders]);


    // useEffect(() => {
    //     if(updateOrderDetailDone) {
    //         alert(updateOrderDetail.msg);
    //         window.location.reload();
    //     }
    //
    //     if(updateOrderDetailError) {
    //         alert(updateOrderDetailError.msg);
    //         dispatch(updateOrderDetailResetAction());
    //     }
    // }, [updateOrderDetailDone, updateOrderDetailError]);


    return (
        <div id="delayProductOrderModal" className="uk-position-z-index-9999999" data-uk-modal="container:false;">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-body uk-padding-remove-horizontal uk-padding-horizontal@m">
                    <div className="uk-text-0_9 uk-grid-divider" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-1@m">
                            <form onSubmit={onSubmit}>
                                <div>
                                    <div className="uk-margin">
                                        <div className="uk-form-label">
                                            <span>배송지연 사유</span>
                                        </div>
                                        <div className="uk-form-controls">
                                            <select
                                                className="uk-select"
                                                id={`dispatchDelayReasonCode`}
                                                name={`dispatchDelayReasonCode`}
                                                value={dispatchDelayReasonCode}
                                                onChange={onChangeDispatchDelayReasonCode}
                                            >
                                                <option value={""}>배송지연 사유를 선택해주세요.</option>
                                                <option value={"PRODUCT_PREPARE"}>상품 준비 중</option>
                                                <option value={"CUSTOMER_REQUEST"}>고객 요청</option>
                                                <option value={"CUSTOM_BUILD"}>주문 제작</option>
                                                <option value={"RESERVED_DISPATCH"}>예약 발송</option>
                                                <option value={"ETC"}>기타</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="dispatchDelayDetailReason">배송지연 상세사유</label>
                                    <div className="uk-form-controls">
                                        <input
                                            className="uk-input"
                                            id="dispatchDelayDetailReason"
                                            type="text"
                                            placeholder="배송지연 상세사유"
                                            value={dispatchDelayDetailReason}
                                            onChange={onChangeDispatchDelayDetailReason}
                                        />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <a href="#">
                                        <input
                                            className="uk-input uk-form-small"
                                            id="dispatchDueDate"
                                            name="dispatchDueDate"
                                            type="text"
                                            readOnly
                                            value={dispatchDueDate}
                                            placeholder={"배송 예정 날짜"}
                                        />
                                    </a>
                                    <UIKit.DatePicker target={"dispatchDueDate"} onChange={onChangeDispatchDueDate} />
                                </div>

                                <p className="uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button">취소</button>
                                    <button className="uk-button uk-button-primary" type="submit">발송지연 처리</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DelayProductOrderModal;
