import produce from "immer";

export const initialState = {
    loadBrandsLoading: false,
    loadBrandsDone: false,
    loadBrandsError: null,
    brands: null,
    destroyBrandLoading: false,
    destroyBrandDone: false,
    destroyBrandError: null,
    destroyBrand: null,
    loadBrandLoading: false,
    loadBrandDone: false,
    loadBrandError: null,
    brand: null,
    updateBrandLoading: false,
    updateBrandDone: false,
    updateBrandError: null,
    updateBrand: null,
    createBrandLoading: false,
    createBrandDone: false,
    createBrandError: null,
    createBrand: null,
    naverPayTestExcelLoading: false,
    naverPayTestExcelDone: false,
    naverPayTestExcelError: null,
    naverPayTestExcel: null,
};

export const LOAD_BRANDS_REQUEST = "LOAD_BRANDS_REQUEST";
export const LOAD_BRANDS_SUCCESS = "LOAD_BRANDS_SUCCESS";
export const LOAD_BRANDS_FAILURE = "LOAD_BRANDS_FAILURE";

export const DESTROY_BRAND_REQUEST = "DESTROY_BRAND_REQUEST";
export const DESTROY_BRAND_SUCCESS = "DESTROY_BRAND_SUCCESS";
export const DESTROY_BRAND_FAILURE = "DESTROY_BRAND_FAILURE";

export const LOAD_BRAND_REQUEST = "LOAD_BRAND_REQUEST";
export const LOAD_BRAND_SUCCESS = "LOAD_BRAND_SUCCESS";
export const LOAD_BRAND_FAILURE = "LOAD_BRAND_FAILURE";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";
export const UPDATE_BRAND_RESET = "UPDATE_BRAND_RESET";

export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILURE = "CREATE_BRAND_FAILURE";

export const NAVERPAY_TEST_EXCEL_REQUEST = "NAVERPAY_TEST_EXCEL_REQUEST";
export const NAVERPAY_TEST_EXCEL_SUCCESS = "NAVERPAY_TEST_EXCEL_SUCCESS";
export const NAVERPAY_TEST_EXCEL_FAILURE = "NAVERPAY_TEST_EXCEL_FAILURE";


export const createBrandAction = (formData) => ({
    type: CREATE_BRAND_REQUEST,
    data: formData,
});

export const updateBrandAction = (brandSeq, formData) => ({
    type: UPDATE_BRAND_REQUEST,
    brandSeq,
    data: formData,
});

export const loadBrandsAction = (query) => ({
    type: LOAD_BRANDS_REQUEST,
    data: query,
});

export const destroyBrandAction = (seq) => ({
    type: DESTROY_BRAND_REQUEST,
    data: seq,
});

export const loadBrandAction = (seq) => ({
    type: LOAD_BRAND_REQUEST,
    data: seq,
});

export const updateBrandResetAction = (seq) => ({
    type: UPDATE_BRAND_RESET,
});

export const naverPayTestExcelAction = (data) => ({
    type: NAVERPAY_TEST_EXCEL_REQUEST,
    data,
});


const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case UPDATE_BRAND_RESET: {
                draft.updateBrandLoading = false;
                draft.updateBrandDone = false;
                draft.updateBrand = null;
                break;
            }
            case CREATE_BRAND_REQUEST: {
                draft.createBrandLoading = true;
                draft.createBrandDone = false;
                draft.createBrandError = null;
                break;
            }
            case CREATE_BRAND_SUCCESS: {
                draft.createBrandLoading = false;
                draft.createBrandDone = true;
                draft.createBrand = {brandSeq: action.brandSeq, data: action.data};
                break;
            }
            case CREATE_BRAND_FAILURE: {
                draft.createBrandLoading = false;
                draft.createBrandError = action.error;
                break;
            }
            case UPDATE_BRAND_REQUEST: {
                draft.updateBrandLoading = true;
                draft.updateBrandDone = false;
                draft.updateBrandError = null;
                break;
            }
            case UPDATE_BRAND_SUCCESS: {
                draft.updateBrandLoading = false;
                draft.updateBrandDone = true;
                draft.updateBrand = {brandSeq: action.brandSeq, data: action.data};
                break;
            }
            case UPDATE_BRAND_FAILURE: {
                draft.updateBrandLoading = false;
                draft.updateBrandError = action.error;
                break;
            }
            case LOAD_BRAND_REQUEST: {
                draft.loadBrandLoading = true;
                draft.loadBrandDone = false;
                draft.loadBrandError = null;
                draft.brand = null;
                break;
            }
            case LOAD_BRAND_SUCCESS: {
                draft.brand = action.data;
                draft.loadBrandLoading = false;
                draft.loadBrandDone = true;
                break;
            }
            case LOAD_BRAND_FAILURE: {
                draft.loadBrandLoading = false;
                draft.loadBrandError = action.error;
                break;
            }
            case DESTROY_BRAND_REQUEST: {
                draft.destroyBrandLoading = true;
                draft.destroyBrandDone = false;
                draft.destroyBrandError = null;
                break;
            }
            case DESTROY_BRAND_SUCCESS: {
                draft.destroyBrand = {brandSeq: action.brandSeq, data: action.data};
                draft.destroyBrandLoading = false;
                draft.destroyBrandDone = true;
                break;
            }
            case DESTROY_BRAND_FAILURE: {
                draft.destroyBrandLoading = false;
                draft.destroyBrandError = action.error;
                break;
            }
            case LOAD_BRANDS_REQUEST: {
                draft.loadBrandsLoading = true;
                draft.loadBrandsDone = false;
                draft.loadBrandsError = null;
                draft.destroyBrandDone = false;
                draft.createBrandError = null;
                draft.updateBrandError = null;
                break;
            }
            case LOAD_BRANDS_SUCCESS: {
                draft.brands = action.data;
                draft.loadBrandsLoading = false;
                draft.loadBrandsDone = true;
                break;
            }
            case LOAD_BRANDS_FAILURE: {
                draft.loadBrandsLoading = false;
                draft.loadBrandsError = action.error;
                break;
            }
            case NAVERPAY_TEST_EXCEL_REQUEST: {
                draft.naverPayTestExcelLoading = true;
                draft.naverPayTestExcelDone = false;
                draft.naverPayTestExcelError = null;
                break;
            }
            case NAVERPAY_TEST_EXCEL_SUCCESS: {
                draft.naverPayTestExcel = action.data;
                draft.naverPayTestExcelLoading = false;
                draft.naverPayTestExcelDone = true;
                break;
            }
            case NAVERPAY_TEST_EXCEL_FAILURE: {
                draft.naverPayTestExcelLoading = false;
                draft.naverPayTestExcelError = action.error;
                break;
            }
            
            default: {
                break;
            }
        }
    })
}

export default reducer;
