import React, { useEffect, useMemo } from "react";
import { useSelector , useDispatch } from 'react-redux';

import { loadMeAction } from '../../reducers/user';

import { useFormData } from '../../hook';

import { initDatas } from './common';

import UIKit from "../../Components/UIKit";
import OrderTableStyle from '../../Components/Widget/Order/TableStyle';
import SearchHead from "../../Components/Widget/SearchHead";
import SearchModal from '../../Components/Widget/SearchModal';
import SearchForm from '../../Components/Widget/SearchForm';


const List = ({ history, location }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone, loadMeError } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(loadMeAction());
    }, []);

    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all',
        deliveryStatus: 'all',
    });

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <div>
                <SearchHead modalId={"orderSearchModal"}  />
                <SearchModal modalId={"orderSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>

            </div>
            <div className="uk-margin-medium-top">
                <OrderTableStyle />
            </div>
        </div>
    )
};

export default List;
