import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../../reducers/user';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import { useFormData } from '../../../hook';

import Loader from "../../../Components/Widget/Loader";
import Header from "../../../Components/Widget/Header";
import UIKit from "../../../Components/UIKit";
import BrandTableStyle from '../../../Components/Widget/Brand/TableStyle';
import SearchHead from "../../../Components/Widget/SearchHead";
import SearchModal from '../../../Components/Widget/SearchModal';
import SearchForm from '../../../Components/Widget/SearchForm';

const initDatas = {
    useSearchComponents: {
        dateSearch: {
            isUse: true,
            title: '조회기간',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'regDate',
                    name: '등록일'
                }
            ]
        },
        informationSearch: {
            isUse: true,
            title: '브랜드 정보',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'name',
                    name: '브랜드명'
                }, {
                    value: 'domain',
                    name: '도메인'
                }, {
                    value: 'description',
                    name: '설명'
                }, {
                    value: 'keyword',
                    name: '키워드'
                }
            ]
        },
        statusSearch: {
            isUse: true,
            title: '사용여부'
        }
    }

};

const List = ({ history }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, me, loadMeDone } = useSelector((state) => state.user);
    const searchForm = useFormData({
        dateSelect: 'all',
        dateRadio: '',
        startDate: '',
        endDate: '',
        searchType: 'all',
        searchValue: '',
        status: 'all'
    });


    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    const buttons = [
        {
            buttonOptions:
                "uk-button uk-button-small uk-button-transparent uk-button-radius",
            buttonName: "등록",
            onClickAction: () => history.push("/setting/brand/new")
        },
    ];


    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"브랜드 목록"} buttons={buttons} />
            </UIKit.Div>
            <div>
                <SearchHead modalId={"brandSearchModal"}  />
                <SearchModal modalId={"brandSearchModal"} searchForm={searchForm}>
                    <SearchForm
                        searchForm={searchForm}
                        useSearchComponents={initDatas.useSearchComponents}
                    />
                </SearchModal>
                <BrandTableStyle />
            </div>
        </div>
    );
};

export default List;
