import { useSelector } from 'react-redux';
import { loadReviewsAction  } from '../reducers/review';

import ConfigData from "./ConfigData";

class Review extends ConfigData {
    data = {
        /*********** 리스트타입1 START ***********/
        list1 : {
            element: {
                itemLink: {
                    to : function(item){
                        return "/product/review/all#review"+item.seq;
                    },
                    scroll : function(index){
                        return index==0? -1000 : -115;
                    }
                },
                status: {
                    class : function (item) {
                        return item.status === 0 ? "uk-text-danger" : "uk-text-success";
                    },
                    value : function (item) {
                        return item.status === 0 ? "숨김" : "노출";
                    }
                },
                naverPayBadge: function (item) {
                    return item.payReviewId && item.payReviewId.split("_")[0] === "2";
                },
                title: function (item) {
                    return item.preview.content;
                },
                score: function (item) {
                    return item.score;
                },
                writer: function (item) {
                    return item.writer;
                },
                regdate: function (item, separator) {
                    return (separator ? " | " : "") + item.regdate;
                },
                image: function (item) {
                    return item.product && item.product.img && item.product.img[0] ? item.product.img[0] : "";
                }
            }
        }
       /*********** 리스트타입1 END ***********/
    };

    constructor() {
        super(useSelector((state) => state.review));
        this.rtn.data = this.data;
    }

    get(type, query=""){
        if(type=="list"){
            this.makeQuery(query);
            this.rtn.reducer = {obj:this.state.reviews, action:loadReviewsAction, loading:this.state.loadReviewsLoading, done:this.state.loadReviewsDone};
        }
        return this.rtn;
    }
};

export default Review;
