import React from "react";
import PropTypes from "prop-types";

const Div = ({ classOptions = "uk-width-1-1", children }) => {
    return <div className={classOptions}>{children}</div>;
};

Div.propTypes = {
    classOptions: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default Div;
