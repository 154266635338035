import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../Components/Widget/Loader";
import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";

const List = () => {

    return (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header title={"포인트 목록"} />
            </UIKit.Div>
        </div>
    );
};

export default List;
