import produce from "immer";

export const initialState = {
    loadReviewsLoading: false,
    loadReviewsDone: false,
    loadReviewsError: null,
    reviews: null,
    uploadExcelForReviewLoading: false,
    uploadExcelForReviewDone: false,
    uploadExcelForReviewError: null,
    uploadExcelForReview: null,
    createExcelConvertDataReviewLoading: false,
    createExcelConvertDataReviewDone: false,
    createExcelConvertDataReviewError: null,
    createExcelConvertDataReview: null,
    destroyReviewLoading: false,
    destroyReviewDone: false,
    destroyReviewError: null,
    destroyReview: null,
    updateReviewLoading: false,
    updateReviewDone: false,
    updateReviewError: null,
    updateReview: null,
};

export const LOAD_REVIEWS_REQUEST = "LOAD_REVIEWS_REQUEST";
export const LOAD_REVIEWS_SUCCESS = "LOAD_REVIEWS_SUCCESS";
export const LOAD_REVIEWS_FAILURE = "LOAD_REVIEWS_FAILURE";

export const UPLOAD_EXCEL_FOR_REVIEW_REQUEST = "UPLOAD_EXCEL_FOR_REVIEW_REQUEST";
export const UPLOAD_EXCEL_FOR_REVIEW_SUCCESS = "UPLOAD_EXCEL_FOR_REVIEW_SUCCESS";
export const UPLOAD_EXCEL_FOR_REVIEW_FAILURE = "UPLOAD_EXCEL_FOR_REVIEW_FAILURE";

export const CREATE_EXCEL_CONVERT_DATA_REVIEW_REQUEST = "CREATE_EXCEL_CONVERT_DATA_REVIEW_REQUEST";
export const CREATE_EXCEL_CONVERT_DATA_REVIEW_SUCCESS = "CREATE_EXCEL_CONVERT_DATA_REVIEW_SUCCESS";
export const CREATE_EXCEL_CONVERT_DATA_REVIEW_FAILURE = "CREATE_EXCEL_CONVERT_DATA_REVIEW_FAILURE";

export const DESTROY_REVIEW_REQUEST = "DESTROY_REVIEW_REQUEST";
export const DESTROY_REVIEW_SUCCESS = "DESTROY_REVIEW_SUCCESS";
export const DESTROY_REVIEW_FAILURE = "DESTROY_REVIEW_FAILURE";

export const UPDATE_REVIEW_REQUEST = "UPDATE_REVIEW_REQUEST";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";
export const UPDATE_REVIEW_FAILURE = "UPDATE_REVIEW_FAILURE";
export const UPDATE_REVIEW_RESET = "UPDATE_REVIEW_RESET";



export const loadReviewsAction = (query) => ({
    type: LOAD_REVIEWS_REQUEST,
    data: query,
});

export const uploadExcelForReviewAction = (data) => ({
    type: UPLOAD_EXCEL_FOR_REVIEW_REQUEST,
    data,
});

export const createExcelConvertDataAction = (data) => ({
    type: CREATE_EXCEL_CONVERT_DATA_REVIEW_REQUEST,
    data,
});

export const destroyReviewAction = (reviewSeq) => ({
    type: DESTROY_REVIEW_REQUEST,
    data: reviewSeq
});

export const updateReviewAction = (reviewSeq, data) => ({
    type: UPDATE_REVIEW_REQUEST,
    reviewSeq,
    data
});

export const updateReviewResetAction = () => ({
    type: UPDATE_REVIEW_RESET,
});

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case UPDATE_REVIEW_RESET: {
                draft.updateReviewLoading = false;
                draft.updateReviewDone = false;
                draft.updateReviewError = null;
                draft.updateReview = null;
                break;
            }
            case UPDATE_REVIEW_REQUEST: {
                draft.updateReviewLoading = true;
                draft.updateReviewDone = false;
                draft.updateReviewError = null;
                break;
            }
            case UPDATE_REVIEW_SUCCESS: {
                draft.updateReview = action.data;
                draft.updateReviewLoading = false;
                draft.updateReviewDone = true;
                break;
            }
            case UPDATE_REVIEW_FAILURE: {
                draft.updateReviewLoading = false;
                draft.updateReviewError = action.error;
                break;
            }
            case DESTROY_REVIEW_REQUEST: {
                draft.destroyReviewLoading = true;
                draft.destroyReviewDone = false;
                draft.destroyReviewError = null;
                break;
            }
            case DESTROY_REVIEW_SUCCESS: {
                draft.destroyReview = {reviewSeq: action.reviewSeq, data: action.data};
                draft.destroyReviewLoading = false;
                draft.destroyReviewDone = true;
                break;
            }
            case DESTROY_REVIEW_FAILURE: {
                draft.destroyReviewLoading = false;
                draft.destroyReviewError = action.error;
                break;
            }
            case CREATE_EXCEL_CONVERT_DATA_REVIEW_REQUEST: {
                draft.createExcelConvertDataReviewLoading = true;
                draft.createExcelConvertDataReviewDone = false;
                draft.createExcelConvertDataReviewError = null;
                break;
            }
            case CREATE_EXCEL_CONVERT_DATA_REVIEW_SUCCESS: {
                draft.createExcelConvertDataReview = action.data;
                draft.createExcelConvertDataReviewLoading = false;
                draft.createExcelConvertDataReviewDone = true;
                break;
            }
            case CREATE_EXCEL_CONVERT_DATA_REVIEW_FAILURE: {
                draft.createExcelConvertDataReviewLoading = false;
                draft.createExcelConvertDataReviewError = action.error;
                break;
            }
            case UPLOAD_EXCEL_FOR_REVIEW_REQUEST: {
                draft.uploadExcelForReviewLoading = true;
                draft.uploadExcelForReviewDone = false;
                draft.uploadExcelForReviewError = null;
                break;
            }
            case UPLOAD_EXCEL_FOR_REVIEW_SUCCESS: {
                draft.uploadExcelForReview = action.data;
                draft.uploadExcelForReviewLoading = false;
                draft.uploadExcelForReviewDone = true;
                break;
            }
            case UPLOAD_EXCEL_FOR_REVIEW_FAILURE: {
                draft.uploadExcelForReviewLoading = false;
                draft.uploadExcelForReviewError = action.error;
                break;
            }
            case LOAD_REVIEWS_REQUEST: {
                draft.loadReviewsLoading = true;
                draft.loadReviewsDone = false;
                draft.loadReviewsError = null;
                break;
            }
            case LOAD_REVIEWS_SUCCESS: {
                draft.reviews = action.data;
                draft.loadReviewsLoading = false;
                draft.loadReviewsDone = true;
                break;
            }
            case LOAD_REVIEWS_FAILURE: {
                draft.loadReviewsLoading = false;
                draft.loadReviewsError = action.error;
                break;
            }
            default: {
                break;
            }
        }
    })
}

export default reducer;
