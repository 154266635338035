import {
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_RESET,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_RESET,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_SUCCESS,
    LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_FAILURE,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST,
    UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_RESET,
} from './type';

export const managerChangeOrderDetailStatusAction = (orderDetailSeq, data) => ({
    type: UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_REQUEST,
    orderDetailSeq,
    data,
});

export const managerChangeOrderDetailStatusResetAction = () => ({
    type: UPDATE_MANAGER_CHANGE_ORDER_DETAIL_STATUS_RESET,
});

export const managerChangeOrderBasedStatusAction = (orderSeq, data) => ({
    type: UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_REQUEST,
    orderSeq,
    data,
});

export const managerChangeOrderBasedStatusResetAction = () => ({
    type: UPDATE_MANAGER_CHANGE_ORDER_BASED_STATUS_RESET,
});

export const loadGetOrderDetailCountByStatusAction = (division) => ({
    type: LOAD_GET_ORDER_DETAIL_COUNT_BY_STATUS_REQUEST,
    division
});

export const managerChangeOrderForceCancelAction = (orderSeq) => ({
  type: UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_REQUEST,
  orderSeq,
});

export const managerChangeOrderForceCancelResetAction = () => ({
  type: UPDATE_MANAGER_CHANGE_ORDER_FORCE_CANCEL_RESET,
});
