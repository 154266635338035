import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { removeImageAction, onChangeImageAction } from '../../../actions/product/action';

import { readFileAsync } from '../../../common';
import ImageDnd from "./ImageDnd";
import UIKit from "../../UIKit";

const ImageCard = (props) => {
    const dispatch = useDispatch();
    const { product } = useSelector((state) => state.product);

    const onClickRemoveImage = useCallback((division, index) => {
        const data = [...props.imgArray];
        const removeData = data.splice(index, 1);
        props.setImgArray(data);

        dispatch(removeImageAction(division, index));
    }, [props.imgArray]);

    const onChangeImage = useCallback(async(e) => {
        let files = e.target.files;
        for(let file of files) {
            const imageFile = file;
            const result = await readFileAsync(imageFile);

            const newData = {
                id : "preview-"+props.imgArray.length,
                src : result,
                fileData : imageFile,
                division : "preview"
            };
            props.setImgArray([...props.imgArray, newData]);

            dispatch(onChangeImageAction(imageFile));
        }
    }, [props.imgArray]);

	useEffect(() => {
		const result = product.img.map((data,index) => ({
			id : "Image-"+index,
			src : data,
			division : "image"
		}));

        const allStrings = product.img.every(data => typeof data === 'string');
        if (allStrings) {
            props.setImgArray(result);
        }
    },[]);

    return (
        <UIKit.Card
            title={"이미지"}
            cardOptions={"uk-card-default uk-card-style"}
            cardBodyOptions={"uk-position-relative uk-overflow-auto uk-height-300@m uk-height-250@l uk-min-height-140 uk-max-height-300 uk-padding-15"}
            isImageCard={true}
            onChangeImage={onChangeImage}
        >
			<ImageDnd imgArray={props.imgArray} setImgArray={props.setImgArray} onClickRemoveImage={onClickRemoveImage}/>
            {
                product.previewImages && product.previewImages.length == 0 && product.img && product.img.length==0 ?
                    (<div className="uk-width-1-1 uk-position-center">이미지를 선택해 주세요</div>)
                    :
                    ("")
            }
        </UIKit.Card>
    );
};


export default ImageCard;
