import Qna from "./Qna";
import Review from "./Review";
import Analytics from "./Analytics";
import Analytics2 from "./Analytics2";

export default {
    Analytics,
    Analytics2,
    Qna,
    Review
};
