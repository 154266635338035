import React, {} from 'react';
import _ from 'lodash';

const FontSetting = (props) => {
    const fontAllData = {
		"korAndChar":[
            {"engName":"GowunBatang","korName":"고운 바탕"},
			{"engName":"NanumBarunGothic","korName":"나눔 바른고딕"},
            {"engName":"NanumSquareRound","korName":"나눔 스퀘어 라운드"},
			{"engName":"SpoqaHanSans","korName":"스포카 한 산스"},
			{"engName":"IropkeBatang","korName":"이롭게 바탕"},
			{"engName":"JejuMyeongjo","korName":"제주 명조"},
			{"engName":"JejuGothic","korName":"제주 고딕"},
			{"engName":"HangeulNuri","korName":"한글 누리"},
			{"engName":"SCoreDream","korName":"에스코어드림"},
			{"engName":"NotoSans"},
			{"engName":"NotoSerif"},
			{"engName":"Pretendard"},
		],
		"engAndNum":[
			{"engName":"Alegreya"},
			{"engName":"BarlowSemiCondensed"},
			{"engName":"BebasNeue"},
			{"engName":"Cinzel"},
			{"engName":"CormorantGaramond"},
			{"engName":"EncodeSans"},
			{"engName":"Hind"},
			{"engName":"LexendPeta"},
			{"engName":"Oswald"},
			{"engName":"PlayfairDisplay"},
			{"engName":"Quicksand"},
			{"engName":"Raleway"},
			{"engName":"Roboto"},
			{"engName":"SpaceMono"},
			{"engName":"TexGyreCursor"},
            {"engName":"EBGaramond"},
            {"engName":"LibreBaskerville"},
            {"engName":"WorkSans"},
        ]
	};
    fontAllData.engAndNum = _.sortBy(_.union(fontAllData.korAndChar, fontAllData.engAndNum), ['korName', 'engName']);

    return (
        <div className="uk-margin-medium-bottom">
            <div className="uk-position-relative uk-margin-small-bottom">
                <h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">폰트</span></h4>
            </div>
            <div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>한글</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                        <select
                            className={`uk-select font-${props.data.value.font.ko}`}
                            name="font.ko"
                            onChange={props.data.onChange}
                            value={props.data.value.font.ko}
                        >
                            <option key={-1} value={""}>선택</option>
                            {
                                fontAllData["korAndChar"].map((data, index) => (
                                    <option className={`font-${data.engName}`} key={index} value={data.engName}>{(data.korName) ? data.korName : data.engName}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>영문</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                        <select
                            className={`uk-select font-${props.data.value.font.en}`}
                            name="font.en"
                            onChange={props.data.onChange}
                            value={props.data.value.font.en}
                        >
                            <option key={-1} value={""}>선택</option>
                            {
                                fontAllData["engAndNum"].map((data, index) => (
                                    <option className={`font-${data.engName}`} key={index} value={data.engName}>{(data.korName) ? data.korName : data.engName}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>숫자</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                        <select
                            className={`uk-select font-${props.data.value.font.num}`}
                            name="font.num"
                            onChange={props.data.onChange}
                            value={props.data.value.font.num}
                        >
                            <option key={-1} value={""}>선택</option>
                            {
                                fontAllData["engAndNum"].map((data, index) => (
                                    <option className={`font-${data.engName}`} key={index} value={data.engName}>{(data.korName) ? data.korName : data.engName}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="settingItem uk-grid-collapse uk-flex-middle" data-uk-grid>
                    <div className='uk-width-100 uk-text-0_9'>
                        <span>특수문자</span>
                    </div>
                    <div className="uk-width-expand uk-form-controls">
                        <select
                            className={`uk-select font-${props.data.value.font.char}`}
                            name="font.char"
                            onChange={props.data.onChange}
                            value={props.data.value.font.char}
                        >
                            <option key={-1} value={""}>선택</option>
                            {
                                fontAllData["korAndChar"].map((data, index) => (
                                    <option className={`font-${data.engName}`} key={index} value={data.engName}>{(data.korName) ? data.korName : data.engName}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FontSetting;