import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import StarRatingComponent from 'react-star-rating-component';
import PropTypes from 'prop-types';

import { updateReviewAction, updateReviewResetAction } from '../../../reducers/review';
import { loadUserAction } from '../../../reducers/user';

import Editor from '../Editor';
import OrderModal from '../Order/OrderModal';

const CardStyle = ({ review, checkboxForm, onClickOpenUserModal }) => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);

    const [content, setContent] = useState(review.content);
    const [isUpdate, setIsUpdate] = useState(false);
    const [openOrderModal, setOpenOrderModal] = useState(false);

	const [status, setStatus] = useState(review.status);

    const onClickStatusChange = useCallback((status) => {
        const formData = new FormData();
        formData.append('status', status);
        formData.append("_method", "PUT");
        dispatch(updateReviewAction(review.seq, formData));
		setStatus(status);
    }, [review]);

    const onSubmitContent = useCallback(() => {
        const formData = new FormData();
        formData.append("_method", "PUT");
        formData.append('content', content);
        dispatch(updateReviewAction(review.seq, formData));
    }, [content]);

    const onClickHideEditMode = useCallback(() => {
        setIsUpdate(!isUpdate);
    }, [isUpdate]);

    const onClickOpenOrderModal = useCallback(() => {
        setOpenOrderModal(true);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateReviewResetAction());
        }
    }, []);

    return (
        <div id={"review"+review.seq} className={`reviewCard uk-card uk-card-default uk-card-small uk-margin-top`}>
            <div className="uk-card-header uk-padding-17-left uk-grid-collapse" data-uk-grid>
                <div className="uk-width-auto">
                    {
                        checkboxForm &&
                        checkboxForm.value &&
                        checkboxForm.value[review.seq] && (
                            <input
                                type="checkbox"
                                className="uk-checkbox"
                                {...checkboxForm.value[review.seq]}
                                onChange={() => checkboxForm.onChangeCheckbox(review.seq)}
                            />
                        )
                    }
                </div>
                <div className="uk-width-expand uk-margin-small-left">
                    <p className="uk-margin-remove">
                        <span className={`uk-text-0_9 uk-display-inline-block uk-text-top uk-margin-1-top ${status==0 ? "uk-text-danger" : "uk-text-success" }`}>[{status==0 ? "숨김" : "노출"}]</span>
                        {
                            review.payReviewId && review.payReviewId.split("_")[0]==2 && (
                                <img src="/image/naver_simple.png" className="uk-object-cover uk-width-13 uk-height-auto uk-margin-xsmall-left uk-text-top uk-margin-xsmall-top" />
                            )
                        }
                        <span className="uk-text-middle uk-text-0_6 uk-margin-xsmall-left">
                            <StarRatingComponent
                                disabled
                                id="ReviewScore"
                                name="ReviewScore"
                                starCount={5}
                                value={review.score}
                                renderStarIcon={() => <span className="mdi mdi-heart"></span>}
                                starColor={"#ff2e55"}
                            />
                        </span>
                        <span className="uk-text-top uk-margin-small-left uk-display-inline-block uk-text-0_8 uk-margin-2-top uk-text-primary">
                        {
                            status === 1 ? (
                                <span className="uk-link-reset uk-pointer" onClick={() => onClickStatusChange(0)}>숨김</span>
                            ) : (
                                <span className="uk-link-reset uk-pointer" onClick={() => onClickStatusChange(1)}>숨김 해제</span>
                            )
                        }
                        </span>
                    </p>
                    <p className="uk-margin-xsmall-top">
                        <img src={review.product && review.product.img && review.product.img[0] ? review.product.img[0] : ""} className="uk-object-cover uk-width-50 uk-height-50 uk-margin-xsmall-right" />
                        <p className="uk-display-inline-block uk-text-top uk-margin-remove">
                            <p className="uk-text-0_8 uk-margin-remove">{review.product && review.product.name}</p>
                            <p className="uk-text-0_8 uk-margin-small-top uk-margin-remove-bottom">
                                <span className={"uk-pointer"} onClick={() => review.user_seq ? onClickOpenUserModal(review.user_seq) : onClickOpenOrderModal()}>
                                    {review.writer}
                                </span> | {review.regdate}
                            </p>
                        </p>
                    </p>
                </div>
            </div>
            <div className={`uk-card-body uk-padding-50-left uk-text-0_9 ${status == 0 ? "uk-opacity-0-8" : ""}`}>
                {
                    isUpdate ? (
                        <div className={`uk-width-1-1`}>
                            <div id={`editor_${review.seq}`}>
                                <Editor
                                    name="content"
                                    value={content}
                                    onChange={(newContent) => setContent(newContent)}
                                />
                            </div>
                            <button
                                className="uk-button uk-button-default uk-button-primary uk-button-small uk-margin-small-top"
                                onClick={onSubmitContent}
                            >
                                수정완료
                            </button>
                            <button
                                className="uk-button uk-button-default uk-button-small uk-margin-small-top uk-margin-small-left"
                                onClick={onClickHideEditMode}
                            >
                                취소
                            </button>
                        </div>
                    ) : (
                        <div>
                            {ReactHtmlParser(review.content ? review.content : "")}
                        </div>
                    )
                }

                <div className={review.isUpdate === 1 ? "uk-hidden" : "uk-margin-medium-top"}>
                    <button className="uk-button uk-button-default uk-button-small" onClick={onClickHideEditMode}>수정</button>
                </div>
            </div>
            <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} orderSeq={review.order_seq} model={"구매평"} />
        </div>
    )
};

CardStyle.propTypes = {
    review: PropTypes.object.isRequired,
    checkboxForm: PropTypes.object.isRequired,
};

export default CardStyle;
