import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../../reducers/user';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';
import { updateCategoriesAction, updateCategoriesResetAction } from '../../../actions/product/action';

import UseFormForCategory from '../../../hook/UseFormForCategory';

import Header from "../../../Components/Widget/Header";
import UIKit from "../../../Components/UIKit";
import CategoryForm from '../../../Components/Widget/Product/CategoryForm';

const Edit = ({ match }) => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone, me, loadMeError } = useSelector((state) => state.user);
    const { updateCategoriesDone, updateCategories, updateCategoriesError } = useSelector((state) => state.product);
    const [paramId, setParamId] = useState("");
    const formDatas = UseFormForCategory({
        brandAndCategories : {},
        selectedCategory: {},
        selectedBrand: -1
    });

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    const onSubmit = useCallback((e) => {
            e.preventDefault();
            for(let brandCategoriesKey of Object.keys(formDatas.value.brandAndCategories)) {
                for(let category of formDatas.value.brandAndCategories[brandCategoriesKey]) {
                    if(category.name.trim() === "") {
                        alert("공백은 넣을수 없습니다.");
                        return;
                    }
                }
                const sortable = document.getElementById(`categorySort_${brandCategoriesKey}`);
                let i = 1;
                for(let childNode of sortable.childNodes) {
                    for(let category of formDatas.value.brandAndCategories[brandCategoriesKey]) {
                        if(category.seq === parseInt(childNode.dataset.categorySeq, 10)) {
                            category.order = i;
                        }
                    }
                    i++;

                }
            }
            const sendData = {
                data: JSON.stringify(formDatas.value.brandAndCategories)
            }
            dispatch(updateCategoriesAction(sendData));
        }, [formDatas]);

        useEffect(() => {
            if(updateCategoriesDone) {
                alert(updateCategories.msg);
            }

            if(updateCategoriesError) {
                if(Object.keys(updateCategoriesError)[0]) {
                    Object.keys(updateCategoriesError)[0].focus();
                }
                if(updateCategoriesError.msg) {
                    alert(updateCategoriesError.msg);
                } else {
                    alert(updateCategoriesError.message);
                }
            }
        }, [updateCategoriesDone, updateCategoriesError]);

    const buttons = [
            {
                buttonOptions:
                    "uk-button uk-button-small uk-button-transparent uk-button-radius uk-margin-small-right",
                buttonName: '저장',
                onClickAction: onSubmit
            }
        ];

    useEffect(() => {
        return () => {
            dispatch(updateCategoriesResetAction());
        }
    }, []);

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <Header
                    title={'상품분류 설정'}
                    buttons={buttons}
                />
            </UIKit.Div>
            <Fragment>
                <div className='uk-width-1-1' data-uk-grid>
                    <div className='uk-width-1-1'>
                        <div data-uk-grid>
                            <CategoryForm formDatas={formDatas} />
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    );
};

export default Edit;
