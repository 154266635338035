import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_CATEGORIES_REQUEST,
    LOAD_CATEGORIES_SUCCESS,
    LOAD_CATEGORIES_FAILURE,
    UPDATE_CATEGORIES_REQUEST,
    UPDATE_CATEGORIES_SUCCESS,
    UPDATE_CATEGORIES_FAILURE,
    LOAD_PRODUCTS_REQUEST,
    LOAD_PRODUCTS_SUCCESS,
    LOAD_PRODUCTS_FAILURE,
    LOAD_PRODUCT_REQUEST,
    LOAD_PRODUCT_SUCCESS,
    LOAD_PRODUCT_FAILURE,
    DESTROY_PRODUCT_REQUEST,
    DESTROY_PRODUCT_SUCCESS,
    DESTROY_PRODUCT_FAILURE,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    LOAD_SKUS_REQUEST,
    LOAD_SKUS_SUCCESS,
    LOAD_SKUS_FAILURE,
    LOAD_ALL_SKUS_REQUEST,
    LOAD_ALL_SKUS_SUCCESS,
    LOAD_ALL_SKUS_FAILURE,
    CREATE_SKU_SUCCESS,
    UPDATE_SKU_REQUEST,
    UPDATE_SKU_SUCCESS,
    UPDATE_SKU_FAILURE,
    LOAD_PRODUCT_INFO_REQUEST,
    LOAD_PRODUCT_INFO_SUCCESS,
    LOAD_PRODUCT_INFO_FAILURE,
    LOAD_PRODUCT_INFOS_REQUEST,
    LOAD_PRODUCT_INFOS_SUCCESS,
    LOAD_PRODUCT_INFOS_FAILURE,
    LOAD_PRODUCT_INFO_SAVES_REQUEST,
    LOAD_PRODUCT_INFO_SAVES_SUCCESS,
    LOAD_PRODUCT_INFO_SAVES_FAILURE,
    CHANGE_PRODUCT_INFO_ALL,
    CREATE_PRODUCT_INFO_SAVES_REQUEST,
    CREATE_PRODUCT_INFO_SAVES_SUCCESS,
    CREATE_PRODUCT_INFO_SAVES_FAILURE,
} from '../actions/product/type';

const getMe = state => {
    return state.user.me;
}

function loadCategoriesAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get("productCategories", {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadCategories(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadCategoriesAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_CATEGORIES_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_CATEGORIES_FAILURE,
      error: e.response.data,
    });
  }
}

function updateCategoriesAPI(user, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post("productCategories/updateAll", data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateCategories(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(updateCategoriesAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: UPDATE_CATEGORIES_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_CATEGORIES_FAILURE,
      error: e.response.data,
    });
  }
}

function loadProductsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  let endPoint = "";
  if(query) {
      endPoint = `/products${query}`
  } else {
      endPoint = "/products";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadProducts(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadProductsAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: LOAD_PRODUCTS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PRODUCTS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadProductAPI(user, productSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`/products/${productSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadProduct(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadProductAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: LOAD_PRODUCT_SUCCESS,
      data: result.data,
    });

    if(result.data.productInfo && result.data.productInfo.code) {
        yield put({
            type: LOAD_PRODUCT_INFOS_REQUEST,
            code: result.data.productInfo.code,
            isServerInit: true,
        });
    }

  } catch (e) {
    // loginAPI 실패
    console.log(e);
    yield put({
      type: LOAD_PRODUCT_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyProductAPI(user, productSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }


  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.delete(`/products/${productSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyProduct(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(destroyProductAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: DESTROY_PRODUCT_SUCCESS,
      data: result.data,
      productSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_PRODUCT_FAILURE,
      error: e.response.data,
    });
  }
}

function updateProductAPI(user, productSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/products/${productSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateProduct(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateProductAPI, user, action.productSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_PRODUCT_SUCCESS,
      data: result.data,
      productSeq: action.productSeq,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_PRODUCT_FAILURE,
      error: e.response.data,
    });
  }
}

function createProductAPI(user, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/products', formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createProduct(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(createProductAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: CREATE_PRODUCT_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_PRODUCT_FAILURE,
      error: e.response.data,
    });
  }
}

function loadSkusAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  let endPoint = "";
  if(query) {
      endPoint = `/skus?${query}`
  } else {
      endPoint = "/skus";
  }

  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadSkus(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadSkusAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_SKUS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_SKUS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadAllSkusAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  let endPoint = "";
  if(query) {
      endPoint = `/skus/selectAll?${query}`
  } else {
      endPoint = "/skus";
  }

  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadAllSkus(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(loadAllSkusAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_ALL_SKUS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_ALL_SKUS_FAILURE,
      error: e.response.data,
    });
  }
}

function updateSkuAPI(user, skuSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  return axios.post(`/skus/${skuSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        // Brand: brand,
    }
  });
}

function* updateSku(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateSkuAPI, user, action.skuSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: result.data.type == "insert" ? CREATE_SKU_SUCCESS : UPDATE_SKU_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_SKU_FAILURE,
      error: e.response.data,
    });
  }
}

function loadProductInfoAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`products/productInfo?${query}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadProductInfo(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadProductInfoAPI, user, action.query);
    yield put({
      // put은 dispatch 동일
      type: LOAD_PRODUCT_INFO_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PRODUCT_INFO_FAILURE,
      error: e.response.data,
    });
  }
}

function loadProductInfosAPI(user, code) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`productInfos/${code}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadProductInfos(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadProductInfosAPI, user, action.code);
    yield put({
      // put은 dispatch 동일
      type: LOAD_PRODUCT_INFOS_SUCCESS,
      data: result.data,
      code: action.code,
      isServerInit: action.isServerInit ? true : false,
    });

    if(action.productInfoSaveIndex !== undefined && action.productInfoSaveIndex !== -1) {
        yield put({
            type: CHANGE_PRODUCT_INFO_ALL,
            productInfoSaveIndex: action.productInfoSaveIndex,
        });
    }
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PRODUCT_INFOS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadProductInfoSavesAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get(`productInfoSaves?${query}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadProductInfoSaves(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadProductInfoSavesAPI, user, action.query);
    yield put({
      // put은 dispatch 동일
      type: LOAD_PRODUCT_INFO_SAVES_SUCCESS,
      data: result.data,
      code: action.code,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_PRODUCT_INFO_SAVES_FAILURE,
      error: e.response.data,
    });
  }
}

function createProductInfoSavesAPI(user, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post (`productInfoSaves`, data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createProductInfoSaves(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(createProductInfoSavesAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: CREATE_PRODUCT_INFO_SAVES_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_PRODUCT_INFO_SAVES_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadCategories() {
  yield takeEvery(LOAD_CATEGORIES_REQUEST, loadCategories);
}

function* watchUpdateCategories() {
  yield takeEvery(UPDATE_CATEGORIES_REQUEST, updateCategories);
}

function* watchLoadProducts() {
  yield takeEvery(LOAD_PRODUCTS_REQUEST, loadProducts);
}

function* watchLoadProduct() {
  yield takeLatest(LOAD_PRODUCT_REQUEST, loadProduct);
}

function* watchDestroyProduct() {
  yield takeLatest(DESTROY_PRODUCT_REQUEST, destroyProduct);
}

function* watchUpdateProduct() {
  yield takeLatest(UPDATE_PRODUCT_REQUEST, updateProduct);
}

function* watchCreateProduct() {
  yield takeLatest(CREATE_PRODUCT_REQUEST, createProduct);
}

function* watchUpdateSku() {
  yield takeLatest(UPDATE_SKU_REQUEST, updateSku);
}

function* watchLoadAllSkus() {
  yield takeEvery(LOAD_ALL_SKUS_REQUEST, loadAllSkus);
}

function* watchLoadSkus() {
  yield takeEvery(LOAD_SKUS_REQUEST, loadSkus);
}

function* watchLoadProductInfo() {
  yield takeEvery(LOAD_PRODUCT_INFO_REQUEST, loadProductInfo);
}

function* watchLoadProductInfos() {
  yield takeEvery(LOAD_PRODUCT_INFOS_REQUEST, loadProductInfos);
}

function* watchLoadProductInfoSaves() {
  yield takeEvery(LOAD_PRODUCT_INFO_SAVES_REQUEST, loadProductInfoSaves);
}

function* watchCreateProductInfoSaves() {
  yield takeEvery(CREATE_PRODUCT_INFO_SAVES_REQUEST, createProductInfoSaves);
}

export default function* productSaga() {
  yield all([
      fork(watchLoadCategories),
      fork(watchUpdateCategories),
      fork(watchLoadProducts),
      fork(watchLoadProduct),
      fork(watchDestroyProduct),
      fork(watchUpdateProduct),
      fork(watchCreateProduct),
      fork(watchUpdateSku),
      fork(watchLoadAllSkus),
      fork(watchLoadSkus),
      fork(watchLoadProductInfo),
      fork(watchLoadProductInfos),
      fork(watchLoadProductInfoSaves),
      fork(watchCreateProductInfoSaves),
  ]);
}
