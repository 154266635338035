export const initDatas = {
    useSearchComponents: {
        dateSearch: {
            isUse: true,
            title: '조회기간',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'regDate',
                    name: '등록일'
                }
            ]
        },
        informationSearch: {
            isUse: true,
            title: '주문 정보',
            options: [
                {
                    value: 'all',
                    name: '전체'
                }, {
                    value: 'seq',
                    name: '주문번호'
                }, {
                    value: 'buyer->name',
                    name: '구매자명'
                }, {
                    value: 'buyer->email',
                    name: '구매자 이메일',
                }, {
                    value: 'buyer->phone',
                    name: '구매자 휴대폰 번호'
                }, {
                    value: 'delivery->name',
                    name: '수취인명'
                },{
                    value: 'delivery->phone',
                    name: '수취인 휴대폰 번호'
                }, {
                    value: 'invoice',
                    name: '송장번호',
                }, {
                    value: 'memo',
                    name: '메모',
                }
            ]
        },
        statusSearch: {
            isUse: false,
            title: '사용여부'
        },
        starScoreSearch: {
            isUse: false,
            title: '별'
        },
        deliveryStatusSearch: {
            isUse: true,
            title: '배송상태',
        }
    }
};
