import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { loadMeAction } from '../../reducers/user';
import { loadDesignAction, updateDesignAction } from '../../actions/design/action';

import UseFormForDesign from '../../hook/UseFormForDesign';

import UIKit from "../../Components/UIKit";
import Loader from "../../Components/Widget/Loader"
import FontSetting from "../../Components/Widget/Design/FontSetting.js";
import BasicSetting from "../../Components/Widget/Design/BasicSetting.js";
import TextSetting from "../../Components/Widget/Design/TextSetting.js";
import IconSetting from "../../Components/Widget/Design/IconSetting.js";
import HeaderSetting from "../../Components/Widget/Design/HeaderSetting.js";
import ProductSetting from "../../Components/Widget/Design/ProductSetting.js";
import PreviewSetting from "../../Components/Widget/Design/PreviewSetting.js";

const Design = () => {
	const {
		design,
		brand,
		loadDesignDone,
		loadDesignLoading,
		updateDesign,
        updateDesignDone,
        updateDesignError,
	} = useSelector((state) => state.design);
	const { me, loadMeLoading, loadMeDone } = useSelector((state) => state.user);
	
	const dispatch = useDispatch();
	const formRef = useRef();

	const [selectedBrand, setSelectedBrand] = useState("all");

	const formDatas = UseFormForDesign({
		font: {
			en: '',
			ko: '',
			num: '',
			char: '',
		},
		template: {
			basic: {
        text: {
          qna: '',
          cart: '',
          review: '',
          purchase: '',
          bestReview: '',
          reviewScore: '',
          productDetail: ''
        },
        color: {
          font: {
            price: '',
            button: '',
            reviewScore: ''
          },
          background: {
            button: ''
          },
        },
				icon:{
					cart:'',
					login:'',
					logout:'',
					mupage:'',
					mnueBar:'',
					qnaSecreat:'',
					photoReview:'',
					reviewScore:'',
				},
				option:{
					kakaoChat: {
						status: null,
						color:'',
						backgroundColor:'',
					},
				}
			},
			header: {
				type: '',
				desktopExpand: null,
				mobileMenuType: '',
				page: [
					{
						href:'',
						name:'',
						type:''
					},
				],
				sticky: {
					mobile: {
						default:'',
						productDetail:''
					},
					desktop: {
						default:'',
						productDetail:''
					}
				},
				feature: {
					cart: {
						name:'',
						display:{
							mobile:'',
							desktop:''
						}
					},
					login: {
						name:'',
						display:{
							mobile:'',
							desktop:''
						}
					},
					logout: {
						name:'',
						display:{
							mobile:'',
							desktop:''
						}
					},
					mypage: {
						name:'',
						display:{
							mobile:'',
							desktop:''
						}
					},
				},
				logoSize: {
					mobile:0,
					desktop:0
				},
				position: {
					mobile: {
						logo:'',
						feature:'',
						mobileMenu:''
					},
					desktop: {
						logo:'',
						page:'',
						feature:'',
						mobileMenu:''
					}
				},
				overlapping:{
					mobile:null,
					desktop:null
				},
        color:{
          font: {
            sticky:'',
            default:'',
            mobileMenu:'',
            overlapping:''
          },
          background: {
            sticky:'',
            default:'',
            mobileMenu:'',
          },
          logo: {
            sticky:{
              value: '',
              status: null
            },
            default:{
              value: '',
              status: null
            },
            mobileMenu:{
              value: '',
              status: null
            },
            overlapping:{
              value: '',
              status: null
            }
          }
        },
				verticalPadding:{
					mobile:0,
					desktop:0
				},
			},
			productList:{
				type:'',
				numberOfItem:{
					mobile:0,
					desktop:0
				}
			},
			productDetail:{
				type:''
			}
		},
	});
	const [errorMessage, setErrorMessage] = useState("");

	const beforeunload = (e) => {
		const dialogText = '페이지 이동';
		e.returnValue = dialogText;
		return dialogText;
	};

	useEffect(() => {
		if(selectedBrand === 'all') {
			window.onbeforeunload = null;
			return;
		}else{
			window.onbeforeunload = beforeunload;
		};
		dispatch(loadDesignAction(selectedBrand));
		window.scrollTo(0, 0);
	}, [selectedBrand]);

	useEffect(() => {
		if(loadDesignDone) {
			formDatas.setInitValue(design);
		}
	}, [loadDesignDone]);

	useEffect(() => {
		dispatch(loadMeAction());

		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
        if(updateDesignDone) {
            alert(updateDesign.msg);
        }
    }, [updateDesignDone]);

	useEffect(() => {
        if(updateDesignError) {
            setErrorMessage(Object.values(updateDesignError)[0]);
        }
    }, [updateDesignError]);

	const onSubmit = useCallback((e) => {
		e.preventDefault();
		const target = document.getElementById("designForm");
		const formData = new FormData(target);

		formData.append("font", JSON.stringify(formDatas.value.font));
		formData.append("template", JSON.stringify(formDatas.value.template));
		formData.append("_method", "PUT");

		if (selectedBrand == "all"){
			alert("브랜드를 선택해주세요");
		}else{
			dispatch(updateDesignAction(selectedBrand, formData));
		}
	},[selectedBrand, formDatas]);

	const onChangeBrand = useCallback((e) => {
        if(window.location.pathname == "/design" && selectedBrand != "all" && selectedBrand != null){
            if(window.confirm("저장하지 않은 내용은 손실됩니다.\n브랜드를 변경하시겠습니까?")){
                setSelectedBrand(e.target.value);
            }
        }else{
            setSelectedBrand(e.target.value);
        }
    }, [selectedBrand]);

	const toggleMenu = () => {
		const btn = document.getElementById("sideNav");
		const main = document.getElementById("main");
		btn.classList.toggle('actBtn');
		main.classList.toggle('actMain');
	}
	
	return loadMeLoading ? null : loadMeDone && (
		<div> 
			{
				errorMessage && (
					<div className="uk-alert-danger uk-position-absolute uk-position-top uk-position-z-index-9999999"  style={{width:"239px",marginTop:"52px"}} data-uk-alert>
						<a
							className="uk-alert-close"
							data-uk-close
							onClick={() => setErrorMessage("")}
						/>
						<p>{errorMessage}</p>
					</div>
				)
			}
			<header id="top-head" className="uk-position-fixed uk-position-left-0">
				<div className="uk-container uk-container-expand uk-background-primary uk-padding-horizontal uk-padding-medium-horizontal@m">
					<nav className="uk-navbar">
						{
							selectedBrand === 'all' ? (
								<div className="uk-navbar-center">
									<span className="uk-navbar-item uk-text-white uk-text-1_2">디자인 템플릿 관리</span>
								</div>
							) : (
								<>
									<div className="uk-navbar-left">
										<div className="uk-navbar-item uk-padding-remove-horizontal uk-right">
											<UIKit.Button
												buttonOptions="uk-button uk-button-small uk-button-transparent uk-button-radius uk-margin-small-right"
												buttonName="설정 열기"
												onClickAction={toggleMenu}
											/>
										</div>
									</div>
									<div className="uk-navbar-center">
										<span className="uk-text-white uk-text-1_2">디자인 템플릿 관리</span>
									</div>
									<div className="uk-navbar-right">
										<div className="uk-navbar-item uk-padding-remove-horizontal uk-light">
											<select className="uk-select uk-height-30 uk-width-160" name="selectedBrand" value={selectedBrand} onChange={onChangeBrand}>
												<option value={'all'}>선택</option>
												{
													me && me.brand && me.brand.map(data => (
														<option key={data.seq} value={data.seq}>{data.name}</option>
													))
												}
											</select>
										</div>
									</div>
								</>
							)
						}
					</nav>
				</div>
			</header>
			{
				selectedBrand === 'all' ? (
					<div className="uk-position-relative">
						<h4 className="uk-position-center uk-margin-large-top">브랜드를 선택해 주세요.</h4>

						<div className="uk-position-center uk-margin-xlarge-top">
							<select className="uk-select uk-height-30 uk-width-160" name="selectedBrand" value={selectedBrand} onChange={onChangeBrand}>
								<option value={'all'}>선택</option>
								{
									me && me.brand && me.brand.map(data => (
										<option key={data.seq} value={data.seq}>{data.name}</option>
									))
								}
							</select>
						</div>
					</div>
				) : (
					loadDesignLoading ? (
						<Loader />
					) : (
						loadDesignDone && formDatas.value && (
						<div style={{backgroundColor: "#f6f6f8"}}>
							<div id="main" className="main uk-padding-small-top uk-padding-large-bottom" style={{marginTop:52}} data-uk-grid>
								<UIKit.Div classOptions={"uk-width-expand@m uk-padding-remove"}>
									<PreviewSetting
										data={formDatas.value}
										brandData={brand}
									/>
								</UIKit.Div>
							</div>
							
							<div id="sideNav" className="sidenav font-NanumSquareRound">
								<div className="uk-padding-small">
									<UIKit.Button
										buttonOptions="uk-button uk-button-small uk-button-green uk-button-radius uk-margin-small-right"
										buttonName="적용하기"
										onClickAction={onSubmit}
									/>
									<button className="uk-button uk-button-small uk-font-color-black closebtn" onClick={()=> toggleMenu()} type="button">&times;</button>
								</div>

								<form className="toggle-class" id="designForm" onSubmit={onSubmit}  ref={formRef}>
									<div className="uk-card uk-card-default uk-width-1-1">
										<div className="uk-card-header uk-padding-cardHeaderOption">
											<p className="uk-margin-remove-bottom uk-text-1_3 uk-text-center">홈페이지 기본 설정</p>
										</div>
										<div className="uk-card-body uk-padding-top" style={{padding: 10}}>
											<FontSetting 
												data={formDatas}
											/>
                      <TextSetting 
												data={formDatas}
											/>
											<IconSetting 
												data={formDatas}
											/>
											<BasicSetting 
												data={formDatas}
											/>
										</div>
									</div>

									<div className="uk-card uk-card-default uk-width-1-1 uk-margin-top">
										<div className="uk-card-header uk-padding-cardHeaderOption">
											<p className="uk-margin-remove-bottom uk-text-1_3 uk-text-center">헤더 설정</p>
										</div>
										<div className="uk-card-body uk-padding-top" style={{padding: 10}}>
											<HeaderSetting 
												data={formDatas}
											/>
										</div>
									</div>

									<div className="uk-card uk-card-default uk-width-1-1 uk-margin-top">
										<div className="uk-card-header uk-padding-cardHeaderOption">
											<p className="uk-margin-remove-bottom uk-text-1_3 uk-text-center">상품 리스트 페이지 설정</p>
										</div>
										<div className="uk-card-body uk-padding-top" style={{padding: 10}}>
											<ProductSetting 
												data={formDatas}
											/>
										</div>
									</div>

									<div className="uk-card uk-card-default uk-width-1-1 uk-margin-top">
										<div className="uk-card-header uk-padding-cardHeaderOption">
											<p className="uk-margin-remove-bottom uk-text-1_3 uk-text-center">상품 상세 페이지 설정</p>
										</div>
										<div className="uk-card-body uk-padding-top" style={{padding: 10}}>
											<div className="uk-margin-medium-bottom">
												<div className="uk-position-relative uk-margin-small-bottom">
													<h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">타입</span></h4>
												</div>
												<div>
													<select
														className={`uk-select`}
														name="template.productDetail.type"
														onChange={formDatas.onChange}
														value={formDatas.value.template.productDetail.type}
													>
														<option key={-1} value={""}>선택</option>
														<option key={1} value={"A"}>A</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						)
					)
				)
			}
		</div>
	);
};

export default Design;
