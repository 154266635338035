import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionLoader from '../../Widget/ActionLoader';
import { HashLink } from 'react-router-hash-link';

const Type2 = ({instance, isDispatch=false}) => {
    const dispatch = useDispatch();
    const { selectedBrand } = useSelector((state) => state.user);

    useEffect(() => {
        if(isDispatch){
            dispatch(instance.reducer.action("?"+instance.queryString));
        }
    }, [selectedBrand, instance.queryString]);

    return (
        instance.reducer.loading ? (
            // 로딩 중 처리 START
            <ActionLoader />
            // 로딩 중 처리 END
        ) : (
            // 로딩 후 처리 START
            instance.reducer.done ? (
                // 데이터 처리 성공 START
                instance.reducer.obj && instance.reducer.obj.data && instance.reducer.obj.data.length > 0 ? (
                    // 데이터 존재 START
                    <table className="uk-table uk-table-divider uk-table-hover uk-table-small uk-table-middle uk-text-0_8">
                        <thead className="uk-text-center uk-text-1_1">
                            <tr>
                                {instance.data.list2.element.col.map((colItem, colIndex) => (
                                    <th className="uk-table-shrink uk-text-nowrap" key={colIndex}>
                                        {colItem.header.map((dataItem, dataIndex) => (
                                            <span className={dataItem.class ? dataItem.class(colItem) : ""} key={dataIndex}>{dataItem.value(colItem)}</span>
                                        ))}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="uk-text-center">
                            {instance.reducer.obj.data.map((item, index) => (
                                <tr key={index}>
                                    {instance.data.list2.element.col.map((colItem, colIndex) => (
                                        <td className="uk-table-shrink uk-text-nowrap" key={colIndex}>
                                            {colItem.body.map((dataItem, dataIndex) => (
                                                <span className={dataItem.class ? dataItem.class(item) : ""} key={dataIndex}>{dataItem.value(item)}</span>
                                            ))}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        {instance.data && instance.data.list2 && instance.data.list2.option && instance.data.list2.option.isFooter && (
                            <tfoot className="uk-text-center uk-text-1_1">
                                <tr>
                                    {instance.data.list2.element.col.map((colItem, colIndex) => (
                                        <td className="uk-table-shrink uk-text-nowrap" key={colIndex}>
                                            {colItem.footer.map((dataItem, dataIndex) => (
                                                <span className={dataItem.class ? dataItem.class(colItem) : ""} key={dataIndex}>{dataItem.value(colItem)}</span>
                                            ))}
                                        </td>
                                    ))}
                                </tr>
                            </tfoot>
                        )}
                    </table>
                    // 데이터 존재 END
                ) : (
                    // 데이터 없음 START
                    <div className="uk-text-0_8 uk-text-center">
                        데이터가 없습니다.
                    </div>
                    // 데이터 없음 END
                )
                // 데이터 처리 성공 END
            ) : (
                // 데이터 처리 실패 START
                <div className="uk-text-0_8 uk-text-center">
                    데이터 조회가 실패하였습니다.
                </div>
                // 데이터 처리 실패 END
            )
            // 로딩 후 처리 END
        )
    )
};

export default Type2;
