import React, { useState, useCallback, useEffect } from 'react';
import reactCSS from 'reactcss'
import UIkit from 'uikit';
import Icon from '../Icon'
import _ from 'lodash';

const IconSetting = (props) => {
	const IconList = [
        "cart",
        "cart2",
        "cart3",
        "cart4",
        "login",
        "logout",
        "user",
        "user2",
        "camera",
        "lock",
        "heart",
        "star",
        "gift",
        "gift2",
        "diamond",
        "menuBar",
        "menuBar2",
        "close",
        "minus",
        "plus",
        "angleDown",
        "angleDown2",
        "angleUp",
        "angleUp2",
        "arrowLeft",
        "arrowLeft2",
        "arrowRight",
        "arrowRight2",
        "quoteRight",
        "comment",
        "desktop",
        "mobile",
    ];
    const [iconInputName, setIconInputName] = useState("");

    const handleClick = useCallback((name) => {
        setIconInputName(name);
		UIkit.modal("#iconModal").show();
	}, []);

    const handleChange = useCallback((icon) => {
        UIkit.modal("#iconModal").hide();
        props.data.onChangeNameSet(icon, iconInputName);
	}, [iconInputName]);

    const styles = reactCSS({
		'default': {
			IconBox: {
				border: "1px solid #ddd",
				borderRadius: "5%",
				cursor: "pointer",
			}
		},
	});

	return (
        <div className="uk-margin-medium-bottom">
            <div className="uk-position-relative uk-margin-small-bottom">
                <h4 className="uk-margin-remove-vertical uk-text-1_2 uk-heading-line"><span className="font-NanumSquareRound">기본아이콘</span></h4>
            </div>
            <div id="iconModal" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body font-NanumSquareRound" style={{width:400}}>
                    <div>
                        <p className="uk-text-1_1 uk-text-center">아이콘 선택</p>
                    </div>
                    <div className="uk-grid-column-small uk-grid-row-small uk-child-width-1-5 uk-text-center" data-uk-grid>
                        {
                            IconList.map((val, key) => (
                                <div key={key}>
                                    <div data-uk-tooltip={val} className={`uk-card uk-card-default uk-card-body uk-padding-small ${_.get(props.data.value, iconInputName) == val ? "uk-background-blue uk-font-color-blue" : ""}`} style={styles.IconBox} onClick={() => handleChange(val)}>
                                        <Icon name={val} size={25} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="uk-child-width-1-3 uk-text-center uk-grid-small" data-uk-grid>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.cart")}>
                        <Icon name={props.data.value.template.basic.icon.cart} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.cart"
                            value={props.data.value.template.basic.icon.cart}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">장바구니</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.login")}>
                        <Icon name={props.data.value.template.basic.icon.login} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.login"
                            value={props.data.value.template.basic.icon.login}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">로그인</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.logout")}>
                        <Icon name={props.data.value.template.basic.icon.logout} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.logout"
                            value={props.data.value.template.basic.icon.logout}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">로그아웃</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.mypage")}>
                        <Icon name={props.data.value.template.basic.icon.mypage} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.mypage"
                            value={props.data.value.template.basic.icon.mypage}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">마이페이지</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.menuBar")}>
                        <Icon name={props.data.value.template.basic.icon.menuBar} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.menuBar"
                            value={props.data.value.template.basic.icon.menuBar}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">모바일 메뉴 바</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.qnaSecreat")}>
                        <Icon name={props.data.value.template.basic.icon.qnaSecreat} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.qnaSecreat"
                            value={props.data.value.template.basic.icon.qnaSecreat}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">QNA 비밀글</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.photoReview")}>
                        <Icon name={props.data.value.template.basic.icon.photoReview} size={25} />
                        <input
                            type="hidden"
                            name="template.basic.icon.photoReview"
                            value={props.data.value.template.basic.icon.photoReview}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">포토구매평</span>
                    </div>
                </div>
                <div>
                    <div className="uk-pointer" onClick={() => handleClick("template.basic.icon.reviewScore")}>
                        <Icon name={props.data.value.template.basic.icon.reviewScore} size={25} color={props.data.value.template.basic.color.font.reviewScore} />
                        <input
                            type="hidden"
                            name="template.basic.icon.reviewScore"
                            value={props.data.value.template.basic.icon.reviewScore}
                        />
                        <span className="uk-display-block uk-margin-4-top uk-text-0_9">구매평 별점</span>
                    </div>
                </div>
            </div>
        </div>
	)
}
export default IconSetting;