import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_DEVSETTINGS_REQUEST,
    LOAD_DEVSETTINGS_SUCCESS,
    LOAD_DEVSETTINGS_FAILURE,
    DESTROY_DEVSETTING_REQUEST,
    DESTROY_DEVSETTING_SUCCESS,
    DESTROY_DEVSETTING_FAILURE,
    LOAD_DEVSETTING_REQUEST,
    LOAD_DEVSETTING_SUCCESS,
    LOAD_DEVSETTING_FAILURE,
    UPDATE_DEVSETTING_REQUEST,
    UPDATE_DEVSETTING_SUCCESS,
    UPDATE_DEVSETTING_FAILURE,
    CREATE_DEVSETTING_REQUEST,
    CREATE_DEVSETTING_SUCCESS,
    CREATE_DEVSETTING_FAILURE,
} from '../reducers/devSetting';

const getMe = state => {
    return state.user.me;
}

function loadDevSettingsAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `devSettings${query}`
  } else {
      endPoint = "devSettings";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadDevSettings(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadDevSettingsAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_DEVSETTINGS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_DEVSETTINGS_FAILURE,
      error: e.response.data,
    });
  }
}

function destroyDevSettingAPI(user, devSettingSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.delete(`/devSettings/${devSettingSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* destroyDevSetting(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(destroyDevSettingAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: DESTROY_DEVSETTING_SUCCESS,
      data: result.data,
      devSettingSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_DEVSETTING_FAILURE,
      error: e.response.data,
    });
  }
}

function loadDevSettingAPI(user, devSettingSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";

  return axios.get(`/devSettings/${devSettingSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadDevSetting(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadDevSettingAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_DEVSETTING_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_DEVSETTING_FAILURE,
      error: e.response.data,
    });
  }
}

function updateDevSettingAPI(user, devSettingSeq, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/devSettings/${devSettingSeq}`, formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateDevSetting(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateDevSettingAPI, user, action.devSettingSeq, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_DEVSETTING_SUCCESS,
      data: result.data,
      devSettingSeq: action.devSettingSeq,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_DEVSETTING_FAILURE,
      error: e.response.data,
    });
  }
}

function createDevSettingAPI(user, formData) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }

  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post('/devSettings', formData, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* createDevSetting(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(createDevSettingAPI, user, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: CREATE_DEVSETTING_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: CREATE_DEVSETTING_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadDevSettings() {
  yield takeEvery(LOAD_DEVSETTINGS_REQUEST, loadDevSettings);
}

function* watchDestroyDevSetting() {
  yield takeEvery(DESTROY_DEVSETTING_REQUEST, destroyDevSetting);
}

function* watchLoadDevSetting() {
  yield takeEvery(LOAD_DEVSETTING_REQUEST, loadDevSetting);
}

function* watchUpdateDevSetting() {
  yield takeLatest(UPDATE_DEVSETTING_REQUEST, updateDevSetting);
}

function* watchCreateDevSetting() {
  yield takeLatest(CREATE_DEVSETTING_REQUEST, createDevSetting);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadDevSettings),
      fork(watchDestroyDevSetting),
      fork(watchLoadDevSetting),
      fork(watchUpdateDevSetting),
      fork(watchCreateDevSetting),
  ]);
}
