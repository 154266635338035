import React from 'react';
import PropTypes from 'prop-types';

const OrderLogModal = ({ modalId, orderLogDetail }) => {
    return (
        <div id={modalId} data-uk-modal className="uk-modal-container">
            <div className="uk-modal-dialog">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <div className="uk-modal-body" data-uk-overflow-auto>
                    <div data-uk-grid>
                        <div className="uk-width-1-1">
                            <h2>결제정보</h2>
                        </div>
                        <div className="uk-width-1-1">
                            <table className="uk-table">
                                <thead className="uk-table uk-text-center">
                                    <tr >
                                        <th>이름</th>
                                        <th>이메일</th>
                                        <th>휴대폰 번호</th>
                                        <th>결제수단</th>
                                    </tr>
                                </thead>
                                <tbody className="uk-table uk-text-center">
                                    {
                                        orderLogDetail.buyer ? (
                                            <tr>
                                                <td>{orderLogDetail.buyer.name}</td>
                                                <td>{orderLogDetail.buyer.email}</td>
                                                <td>{orderLogDetail.buyer.phone}</td>
                                                    {
                                                        orderLogDetail.payLog && orderLogDetail.payLog.result ? (
                                                            <td>
                                                                {orderLogDetail.payLog.result.payMethodName}
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                {
                                                                    orderLogDetail.poId ? "네이버 페이" : "결제를 시도하지 않음"
                                                                }
                                                            </td>
                                                        )
                                                    }

                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>등록되지 않은 정보입니다.</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="uk-width-1-1">
                            <hr />
                        </div>

                        <div className="uk-width-1-1">
                            <h2>배송정보</h2>
                        </div>
                        <div className="uk-width-1-1">
                            <table className="uk-table">
                                <thead className="uk-table uk-text-center">
                                    <tr >
                                        <th>이름</th>
                                        <th>전화번호</th>
                                        <th>주소</th>
                                        <th>배송메시지</th>
                                    </tr>
                                </thead>
                                <tbody className="uk-table uk-text-center">
                                    {
                                        orderLogDetail.delivery ? (
                                            <tr>
                                                <td>{orderLogDetail.delivery.name}</td>
                                                <td>{orderLogDetail.delivery.phone}</td>
                                                <td>
                                                    {`${orderLogDetail.delivery.address} ${orderLogDetail.delivery.addressDetail} 우) ${orderLogDetail.delivery.postcode}`}
                                                </td>
                                                <td>{orderLogDetail.delivery.message}</td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>등록되지 않은 정보입니다.</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="uk-width-1-1">
                            <hr />
                        </div>

                        <div className="uk-width-1-1">
                            <h2>주문상세 정보</h2>
                        </div>
                        <div className="uk-width-1-1">
                            {
                                orderLogDetail.detail && orderLogDetail.detail.length > 0 ? (
                                    <table className="uk-table">
                                        <thead className="uk-table uk-text-center">
                                            <tr>
                                                <th>옵션명</th>
                                                <th>가격</th>
                                                <th>개수</th>
                                                <th>취소사유</th>
                                                <th>상태</th>
                                            </tr>
                                        </thead>
                                        <tbody className="uk-table uk-text-center">
                                            {
                                                orderLogDetail.detail.map((detail, index) => (
                                                    <tr key={index}>
                                                        <td>{detail.option_name}</td>
                                                        <td>{detail.price.toLocaleString()}</td>
                                                        <td>{detail.qty}개</td>
                                                        <td>{detail.cancelReason}</td>
                                                        <td>{detail.statusName}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                ) : (
                                    <span>존재하지않음</span>
                                )
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
};

OrderLogModal.propTypes = {
    modalId: PropTypes.string.isRequired,
    orderLogDetail: PropTypes.object.isRequired,
};

export default OrderLogModal;
