import React, { Fragment, useCallback } from 'react';
import UIKIT from 'uikit';
import PropTypes from 'prop-types';

import Editor from '../Editor';

const ExcelConvertItem = ({ review, reviewForm, index }) => {
    const onClickContent = useCallback(() => {
        UIKIT.modal(`#contentModal_${index}`).show();
    }, []);

    const onClickConfirm =useCallback(() => {
        UIKIT.modal(`#contentModal_${index}`).hide();
    }, []);

    return (
        <tr key={index}>
            <td>
                <span>{review.product.name}</span>
            </td>
            <td className="uk-pointer" onClick={onClickContent}>
                <div data-uk-grid>
                    <div className="uk-width-1-3">
                        {
                            review.preview && review.preview.image && (
                                <img src={review.preview.image} />
                            )
                        }
                    </div>
                    <div className="uk-width-2-3 uk-text-left">
                        {
                            review.preview && review.preview.content && (
                                <span>
                                    {review.preview.content.length > 35 ? `${review.preview.content.substring(0, 35)}...` : review.preview.content}
                                </span>
                            )
                        }
                    </div>
                </div>
            </td>
            <td>
                <span>{review.customWriter}</span>
            </td>
            <td>
                <span>{review.score}</span>
            </td>
            <Fragment>
                <div id={`contentModal_${index}`} className="uk-modal-container" data-uk-modal="stack: true">
                    <div
                        className="uk-modal-body uk-modal-dialog uk-width-800 uk-margin-auto-vertical uk-padding-xsmall-horizontal uk-padding-xsmall-top"
                    >
                        <div className="uk-padding-small uk-height-300">
                            <div className="uk-width-1-1">
                                <h4 className="uk-margin-small-bottom">내용수정</h4>
                            </div>
                            <div>
                                <Editor
                                    name="content"
                                    value={review.content ? review.content : ""}
                                    onChange={(content) => reviewForm.onChange(content, index)}
                                />
                            </div>
                            <div className="uk-width-1-1">
                                <p data-uk-margin className="uk-text-center uk-margin-remove-bottom uk-margin-medium-top">
                                    <button className="uk-button uk-button-primary" onClick={() => onClickConfirm(index)}>완료</button>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        </tr>
    )
}

ExcelConvertItem.propTypes = {
    review: PropTypes.object.isRequired,
    reviewForm: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default ExcelConvertItem;
