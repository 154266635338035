import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { destroyAdminAction, loadAdminsAction } from '../../../reducers/admin';

import { useFormData } from '../../../hook';
import useCheckboxForm from '../../../hook/UseCheckboxForm';

import UIKit from "../../../Components/UIKit";
import Pagination from "../Pagination";
import Loader  from '../../../Components/Widget/Loader';
import HeaderDetailOptions from '../HeaderDetailOptions';

const TableStyle = () => {
    const dispatch = useDispatch();
    const {
        admins,
        loadAdminsLoading,
        loadAdminsDone,
        loadAdminError,
        destroyAdminDone,
        destroyAdmin,
    } = useSelector((state) => state.admin);

    const [isShowDetailOptions, setIsShowDetailOptions] = useState(false);

    const checkboxForm =  useCheckboxForm(null);
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const sortForm = useFormData({
        column: defaultColumn ? defaultColumn :'regdate',
        sort: 'desc'
    });


    useEffect(() => {
        let query = location.search;
        dispatch(loadAdminsAction(query));
    }, [location, destroyAdminDone]);

    useEffect(() => {
        if(loadAdminError) {

        }
    }, [loadAdminError]);

    useEffect(() => {
        if(loadAdminsDone) {
            checkboxForm.init(admins);
        }
    }, [loadAdminsDone]);

    useEffect(() => {
        if(checkboxForm && checkboxForm.value) {
            if(checkboxForm.value.total.checked) {
                setIsShowDetailOptions(true);
                return;
            }

            const keys = Object.keys(checkboxForm.value);
            for(let key of keys) {
                if(key !== 'total' && key !== 'isShowDetailOption') {
                    if(checkboxForm.value[key].checked) {
                        setIsShowDetailOptions(true);
                        return;
                    }
                }
            }
            setIsShowDetailOptions(false);
        }
    }, [checkboxForm.value]);

    const onClickDetail = useCallback((seq) => {
        history.push(`/setting/admin/all/${seq}`);
    }, []);

    const onClickColumn = useCallback((clickedColumn) => {
        let sort = {};
        let sortQuery = '';
        if (sortForm.value.column !== clickedColumn) {
            sortForm.onChange({column : clickedColumn, sort: 'desc'});
            sort[clickedColumn] = 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        } else {
            sortForm.onChange({
                sort: sortForm.value.sort === 'desc' ? 'asc' : 'desc'
            });
            sort[clickedColumn] = sortForm.value.sort === 'desc' ? 'asc' : 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        }

        let nextQueryString = sortQuery;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.sort;
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${sortQuery}`;
            }
        }

        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
    }, [history, location, sortForm]);

    useEffect(() => {
        if(destroyAdminDone) {
            alert(destroyAdmin.data.msg);
            setIsShowDetailOptions(false);
            checkboxForm.remove(destroyAdmin.adminSeq);
        }
    }, [destroyAdminDone]);

    return loadAdminsLoading ? (
        <div>
            <Loader />
        </div>
    ) : loadAdminsDone && (
        <div>
            {
                checkboxForm && checkboxForm.value && isShowDetailOptions && (
                    <HeaderDetailOptions
                        checkboxForm={checkboxForm}
                        destroyModelAction={destroyAdminAction}
                    />
                )
            }

            <UIKit.Card
                title={""}
                cardOptions={"uk-card-default uk-card-style uk-margin-medium-top uk-text-0_8"}
                cardBodyOptions={"uk-padding-remove-top"}
            >

                    <div>
                        <span className="uk-padding-12-left uk-margin-top">
                            <span className="uk-hidden@m uk-padding-xsmall-right">
                                <input type="checkbox" className="uk-checkbox" />
                            </span>
                            <span>총 {admins.total}건</span>
                        </span>
                        <div className='uk-overflow-auto'>
                            <table className="uk-table uk-table-pointer uk-table-small uk-table-hover uk-table-divider uk-table-middle">
                                <thead className="uk-text-center">
                                    <tr>
                                        <th className="uk-table-shrink">
                                            {
                                                checkboxForm && checkboxForm.value && (
                                                    <input
                                                        className="uk-checkbox"
                                                        type="checkbox"
                                                        checked={checkboxForm.value.total.checked}
                                                        onChange={checkboxForm.onChangeCheckboxAll}
                                                    />
                                                )
                                            }

                                        </th>
                                        <th className="uk-pointer uk-table-expand">
                                            <span>이미지</span>
                                        </th>
                                        <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('id')}>
                                            <span>아이디</span>
                                            {sortForm.value.column === 'id' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                        </th>
                                        <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('name')}>
                                            <span>이름</span>
                                            {sortForm.value.column === 'name' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                        </th>
                                        <th className="uk-pointer uk-table-expand" onClick={() => onClickColumn('regdate')}>
                                            <span>등록일</span>
                                            {sortForm.value.column === 'regdate' && <i className={sortForm.value.sort === 'desc' ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} />}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        admins && admins.data.map((admin) => (
                                            <tr key={admin.seq}>
                                                <td>
                                                    {
                                                        checkboxForm && checkboxForm.value && checkboxForm.value[admin.seq] && (
                                                            <input
                                                                className="uk-checkbox"
                                                                type="checkbox"
                                                                checked={checkboxForm.value[admin.seq].checked}
                                                                onChange={() => checkboxForm.onChangeCheckbox(admin.seq)}
                                                            />
                                                        )
                                                    }


                                                </td>
                                                <td>
                                                    <img
                                                        className="list-img uk-preserve-width"
                                                        src={admin.img ? admin.img : require("../../../resource/uikit/avatar.svg")}
                                                        alt={"사진"}
                                                        width="60"
                                                    />
                                                </td>
                                                <td
                                                    className="uk-text-center"
                                                    onClick={() => onClickDetail(admin.seq)}
                                                >
                                                    {admin.id}
                                                </td>
                                                <td
                                                    className="uk-text-center"
                                                    onClick={() => onClickDetail(admin.seq)}
                                                >
                                                    {admin.name}
                                                </td>
                                                <td
                                                    className="uk-text-center"
                                                    onClick={() => onClickDetail(admin.seq)}
                                                >
                                                    {admin.regdate}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <Pagination
                                currentPage={admins.current_page}
                                pageLast={admins.last_page}
                            />
                        </div>
                    </div>
            </UIKit.Card>
        </div>
    )
};

export default TableStyle;
