import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_QNAS_REQUEST,
    LOAD_QNAS_SUCCESS,
    LOAD_QNAS_FAILURE,
    DESTROY_QNA_REQUEST,
    DESTROY_QNA_SUCCESS,
    DESTROY_QNA_FAILURE,
    ANSWER_QNA_REQUEST,
    ANSWER_QNA_SUCCESS,
    ANSWER_QNA_FAILURE,
    UPDATE_QNA_REQUEST,
    UPDATE_QNA_SUCCESS,
    UPDATE_QNA_FAILURE,
} from '../reducers/qna';

const getMe = state => {
    return state.user.me;
}

function loadQnasAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `qnas${query}`
  } else {
      endPoint = "qnas";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadQnas(action) {
  try {
    const user =  yield select(getMe);
    const result = yield call(loadQnasAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_QNAS_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_QNAS_FAILURE,
      error: e.response.data,
    });
  }
}

function loadDestroyQnaAPI(user, qnaSeq) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.delete(`qnas/${qnaSeq}`, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* loadDestroyQna(action) {
  try {
     const user =  yield select(getMe);
    const result = yield call(loadDestroyQnaAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: DESTROY_QNA_SUCCESS,
      data: result.data,
      qnaSeq: action.data
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: DESTROY_QNA_FAILURE,
      error: e.response.data,
    });
  }
}

function answerQnaAPI(user, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`qnas/answer`, data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* answerQna(action) {
  try {
    const user =  yield select(getMe);
    const result = yield call(answerQnaAPI, user, action.data);
    yield put({
      // put은 dispatch 동일
      type: ANSWER_QNA_SUCCESS,
      data: result.data,
      qnaSeq: action.data.seq,
      answer: action.data.answer,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: ANSWER_QNA_FAILURE,
      error: e.response.data,
    });
  }
}

function updateQnaAPI(user, qnaSeqs, data) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }


  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.post(`/reviews/${qnaSeqs}`, data, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function* updateQna(action) {
  try {
     const user =  yield select(getMe);
     const result = yield call(updateQnaAPI, user, action.qnaSeqs, action.data);
    if(result.data.token) {
        localStorage.setItem("token", result.data.token);
    }

    yield put({
      // put은 dispatch 동일
      type: UPDATE_QNA_SUCCESS,
      data: result.data,
      userSeq: action.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: UPDATE_QNA_FAILURE,
      error: e.response.data,
    });
  }
}


function* watchLoadQnas() {
  yield takeEvery(LOAD_QNAS_REQUEST, loadQnas);
}

function* watchDestroyQna() {
  yield takeEvery(DESTROY_QNA_REQUEST, loadDestroyQna);
}

function* watchAnswerQna() {
  yield takeEvery(ANSWER_QNA_REQUEST, answerQna);
}

function* watchUpdateQna() {
  yield takeEvery(UPDATE_QNA_REQUEST, updateQna);
}

export default function* userSaga() {
  yield all([
      fork(watchLoadQnas),
      fork(watchDestroyQna),
      fork(watchAnswerQna),
      fork(watchUpdateQna),
  ]);
}
