import React from "react";
import PropTypes from "prop-types";

const Button = ({
    buttonOptions = "uk-button-default",
    buttonName,
    onClickAction,
    toggle=''
}) => {
    return toggle ? (
        <button
            type="button"
            className={`uk-button ${buttonOptions}`}
            onClick={onClickAction}
            data-uk-toggle={toggle}
        >
            {buttonName}
        </button>
    ) : (
        <button
            type="button"
            className={`uk-button ${buttonOptions}`}
            onClick={onClickAction}
        >
            {buttonName}
        </button>
    )
};

Button.propTypes = {
    buttonOptions: PropTypes.string,
    buttonName: PropTypes.string,
    onClickAction: PropTypes.func
};
export default Button;
